import type { ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { IntlProvider } from 'react-intl';

import stripHtml from '~utils/stripHtml';

// this is super ugly, but works, it essentially renders the message jsx as
// a string and removes the html
export default function jsxToText(jsx: ReactNode) {
  return stripHtml(renderToStaticMarkup(<IntlProvider locale="en">{jsx}</IntlProvider>));
}
