import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import usePermissions from '~hooks/usePermissions';
import {
  selectAddonsIsPublishingEnabled,
  selectContext,
  selectCurrentPageId,
  selectDraftEntityStatusById,
} from '~store/selectors';
import { saveEntityWorker, startEditingEntity, stopEditingEntity } from '~store/thunks/entity';

export default function useEditModules() {
  const { canManageModules } = usePermissions();

  const dispatch = useAppDispatch();
  const context = useAppSelector(selectContext);
  const currentPageId = useAppSelector(selectCurrentPageId);
  const isPublishingEnabled = useAppSelector(selectAddonsIsPublishingEnabled);
  const entityStatus = useAppSelector(state =>
    isPublishingEnabled
      ? selectDraftEntityStatusById(state, currentPageId)
      : selectDraftEntityStatusById(state, currentPageId),
  );

  const isEditing = currentPageId === context;
  const isStable = entityStatus && (entityStatus.isComplete == null || entityStatus.isComplete === true);
  const isProcessing = !isStable;
  const [isSaving, setIsChangingModules] = useState(false);

  const prevIsEditingModules = usePrevious(isEditing);
  useEffect(() => {
    if (prevIsEditingModules !== undefined) {
      setIsChangingModules(isProcessing);
    }
  }, [isProcessing, prevIsEditingModules]);

  const onEdit = useCallback(() => {
    dispatch(startEditingEntity({ id: currentPageId }));
  }, [currentPageId, dispatch]);

  const onSave = useCallback(() => {
    setIsChangingModules(true);
    dispatch(saveEntityWorker({ entityId: currentPageId }));
  }, [currentPageId, dispatch]);

  const onDiscard = useCallback(() => {
    setIsChangingModules(true);
    dispatch(stopEditingEntity(currentPageId));
  }, [currentPageId, dispatch]);

  return useMemo(
    () => ({
      canEdit: canManageModules,
      onEdit,
      isEditing,
      isSaving,
      onSave,
      onDiscard,
    }),
    [canManageModules, onEdit, isEditing, isSaving, onSave, onDiscard],
  );
}
