import type { ActionCreator, ThunkAction } from '@reduxjs/toolkit';
import { AnyAction, configureStore } from '@reduxjs/toolkit';
import dynamicMiddlewares, { addMiddleware } from 'redux-dynamic-middlewares';
import { createLogger } from 'redux-logger';

import createGoogleAnalyticsGtagMiddleware from '~services/tracking/google-analytics-gtag';
import createGoogleTagManagerMiddleware from '~services/tracking/google-tag-manager';
import log from './services/log';
import createMatomoMiddleware from './services/tracking/matomo';
import {
  AddonsActions,
  ConfigActions,
  ContextActions,
  CookieModalActions,
  LoginActions,
  SessionActions,
  TranslationsActions,
  UserActions,
  WidgetsFooterActions,
  WidgetsSidebarActions,
} from './store/actions';
import { makeEnhancers } from './store/enhancer';
import { NotificationMiddleware } from './store/NotificationMiddleware';
import { PersistenceMiddleware, readStorage } from './store/PersistenceMiddleware';
import { makeRootReducer } from './store/reducer';
import { RoutingMiddleware } from './store/RoutingMiddleware';
import { routerMiddleware as RouterMiddleware } from './store/slices/router';
import { EBM_DEVMODE } from './env';

log('Creating store...');
const rootReducer = makeRootReducer();
const store = configureStore({
  reducer: rootReducer,
  devTools:
    process.env.NODE_ENV !== 'production'
      ? {
          maxAge: 500,
        }
      : false,
  preloadedState: window.__INITIAL_STATE__,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      // serializableCheck: isDev
      //   ? {
      //       ignoredActions: [
      //         ControlActions.setWorkableEntity.type,
      //         ControlActions.setWorkableEntityChange.type,
      //         'trigger/change/workable-entity',
      //       ],
      //     }
      //   : false,
    })
      .concat(PersistenceMiddleware.middleware)
      .concat(RouterMiddleware)
      .concat(RoutingMiddleware.middleware)
      .concat(NotificationMiddleware.middleware)
      .concat(dynamicMiddlewares),
  enhancers: makeEnhancers,
});

export function initStore(lang: string, siteInfo?: SiteInfoDto) {
  log('Initializing store...');

  // Enable Matomo Tracking by adding the matomo middleware
  const matomoSiteId = siteInfo?.configs?.matomoSiteId;
  const matomoUrl = siteInfo?.configs?.matomoUrl;
  if (matomoSiteId && matomoUrl) {
    const matomoMiddleware = createMatomoMiddleware(matomoUrl, matomoSiteId);
    if (typeof matomoMiddleware === 'function') {
      addMiddleware(matomoMiddleware);
    }
  }

  // only add google gtag/gtm tracking when we are on live or stage server
  // because google does not support private domains
  if (!EBM_DEVMODE && window.ebm?.env !== 'dev') {
    // if analytics id is not set do not include the middleware
    // otherwise include GTM or GTAG, depending on googleAnalyticsID
    const googleAnalyticsID = siteInfo?.configs?.googleAnalyticsID;
    if (googleAnalyticsID) {
      if (googleAnalyticsID.startsWith('GTM')) {
        const gtm = createGoogleTagManagerMiddleware(googleAnalyticsID);
        if (gtm) {
          addMiddleware(gtm);
        }
      } else {
        const gtag = createGoogleAnalyticsGtagMiddleware(googleAnalyticsID);
        if (gtag) {
          addMiddleware(gtag);
        }
      }
    }
  }

  // In Dev mode add logger
  if (EBM_DEVMODE) {
    const logger = createLogger({
      collapsed: (_getState, _action, logEntry) => !logEntry.error,
    });
    if (logger) {
      addMiddleware(logger);
    }
  }

  // Load from persist storage
  log('Restore previous session...');
  store.dispatch(SessionActions.load(readStorage()));

  if (siteInfo) {
    // Process 'siteinfo' API response
    log('Set initial application context...', siteInfo);
    const {
      configs,
      userInfo,
      loginInfo,
      cookieModal,
      widgetsFooter,
      widgetsSidebar,
      addons,
      iconLibrary,
      favourites,
      translations,
      ...context
    } = siteInfo;
    store.dispatch(ContextActions.init(context as ApplicationContext));
    store.dispatch(ConfigActions.set(configs));
    store.dispatch(UserActions.set(userInfo));
    store.dispatch(LoginActions.set(loginInfo));
    store.dispatch(CookieModalActions.set(cookieModal));
    store.dispatch(WidgetsFooterActions.set(widgetsFooter));
    store.dispatch(WidgetsSidebarActions.set(widgetsSidebar));
    store.dispatch(AddonsActions.set(addons));
    store.dispatch(AddonsActions.setIconLibraryConfig(iconLibrary));
    store.dispatch(AddonsActions.setFavouritesConfig(favourites));
    store.dispatch(TranslationsActions.set(translations));

    const langToSet = lang || configs.defaultLanguage;
    log('Set current app language...', {
      param: lang,
      default: configs.defaultLanguage,
      set: langToSet,
    });
    store.dispatch(ContextActions.setLanguage(langToSet));
  }

  return store;
}

export default function getStore() {
  return store;
}

// Get type for dispatch function (It is recommended to give the type a different name like AppDispatch to prevent confusion, as the type name Dispatch is usually overused.)
export type RootState = Resolve<ReturnType<typeof store.getState>>;
export type AppDispatch = typeof store.dispatch;
export type ThunkCreator<T = unknown> = ActionCreator<ThunkAction<T, RootState, unknown, AnyAction>>;
