import { EditorState } from 'draft-js';
import { ComponentType, MouseEvent, PureComponent } from 'react';

import { HeadlinesPicker } from './HeadlinesPicker';

export type HeadlinesButtonProps = {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  onOverrideContent: (content: ComponentType | undefined) => void;
  theme: {
    buttonWrapper: string;
    button: string;
    active: string;
  };
  getEditorState: () => EditorState;
};

export class HeadlinesButton extends PureComponent<HeadlinesButtonProps> {
  // When using a click event inside overridden content, mouse down
  // events needs to be prevented so the focus stays in the editor
  // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
  onMouseDown = (event: MouseEvent<HTMLDivElement>) => event.preventDefault();

  onClick = () => {
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    this.props.onOverrideContent(props => (
      <HeadlinesPicker
        h1={this.props.h1}
        h2={this.props.h2}
        h3={this.props.h3}
        h4={this.props.h4}
        h5={this.props.h5}
        h6={this.props.h6}
        {...props}
      />
    ));
  };

  isActive = () => {
    const editorState = this.props.getEditorState();
    const type = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType();
    return type ? type.startsWith('header-') : false;
  };

  showButton = () => this.props.h1 || this.props.h2 || this.props.h3 || this.props.h4 || this.props.h5 || this.props.h6;

  render() {
    if (!this.showButton()) return null;

    const isActive = this.isActive();

    return (
      <div onMouseDown={this.onMouseDown} className={this.props.theme.buttonWrapper}>
        <button
          onClick={this.onClick}
          type="button"
          className={`${this.props.theme.button} ${isActive ? `${this.props.theme.active}` : ''}`}
        >
          <span>H</span>
        </button>
      </div>
    );
  }
}
