import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => {
  return {
    data: {
      type: 'paragraph--sketch_library',
      id: createTemporaryEntityId(),
      attributes: {
        langcode: getEntityTemplateLang(),
        status: true,
        default_langcode: true,
        field_text: {
          value: null,
          format: 'rich_text',
        },
        field_hide_text: false,
        field_version: '1.0.0',
        field_title: {
          value: null,
          format: 'richt_text',
        },
      },
      relationships: {
        field_image: {
          data: null,
        },
      },
      links: {},
      log: [],
      permissions: {
        update: true,
        create: true,
        delete: true,
      },
    },
  };
};
