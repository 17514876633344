import { MouseEvent, PureComponent } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export interface ICookieHintProps {
  texts?: string[];
  buttons?: CookieModalButton[];
}

export class CookieHint extends PureComponent<ICookieHintProps> {
  onCookieUpdate = () => {
    cookies.removeChangeListener(this.onCookieUpdate);
    this.forceUpdate();
  };

  setCookie = (e: MouseEvent) => {
    e.preventDefault();
    cookies.addChangeListener(this.onCookieUpdate);

    // Date: Today + 14 Days (https://stackoverflow.com/a/7751977)
    const dateTodayPlus14Days = new Date(Date.now() + 12096e5);
    cookies.set('ebm_consent', dateTodayPlus14Days.toUTCString(), {
      path: '/',
      sameSite: 'lax',
      expires: dateTodayPlus14Days,
      secure: window.location.protocol === 'https:',
    });
  };

  setMatomoOptOut = (e: MouseEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;

    if (typeof window['_paq'] !== 'undefined') {
      window['_paq'].push([element.checked ? 'forgetUserOptOut' : 'optUserOut']);
      if (element.checked) {
        window['_paq'].push(['rememberConsentGiven']);
      }
      window['_paq'].push([
        function () {
          // isUserOptedOut() result:
          //   true -> user disagreed, mtm_consent_removed cookie present
          //   false -> user agreed, mtm_consent cookie present
          // eslint-disable-next-line
          // @ts-ignore
          element.checked = !this.isUserOptedOut();
        },
      ]);
    }
  };

  render() {
    const ebmCookie = cookies.get('ebm_consent');
    const { texts, buttons } = this.props;

    const isMatomoUsed = typeof window['_paq'] !== 'undefined';
    const matomoCookie = cookies.get('mtm_consent');

    const btn = buttons[0];
    const isActionBtn = btn.link === '#';
    const btnColor = isActionBtn ? 'c-button--dark' : 'c-button--light';
    const action = isActionBtn ? this.setCookie : undefined;

    if (!ebmCookie && texts[0]) {
      return (
        <div id="cookie-show">
          <div className="container px-0">
            <div className="row">
              <div className={isMatomoUsed && texts[1] ? 'col col-6' : 'col col-12'}>
                <p dangerouslySetInnerHTML={{ __html: texts[0] }}></p>
                <p>
                  {btn.link && btn.label && (
                    <a href={btn.link} onClick={action} className={'cookie-btn c-button ' + btnColor}>
                      {btn.label}
                    </a>
                  )}
                </p>
              </div>
              {isMatomoUsed && texts[1] ? (
                <div className="col col-6">
                  <p dangerouslySetInnerHTML={{ __html: texts[1] }}></p>
                  <p>
                    <input type="checkbox" id="optout" onClick={this.setMatomoOptOut} defaultChecked={!!matomoCookie} />
                    <label htmlFor="optout">
                      &nbsp;
                      <strong>{buttons[1].label}</strong>
                    </label>
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default CookieHint;
