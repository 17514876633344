/**
 * JiraRequestWidget component
 * Loads the Jira Helpdesk script and dispatches a DOMContentLoaded event when the script is loaded
 * Removes the script when the component is unmounted or when isEnabled is false and isLoggedIn is false.
 * Documentation of Jira Service Management - https://support.atlassian.com/jira-service-management-cloud/docs/collect-requests-from-anywhere-with-an-embeddable-widget/
 */

import { useEffect } from 'react';

import useAppSelector from '~hooks/useAppSelector';
import { selectAddonsJiraRequestWidget, selectUserIsLoggedIn } from '~store/selectors';

const JiraRequestWidget: React.FC = () => {
  const isLoggedIn = useAppSelector(selectUserIsLoggedIn);

  // Get the Jira Request Widget addon configuration from the Redux store
  const jiraRequestWidget = useAppSelector(selectAddonsJiraRequestWidget);
  // Check if the Jira Request Widget is enabled based on its status
  const isEnabled = Boolean(jiraRequestWidget.status);
  // Get the data-key attribute value from the Jira Request Widget configuration
  const dataKey = jiraRequestWidget['data-key'];
  // Get the src attribute value (script URL) from the Jira Request Widget configuration
  const src = jiraRequestWidget.src;
  // Get the data-base-url attribute value from the Jira Request Widget configuration
  const dataBaseUrl = jiraRequestWidget['data-base-url'];

  useEffect(() => {
    if (!isEnabled || !isLoggedIn) {
      return;
    }

    const jiraHelpdesk = (callback: () => void) => {
      const jhdScript = document.createElement('script') as HTMLScriptElement & {
        onreadystatechange?: () => void;
        readyState?: string;
      };
      jhdScript.type = 'text/javascript';
      jhdScript.setAttribute('data-jsd-embedded', '');
      jhdScript.setAttribute('data-key', dataKey);
      jhdScript.setAttribute('data-base-url', dataBaseUrl);
      jhdScript.src = src;

      const handleScriptLoad = () => {
        jhdScript.onreadystatechange = undefined;
        jhdScript.onload = null;
        callback();
      };

      if (jhdScript.readyState) {
        // old IE support
        jhdScript.onreadystatechange = () => {
          if (jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete') {
            handleScriptLoad();
          }
        };
      } else {
        // modern browsers
        jhdScript.onload = handleScriptLoad;
      }

      document.head.appendChild(jhdScript);
    };

    jiraHelpdesk(() => {
      const DOMContentLoaded_event = new Event('DOMContentLoaded', {
        bubbles: true,
        cancelable: true,
      });
      window.document.dispatchEvent(DOMContentLoaded_event);
    });
  }, [dataBaseUrl, dataKey, isEnabled, isLoggedIn, src]);

  return null;
};

export default JiraRequestWidget;
