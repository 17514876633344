import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => {
  return {
    data: {
      type: 'paragraph--ui_component',
      id: createTemporaryEntityId(),
      attributes: {
        langcode: getEntityTemplateLang(),
        status: true,
        default_langcode: true,
        field_title: { value: 'UI Component', format: 'rich_text' },
        field_hide_title: false,
        field_text: {
          value:
            'This is the description of an UI Component. Here you describe things like the usage of the UI Component.',
          format: 'rich_text',
        },
        field_hide_text: false,
        field_html_source_code: null,
        field_link_github: null,
        field_link_codepen: null,
        field_show_code_by_default: false,
        field_layout: 1,
      },
      relationships: {
        field_tags: {
          data: [],
        },
      },
      links: {},
      log: [],
      permissions: {
        update: true,
        create: true,
        delete: true,
      },
    },
  };
};
