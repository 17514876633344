import { ContentState } from 'draft-js';
import { FC, ReactNode } from 'react';

export interface ITextLinkProps {
  contentState: ContentState;
  entityKey: string;
  children: ReactNode[];
}

export const TextLink: FC<ITextLinkProps> = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
};

export default TextLink;
