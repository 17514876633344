import Radio from '~components/generic/elements/Radio';
import { SidebarContextSettingsFieldInputProps } from '~components/sidebar/context/SidebarContextSettingsField';

export const SidebarContextSettingsRadio = ({
  data,
  fieldDef,
  onChange,
  dependencyValue,
}: SidebarContextSettingsFieldInputProps) => {
  const fieldValues = !dependencyValue ? fieldDef.options.values : fieldDef.options.values[dependencyValue];
  return (
    <div className="c-settings__field">
      <Radio onChange={onChange} value={data} values={fieldValues} className="c-form-element--type-radio--dark" />
    </div>
  );
};

export default SidebarContextSettingsRadio;
