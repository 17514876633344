import { ContentBlock, ContentState, EditorState, SelectionState } from 'draft-js';

import { ModuleTypes } from '$const';
import getStore from '$store';
import { selectEntitiesState } from '~store/selectors/entities';
import { selectMenuElementById } from '~store/selectors/menu';
import { getFieldValue, makeSlugForAnchorEntity } from './entity-helpers';
import { getModuleUrlIdFromData } from './location-helpers';

export function selectAllContent(editorState: EditorState): EditorState {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();
  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: 0,
    focusKey: key,
    focusOffset: length,
  });

  return EditorState.acceptSelection(editorState, selection);
}

export function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

export interface ITargetModuleData {
  // the module identifier
  id?: string;
  type?: string;
}

export interface ITextLinkData extends ITargetModuleData {
  // either a url to an external site
  url?: string;
  useExternal?: boolean;
}

export function getTextLinkDataFromUrl(url: string): ITextLinkData {
  const externalLinkRegex = /^https?:\/\//;
  const isExternal = externalLinkRegex.test(url);
  if (!url) {
    return {};
  }
  const internalLinkMatcher = /(.*)#(.*)~(.*)--(.*)/.exec(url);
  if (!internalLinkMatcher || internalLinkMatcher.length < 4) {
    return {
      url,
    };
  }
  if (internalLinkMatcher) {
    const [_orig, _path, uuid, bundle, type] = internalLinkMatcher;
    return {
      id: uuid,
      type: `${bundle}--${type}`,
    };
  }
  // if is external link, just use the url
  if (isExternal) {
    return {
      url,
    };
  }
}

export function getUrlFromTextLinkData({ url, id, type, useExternal }: ITextLinkData): string {
  if (!id || !type || useExternal) {
    return url;
  }

  const moduleId = getModuleUrlIdFromData(id, type);

  if (type === ModuleTypes.internalLink) {
    const element = selectMenuElementById(getStore().getState(), id);
    return `${element ? element.alias : ''}#${moduleId}`;
  } else if (type === ModuleTypes.anchorLink) {
    const entities = selectEntitiesState(getStore().getState());
    const entity = entities[id];
    if (!entity) {
      throw new Error(`Entity with id ${id} not found!`);
    }
    const entityData = entity.data;

    return `${getFieldValue<string>(entityData, 'attributes.field_path')}/${makeSlugForAnchorEntity(
      entityData,
    )}#${moduleId}`;
  }
}
