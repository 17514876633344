import { useDebounce, useWindowSize } from 'react-use';

import useAppDispatch from '~hooks/useAppDispatch';
import { resize } from '~store/thunks/responsive';

export default function ResponsiveHelper() {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  useDebounce(
    () => {
      dispatch(resize());
    },
    200,
    [width],
  );

  return null;
}
