import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';

export const SecondaryColorSettings = {
  blockType: 'secondary-color',
  className: 'color--brand-second',
  element: 'span',
};

export const SecondaryColorButton = createBlockStyleButton({
  blockType: SecondaryColorSettings.blockType,
  children: <span>Secondary Color</span>,
});
