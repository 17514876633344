import clsx from 'clsx';

import noop from '~utils/noop';
import { MemoizedPictureDropzone } from './PictureDropzone';
import { PictureEditMenu } from './PictureEditMenu';
import { PictureUploadProgress } from './PictureUploadProgress';
import styles from './PictureStyles.module.css';

export type EditablePictureEditorProps = {
  hasImage?: boolean;
  uploadProgress?: number;
  onClearImage?: () => void;
  onUploadImage: (files: File[]) => void;
  acceptMimeTypes: string;
  downloadUrl?: string;
};

export const EditablePictureEditor = ({
  hasImage = false,
  uploadProgress,
  onClearImage = noop,
  onUploadImage,
  acceptMimeTypes,
  downloadUrl,
}: EditablePictureEditorProps) => {
  const isUploading = uploadProgress >= 0;
  return (
    <div className={clsx(styles.editRoot, 'o-text')}>
      {hasImage ? (
        <PictureEditMenu onClear={onClearImage} downloadUrl={downloadUrl} />
      ) : isUploading ? (
        <PictureUploadProgress progress={uploadProgress} />
      ) : (
        <MemoizedPictureDropzone onUpload={onUploadImage} acceptMimeTypes={acceptMimeTypes} />
      )}
    </div>
  );
};
