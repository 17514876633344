import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default function template() {
  const itemId = createTemporaryEntityId();
  return {
    data: {
      type: 'paragraph--teasers',
      id: createTemporaryEntityId(),
      attributes: {
        langcode: getEntityTemplateLang(),
        status: true,
        default_langcode: true,
        field_grid_type: 2,
        field_hide_title: false,
        field_hide_text: false,
        field_search_text: null,
        field_text: {
          value:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.',
          format: null,
        },
        field_title: { value: 'I am a Teaser Module', format: 'rich_text' },
      },
      relationships: {
        field_assets: {
          data: [],
        },
        field_tags: {
          data: [],
        },
        field_items: {
          data: [
            {
              type: 'paragraph--teasers_item',
              id: itemId,
            },
          ],
        },
      },
      links: {},
      log: [],
      permissions: {
        update: true,
        create: true,
        delete: true,
      },
    },
    included: [
      {
        type: 'paragraph--teasers_item',
        id: itemId,
        attributes: {
          langcode: getEntityTemplateLang(),
          status: true,
          default_langcode: true,
          field_hide_title: false,
          field_link_external: null,
          field_link_module: null,
          field_link_text: null,
          field_search_text: null,
          field_text: {
            value: 'Morbi +1 nam molestie before they sold out adipiscing vivamus',
            format: null,
          },
          field_title: { value: 'I am a Teaser Item', format: 'rich_text' },
        },
        relationships: {
          field_image: {},
          field_tags: {
            data: [],
          },
        },
        links: {},
        log: [],
        permissions: {
          update: true,
          create: true,
          delete: true,
        },
      },
    ],
  };
}
