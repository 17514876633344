import { Transition } from '@headlessui/react';
import { useState } from 'react';

import { MetaTag } from '~components/generic/MetaTag/MetaTag';
import useLabels from '~hooks/useLabels';
import useTopics from '~hooks/useTopics';
import { ChangeIndicator } from './ChangeIndicator';

type TagSelectorProps = {
  topics: Topic[];
  onChange: (topics: Topic[]) => void;
  saving: boolean;
};

export function TagSelector({ topics, onChange, saving }: TagSelectorProps) {
  const { rootIds, getTopicById } = useTopics();
  const { msgMetaTopics, hlMainTopic, hlSubTopic } = useLabels(['msgMetaTopics', 'hlMainTopic', 'hlSubTopic']);
  const [hasMainTopicChange, setHasMainTopicChange] = useState(false);
  const [hasSubTopicChange, setHasSubTopicChange] = useState(false);

  function handleMainTopicSelect(id: number) {
    onChange?.([getTopicById(id)]);
    setHasMainTopicChange(true);
    setHasSubTopicChange(true);
  }

  function handleSubTopicSelect(id: number) {
    onChange?.([topics[0], getTopicById(id)]);
    setHasSubTopicChange(true);
  }

  if (rootIds.length === 0) return null;

  const mainTopic = topics[0];
  const mainTopicId = mainTopic?.id;
  const subTopic = topics[1];
  const subTopicId = subTopic?.id;

  return (
    <>
      <div className="text-base"> {msgMetaTopics}</div>
      <div className="text-base font-bold mt-4 mb-2">
        <ChangeIndicator as="div" className="inline-block relative pr-1" hasChange={hasMainTopicChange}>
          {hlMainTopic}
        </ChangeIndicator>
      </div>
      <div className="-mx-1">
        {rootIds.map(topicId => {
          const topic = getTopicById(topicId);
          if (!saving) {
            return (
              <MetaTag
                key={topicId}
                color={topic.color}
                className="m-1"
                onClick={() => handleMainTopicSelect(topicId)}
                selected={mainTopicId === topicId}
                label={topic.label}
              />
            );
          }
          return (
            <MetaTag
              key={topicId}
              label={topic.label}
              color={topic.color}
              className="m-1"
              selected={mainTopicId === topicId}
              child
            />
          );
        })}
      </div>
      <Transition
        show={Boolean(mainTopic) && mainTopic.children?.length > 0}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="text-base font-bold mt-4 mb-2">
          <ChangeIndicator as="div" className="inline-block relative pr-1" hasChange={hasSubTopicChange}>
            {hlSubTopic}
          </ChangeIndicator>
        </div>
        <div className="-mx-1">
          {mainTopic?.children?.map(childTopicId => {
            const topic = getTopicById(childTopicId);
            if (!saving) {
              return (
                <MetaTag
                  key={childTopicId}
                  color={topic.color || mainTopic.color}
                  className="m-1"
                  onClick={() => handleSubTopicSelect(childTopicId)}
                  selected={subTopicId === childTopicId}
                  label={topic.label}
                  child
                />
              );
            }
            return (
              <MetaTag
                key={topic.label}
                color={topic.color || mainTopic.color}
                className="m-1"
                selected={subTopicId === childTopicId}
                label={topic.label}
                child
              />
            );
          })}
        </div>
      </Transition>
    </>
  );
}
