import Loader from '~components/generic/loader/Loader';
import { SidebarContextSettings } from '~components/sidebar/context/SidebarContextSettings';
import { IEntityComponentProps } from '~interfaces/props';

export const SidebarContext = (props: IEntityComponentProps) => {
  if (!props.data) {
    return <Loader theme="dark" />;
  }

  return (
    <div className="c-sidebar__context c-context u-margin-bottom-xl u-padding-top-xxl">
      <SidebarContextSettings {...props} />
    </div>
  );
};

export default SidebarContext;
