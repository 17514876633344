import { defaulted, Describe, is, nullable, optional, string, type } from 'superstruct';

export const FavouritesValidator: Describe<FavouritesDto> = defaulted(
  type({
    defaultLibraryLink: optional(nullable(string())),
  }),
  {
    defaultLibraryLink: '/brandguide/media-library',
  },
);
export const isFavourites = (obj: unknown) => is(obj, FavouritesValidator);
