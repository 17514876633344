import { isNodePageEntity } from './entity-helpers';

export function getPageByPath(entities: EntityInformationRecords, path: string): string {
  const result = Object.values(entities)
    .map(entity => entity.data)
    .filter(entity => entity)
    .filter(isNodePageEntity)
    .filter(entity => entity.attributes.field_path === path || entity.attributes.field_path === `/page${path}`)
    .map(entity => entity.id);

  if (!result.length) {
    return null;
  }

  return result[0];
}
