import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'paragraph--file',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      default_langcode: true,
      field_search_text: null,
      field_hide_title: false,
      field_text: {
        value: 'File description',
        format: 'rich_text',
      },
      field_title: { value: 'I am a Downloads Module', format: 'rich_text' },
    },
    relationships: {
      field_file: {},
    },
    links: {},
    log: [],
    permissions: { update: true, create: true, delete: true },
  },
});
