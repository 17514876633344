import styles from './PictureStyles.module.css';

export type PictureUploadProgressProps = { progress: number };

export const PictureUploadProgress = ({ progress }: PictureUploadProgressProps) => (
  <div className={styles.progressRoot}>
    <div className={styles.progressContent}>
      <div>
        <strong>Image is uploading …</strong>
      </div>
      <div>{progress} %</div>
      <div className="c-progress c-progress--thin">
        <div className="c-progress__bar" style={{ width: `${progress}%` }} />
      </div>
    </div>
  </div>
);
