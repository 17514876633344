import { useContext } from 'react';

import { PermissionsContext } from '~context/Permissions';

export default function usePermissions() {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(`usePermissions must be used within a PermissionsProvider`);
  }
  return context;
}
