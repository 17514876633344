import { createSelector } from '@reduxjs/toolkit';

import { lookupSelector } from '~store/lookupSelector';
import { stringSelector } from '~store/paramSelector';
import { MenuStatus, selectors as MenuSelectors } from '~store/slices/menu';
import { findMenuElementById, findMenuElementsByAlias } from '~utils/menu';
import { selectRootState } from './root';

export const selectMenuState = lookupSelector(selectRootState, state => state.menu);

// Slice: Menu Selectors
export const selectMenuElements = lookupSelector(selectMenuState, MenuSelectors.elements);
export const selectUnfilteredMenuElements = lookupSelector(selectMenuState, MenuSelectors.unfilteredElements);
export const selectMenuIsEditable = lookupSelector(selectMenuState, MenuSelectors.isEditable);
export const selectMenuStatus = lookupSelector(selectMenuState, MenuSelectors.status);

export const selectMenuStatusIsIdle = lookupSelector(selectMenuStatus, status => status === MenuStatus.Idle);
export const selectMenuStatusIsLoading = lookupSelector(selectMenuStatus, status => status === MenuStatus.Loading);
export const selectMenuStatusIsEditing = lookupSelector(selectMenuStatus, status => status === MenuStatus.Editing);
export const selectMenuStatusIsSaving = lookupSelector(selectMenuStatus, status => status === MenuStatus.Saving);
export const selectMenuStatusIsProcessing = createSelector(
  [selectMenuStatusIsLoading, selectMenuStatusIsSaving],
  (isLoading, isSaving) => isLoading || isSaving,
);

export const selectMenuElementById = createSelector([selectMenuElements, stringSelector], (data, id) =>
  findMenuElementById(data, id),
);

export const selectUnfilteredMenuElementById = createSelector(
  [selectUnfilteredMenuElements, stringSelector],
  (data, id) => findMenuElementById(data, id),
);

export const selectMenuElementsByName = createSelector([selectMenuElements, stringSelector], (data, name) =>
  findMenuElementsByAlias(data, name, false),
);

export const selectMenuElementByAlias = createSelector(
  [selectMenuElements, stringSelector],
  (data, name) => (findMenuElementsByAlias(data, name, true) || [])[0],
);
