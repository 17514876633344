import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type WidgetsFooterState = WidgetsFooter;

// Initial State
export const initialState: WidgetsFooterState = {} as WidgetsFooterState;

// Reducers
const set: CaseReducer<WidgetsFooterState, PayloadAction<WidgetsFooterDto>> = (_state, action) => action.payload;

// Slice
const slice = createSlice({
  name: 'widgetsFooter',
  initialState,
  reducers: {
    set,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: WidgetsFooterState) => state;
export const selectors = {
  self: selectSelf,
  content: (state: WidgetsFooterState) => state.content,
  footer: (state: WidgetsFooterState) => state.footer,
};
