import { URL_REPLACEMENT } from '$const';
import { PopupMenu, PopupMenuItemShape } from '~components/generic/popup-menu/PopupMenu';
import { DocumentUpdateIcon, MenuIcon, TeamIcon, UserIcon } from '~components/icons';
import useAppDispatch from '~hooks/useAppDispatch';
import usePermissions from '~hooks/usePermissions';
import { RouterActions } from '~store/actions';
import replaceUrl from '~utils/replaceUrl';

export type SettingsMenuProps = {
  onClose: () => void;
  pageX: number;
  pageY: number;
  userId: number;
};

export const SettingsMenu = (props: SettingsMenuProps) => {
  const dispatch = useAppDispatch();
  const handleNavigateToEditNavigation = () => dispatch(RouterActions.navigateToEditNavigation());
  const handleNavigateToEditUsergroups = () => dispatch(RouterActions.navigateToEditUsergroups());
  const handleNavigateToChangelog = () => dispatch(RouterActions.navigateToChangelog());
  const { hasManageMenuElementsPermission, hasManageUsergroupsPermission, hasViewChangelogPermission } =
    usePermissions();

  const items: PopupMenuItemShape[] = [
    {
      id: 'editUserProfile',
      label: 'btnEditUserProfile',
      onClick: () => {
        if (props.userId) {
          window.location.href = replaceUrl(`${URL_REPLACEMENT.BACKEND_URL}/user/${props.userId}/edit`);
        }
      },
      disabled: !props.userId,
      icon: <UserIcon />,
    },
  ];

  if (hasManageMenuElementsPermission) {
    items.push({
      id: 'editNavigation',
      label: 'btnEditNavigation',
      onClick: handleNavigateToEditNavigation,
      icon: <MenuIcon />,
    });
  }

  if (hasManageUsergroupsPermission) {
    items.push({
      id: 'editUsergroups',
      label: 'btnEditUsergroups',
      onClick: handleNavigateToEditUsergroups,
      icon: <TeamIcon />,
    });
  }

  if (hasViewChangelogPermission) {
    items.push({
      id: 'changelog',
      label: 'btnChangelog',
      onClick: handleNavigateToChangelog,
      icon: <DocumentUpdateIcon />,
    });
  }

  return <PopupMenu onClose={props.onClose} items={items} x={props.pageX} y={props.pageY} position="BOTTOM_LEFT" />;
};
