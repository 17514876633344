import { DependencyList, EffectCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useShallowCompareEffect } from 'react-use';

function isPrimitive(val: unknown) {
  return val !== Object(val);
}

function useCompareReplaceValuesEffect(effect: EffectCallback, deps: DependencyList) {
  const useEffectHook = deps.every(isPrimitive) ? useEffect : useShallowCompareEffect;
  useEffectHook(effect, deps);
}

export default function useLabels<T extends string>(
  labelIds: T[],
  replaceValues?: Record<string, string | number | boolean | Date>,
) {
  const intlHook = useIntl();
  const [ids] = useState(labelIds);
  const [values, setValues] = useState(replaceValues);

  useCompareReplaceValuesEffect(() => {
    setValues(replaceValues);
  }, [replaceValues]);

  return useMemo(() => {
    const labels = {} as Record<T, string>;
    for (const id of ids) {
      labels[id] = intlHook.formatMessage({ id, defaultMessage: id }, values);
    }
    return labels;
  }, [intlHook, ids, values]);
}

export function useLabel<T extends string>(
  labelId: T,
  replaceValues?: Record<string, string | number | boolean | Date>,
) {
  const intlHook = useIntl();
  const [id] = useState(labelId);
  const [values, setValues] = useState(replaceValues);

  useCompareReplaceValuesEffect(() => {
    setValues(replaceValues);
  }, [replaceValues]);

  return useMemo(() => intlHook.formatMessage({ id, defaultMessage: id }, values), [intlHook, id, values]);
}
