import { EditorState } from 'draft-js';
import { Component, PureComponent, ReactNode } from 'react';

import { TextLinkWidget } from '~components/generic/editable-field/TextLinkWidget';

export interface ILinkSettingsProps {
  onOverrideContent: (x?: ReactNode) => Component;
  getEditorState: () => EditorState;
  setEditorState: (e: EditorState) => void;
}

export class LinkSettings extends PureComponent<ILinkSettingsProps> {
  _ref: HTMLDivElement;

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = (_: MouseEvent) => {
    /* if (
      e.target &&
      (!this._ref.contains(e.target) || e.target === document.body)
    ) {
      this.props.onOverrideContent(undefined);
    } */
  };

  render() {
    const { onOverrideContent, setEditorState, getEditorState } = this.props;
    return (
      <div ref={e => (this._ref = e)}>
        <TextLinkWidget
          onOverrideContent={onOverrideContent}
          setEditorState={setEditorState}
          getEditorState={getEditorState}
        />
      </div>
    );
  }
}

export default LinkSettings;
