import { useCallback, useMemo, useState } from 'react';

import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import usePermissions from '~hooks/usePermissions';
import { selectCurrentPageId, selectEntityWithChangesById } from '~store/selectors';
import { createDraft, deleteDraft } from '~store/thunks/draft';

export default function useDraft() {
  const dispatch = useAppDispatch();
  const { canCreateDraft, canPublishDraft, canDeleteDraft } = usePermissions();
  const currentPageId = useAppSelector(selectCurrentPageId);
  const pageData = useAppSelector(state => selectEntityWithChangesById(state, currentPageId));
  const hasUnpublishedChanges = false; // ToDo: calculate if Page has unpublished changes
  const [isCreatingDraft, setIsCreatingDraft] = useState(false);
  const [isDeletingDraft, setIsDeletingDraft] = useState(false);

  const onCreateDraft = useCallback(async () => {
    setIsCreatingDraft(true);
    await dispatch(createDraft(pageData.attributes.nid)).unwrap();
    setIsCreatingDraft(false);
  }, [dispatch, pageData.attributes.nid]);

  const onDeleteDraft = useCallback(async () => {
    setIsDeletingDraft(true);
    await dispatch(deleteDraft({ nodeId: pageData.attributes.nid, uuid: pageData.attributes.uuid })).unwrap();
    setIsDeletingDraft(false);
  }, [dispatch, pageData.attributes.nid, pageData.attributes.uuid]);

  return useMemo(
    () => ({
      canCreateDraft,
      canPublishDraft,
      canDeleteDraft,
      isDeletingDraft,
      isCreatingDraft,
      onCreateDraft,
      onDeleteDraft,
      hasUnpublishedChanges,
    }),
    [
      canCreateDraft,
      canPublishDraft,
      canDeleteDraft,
      isDeletingDraft,
      isCreatingDraft,
      onCreateDraft,
      onDeleteDraft,
      hasUnpublishedChanges,
    ],
  );
}
