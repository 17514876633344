import { EBM_DEVMODE } from '$env';
import consoleLog from '~utils/consoleLog';

export default function log(message: string, args?: unknown, type: 'error' | 'warning' | 'log' = 'log') {
  (window as any).____LOG = (window as any).____LOG || [];

  (window as any).____LOG.push({
    time: new Date(),
    type,
    message,
    args,
  });

  if (EBM_DEVMODE) {
    consoleLog(message, args, type);
  }
}
