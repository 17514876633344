import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'paragraph--tabs_item',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      default_langcode: true,
      field_search_text: null,
      field_title: { value: 'Tab item', format: 'rich_text' },
    },
    relationships: {
      field_modules: {
        data: [],
      },
      field_tags: {
        data: [],
      },
    },
    permissions: {
      update: true,
      create: true,
      delete: true,
    },
  },
});
