import { FC } from 'react';

import SidebarContentContainer from '~components/sidebar/content/SidebarContentContainer';
import { SidebarFooter } from '~components/sidebar/SidebarFooter';

export const Sidebar: FC = () => {
  return (
    <div className="c-sidebar u-size-sm u-overflow-hidden">
      <SidebarContentContainer />
      <SidebarFooter />
    </div>
  );
};

export default Sidebar;
