function clearAndUpper(text: string) {
  return text.replace(/-|_|\s|\./, '').toUpperCase();
}

/**
 * Converts string to camelCase.
 * ```
 * 'some-module-element' => 'someModuleElement'
 * ```
 * @param text string - Text to convert
 */
export function toCamelCase(text: string) {
  return text.toLowerCase().replace(/(-\w|_\w|\s\w|\.\w)/g, clearAndUpper);
}

/**
 * Converts string to PascalCase.
 * ```
 * 'some-module-element' => 'SomeModuleElement'
 * ```
 * @param text string - Text to convert
 */
export function toPascalCase(text: string) {
  return text.toLowerCase().replace(/(^\w|-\w|_\w|\s\w|\.\w)/g, clearAndUpper);
}

/**
 * Capitalizes the first character of a given string.
 * ```
 * 'loremipsum' => 'Loremipsum'
 * ```
 * @param text string - Text to convert
 */
export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Tells if a string contains non-whitespace characters.
 * @param str String to check
 */
export function isNonEmptyString(str: string) {
  return str && str.trim().length > 0;
}

/**
 * Removes leading '/'
 * @param path string - Path to trim
 */
export function trimLeadingSlash(path: string) {
  if (!path) return path;
  if (path.startsWith('/page/')) {
    return path.substring(6);
  }
  if (path.startsWith('/')) {
    return path.substring(1);
  }
  return path;
}
