import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type CookieModalState = CookieModal;

// Initial State
export const initialState: CookieModalState = {
  cookieTexts: [],
  buttons: [],
};

// Reducers
const set: CaseReducer<CookieModalState, PayloadAction<CookieModalDto>> = (_state, action) => action.payload;

// Slice
const slice = createSlice({
  name: 'cookieModal',
  initialState,
  reducers: {
    set,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: CookieModalState) => state;
export const selectors = {
  self: selectSelf,
  texts: (state: CookieModalState) => state.cookieTexts,
  buttons: (state: CookieModalState) => state.buttons,
};
