import { ReactNode, useCallback } from 'react';
import { createContext, useMemo } from 'react';
import { useMap } from 'react-use';

import { Asset, AssetId } from '~modules/media-library/model/Asset';
import { mergeDeep } from '~services/immutable';

type PartialAsset = Resolve<DeepPartial<Asset> & Pick<Asset, 'id'>>;

export const AssetsContext = createContext<
  | {
      byId: Record<AssetId, Asset>;
      getAssetById: (id: AssetId) => Asset | undefined;
      hasAssetById: (id: AssetId) => boolean;
      setAsset: (asset: PartialAsset) => void;
      setAssets: (assets: PartialAsset[]) => void;
    }
  | undefined
>(undefined);

type AssetsProviderProps = {
  children: ReactNode;
};

export function AssetsProvider({ children }: AssetsProviderProps) {
  const [assetMap, { set, get, setAll }] = useMap<Record<AssetId, Asset>>({});

  const setAsset = useCallback(
    (asset: PartialAsset) => {
      set(asset.id, mergeDeep(get(asset.id), asset as Asset));
    },
    [get, set],
  );

  const setAssets = useCallback(
    (assets: PartialAsset[] = []) => {
      const newOrChangedAssets: typeof assetMap = {};
      for (const asset of assets) {
        newOrChangedAssets[asset.id] = mergeDeep(get(asset.id), asset as Asset);
      }
      setAll({
        ...assetMap,
        ...newOrChangedAssets,
      });
    },
    [assetMap, get, setAll],
  );

  const value = useMemo(() => {
    return {
      byId: assetMap,
      getAssetById: get,
      hasAssetById: (id: AssetId) => Boolean(get(id)),
      setAsset,
      setAssets,
    };
  }, [assetMap, get, setAssets, setAsset]);

  return <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>;
}
