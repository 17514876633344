import { URL_REPLACEMENT } from '$const';
import { get, patch, post } from '~services/api/methods';

export const getListOfOrdersFiltered = async queryString => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders?${queryString}`;
  return await get<OrderList>(requestUrl, { useBearerToken: true });
};

export const getListOfOrders = async (limit = 20, offset = 1) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders?limit=${limit}&offset=${offset}`;
  return await get<OrderList>(requestUrl, { useBearerToken: true });
};

// approve order request - branding
export const approveOrderRequestBranding = async (id: any) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/branding_approve/${id}`;
  return await patch(requestUrl, null, { useBearerToken: true });
};

// reject order request - branding
export const rejectOrderRequestBranding = async (id: string, message: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/branding_reject/${id}`;
  return await patch(requestUrl, { message }, { useBearerToken: true });
};

// approve order request - supplier
export const approveOrderRequestSupplier = async (id: any) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/supplier_approve/${id}`;
  return await patch(requestUrl, null, { useBearerToken: true });
};

// reject order request - supplier
export const rejectOrderRequestSupplier = async (id: string, message: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/supplier_reject/${id}`;
  return await patch(requestUrl, { message }, { useBearerToken: true });
};

// create order
export const createOrder = async (data: any) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders`;
  return await post(requestUrl, data, { useBearerToken: true });
};

// set responsible for order
export const setResponsibleForOrder = async (orderId: number, name: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/set_responsible/${orderId}`;
  return await patch(requestUrl, { name: name }, { useBearerToken: true });
};

// set order in progress
export const setOrderInProgress = async (orderId: number) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/set_in_progress/${orderId}`;
  return await patch(requestUrl, null, { useBearerToken: true });
};

// update order - set high priority
export const setOrderPriority = async (id: number, priority: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/set_priority/${id}`;
  return await patch(requestUrl, { priority }, { useBearerToken: true });
};
