import log from '~utils/consoleLog';
import { isNonEmptyString } from '~utils/string';

// DevMode
let _EBM_DEVMODE = /true/i.test(window?._env_?.EBM_DEVMODE) || false;
if (!_EBM_DEVMODE && window?.ebm?.env === 'dev') {
  _EBM_DEVMODE = true;
}
if (!_EBM_DEVMODE && process.env.NODE_ENV === 'development') {
  _EBM_DEVMODE = true;
}
export const EBM_DEVMODE = _EBM_DEVMODE;

// Base Path
export const BASE_PATH_FALLBACK = process.env.NODE_ENV_DIR || 'brandguide';
let _BASE_PATH = process.env.BASE_PATH || '';
if (!isNonEmptyString(_BASE_PATH)) {
  log(
    'BASE_PATH is invalid. Using fallback.',
    {
      BASE_PATH: `${_BASE_PATH}`,
      fallback: `${BASE_PATH_FALLBACK}`,
    },
    'warning',
  );
  _BASE_PATH = BASE_PATH_FALLBACK;
}
export const BASE_PATH = _BASE_PATH;

// Backend API URL
export const EBM_BACKEND_URL_FALLBACK = `//${window.location.hostname}`;
let _EBM_BACKEND_URL = window._env_?.EBM_BACKEND_URL || '';
if (!isNonEmptyString(_EBM_BACKEND_URL)) {
  log(
    'EBM_BACKEND_URL is invalid. Using fallback.',
    {
      EBM_BACKEND_URL: `${_EBM_BACKEND_URL}`,
      fallback: `${EBM_BACKEND_URL_FALLBACK}`,
    },
    'warning',
  );
  _EBM_BACKEND_URL = EBM_BACKEND_URL_FALLBACK;
}
export const EBM_BACKEND_URL = _EBM_BACKEND_URL;

// Theme
export const EBM_THEME_FALLBACK = 'ebm-themebase';
let _EBM_THEME = window._env_?.EBM_THEME || '';
if (!isNonEmptyString(_EBM_THEME)) {
  log(
    'EBM_THEME is invalid. Using fallback.',
    {
      EBM_THEME: `${_EBM_THEME}`,
      fallback: `${EBM_THEME_FALLBACK}`,
    },
    'warning',
  );
  _EBM_THEME = EBM_THEME_FALLBACK;
}
export const EBM_THEME = _EBM_THEME;

// Cookie Consent
export const EBM_INTERN_COOKIE_CONSENT = (window._env_?.EBM_COOKIE_CONSENT_ONETRUST_SITE_ID || false) === false;
