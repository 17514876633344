export default function whichOs() {
  let curOS = '';

  if (navigator.appVersion.indexOf('Win') !== -1) curOS = 'Windows';
  if (navigator.appVersion.indexOf('Mac') !== -1) curOS = 'MacOS';
  if (navigator.appVersion.indexOf('X11') !== -1) curOS = 'UNIX';
  if (navigator.appVersion.indexOf('Linux') !== -1) curOS = 'Linux';

  return curOS;
}
