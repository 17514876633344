import clsx from 'clsx';
import { useCallback } from 'react';

import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { StatusActions } from '~store/actions';
import { selectStatusNotification } from '~store/selectors';

export default function Notifications() {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectStatusNotification);
  const handleClearNotification = useCallback(() => {
    dispatch(StatusActions.unsetNotification());
  }, [dispatch]);

  if (!notification) return null;

  return (
    <div
      style={{ bottom: '10px', right: '10px', top: 'auto' }}
      className={clsx('c-alerts c-alerts--fixed', 'c-alerts--bottomright')}
    >
      <div
        className={clsx({
          'c-alert': true,
          'c-alert--closable': true,
          'c-alert--error': notification.type === 'error',
          'c-alert--success': notification.type === 'success',
          'c-alert--brand': notification.type === 'info',
        })}
      >
        <div className="c-alert__content">
          <button onClick={handleClearNotification} className="c-button c-button--close">
            ×
          </button>
          <p>{notification.message}</p>
        </div>
      </div>
    </div>
  );
}
