import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => {
  const itemId1 = createTemporaryEntityId();
  const itemId2 = createTemporaryEntityId();
  const itemId3 = createTemporaryEntityId();

  return {
    data: {
      type: 'paragraph--columns',
      id: createTemporaryEntityId(),
      attributes: {
        langcode: getEntityTemplateLang(),
        status: true,
        default_langcode: true,
        field_hide_title: false,
        field_title: { value: 'I am a Columns Module', format: 'rich_text' },
        field_num_columns: 2,
        field_layout: 1,
      },
      relationships: {
        field_assets: {
          data: [],
        },
        field_tags: {
          data: [],
        },
        field_modules: {
          data: [
            {
              type: 'paragraph--columns_item',
              id: itemId1,
            },
            {
              type: 'paragraph--columns_item',
              id: itemId2,
            },
            {
              type: 'paragraph--columns_item',
              id: itemId3,
            },
          ],
        },
      },
      permissions: {
        update: true,
        create: true,
        delete: true,
      },
    },
    included: [
      {
        type: 'paragraph--columns_item',
        id: itemId1,
        attributes: {
          langcode: getEntityTemplateLang(),
          status: true,
          default_langcode: true,
        },
        relationships: {
          field_modules: {
            data: [],
          },
        },
        links: {},
        permissions: {
          update: true,
          create: true,
          delete: true,
        },
      },
      {
        type: 'paragraph--columns_item',
        id: itemId2,
        attributes: {
          langcode: getEntityTemplateLang(),
          status: true,
          default_langcode: true,
        },
        relationships: {
          field_modules: {
            data: [],
          },
        },
        links: {},
        permissions: {
          update: true,
          create: true,
          delete: true,
        },
      },
      {
        type: 'paragraph--columns_item',
        id: itemId3,
        attributes: {
          langcode: getEntityTemplateLang(),
          status: true,
          default_langcode: true,
        },
        relationships: {
          field_modules: {
            data: [],
          },
        },
        links: {},
        permissions: {
          update: true,
          create: true,
          delete: true,
        },
      },
    ],
  };
};
