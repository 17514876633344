import { createSelector } from '@reduxjs/toolkit';

import { getEntityDefinition } from '~services/entity-definitions/helpers';
import { lookupSelector } from '~store/lookupSelector';
import { selectors as ControlSelectors } from '~store/slices/control';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectControlState = lookupSelector(selectRootState, state => state.control);

// Slice: Control Selectors
export const selectActiveAnchor = lookupSelector(selectControlState, ControlSelectors.activeAnchor);
export const selectActiveMenu = lookupSelector(selectControlState, ControlSelectors.activeMenu);
export const selectActiveMenuItem = lookupSelector(selectControlState, ControlSelectors.activeMenuItem);
export const selectAnchorLinkAutosuggestSearchResult = lookupSelector(
  selectControlState,
  ControlSelectors.anchorLinkAutosuggestSearchResult,
);
export const selectContext = lookupSelector(selectControlState, ControlSelectors.context);
export const selectDownloadFile = lookupSelector(selectControlState, ControlSelectors.downloadFile);
export const selectEmptyPageTemporaryEntityId = lookupSelector(
  selectControlState,
  ControlSelectors.emptyPageTemporaryEntityId,
);
export const selectSidebarIsCollapsed = lookupSelector(selectControlState, ControlSelectors.sidebarIsCollapsed);
export const selectTargetModule = lookupSelector(selectControlState, ControlSelectors.targetModule);
export const selectTemporaryEntityReferences = lookupSelector(
  selectControlState,
  ControlSelectors.temporaryEntityReferences,
);
export const selectPlayVideo = lookupSelector(selectControlState, ControlSelectors.playVideo);
export const selectWorkableEntities = lookupSelector(selectControlState, ControlSelectors.workableEntities);

export const selectContextEntityDefinition = createSelector(
  [selectWorkableEntities, selectContext],
  (workableEntities, context) => {
    const entity = workableEntities[context];
    if (!entity) {
      return null;
    }
    return getEntityDefinition(entity.type);
  },
);

export const selectWorkableEntitiesIds = lookupSelector(selectWorkableEntities, workableEntities =>
  Object.keys(workableEntities),
);
export const selectHasWorkableEntities = lookupSelector(selectWorkableEntitiesIds, ids => ids.length !== 0);

export const selectLockedPageId = lookupSelector(selectControlState, ControlSelectors.lockedPageId);
