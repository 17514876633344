import getStore from '$store';
import { getConfigApiPrefix, getConfigBackendUrl } from '~store/getter';
import { selectApiLanguagePrefix } from '~store/selectors';
import stripHtml from '~utils/stripHtml';

export const getApiBase = (checkBgApiSuffix?: boolean) => {
  const langPrefix = selectApiLanguagePrefix(getStore().getState());
  const apiPrefix = getConfigApiPrefix();
  const apiSuffix = checkBgApiSuffix && !apiPrefix.toString().endsWith('/bgapi') ? '/bgapi' : '';
  return `${getConfigBackendUrl()}/${langPrefix}${apiPrefix}${apiSuffix}`;
};

export class ApiErrorDescriptor {
  constructor(public error: string, public status: number, public url: string) {}
}

export function getResponseError(res): string {
  if (!res) {
    return 'API Error';
  }
  return (
    res.error ||
    res.message ||
    (res.errors && res.errors[0] ? `${res.errors[0].title as string}: ${stripHtml(res.errors[0].detail)}` : false) ||
    res
  );
}
