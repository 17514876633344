import { Checkbox } from '~components/generic/elements/Checkbox/Checkbox';
import { SidebarContextSettingsFieldInputProps } from '~components/sidebar/context/SidebarContextSettingsField';

export const SidebarContextSettingsCheckbox = ({ data, fieldDef, onChange }: SidebarContextSettingsFieldInputProps) => {
  const isDisabled = fieldDef.options.disabled;
  const disabledClass = isDisabled ? ' disabled' : '';
  return (
    <div className={'c-settings__field' + disabledClass} title={isDisabled ? 'This field is disabled by default!' : ''}>
      <Checkbox
        label={fieldDef.options.label}
        onChange={onChange}
        value={data || fieldDef.options.defaultValue}
        className="c-form-element--type-checkbox--dark"
        disabled={fieldDef.options.disabled}
      />
    </div>
  );
};

export default SidebarContextSettingsCheckbox;
