export default function operateOnItemOrArray<T, S>(
  value: T | T[],
  fn: (value: T, i?: number) => S | void,
  turnIntoArray?: boolean,
): unknown {
  const fnIsDefined = typeof fn === 'function';
  const valueIsArray = value && value.constructor === Array;

  // if value is null or undefined, simply return it
  if (value === undefined || value === null) {
    return value;
  }
  // if we said it should be an array, turn it into one
  if (turnIntoArray && !valueIsArray) {
    return fnIsDefined ? [value as T].map(fn) : [value];
  }
  // operate on the value/array and return
  return !fnIsDefined ? value : valueIsArray ? value.map(fn) : fn(value as T);
}
