/* eslint-disable @typescript-eslint/no-explicit-any */
import { produce } from 'immer';

import { isObject } from '~utils/object';

/**
 * Set value of an objects property by providing the path.
 * @param state Any object
 * @param path String representing the path
 * @param value New value to be set
 */
export function objectSet<T extends { [key: string]: any }>(state: T, path: string, value: unknown): T {
  const vector = path.split('.');
  const nextState = produce(state, draft => {
    let object: { [key: string]: any } = draft;
    const l = vector.length;
    for (let i = 0; i < l; i++) {
      const p = vector[i];

      // If isArray -> good
      // If isObject -> good
      // Else -> create based on next index
      // -- if no nextIndex exists -> make object
      // -- Else
      // -- -- if nextIndex is parsable to int -> create array
      // -- -- else -> create object
      if (!Array.isArray(object[p]) && !isObject(object[p])) {
        if (i + 1 >= l) {
          object[p] = {};
        } else {
          const nextP = vector[i + 1];
          const idx = parseInt(nextP, 10);
          if (isNaN(idx)) {
            object[p] = {};
          } else {
            object[p] = [];
          }
        }
      }
      if (i === vector.length - 1) {
        object[p] = value;
      }
      object = object[p];
    }

    const propName = vector.pop();
    if (propName) {
      object = vector.reduce((it, prop) => it[prop], draft);
      object[propName] = value;
    }
  });
  return nextState;
}

/**
 * Inserts an item into an array at a specified position (default position is at the end).
 * @param array Array of items
 * @param item Item to be inserted
 * @param position Position where to insert the item
 */
export function insertItemIntoArrayAtPosition<T>(array: T[], item: T, position?: number) {
  if (typeof position === 'undefined') {
    position = array.length;
  }
  if (position > array.length) {
    // log('Can not insert item at position. Appending it instead.', { array, item, position }, 'error');
    position = array.length;
  }
  return [...array.slice(0, position), item, ...array.slice(position)];
}

/**
 * Updates an item in an array at a specified position
 * @param array Array of items
 * @param item Partial item updates
 * @param position Position of the item to be updated
 */
export function updateItemInArrayAtPosition<T>(array: T[], item: Partial<T>, position: number) {
  return produce(array, draftArray => {
    draftArray[position] = {
      ...draftArray[position],
      ...item,
    };
  });
}

/**
 * Removes an item from an array at a specified position.
 * @param array Array of items
 * @param position Position of the item to be removed
 */
export function removeItemFromArrayAtPosition<T>(array: readonly T[], position: number): T[] {
  if (position >= array.length || position < 0) {
    // log('Can not remove item at position.', { array, position }, 'error');
    return array as T[];
  }
  return [...array.slice(0, position), ...array.slice(position + 1)];
}

export function removeItemFromPrimitiveArray<T>(array: readonly T[], item: T): T[] {
  const idx = array.findIndex(arrayItem => arrayItem === item);
  return removeItemFromArrayAtPosition(array, idx);
}

export function addItemToPrimitiveArray<T>(array: readonly T[], item: T): T[] {
  const idx = array.findIndex(arrayItem => arrayItem === item);
  if (idx === -1) {
    return [...array, item];
  }
  return array as T[];
}

export function toggleItemInPrimitiveArray<T>(array: readonly T[], item: T): T[] {
  const idx = array.findIndex(arrayItem => arrayItem === item);
  if (idx === -1) {
    return [...array, item];
  }
  return removeItemFromArrayAtPosition(array, idx);
}

/**
 * Move an item inside an array from one position to another position.
 * @param array Array of items
 * @param from Index of the item to move
 * @param to Position where the item should go to
 */
export function moveItemInsideArray<T>(array: readonly T[], from: number, to: number): T[] {
  const clone = [...array];
  Array.prototype.splice.call(clone, to, 0, Array.prototype.splice.call(clone, from, 1)[0]);
  return clone;
}

/**
 * Merges two objects and returns a new copy (immutable)
 * @param target Target object
 * @param source Source (partial) object
 */
export function mergeDeep<T>(target: T, source: Partial<T>): T {
  if (isObject(source) && (!target || target === null)) {
    return Object.assign({} as T, source);
  }
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

/**
 * (DEPRECATED) Use removeItemFromArrayAtPosition
 * @param arr
 * @param start
 * @param deleteCount
 * @param items
 */
export function splice<T>(arr: T[], start: number, deleteCount: number, ...items: T[]): T[] {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

/**
 * Updated an item within a deep nested tree-like data array
 * @param items Array with nested data structure
 * @param id
 * @param values Partial values to update
 */
export function deepUpdateItemById<T extends object & { id: string; children?: T[] }>(
  items: T[],
  id: string,
  values: Partial<T>,
) {
  return produce(items, draftArray => {
    return draftArray.map(item => {
      if (item.id === id) {
        return {
          ...item,
          ...values,
        };
      }
      if (item.children && item.children.length > 0) {
        return {
          ...item,
          children: deepUpdateItemById(item.children as T[], id, values),
        };
      }
      return item;
    });
  });
}
