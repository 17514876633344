import { ReactNode } from 'react';

import { CaretDownIcon } from '~components/icons';
import useLabels from '~hooks/useLabels';
import { TagPack } from './TagPack';

type MetaBarProps = {
  openable?: boolean;
  thumbnail?: ReactNode;
  title?: string;
  description?: string;
  topics?: Topic[];
};

export function MetaBar({ openable = false, thumbnail, title, description, topics }: MetaBarProps) {
  const { labelMetaDescription, labelMetaTitle } = useLabels(['labelMetaTitle', 'labelMetaDescription']);

  return (
    <div className="relative mt-3 mb-2">
      <div className="bg-white rounded shadow-md p-2 font-sans">
        <div className="items-center flex w-full">
          <div className="w-14 h-14">{thumbnail}</div>
          <div className="flex-1 mx-3 overflow-hidden leading-none text-base">
            <div className="font-bold truncate">
              {title || <span className="italic opacity-50">{labelMetaTitle}</span>}
            </div>
            <div className="truncate">
              {description || <span className="italic opacity-50">{labelMetaDescription}</span>}
            </div>
          </div>
          <div className="border-gray border-r border-solid mx-3 self-stretch" />
          <div className="w-1/3">
            <TagPack mainTopic={topics[0]} subTopic={topics[1]} />
          </div>
          {openable ? (
            <div className="w-8 h-8 cursor-pointer p-1">
              <CaretDownIcon />
            </div>
          ) : (
            <div className="w-8 h-8" />
          )}
        </div>
      </div>
    </div>
  );
}
