import { array, boolean, defaulted, Describe, is, nullable, number, optional, string, type } from 'superstruct';

import { SiteAccessesValidator } from './SiteAccess';

const PermissionsValidator: Describe<PermissionsDto> = defaulted(
  type({
    manage_user_groups: optional(boolean()),
    manage_menu_grants: optional(boolean()),
    manage_menu_elements: optional(boolean()),
    toggle_editmode: optional(boolean()),
    view_changelog: optional(boolean()),
    view_myfiles: optional(boolean()),
    manage_changelog: optional(boolean()),
    send_changelog_newsletter: optional(boolean()),
    manage_truck_labeling_orders: optional(boolean()),
    view_truck_labeling_orders: optional(boolean()),
    approve_new_truck_labeling_orders: optional(boolean()),
    pass_maintenance_mode: optional(boolean()),
  }),
  {
    manage_user_groups: false,
    manage_menu_grants: false,
    manage_menu_elements: false,
    toggle_editmode: false,
    view_changelog: false,
    manage_changelog: false,
    send_changelog_newsletter: false,
    view_truck_labeling_orders: false,
    approve_new_truck_labeling_orders: false,
    pass_maintenance_mode: false,
  },
);

export const UserInfoValidator: Describe<UserInfoDto> = type({
  country: optional(nullable(string())),
  current_language: string(),
  first_name: optional(string()),
  last_name: optional(string()),
  name: optional(string()),
  roles: optional(array(string())),
  tracking_company: optional(string()),
  tracking_division: optional(string()),
  tracking_name: optional(string()),
  tracking_roles: optional(array(string())),
  uid: number(),
  user_valid: defaulted(boolean(), true),
  uuid: optional(string()),
  permissions: PermissionsValidator,
  siteaccesses: optional(SiteAccessesValidator),
});
export const isUserInfo = (obj: unknown) => is(obj, UserInfoValidator);
