import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingBlocker from '~components/loadingBlocker/LoadingBlocker';
import { queries } from '~extension/favourite/api/Queries';
import useAppSelector from '~hooks/useAppSelector';
import { makeSlugForAnchorEntity } from '~services/entity-helpers';
import { selectActiveAnchor } from '~store/selectors/control';
import { selectCurrentPageAnchors, selectCurrentPageId } from '~store/selectors/custom';
import useFavouriteService from '../hook/useFavouriteService';
import FavouriteDto from '../model/FavouriteDto';

export function FavouriteButton() {
  const { removeContentFavourite, addContentFavourite } = useFavouriteService();

  const [favourites, setFavourites] = useState<FavouriteDto[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pageAnchors: Entity[] = useAppSelector(selectCurrentPageAnchors);
  const pageId = useAppSelector(selectCurrentPageId);
  const activeAnchorSlug = useAppSelector(selectActiveAnchor);

  useQuery({
    ...queries.favourites.getFavourites(),
    enabled: !Boolean(favourites),
    onSuccess(data) {
      setFavourites(data);
    },
  });

  const handleAddFavourite = useCallback(
    async (key: string) => {
      setIsLoading(true);

      const res = await addContentFavourite(key);
      if (res) setFavourites(res);

      setIsLoading(false);
    },
    [addContentFavourite],
  );

  const handleRemoveFavourite = useCallback(
    async (key: string) => {
      setIsLoading(true);

      const res = await removeContentFavourite(key);
      if (res) setFavourites(res);

      setIsLoading(false);
    },
    [removeContentFavourite],
  );

  const getActiveAnchor = () => {
    const list = pageAnchors.filter(item => {
      return activeAnchorSlug === makeSlugForAnchorEntity(item);
    });
    return list[0] ? (list[0] as Entity) : null;
  };
  const anchor = getActiveAnchor();

  const key = anchor ? `${anchor.id}~${anchor.type}` : `${pageId}~page`;

  const getIsFavourite = (key: string, favourites: FavouriteDto[]) => {
    for (let i = 0; i < favourites.length; i++) {
      if (favourites[i].key === key) {
        return true;
      }
    }
    return false;
  };
  const isFavourite = favourites !== null ? getIsFavourite(key, favourites) : false;

  return (
    <LoadingBlocker loading={isLoading}>
      {isFavourite && (
        <FormattedMessage id="btnFavouriteUnsetHint">
          {(msg: string) => (
            <button
              className="c-button c-button--light c-icon-custom"
              type="button"
              onClick={() => {
                handleRemoveFavourite(key);
              }}
              title={msg}
            >
              <i className="c-icon-custom u-vertical-middle c-icon-custom--favourite-filled c-icon-custom--vertical-centered"></i>
            </button>
          )}
        </FormattedMessage>
      )}
      {!isFavourite && (
        <FormattedMessage id="btnFavouriteSetHint">
          {(msg: string) => (
            <button
              className="c-button c-button--light c-icon-custom"
              type="button"
              onClick={() => {
                handleAddFavourite(key);
              }}
              title={msg}
            >
              <i className="c-icon-custom u-vertical-middle c-icon-custom--favourite-unfilled c-icon-custom--vertical-centered"></i>
            </button>
          )}
        </FormattedMessage>
      )}
    </LoadingBlocker>
  );
}
