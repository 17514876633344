import debounce from 'lodash.debounce';
import { Component, FormEvent } from 'react';
import Autosuggest, { ChangeEvent } from 'react-autosuggest';

import { ModuleTypes } from '$const';
import { convertToRawValue } from '~services/data-conversion';
import { getFieldValue } from '~services/entity-helpers';
import noop from '~utils/noop';
import stripHtml from '~utils/stripHtml';

export interface IAnchorLinkSelectorProps {
  label: string;
  onSelectedValueChange: (id: string, type: string) => void;
  onSearchChange: (value: string) => void;
  anchors: Entity[];
  links: SuggestionShape[];
  selectedValue: string;
}

export interface IAnchorLinkSelectorState {
  searchValue: string;
}

export type SuggestionShape = {
  type?: string;
  alias: string;
  id: string;
};
type SectionShape = {
  title: string;
  suggestions: SuggestionShape[];
};

class AnchorLinkSelector extends Component<IAnchorLinkSelectorProps, IAnchorLinkSelectorState> {
  debouncedOnChange: (val: string) => void;

  constructor(props: IAnchorLinkSelectorProps) {
    super(props);

    this.state = {
      searchValue: null,
    };

    this.debouncedOnChange = debounce(this._debouncedOnChange, 300);
  }

  onChange = (_event: FormEvent, params: ChangeEvent) => {
    this.setState({ searchValue: params.newValue }, () => {
      this.debouncedOnChange(params.newValue);
    });
  };

  _debouncedOnChange = (newValue: string) => {
    const { onSearchChange } = this.props;
    onSearchChange(newValue);
  };

  onSuggestionSelected = (_event: FormEvent, { suggestion }: { suggestion: SuggestionShape }) => {
    const { onSelectedValueChange } = this.props;
    onSelectedValueChange(suggestion.id, suggestion.type);
  };

  shouldRenderSuggestions = (value: string) => {
    const { selectedValue } = this.props;
    const { searchValue } = this.state;
    if (value) {
      const wasValChanged = value.trim() !== selectedValue && searchValue;
      return value.trim().length > 1 && !!wasValChanged;
    }
    return false;
  };

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      // eslint-disable-next-line
      autosuggest.input.addEventListener('focus', evt => {
        // eslint-disable-next-line
        evt.target.select();
      });
    }
  };

  renderSuggestion = (suggestion: SuggestionShape) => {
    return <div>{suggestion.alias}</div>;
  };

  getSuggestionValue = (suggestion: SuggestionShape) => {
    return suggestion.alias;
  };

  buildSuggestions = (anchors: Entity[], links: { matches: SuggestionShape[] } | SuggestionShape[]) => {
    const suggestions: SectionShape[] = [];
    const _links = Array.isArray(links) ? links : links.matches;

    suggestions.push({
      title: 'Links',
      suggestions: _links.map(linkItem => {
        const alias = linkItem.alias || '';
        return {
          type: ModuleTypes.internalLink,
          alias, // Remove '/page' from alias
          // alias: alias.startsWith('/page/') ? alias.substr(5) : alias, // Remove '/page' from alias
          id: linkItem.id,
        };
      }),
    });

    suggestions.push({
      title: 'Anchors',
      suggestions: anchors
        ? anchors.map(anchorItem => {
            const fieldPath = anchorItem.attributes.field_path || '';
            const path = fieldPath; // Remove '/page' from alias
            // const path = fieldPath.startsWith('/page/') ? fieldPath.substr(5) : fieldPath; // Remove '/page' from alias

            return {
              type: ModuleTypes.anchorLink,
              alias: `${path}/${stripHtml(
                convertToRawValue(getFieldValue<string>(anchorItem, 'attributes.field_title.value'), 'html'),
              )}`,
              id: anchorItem.id,
            };
          })
        : [],
    });

    return suggestions;
  };

  renderSectionTitle = (section: SectionShape) => {
    return (
      <strong className="section-title">
        <span>{section.title}</span>
        <span className="badge">{`(${section.suggestions.length})`}</span>
      </strong>
    );
  };

  getSectionSuggestions = (section: SectionShape) => {
    return section.suggestions;
  };

  render() {
    const { label, anchors, links, selectedValue } = this.props;
    const { searchValue } = this.state;
    const suggestions = this.buildSuggestions(anchors, links);

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Search links...',
      value: searchValue != null ? searchValue : selectedValue,
      onChange: this.onChange,
    };

    return (
      <div className="c-form-element c-form-element--type-textfield c-form-element--undefined">
        <label className="c-label">{label}</label>
        <Autosuggest
          suggestions={suggestions}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          highlightFirstSuggestion
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          ref={this.storeInputReference}
          focusInputOnSuggestionClick={false}
          alwaysRenderSuggestions={false}
          onSuggestionsFetchRequested={noop}
          onSuggestionsClearRequested={noop}
          multiSection
          renderSectionTitle={this.renderSectionTitle}
          getSectionSuggestions={this.getSectionSuggestions}
        />
      </div>
    );
  }
}

export default AnchorLinkSelector;
