import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';

import {
  commitUpload,
  deleteFile,
  downloadFile,
  downloadTemplateOverview,
  getUploadTicket,
  sendFile,
} from '~extension/truck-labeling/api/upload';
import { UploadType } from '~extension/truck-labeling/typings/Config';

//download pdf file
export const downloadPdfFile = createAsyncThunk('orders/download/file', async (data: any) => {
  try {
    const downloadRes = (await downloadFile(data.order_id, data.hash)) as { upload_ticket: string; upload_url: string };

    return {
      response: downloadRes,
    };
  } catch (e) {
    return e;
  }
});

//download template overview
export const downloadTemplate = createAsyncThunk('orders/download/template', async (queryString?: string) => {
  try {
    const response: any = await downloadTemplateOverview(queryString);

    const blob = new Blob([response], {
      type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, 'Truck_Labeling_Templates.xlsx');
  } catch (e) {
    return e;
  }
});

//upload ticket
export const uploadTicket = createAsyncThunk('orders/uploadFile', async (data: any, { dispatch }) => {
  try {
    const ticketRes = (await getUploadTicket()) as { upload_ticket: string; upload_url: string };
    const sendFileRes = (await sendFile(data.file, ticketRes?.upload_url, progress => {
      dispatch(setProgress({ type: data.type, progress }));
    })) as {
      binary: { fileName: string; status: boolean };
    };
    const res = await commitUpload(ticketRes?.upload_ticket, sendFileRes?.binary?.fileName, data.type);

    return {
      response: res,
      fileName: sendFileRes?.binary?.fileName,
      uploadStatus: sendFileRes?.binary?.status,
      uploadTicket: ticketRes?.upload_ticket,
      type: data.type,
    };
  } catch (e) {
    return e;
  }
});

export const removeFile = createAsyncThunk('remove/file', async (data: any) => {
  try {
    const res = await deleteFile(data.assetId, data.uploadTicket, data.type);
    return { response: res, type: data.type };
  } catch (e) {
    return e;
  }
});

const UploadSlice = createSlice({
  name: 'upload',
  initialState: {
    drawing: {
      upload_ticket: '',
      upload_url: '',
      asset_id: null,
      file_name: '',
      uploadStatus: '',
      error: '',
      progress: 0,
    },
    photo: {
      upload_ticket: '',
      upload_url: '',
      asset_id: null,
      file_name: '',
      uploadStatus: '',
      error: '',
      progress: 0,
    },
    upload_ticket: '',
    upload_url: '',
    asset_id: null,
    file_name: '',
    uploadStatus: '',
    error: '',
    progress: 0,
  },
  reducers: {
    removeAsset: state => {
      state.asset_id = null;
    },
    setProgress: (state, action: any) => {
      const { progress, type } = action.payload;

      if (type === 'drawing') {
        state.drawing = { ...state.drawing, progress };
        return;
      }
      if (type === 'photo') {
        state.photo = { ...state.photo, progress };
        return;
      }
      state.progress = progress;
    },
  },
  extraReducers: builder => {
    builder.addCase(uploadTicket.fulfilled, (state, action: any) => {
      const { uploadTicket, upload_url, fileName, uploadStatus, e, response, type } = action?.payload;

      if (type === 'drawing') {
        state.drawing = {
          ...state.drawing,
          upload_ticket: uploadTicket,
          upload_url,
          asset_id: response?.asset_id,
          file_name: fileName,
          uploadStatus,
          error: e?.error,
        };
        return;
      }
      if (type === 'photo') {
        state.photo = {
          ...state.photo,
          upload_ticket: uploadTicket,
          upload_url,
          asset_id: response?.asset_id,
          file_name: fileName,
          uploadStatus,
          error: e?.error,
        };
        return;
      }
      state.error = e?.error;
      state.upload_ticket = uploadTicket;
      state.upload_url = upload_url;
      state.asset_id = response?.asset_id;
      state.file_name = fileName;
      state.uploadStatus = uploadStatus;
      state.progress = null;
    });

    builder.addCase(removeFile.fulfilled, (state, action: any) => {
      if (action.payload.type === 'drawing') {
        state.drawing = { ...state.drawing, asset_id: null };
        return;
      }
      if (action.payload.type === 'photo') {
        state.photo = { ...state.photo, asset_id: null };
        return;
      }

      state.asset_id = null;
    });
  },
});

export const { removeAsset, setProgress } = UploadSlice.actions;

export default UploadSlice.reducer;

// convert list of file extensions (uploadType.allowed_formats) to struct needed by UploadInput
export const getAllowedMimeTypes = (uploadType: UploadType) => {
  const res: string[] = [];
  uploadType.allowed_formats.forEach((ext: string) => {
    // CAUTION: this works only for truck-labeling, where every file except zip or pdf must be an image!
    let mimeType =
      ext === 'pdf' || ext === 'zip' ? `application/${ext}` : ext === 'jpg' ? 'image/jpeg' : `image/${ext}`;
    if (ext === 'svg') {
      // there is no image/svg mime type...
      mimeType = `${mimeType}+xml`;
    }
    res[mimeType] = res[mimeType] !== undefined ? `${res[mimeType]}, .${ext}` : `.${ext}`;
  });
  return Object.entries(res).map(([key, value]) => [key, value]);
};
