import createSoftNewLinePlugin from '@jimmycode/draft-js-soft-newline-plugin';
import clsx from 'clsx';
import { DefaultDraftBlockRenderMap, DraftDecorator, EditorState } from 'draft-js';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import { BoldButton, ItalicButton, OrderedListButton, SupButton, UnorderedListButton } from 'draft-js-buttons';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import Editor from 'draft-js-plugins-editor';
import parse, { domToReact, Element } from 'html-react-parser';
import { Map } from 'immutable';
import { createRef, CSSProperties, Fragment, PureComponent, RefObject } from 'react';
import { Portal } from 'react-portal';

import { AlignTextCenterSettings } from '~components/generic/editable-field/AlignTextCenter/AlignTextCenter';
import { AlignTextLeftSettings } from '~components/generic/editable-field/AlignTextLeft/AlignTextLeft';
import { AlignTextRightSettings } from '~components/generic/editable-field/AlignTextRight/AlignTextRight';
import { LinkButton } from '~components/generic/editable-field/LinkButton';
import { TextLink } from '~components/generic/editable-field/TextLink';
import { XlButton, XlSettings } from '~components/generic/editable-field/XL';
import { Link } from '~components/generic/elements/Link/Link';
import { convertToRawValue } from '~services/data-conversion';
import { findLinkEntities, selectAllContent } from '~services/text-editor-utils';
import { AlignButton } from './AlignButton/AlignButton';
import { HeadlinesButton } from './HeadlinesButton';
import styles from './TextareaField.module.css';
import buttonStyles from '~components/generic/editable-field/buttonStyles.css';
import toolbarStyles from '~components/generic/editable-field/toolbarStyles.css';

type TextareaFieldProps = {
  value: string;
  onChange?: (value: string) => void;
  editing?: boolean;
  className?: string;
  style?: CSSProperties;
  autoFocus?: boolean;
  autoSelect?: boolean;
  excludeControls?: string[];
  controls?: string[];
};

const blockRenderMap = Map({
  unstyled: {
    element: 'div',
    aliasedElements: ['div'],
  },
});
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const toolbarControls = [
  'bold',
  'italic',
  'headlineOne',
  'headlineTwo',
  'headlineThree',
  'headlineFour',
  'headlineFive',
  'headlineSix',
  'xL',
  'unorderedList',
  'orderedList',
  'alignTextLeft',
  'alignTextCenter',
  'alignTextRight',
  'link',
  'sup',
];

const BlockSettings = [AlignTextCenterSettings, AlignTextLeftSettings, AlignTextRightSettings, XlSettings];

type TextareaFieldState = {
  editorState: EditorState;
  hasFocus: boolean;
  value: string;
};

export default class TextareaField extends PureComponent<TextareaFieldProps, TextareaFieldState> {
  editor: Editor;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inlineToolbarPlugin?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blockBreakoutPlugin?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  softNewLinePlugin?: any;
  static defaultProps: Partial<TextareaFieldProps> = {
    editing: false,
    controls: toolbarControls,
  };
  decorators: DraftDecorator[];
  tagRef: RefObject<HTMLElement>;

  constructor(props: TextareaFieldProps) {
    super(props);
    this.decorators = [
      {
        strategy: findLinkEntities,
        component: TextLink,
      },
    ];

    let editorState: EditorState;
    if (props.value) {
      if (typeof props.value === 'string') {
        editorState = EditorState.createWithContent(stateFromHTML(props.value));
      }
    } else {
      editorState = EditorState.createEmpty();
    }

    if (props.autoSelect) {
      editorState = selectAllContent(editorState);
    }

    this.state = {
      editorState,
      hasFocus: false,
      value: props.value,
    };

    this.inlineToolbarPlugin = createInlineToolbarPlugin({
      theme: { buttonStyles, toolbarStyles },
    });
    this.blockBreakoutPlugin = createBlockBreakoutPlugin();
    this.softNewLinePlugin = createSoftNewLinePlugin();

    this.tagRef = createRef();
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus && this.editor) {
      this.editor.focus();
    }
  }

  static getDerivedStateFromProps(props: TextareaFieldProps, state: TextareaFieldState): TextareaFieldState {
    if (props.value !== state.value) {
      return {
        ...state,
        value: props.value,
        editorState: EditorState.push(state.editorState, stateFromHTML(props.value), 'insert-characters'),
      };
    }
    return null;
  }

  blockStyleFn = contentBlock => {
    const type = contentBlock.getType();
    return BlockSettings.find(s => s.blockType === type)?.className;
  };

  calculatePortalPositionerStyle = (): CSSProperties => {
    if (this.tagRef.current) {
      const tagRect = this.tagRef.current.getBoundingClientRect();
      return {
        display: 'block',
        position: 'absolute',
        top: tagRect.top,
        left: tagRect.left,
      };
    }
    return { display: 'none' };
  };

  handleChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  handleFocus = () => {
    this.setState({ hasFocus: true });
  };

  handleBlur = () => {
    this.setState({ hasFocus: false });
    if (this.props.onChange) {
      this.props.onChange(stateToHTML(this.state.editorState.getCurrentContent()));
    }
  };

  render() {
    const { editing, className, style, value } = this.props;
    const DynamicTag = 'div' as keyof JSX.IntrinsicElements;
    const classes = clsx(
      'o-field-text relative',
      styles.base,
      editing && styles.editing,
      this.state.hasFocus && styles.focus,
      className,
    );

    // display editor
    if (editing) {
      const { InlineToolbar } = this.inlineToolbarPlugin;
      const isHtml = true;
      const excludeControls = this.props.excludeControls || [];
      const includedControls = this.props.controls.filter(control => excludeControls.indexOf(control) === -1);
      const portalPositionerStyle = this.calculatePortalPositionerStyle();

      return (
        <DynamicTag style={style} className={classes} ref={this.tagRef}>
          <Editor
            blockRenderMap={extendedBlockRenderMap}
            ref={element => {
              this.editor = element;
            }}
            decorators={this.decorators}
            editorState={this.state.editorState}
            plugins={isHtml ? [this.inlineToolbarPlugin, this.blockBreakoutPlugin, this.softNewLinePlugin] : []}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            blockStyleFn={this.blockStyleFn}
            customStyleMap={{
              BOLD: { fontWeight: '600' as 'bolder' },
              SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' },
            }}
          />
          {isHtml && includedControls.length > 0 && (
            <Portal>
              <div style={portalPositionerStyle}>
                <InlineToolbar>
                  {externalProps => (
                    <Fragment>
                      {includedControls.includes('bold') && <BoldButton {...externalProps} />}
                      {includedControls.includes('italic') && <ItalicButton {...externalProps} />}
                      <HeadlinesButton
                        h1={includedControls.includes('headlineOne')}
                        h2={includedControls.includes('headlineTwo')}
                        h3={includedControls.includes('headlineThree')}
                        h4={includedControls.includes('headlineFour')}
                        h5={includedControls.includes('headlineFive')}
                        h6={includedControls.includes('headlineSix')}
                        {...externalProps}
                      />
                      {includedControls.includes('xL') && <XlButton {...externalProps} />}
                      {includedControls.includes('unorderedList') && <UnorderedListButton {...externalProps} />}
                      {includedControls.includes('orderedList') && <OrderedListButton {...externalProps} />}
                      {(includedControls.includes('alignTextLeft') ||
                        includedControls.includes('alignTextRight') ||
                        includedControls.includes('alignTextCenter')) && <AlignButton {...externalProps} />}
                      {includedControls.includes('link') && <LinkButton {...externalProps} />}
                      {includedControls.includes('sup') && <SupButton {...externalProps} />}
                    </Fragment>
                  )}
                </InlineToolbar>
              </div>
            </Portal>
          )}
        </DynamicTag>
      );
    }

    const cleanData = convertToRawValue<string>(value, 'html') || '';
    if (typeof cleanData !== 'string' && cleanData === null) {
      console.log('CleanData is NOT a string: %s', typeof cleanData);
      console.log(cleanData);
    }

    // render text view
    return (
      <DynamicTag style={style} className={classes}>
        {/*
        Use ReactHtmlParser to transform Html string into react components,
        use the transform function to transform internal links (a tags with
        data-link internal attribute) to <Link> components
      */}
        {parse(cleanData, {
          replace: domNode => {
            if (domNode instanceof Element) {
              const { type, name, attribs, children } = domNode;
              if (type !== 'tag' || name !== 'a') {
                return;
              }
              const { href } = attribs;
              if (href) {
                const [path, id] = href.indexOf('/#/') === -1 && href.indexOf('~') >= 1 ? href.split('#') : [href, ''];
                return (
                  <Link id={id} url={!id && path}>
                    {domToReact(children)}
                  </Link>
                );
              }
            }
            return;
          },
        })}
      </DynamicTag>
    );
  }
}
