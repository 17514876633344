import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  type: 'file--file',
  id: createTemporaryEntityId(),
  attributes: {
    langcode: getEntityTemplateLang(),
    filename: 'christian-martin-437189_0.jpg',
    uri: 'public://christian-martin-437189_0.jpg',
    filemime: 'image/jpeg',
    filesize: 2602232,
    status: true,
    created: 1510667671,
    changed: 1510667676,
    url: '/sites/default/files/christian-martin-437189_0.jpg',
  },
  relationships: {},
  links: {
    self: 'http://test.vm216.hosting.1xinternet.de/api/file/file/a7b43744-18e2-4065-8e6e-e6d231bef9bf',
  },
  meta: {
    derivatives: {
      grid_1_1:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_1_1/public/christian-martin-437189_0.jpg?itok=kW1T9DgS&h=d881dca2',
      grid_1_1_screen_lg:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_1_1_screen_lg/public/christian-martin-437189_0.jpg?itok=RwHviJxZ&h=d881dca2',
      grid_1_1_screen_md:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_1_1_screen_md/public/christian-martin-437189_0.jpg?itok=mheqWZoC&h=d881dca2',
      grid_1_1_screen_sm:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_1_1_screen_sm/public/christian-martin-437189_0.jpg?itok=rzVxBE27&h=d881dca2',
      grid_1_1_screen_xl:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_1_1_screen_xl/public/christian-martin-437189_0.jpg?itok=AS9t8tMV&h=d881dca2',
      grid_1_1_screen_xs:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_1_1_screen_xs/public/christian-martin-437189_0.jpg?itok=67-Kaild&h=d881dca2',
      grid_2_1:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_2_1/public/christian-martin-437189_0.jpg?itok=_40rF6Fn&h=bfd98010',
      grid_2_1_screen_lg:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_2_1_screen_lg/public/christian-martin-437189_0.jpg?itok=a1tIiiGb&h=bfd98010',
      grid_2_1_screen_md:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_2_1_screen_md/public/christian-martin-437189_0.jpg?itok=O_BbKcXy&h=bfd98010',
      grid_2_1_screen_sm:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_2_1_screen_sm/public/christian-martin-437189_0.jpg?itok=nO1konkz&h=bfd98010',
      grid_2_1_screen_xl:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_2_1_screen_xl/public/christian-martin-437189_0.jpg?itok=l-q1F-KE&h=bfd98010',
      grid_2_1_screen_xs:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_2_1_screen_xs/public/christian-martin-437189_0.jpg?itok=cdfEvQjD&h=bfd98010',
      grid_3_4:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_3_4/public/christian-martin-437189_0.jpg?itok=9Np_y8yj&h=97a8d78a',
      grid_3_4_screen_lg:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_3_4_screen_lg/public/christian-martin-437189_0.jpg?itok=K8PQUcKk&h=97a8d78a',
      grid_3_4_screen_md:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_3_4_screen_md/public/christian-martin-437189_0.jpg?itok=p0aFYV87&h=97a8d78a',
      grid_3_4_screen_sm:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_3_4_screen_sm/public/christian-martin-437189_0.jpg?itok=t99VuKOH&h=97a8d78a',
      grid_3_4_screen_xl:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_3_4_screen_xl/public/christian-martin-437189_0.jpg?itok=yWU9pnhJ&h=97a8d78a',
      grid_3_4_screen_xs:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_3_4_screen_xs/public/christian-martin-437189_0.jpg?itok=Cun2opOh&h=97a8d78a',
      grid_16_9:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_16_9/public/christian-martin-437189_0.jpg?itok=HvQvNGU2&h=e781b90b',
      grid_16_9_screen_lg:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_16_9_screen_lg/public/christian-martin-437189_0.jpg?itok=SxD8r1Pd&h=e781b90b',
      grid_16_9_screen_md:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_16_9_screen_md/public/christian-martin-437189_0.jpg?itok=uKQs9bR-&h=e781b90b',
      grid_16_9_screen_sm:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_16_9_screen_sm/public/christian-martin-437189_0.jpg?itok=sWnqkkBg&h=e781b90b',
      grid_16_9_screen_xl:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_16_9_screen_xl/public/christian-martin-437189_0.jpg?itok=RhkmYIw7&h=e781b90b',
      grid_16_9_screen_xs:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_16_9_screen_xs/public/christian-martin-437189_0.jpg?itok=_32tX7Yg&h=e781b90b',
      grid_4_3:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_4_3/public/christian-martin-437189_0.jpg?itok=v6vB6dS9&h=f5b7b13f',
      grid_4_3_screen_lg:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_4_3_screen_lg/public/christian-martin-437189_0.jpg?itok=nLIqWjDR&h=f5b7b13f',
      grid_4_3_screen_md:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_4_3_screen_md/public/christian-martin-437189_0.jpg?itok=1FWpIJ1K&h=f5b7b13f',
      grid_4_3_screen_sm:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_4_3_screen_sm/public/christian-martin-437189_0.jpg?itok=I2CAXuiZ&h=f5b7b13f',
      grid_4_3_screen_xl:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_4_3_screen_xl/public/christian-martin-437189_0.jpg?itok=0BMakECC&h=f5b7b13f',
      grid_4_3_screen_xs:
        'http://test.vm216.hosting.1xinternet.de/sites/default/files/styles/grid_4_3_screen_xs/public/christian-martin-437189_0.jpg?itok=N1uBHFyW&h=f5b7b13f',
    },
  },
});
