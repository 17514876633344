import clsx from 'clsx';
import { MouseEvent, ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { useClickAway } from 'react-use';

import { Spinner } from '~components/generic/spinner/Spinner';
import styles from './PopupMenu.module.css';

export type PopupMenuItemShape = {
  id: string;
  icon?: ReactNode;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  isSeparator?: boolean;
};

export type PopupMenuProps = {
  items: PopupMenuItemShape[];
  onClose?: () => void;
  x: number;
  y: number;
  position?: 'TOP_RIGHT' | 'BOTTOM_LEFT';
  loading?: boolean;
};

export const PopupMenu = ({ items, onClose, x, y, position = 'TOP_RIGHT', loading = false }: PopupMenuProps) => {
  const ref = useRef(null);
  useClickAway(ref, onClose);

  const clsPosition = clsx(
    styles.position,
    position === 'TOP_RIGHT' && styles.positionTopRight,
    position === 'BOTTOM_LEFT' && styles.positionBottomLeft,
  );

  return (
    <Portal>
      <div ref={ref} onClick={onClose} className={clsPosition} style={{ top: y, left: x }}>
        <div className={styles.root}>
          {loading ? (
            <Spinner className={styles.loadingIndicator} />
          ) : (
            <ul className={styles.list}>
              {items.map(item =>
                item.isSeparator ? (
                  <li key={item.id} className={styles.separator} />
                ) : (
                  <PopupMenuItem key={item.id} item={item} />
                ),
              )}
            </ul>
          )}
        </div>
      </div>
    </Portal>
  );
};

const PopupMenuItem = ({ item }: { item: PopupMenuItemShape }) => {
  const handleOnClick = (e: MouseEvent) => {
    if (!item.disabled) {
      item.onClick();
    } else {
      e.stopPropagation();
    }
  };
  return (
    <li key={item.id} className={clsx(styles.item, item.disabled && styles.disabled)} onClick={handleOnClick}>
      {!!item.icon && <span className={styles.icon}>{item.icon}</span>}
      <FormattedMessage id={item.label || item.id} defaultMessage={item.label || item.id} />
    </li>
  );
};
