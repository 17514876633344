import clsx from 'clsx';
import { ForwardedRef, forwardRef, HTMLProps } from 'react';

import styles from '../Hint.module.css';

type HintProps = HTMLProps<HTMLDivElement> & {
  text?: string;
};

export const Hint = forwardRef(
  ({ className, text, children, ...theirProps }: HintProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={clsx(styles.hint, className)} ref={ref} {...theirProps}>
        {text}
        {children}
      </div>
    );
  },
);

Hint.displayName = 'Hint';
