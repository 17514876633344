import { URL_REPLACEMENT } from '$const';
import { getApiBase } from '~services/api/helpers';
import { getConfigBackendUrl, getConfigSymfonyBackendUrl } from '~store/getter';

export default function replaceUrl(url: string) {
  try {
    const mapObj = {
      [URL_REPLACEMENT.BACKEND_URL]: getConfigBackendUrl(),
      [URL_REPLACEMENT.SYMFONY_URL]: getConfigSymfonyBackendUrl(),
      [URL_REPLACEMENT.API_BASE]: getApiBase(true),
    } as Record<string, string>;
    const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    return url.replace(re, function (matched) {
      return Object.prototype.hasOwnProperty.call(mapObj, matched) ? mapObj[matched] : matched;
    });
  } catch (_) {
    // Silently return the original URL, because this "only" happens when 'getConfigBackendUrl()' failes because there is no redux store available!
    return url;
  }
}
