import { URL_REPLACEMENT } from '$const';
import { post } from '~services/api/methods';

export type PostChangelogItemDto = {
  nodeId: string;
  description: string;
  langcode?: string;
  newContent: boolean;
};

export async function postChangelogItem(data: PostChangelogItemDto) {
  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/api/ebm/changelog/items`;
  const response = await post<boolean, PostChangelogItemDto>(requestUrl, data);
  return response;
}
