import { createNextState as produce } from '@reduxjs/toolkit';

import type { FetchTopic, FetchTopics } from '~services/api/validators/Topics';

export const EMPTY_TOPIC_STORE: TopicStore = {
  byId: {},
  rootIds: [],
  allIds: [],
};

const TOPIC_COLORS = ['#c0687b', '#347de0', '#9a6ca6', '#3cb5ae', '#63a615', '#f8ab37'];

function getColorFromDescription(desc: string) {
  if (!desc) return;
  let hexColor = desc.toLowerCase().match(/^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})/g)?.[0];
  if (hexColor && hexColor.length === 4) {
    // convert #abc -> #aabbcc
    hexColor = `#${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}${hexColor[3]}${hexColor[3]}`;
  }
  return hexColor;
}

export function createTopicStore(data: FetchTopics) {
  const baseStore = EMPTY_TOPIC_STORE;

  const store = produce(baseStore, draft => {
    let colorIdx = 0;

    function processTopic(topic: FetchTopic, parentId?: number, inheritedColor?: string) {
      // id
      const id = parseInt(topic.tid, 10);
      draft.allIds.push(id);
      if (!parentId) {
        // only add topic as a root topic if it has children
        if (Object.keys(topic.children || {}).length > 0) {
          draft.rootIds.push(id);
        }
      }

      // color
      const colorFromDescription = getColorFromDescription(topic.description);
      let color: string = colorFromDescription || inheritedColor;
      if (!color && !parentId) {
        color = TOPIC_COLORS[colorIdx % TOPIC_COLORS.length];
        colorIdx += 1;
      }

      // build Topic
      draft.byId[id] = {
        id,
        label: topic.name,
        description: topic.description || '',
        parentId,
        color,
        children: (Object.keys(topic.children || {}) || []).map(tid => parseInt(tid, 10)),
      };

      // proceed with child topic
      Object.values(topic.children || {}).forEach(childTopic => processTopic(childTopic, id, color));
    }

    Object.values(data).forEach(topic => processTopic(topic));
  });

  return store;
}
