import { PureComponent } from 'react';

import Button from '~components/generic/elements/Button';

export interface IPDFDownloadProps {
  enabled: boolean;
  pageId: string;
  generatePagePDF: (pageId: string) => void;
  type?: string;
}

export class PDFDownload extends PureComponent<IPDFDownloadProps> {
  render() {
    const { enabled, pageId, generatePagePDF, type } = this.props;

    if (!enabled) {
      return null;
    }

    if (type === 'section-info') {
      return (
        <div className="c-module u-padding-top-xl u-padding-bottom-xl export-pdf-module">
          <div className="o-container o-container--lg relative">
            <div className="o-grid o-grid--unpad o-grid--wrap">
              <div className="o-grid__cell o-grid__cell--width-3">
                <span className="p42-save-icon"> </span>
              </div>
              <div className="o-grid__cell o-grid__cell--width-9">
                <h3>Create PDF file of this page</h3>
                <p>
                  Here you can create a PDF file of this page as you see it online right now. Please have in mind that
                  it can be outdated when there are updates made to this guideline.
                </p>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    generatePagePDF(pageId);
                  }}
                  className="c-button c-button--dark u-margin-top-lg"
                >
                  <span className="u-vertical-middle">Download PDF</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Button
        visible={true}
        className="c-button u-size-xs"
        noPadding={true}
        label="PDF Download"
        hideLabel={false}
        iconName="download-cloud"
        iconSize="lg"
        onClick={e => {
          e.preventDefault();
          generatePagePDF(pageId);
        }}
        disabled={false}
      />
    );
  }
}

export default PDFDownload;
