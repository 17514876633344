import clsx from 'clsx';
import { FC } from 'react';

import { getConfigAppName, getConfigLogoUrl } from '~store/getter';

interface ILogoProps {
  alt?: string;
  className?: {
    [className: string]: boolean;
  };
}

const Logo: FC<ILogoProps> = ({ alt = getConfigAppName(), className = { 'o-image': true } }) => (
  <img className={clsx(className)} src={getConfigLogoUrl()} alt={alt} />
);

export default Logo;
