const consoleColorMap = {
  log: '#2196F3',
  info: '#2196F3',
  error: '#F44336',
  warning: '#F3D221',
};

export default function consoleLog(message: string, args?: unknown, type: 'error' | 'warning' | 'log' = 'log') {
  console.groupCollapsed(` %c${type}%c \t${message}`, `color: ${consoleColorMap[type]}`, 'color: inherit');
  console.log(args);
  console.groupEnd();
}
