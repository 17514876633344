import type { ThunkCreator } from '$store';
import { fetchAnchors } from './anchor';
import { fetchMenuElements } from './menu';

export const init: ThunkCreator = () => dispatch => {
  // load menu elements
  void dispatch(fetchMenuElements());

  // App needs to load all anchors, otherwise links on anchors will not work
  void dispatch(fetchAnchors());
};
