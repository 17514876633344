import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type WidgetsSidebarState = Array<WidgetSidebar>;

// Initial State
export const initialState: WidgetsSidebarState = [];

// Reducers
const set: CaseReducer<WidgetsSidebarState, PayloadAction<WidgetsSidebarDto>> = (_state, action) => action.payload;

// Slice
const slice = createSlice({
  name: 'widgetsSidebar',
  initialState,
  reducers: {
    set,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: WidgetsSidebarState) => state;

// Export: Selectors (Getter)
export const selectors = {
  self: selectSelf,
};
