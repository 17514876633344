import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'paragraph--annotations',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      default_langcode: true,
      field_annotations_items: ['0(50.0|50.0)<p>Lorem ipsum</p>'],
      field_search_text: null,
      field_hide_title: false,
      field_hide_text: false,
      field_text: {
        value:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.',
        format: null,
      },
      field_title: { value: 'I am an Annotations Module', format: 'rich_text' },
      field_annotations_layout: 0,
    },
    relationships: {
      field_image: {
        data: null,
      },
      field_tags: {
        data: [],
      },
    },
    links: {},
    log: [],
    permissions: {
      update: true,
      create: true,
      delete: true,
    },
  },
  included: [],
});
