import debounce from 'lodash.debounce';
import { createRef, ReactNode, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { ANCHOR_PREFIX, ANCHOR_TOP_ID } from '$const';
import { Scrollbar } from '~components/generic/scrollbar/Scrollbar';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import {
  selectActiveAnchor,
  selectCurrentPageId,
  selectLocationPageAlias,
  selectStatusPageLoading,
} from '~store/selectors';
import { setSlug } from '~store/thunks/navigation';
import delay from '~utils/delay';
import { hasOwnProperty } from '~utils/object';
import styles from './ScrollPane.module.css';

type ScrollPaneProps = {
  children: ReactNode;
};

function useCurrentPageLoaded() {
  const pageAlias = useAppSelector(selectLocationPageAlias);
  const statusPageLoading = useAppSelector(selectStatusPageLoading);
  if (hasOwnProperty(statusPageLoading, `/${pageAlias}`)) {
    return statusPageLoading[`/${pageAlias}`] === false;
  }
  return false;
}

export function ScrollPane({ children }: ScrollPaneProps) {
  const dispatch = useAppDispatch();
  const pageId = useAppSelector(selectCurrentPageId);
  const refPageId = useRef(pageId);
  const activeAnchor = useAppSelector(selectActiveAnchor);
  const refActiveAnchor = useRef(activeAnchor);
  const refIsScrollSpyEnabled = useRef(true);
  const refScrollNode = createRef<HTMLDivElement>();
  const isContentPage = pageId !== null;

  useCurrentPageLoaded();

  const handleAnchorChangeThroughScrolling = useCallback(
    (anchorId?: string) => {
      if (!isContentPage) {
        return;
      }
      if (!refIsScrollSpyEnabled.current) {
        return;
      }
      let slug: string;
      if (!!anchorId && anchorId !== ANCHOR_TOP_ID) {
        slug = anchorId.replace(ANCHOR_PREFIX, '');
      }
      if (slug !== refActiveAnchor.current) {
        refActiveAnchor.current = slug;
        dispatch(setSlug(slug));
      }
    },
    [dispatch, isContentPage],
  );

  const handleScrollToAnchor = useCallback(async (slug: string, delay = 100) => {
    if (!slug) return;
    // temporary disable scrollspy
    refIsScrollSpyEnabled.current = false;
    // update ref
    refActiveAnchor.current = slug;
    // scroll to slug
    await scrollToAnchor(slug, delay);
    // enable scrollspy
    refIsScrollSpyEnabled.current = true;
  }, []);

  useEffect(() => {
    async function initScroll() {
      await delay(150);
      // on mount check if scrolling required
      void handleScrollToAnchor(activeAnchor, 300).catch();
    }
    void initScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scroll = async () => {
      // watch for page change
      if (refPageId.current !== pageId) {
        // temporary disable scrollspy
        refIsScrollSpyEnabled.current = false;

        if (refPageId.current !== undefined) {
          // udpate refs
          refPageId.current = pageId;
          refActiveAnchor.current = undefined;
          // scroll to top
          await scrollToTop();
        }

        // enable scrollspy
        refIsScrollSpyEnabled.current = true;
      }

      // watch for slug change
      if (refActiveAnchor.current !== activeAnchor) {
        await delay(150);
        await handleScrollToAnchor(activeAnchor, 0).catch();
      }
    };

    void scroll().catch();
  }, [pageId, activeAnchor, handleScrollToAnchor]);

  useLayoutEffect(() => {
    const scrollEl = refScrollNode.current;

    // prepare scroll listener
    const listener = debounce(() => {
      const scroll = scrollEl.scrollTop;
      const offset = 120;

      const anchorElements = getAnchorElements();

      const anchors = anchorElements.map(element => {
        const rect = element.getBoundingClientRect();
        const top = clamp(rect.top + scroll - offset);
        return { element, top };
      });

      const currentAnchorId = anchors
        .map(({ element, top }, idx) => {
          let bottom = Infinity;
          if (anchors[idx + 1]) {
            bottom = anchors[idx + 1].top;
          }
          return { id: element.id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom))?.id;

      handleAnchorChangeThroughScrolling(currentAnchorId);
    }, 200);

    // activate scroll listener
    setTimeout(() => {
      scrollEl?.addEventListener('scroll', listener);
    }, 2000);

    // deactive scroll listener
    return () => {
      scrollEl?.removeEventListener('scroll', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <Scrollbar scrollableNodeProps={{ ref: refScrollNode }} disableHorizontalScrolling>
        <div id={ANCHOR_TOP_ID} />
        {children}
      </Scrollbar>
    </div>
  );
}

/**
 * Scroll to element with id = 'anchor--top'
 */
async function scrollToTop() {
  return new Promise<void>(resolve => {
    const element = document.getElementById(ANCHOR_TOP_ID);
    if (element) {
      element.scrollIntoView(false);
      setTimeout(() => {
        void resolve();
      }, 10);
    }
  });
}

/**
 * Scroll to element with id = 'anchor-<slug>'
 */
async function scrollToAnchor(slug: string, delay = 0) {
  return new Promise<void>(resolve => {
    const element = document.getElementById(`${ANCHOR_PREFIX}${slug}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, delay);
      setTimeout(() => {
        void resolve();
      }, delay + 500);
    }
  });
}

/**
 * Restrict value to be between the range [0, value]
 */
function clamp(value: number) {
  return Math.max(0, value);
}

/**
 * Check if number is between two values
 */
function isBetween(value: number, floor: number, ceil: number) {
  return value >= floor && value <= ceil;
}

/**
 * Get all DOM elements with id ^= anchor-
 */
function getAnchorElements() {
  return [...document.querySelectorAll(`[id^="${ANCHOR_PREFIX}"]`)];
}
