import { ContentState } from 'draft-js';
import { Options as ExportOptions, stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import { AlignTextCenterSettings } from '~components/generic/editable-field/AlignTextCenter/AlignTextCenter';
import { AlignTextLeftSettings } from '~components/generic/editable-field/AlignTextLeft/AlignTextLeft';
import { AlignTextRightSettings } from '~components/generic/editable-field/AlignTextRight/AlignTextRight';
import { SecondaryColorSettings } from '~components/generic/editable-field/SecondaryColorButton';
import { XlSettings } from '~components/generic/editable-field/XL';
import operateOnItemOrArray from '~utils/operateOnItemOrArray';
import { IFieldTypes } from './entity-definitions/interface';

const BlockSettings = [
  AlignTextCenterSettings,
  AlignTextLeftSettings,
  AlignTextRightSettings,
  XlSettings,
  SecondaryColorSettings,
];

function convertValue(value: string | ContentState, type: IFieldTypes, toType: 'raw' | 'workable') {
  // this object contains functions which are called conditionally depending on
  // the target and the field type

  const htmlOptions = {
    // @TODO: this should be more generic to work for ALL inline styles
    //defaultBlockTag: 'div',
    inlineStyles: {
      BOLD: { fontWeight: '600' as 'bolder' },
      SUPERSCRIPT: {
        element: 'sup',
        style: {
          fontSize: '0.6em',
          verticalAlign: 'super',
        },
      },
    },
    blockStyleFn: block => {
      const type = block.getType();
      const setting = BlockSettings.find(s => s.blockType === type);
      if (setting) {
        return { element: setting.element, attributes: { class: setting.className } };
      }
    },
    entityStyleFn: entity => {
      const entityType = entity.get('type').toLowerCase();
      if (entityType === 'link') {
        const data = entity.getData();
        return {
          element: 'a',
          attributes: {
            href: data.href || data.url,
          },
        };
      }
    },
  };

  const converters = {
    // util functions for html type
    html: {
      raw: (val: ContentState) => stateToHTML(val, htmlOptions as unknown as ExportOptions),
      workable: (val: string) => {
        // if there is a value create a content state from the html
        if (val) {
          return stateFromHTML(val, {
            elementStyles: {
              // Support <sup> and <sub> inline elements:
              sup: 'SUPERSCRIPT',
              sub: 'SUBSCRIPT',
            },
            customBlockFn: element => {
              const setting = BlockSettings.find(s => element.classList.contains(s.className));
              if (setting) {
                return { type: setting.blockType };
              }
            },
          });
        }
        // ...otherwise initialize an empty content state
        return ContentState.createFromText('');
      },
      getType: val => (val && val.blockMap ? 'workable' : 'raw'),
    },

    // util function for text type
    text: {
      raw: val => val.getPlainText(),
      workable: (val: string) => ContentState.createFromText(val || ''),
      getType: val => (val && val.blockMap ? 'workable' : 'raw'),
    },
  };

  if (!converters[type] || converters[type].getType(value) === toType) {
    return value;
  }
  // only convert if necessary
  return converters[type][toType](value);
}

export function convertToWorkableValue(value: string | ContentState, type: IFieldTypes) {
  return operateOnItemOrArray(value, val => convertValue(val, type, 'workable'));
}

export function convertToRawValue<T>(value: string | ContentState, type: IFieldTypes): T {
  return operateOnItemOrArray(value, val => convertValue(val, type, 'raw')) as T;
}
