import { createAsyncThunk } from '@reduxjs/toolkit';
import _get from 'lodash.get';

import type { RootState, ThunkCreator } from '$store';
import { IEntityReferenceSingle, IJsonApiResponseSingle } from '~interfaces/api';
import {
  createEntity as createEntityCall,
  removeEntity as removeEntityCall,
  updateEntity as updateEntityCall,
} from '~services/api/calls/entity';
import { convertToRawValue, convertToWorkableValue } from '~services/data-conversion';
import { getEntityDefinition, getEntityTemplate, IEntityFieldDefinition } from '~services/entity-definitions';
import {
  changeEntity,
  findEntityReferences,
  getRelatedEntityIds,
  getTitle,
  isNodePageEntity,
  isTempEntity,
} from '~services/entity-helpers';
import { ApiError } from '~services/error-helpers';
import { objectSet } from '~services/immutable';
import log from '~services/log';
import { ControlActions, DraftEntitiesActions, EntitiesActions, StatusActions } from '~store/actions';
import {
  selectAddonsIsPublishingEnabled,
  selectCurrentPageId,
  selectCurrentPagePermissions,
  selectDraftEntitiesState,
  selectEntitiesState,
  selectSessionIsEditing,
} from '~store/selectors';
import {
  selectContext,
  selectLockedPageId,
  selectTemporaryEntityReferences,
  selectWorkableEntities,
} from '~store/selectors/control';
import { selectEntitiesWithChanges, selectEntityWithChangesById } from '~store/selectors/custom';
import delay from '~utils/delay';
import { deepClone, hasOwnProperty } from '~utils/object';
import operateOnItemOrArray from '~utils/operateOnItemOrArray';
import { fetchAnchors } from './anchor';
import { lockPage } from './lock';
import { navigateToDefault } from './navigation';
import { apiErrorNotification } from './notification';
/**
 * Deactive editing, removing the chached changes from the control state slice
 * and resetting to purely entity-state-slice-state
 * @param id string - Entity ID
 */
export const deactivateWorkableEntity: ThunkCreator = (id: string) => (dispatch, getState) => {
  const entity = selectEntityWithChangesById(getState(), id);
  if (!entity) {
    return;
  }
  const entityDef = getEntityDefinition(entity.type);

  // deactivate child ids if this is one of the entity where that should happen
  if (entityDef.meta.workableReferences) {
    for (const fieldName of entityDef.meta.workableReferences) {
      const relatedEntityIds = getRelatedEntityIds(entity, fieldName);
      for (const relEntityId of relatedEntityIds) {
        dispatch(deactivateWorkableEntity(relEntityId));
      }
    }
  }

  dispatch(ControlActions.unsetWorkableEntity(id));
};

export const activateWorkableEntity: ThunkCreator = (entityId: string) => (dispatch, getState) => {
  try {
    const isPublishingEnabled = selectAddonsIsPublishingEnabled(getState());
    const entities = isPublishingEnabled ? selectDraftEntitiesState(getState()) : selectEntitiesState(getState());
    const entityInfo = entities[entityId];
    if (!entityInfo) {
      throw new Error(`Entity with ID ${entityId} wasn't found in entities state slice and cannot be activated!`);
    }
    let entity = entityInfo.data;
    // get the entity definition, get all the editable fields, transform into
    // draftJs content state and put into the control state slice
    const entityDef = getEntityDefinition(entity.type);

    // activate child ids if this is one of the entity where that should happen
    if (entityDef.meta.workableReferences) {
      for (const fieldName of entityDef.meta.workableReferences) {
        const relatedEntityIds = getRelatedEntityIds(entity, fieldName);
        for (const relEntityId of relatedEntityIds) {
          dispatch(activateWorkableEntity(relEntityId));
        }
      }
    }

    // iterate over field defs and transform the fields with a definition to an
    // content state, @TODO use changeEntity entity-helper
    if (entityDef.fields) {
      Object.keys(entityDef.fields).forEach(fieldName => {
        const fieldDef: IEntityFieldDefinition = entityDef.fields[fieldName];
        if (!fieldDef) {
          return;
        }
        const rawValue = _get(entity, fieldName, false);
        if (!rawValue) {
          return;
        }
        const newValue = operateOnItemOrArray(rawValue, val => convertToWorkableValue(val, fieldDef.type));
        // recursively add the states to the entity under the fieldName (field
        // adress with dot-notation)
        entity = objectSet(entity, fieldName, newValue);
      });
    }

    dispatch(ControlActions.setWorkableEntity(entity));
  } catch (e) {
    log('Error in activateWorkableEntity thunk!', e, 'error');
  }
};

export const stopEditingEntity: ThunkCreator = (entityId?: string) => (dispatch, getState) => {
  let _title: string;
  try {
    const context = selectContext(getState());
    entityId = entityId || context;
    if (!entityId) return;

    const entity = selectEntityWithChangesById(getState(), entityId);
    _title = getTitle(entity);
    const entityDef = getEntityDefinition(entity.type);
    // concatenate the values of the two dependent field names into one array
    const refsToStop: string[] = [
      ...(entityDef.meta.saveReferences || []),
      ...(entityDef.meta.workableReferences || []),
    ];

    const workableEntities = selectWorkableEntities(getState());
    // iterate over the reference fieldNames
    for (const fieldName of refsToStop) {
      // only take the entities which are currently in workable state
      const relEntityIds = getRelatedEntityIds(entity, fieldName).filter(id => workableEntities[id]);
      for (const relEntityId of relEntityIds) {
        dispatch(stopEditingEntity(relEntityId));
      }
    }

    dispatch(deactivateWorkableEntity(entityId));

    if (context === entityId) {
      dispatch(ControlActions.setContext());
    }

    // remove active page lock
    if (selectLockedPageId(getState())) {
      dispatch(ControlActions.lockPage(null));
    }
  } catch (e) {
    log('Error in stopEditingEntity thunk!', e, 'error');
    dispatch(
      apiErrorNotification(
        new ApiError({
          type: 'unlock',
          title: _title,
          ...(e as any),
        }),
      ),
    );
  }
};

export const createTemporaryEntity: ThunkCreator<string> =
  ({ type }: { type: string }) =>
  (dispatch, getState) => {
    try {
      const entityTemplate = getEntityTemplate(type);
      const entities = !entityTemplate.included
        ? [entityTemplate.data]
        : [entityTemplate.data, ...entityTemplate.included];
      const isPublishingEnabled = selectAddonsIsPublishingEnabled(getState());

      const setTemporaryEntityAction = isPublishingEnabled
        ? DraftEntitiesActions.setDraftEntity
        : EntitiesActions.setEntity;

      for (const entity of entities) {
        dispatch(
          setTemporaryEntityAction({
            id: entity.id,
            type: entity.type,
            data: entity,
            status: {
              isFetching: false,
              isComplete: true,
            },
          }),
        );
        dispatch(EntitiesActions.setEntityCompleted(entity));

        const references = findEntityReferences(entity.id, entities);
        for (const reference of references) {
          dispatch(ControlActions.setTemporaryEntityReference(reference));
        }
      }

      return entityTemplate.data.id;
    } catch (e) {
      log('Error in createTemporaryEntity thunk!', e, 'error');
    }
  };

export const changeWorkableEntity: ThunkCreator =
  ({ id, changeSet }: ChangeWorkableEntityPayload) =>
  (dispatch, getState) => {
    const entity = selectEntityWithChangesById(getState(), id);
    const newEntity = changeEntity({
      entity,
      changeSet,
    });
    dispatch(ControlActions.setWorkableEntityChange(newEntity));
  };

export const dereferenceTemporaryEntity: ThunkCreator =
  (payload: { oldId: string; replaceReference: IEntityReferenceSingle }) => (dispatch, getState) => {
    try {
      const { oldId, replaceReference } = payload;
      const tempReferences = selectTemporaryEntityReferences(getState());
      const references = tempReferences[oldId];

      for (const reference of references) {
        dispatch(
          changeWorkableEntity({
            id: reference.parentId,
            changeSet: {
              fieldName: reference.fieldName,
              value: oldValue => {
                // replacer function can handle an object or a string
                const replace = (val: { id: string }) => {
                  if (val.id !== oldId) {
                    return val;
                  }
                  return replaceReference;
                };

                // can handle arrays or strings/objects
                return operateOnItemOrArray(oldValue, replace);
              },
            },
          }),
        );
      }

      dispatch(ControlActions.unsetTemporaryEntityReference(oldId));
      dispatch(DraftEntitiesActions.unsetDraftEntity(oldId));
      dispatch(EntitiesActions.unsetEntity(oldId));
    } catch (e) {
      log('Error in dereferenceTemporaryEntity thunk!', e, 'error');
    }
  };

/**
 * Get the entity with changes included
 *
 * @param entityId {string}
 */
const getWorkableEntity: ThunkCreator<Entity> = (entityId: string) => (_dispatch, getState) => {
  const entities = selectEntitiesWithChanges(getState());
  return entities[entityId];
};

/**
 * Get the entity without any changes
 *
 * @param entityId {string}
 */
const getEntity: ThunkCreator<Entity> = (entityId: string) => (_dispatch, getState) => {
  const entities = selectEntitiesState(getState());
  return entities[entityId].data;
};

/**
 * Return a skeleton entity which just has the required data for the API to
 * accept it.
 *
 * @param entityId {string}
 */
const getEmptyEntity: ThunkCreator<Partial<Entity>> = (entityId: string) => (_dispatch, getState) => {
  const entities = selectEntitiesWithChanges(getState());
  const entity = entities[entityId];
  return {
    id: entityId,
    type: entity.type,
    attributes: {},
  };
};

/**
 * Helper to prepare entity data and triggers update in store
 *
 * @param entity Entity
 * @param permissions EntityPermissions
 * @return void
 */
const updateEntity: ThunkCreator =
  ({ entity, permissions }: { entity: Entity; permissions?: EntityPermissions }) =>
  (dispatch, getState) => {
    const isPublishingEnabled = selectAddonsIsPublishingEnabled(getState());
    const data = {
      status: { isFetching: false, isComplete: true },
      id: entity.id,
      type: entity.type,
      data: entity,
    };
    if (permissions) {
      data['permissions'] = permissions;
    }
    if (isPublishingEnabled) {
      dispatch(DraftEntitiesActions.setDraftEntity(data));
    } else {
      dispatch(EntitiesActions.setEntity(data));
    }
  };

/**
 * Called after an entity is saved
 *
 * @param payload ISavedEntityPayload
 */
export const savedEntity: ThunkCreator = (payload: EntitySavedPayload) => (dispatch, getState) => {
  try {
    const { oldId, isTemp, entity } = payload;
    const isPage = isNodePageEntity(entity);

    if (isTemp && !isPage) {
      dispatch(
        dereferenceTemporaryEntity({
          oldId,
          replaceReference: {
            id: entity.id,
            type: entity.type,
            meta: {
              target_revision_id: entity.attributes.revision_id,
            },
          },
        }),
      );
    }

    // remove active page lock
    if (selectLockedPageId(getState())) {
      dispatch(ControlActions.lockPage(null));
    }
  } catch (e) {
    log('Error in savedEntity thunk!', e, 'error');
  }
};

export const saveEntity = createAsyncThunk<Partial<IJsonApiResponseSingle>, SaveEntityPayload, { state: RootState }>(
  'entity/save',
  async (payload, { dispatch, getState }) => {
    let _entityId: string;
    try {
      const {
        resetId,
        dontShowAlert,
        entityId,
        dontClearContext,
        dontLoadEntity,
        dontDeactivateWorkable,
        dontConvertFromWorkableState,
        dontSaveIfNoChange,
        dontSaveTempReferences,
        dontSaveSaveReferences,
        dontSaveWorkable,
        emptyEntity,
        lock,
      } = payload;
      _entityId = entityId;
      const entityUpdater = emptyEntity ? getEmptyEntity : dontSaveWorkable ? getEntity : getWorkableEntity;
      let entity = dispatch(entityUpdater(entityId));
      const isPublishingEnabled = selectAddonsIsPublishingEnabled(getState());

      if (!entity) {
        throw new Error(`Couldn't find entity with ID ${entityId}!`);
      }

      dispatch(StatusActions.showEditLoading());

      if (isPublishingEnabled) {
        dispatch(DraftEntitiesActions.unsetDraftEntityCompleted({ id: entityId }));
      } else {
        dispatch(EntitiesActions.unsetEntityCompleted({ id: entityId }));
      }

      const _isTemp = isTempEntity(entityId);
      // if this entity is the empty page temporary entity, then reset the ID, as we
      // need to create the entity, cache the flag for use in the saving response
      // (to replace the menu item link)
      const isCreateNewPage = _isTemp && isNodePageEntity(entity);
      const oldEntity = deepClone(entity);
      // calculate if this ID should be reset
      const _resetId = _isTemp || resetId || false;
      // get entity definition
      const entityDef = getEntityDefinition(entity.type);

      if (!dontClearContext && !isCreateNewPage) {
        dispatch(ControlActions.setContext());
      }

      // check that we're not referencing temporary entities, and if we are, save them
      if (!dontSaveTempReferences && !emptyEntity) {
        const temporaryEntityReferences = selectTemporaryEntityReferences(getState());
        for (const relKey in entity.relationships) {
          const relationship = entity.relationships[relKey];
          if (relationship && relationship.data) {
            // turn it into an array
            const relArr = operateOnItemOrArray(relationship.data, v => v, true) as IEntityReferenceSingle[];
            // only leave the relationships that are referenced as temporary entity
            // references
            const filteredRelArr = relArr.filter(relItem => temporaryEntityReferences[relItem.id]);
            const filteredRelArrLen = filteredRelArr.length;
            // awkward synchroneous syntax to stop mysql deadlocks:
            // https://www.drupal.org/project/drupal/issues/2833539
            for (let i = 0; i < filteredRelArrLen; i++) {
              await delay(100);
              await dispatch(
                saveEntity({
                  dontDeactivateWorkable: true,
                  entityId: filteredRelArr[i].id,
                }),
              );
            }
          }
        }
        // update the local entity, it might have changed
        entity = dispatch(entityUpdater(entityId));
      }

      if (!dontSaveSaveReferences && !emptyEntity) {
        const workableEntities = selectWorkableEntities(getState());
        // if the entityDef defines saveReferences (children which need to be
        // saved if they have changes), then same them before
        if (entityDef.meta.saveReferences) {
          for (const fieldName of entityDef.meta.saveReferences) {
            const entityIds = getRelatedEntityIds(entity, fieldName).filter(id => workableEntities[id]);
            const entityIdsLen = entityIds.length;
            // see above why we use this awkward syntax
            for (let i = 0; i < entityIdsLen; i++) {
              await delay(100);
              await dispatch(
                saveEntity({
                  entityId: entityIds[i],
                  dontShowAlert: true,
                  dontSaveIfNoChange: true,
                  dontClearContext: true,
                }),
              );
            }
          }
        }
        // update the local entity, it might have changed
        entity = dispatch(entityUpdater(entityId));
      }

      // delete unused local state flags
      if (_resetId) {
        if (hasOwnProperty(entity, 'id')) {
          //delete entity.id;
          const { id: _id, ...restEntity } = entity;
          entity = restEntity;
        }
      }

      // basically the opposite of activateWorkableEntityAction, read the entity
      // definition, undo the editing-state transforms (for example draft js →
      // html/text)
      if (!dontConvertFromWorkableState) {
        if (entityDef.fields) {
          Object.keys(entityDef.fields).forEach(fieldName => {
            const fieldDef: IEntityFieldDefinition = entityDef.fields[fieldName];
            if (!fieldDef) {
              return;
            }
            const value = _get(entity, fieldName, false);
            if (value) {
              const rawValue = convertToRawValue(value, fieldDef.type);
              if (rawValue) {
                entity = objectSet(entity, fieldName, rawValue);
              }
            }
          });
        }
      }

      // if we set this flag we check if there are actual changes we want to save
      // and this entity is not locked, otherwise we just return the entity as-is
      if (dontSaveIfNoChange) {
        if (!entity._changed && !lock) {
          dispatch(EntitiesActions.setEntityCompleted({ id: entityId }));
          return entity;
        }
      }

      // Drupal complains if these are here, remove them
      // simple deleting is not possible in <strict-mode>. so e.g. `delete cleanedEntity._changed;` doesnt work
      const { _changed, attributes, relationships, ...remainingEntity } = entity;
      const {
        changed: _attributeChanged,
        created: _attributeCreated,
        revision_timestamp: _attributeRevisionTimestamp,
        vid: _attributeVid,
        parent_id: _attributeParentId,
        parent_type: _attributeParentType,
        page_path: _attributePagePage,
        permissions: _attributePermissions,
        log: _attributeLog,
        ...remainingAttributes
      } = attributes;
      const { revision_id: _relationshipsRevisionId, ...remainingRelationships } = relationships;
      const cleanedEntity: Partial<Entity> = {
        ...remainingEntity,
        attributes: remainingAttributes,
        relationships: remainingRelationships,
      };

      // if there is no id assume this should be created
      const res = cleanedEntity.id ? await updateEntityCall(cleanedEntity) : await createEntityCall(cleanedEntity);

      if (!res.data) {
        throw res;
      }

      const newEntity = res.data;
      const title = getTitle(newEntity);

      // reset the cached version in control state slice (by calling
      // deactivateWorkableEntityAction)
      if (!dontDeactivateWorkable && !isCreateNewPage) {
        dispatch(deactivateWorkableEntity(entityId));
      }
      // update the entity in the entities state slice
      if (!dontLoadEntity) {
        const parameters = { entity: newEntity };
        if (newEntity.type === 'node--page') {
          const pagePermissions = selectCurrentPagePermissions(getState());
          parameters['permissions'] = pagePermissions;
        }

        dispatch(updateEntity(parameters));
      }
      dispatch(
        savedEntity({
          oldEntity,
          isCreateNewPage,
          oldId: entityId,
          entity: newEntity,
          isTemp: _isTemp,
          origPayload: payload,
          partialEntity: emptyEntity,
        }),
      );
      if (isPublishingEnabled) {
        dispatch(DraftEntitiesActions.setDraftEntityCompleted({ id: newEntity.id }));
      } else {
        dispatch(EntitiesActions.setEntityCompleted({ id: newEntity.id }));
      }

      // do certain things, unless we specified that they shouldn't happen
      if (!dontShowAlert) {
        dispatch(StatusActions.setSuccessNotification(`Saved ${title}!`));
      }

      dispatch(StatusActions.hideEditLoading());

      return res;
    } catch (e) {
      const isPublishingEnabled = selectAddonsIsPublishingEnabled(getState());
      // set the completed state on an error and rethrow the error to propagate
      if (isPublishingEnabled) {
        dispatch(DraftEntitiesActions.setDraftEntityCompleted({ id: _entityId }));
      } else {
        dispatch(EntitiesActions.setEntityCompleted({ id: _entityId }));
      }
      throw e;
    }
  },
);

export const saveEntityWorker: ThunkCreator = (payload: SaveEntityPayload) => async (dispatch, getState) => {
  let _entityId: string;
  let _title = 'Unknown';
  let _isNew = true;
  try {
    _entityId = payload.entityId;
    const entity = selectEntityWithChangesById(getState(), _entityId);
    _isNew = isTempEntity(_entityId);
    _title = getTitle(entity);
    await dispatch(saveEntity(payload));
  } catch (e) {
    dispatch(stopEditingEntity(_entityId));
    log('Error in saveEntity thunk!', e, 'error');
    dispatch(EntitiesActions.setEntityFailed({ id: _entityId, message: 'Error saving!' }));
    dispatch(
      apiErrorNotification(
        new ApiError({
          type: _isNew ? 'create' : 'save',
          title: _title,
          ...(e as any),
        }),
      ),
    );
  }
};

export const deleteEntity = createAsyncThunk<void, DeleteEntityPayload, { state: RootState }>(
  'entity/delete',
  async (payload, { dispatch, getState }) => {
    let _title: string;
    let _entityId: string;
    try {
      const { id, dontDeactivateWorkable, dontClearContext } = payload;
      const entities = selectEntitiesWithChanges(getState());
      const entity = entities[id];
      const isPage = isNodePageEntity(entity);
      const isPublishingEnabled = selectAddonsIsPublishingEnabled(getState());
      _entityId = id;
      _title = getTitle(entity);

      // check if this entity is not in the temporary entities, if it is, the api
      // request will fail, so don't send one
      const tempReferences = selectTemporaryEntityReferences(getState());
      if (tempReferences[id]) {
        return;
      }

      if (!dontDeactivateWorkable) {
        dispatch(deactivateWorkableEntity(id));
      }
      if (isPage || !isPublishingEnabled) {
        dispatch(EntitiesActions.unsetEntity(id));
      } else {
        dispatch(DraftEntitiesActions.unsetDraftEntity(id));
      }

      if (!dontClearContext) {
        dispatch(ControlActions.setContext());
      }

      // dont make the request if it's a page entity, pages will be deleted
      // when the menu item is deleted
      // do not remove entity from backend if publish is enabled. maybe it is still needed from another revision of current page
      if (!isTempEntity(id) && !isPage && !isPublishingEnabled) {
        await removeEntityCall({ type: entity.type, id });
      }

      dispatch(
        deletedEntity({
          entity,
          origPayload: payload,
        }),
      );
    } catch (e) {
      log('Error in deleteEntity thunk!', e, 'error');
      dispatch(EntitiesActions.setEntityFailed({ id: _entityId, message: 'Error deleting!' }));
      dispatch(
        apiErrorNotification(
          new ApiError({
            type: 'delete',
            title: _title,
            ...(e as any),
          }),
        ),
      );
    }
  },
);

export const changeEntityAndSave: ThunkCreator =
  ({ id, changeSet, skipSave }: { id: string; changeSet: EntityChangeset; skipSave: boolean }) =>
  dispatch => {
    dispatch(activateWorkableEntity(id));
    dispatch(changeWorkableEntity({ id, changeSet }));
    if (!skipSave) {
      dispatch(
        saveEntityWorker({
          entityId: id,
          dontClearContext: true,
          dontShowAlert: true,
        }),
      );
    }
  };

// cache editing entity ids so we can relinquish the lock if something goes
// wrong
let editingEntityIds = [];

export const startEditingEntity: ThunkCreator = (payload: EditEntityPayload) => async (dispatch, getState) => {
  // reset cache

  editingEntityIds = [];
  try {
    const { id, preserveContext, reloadAnchors } = payload;
    const pageId = selectCurrentPageId(getState());

    await dispatch(lockPage(pageId)).unwrap(); // This throws if the page is locked

    dispatch(activateWorkableEntity(id));

    // set the editing context to this entity, unless we said otherwise
    if (!preserveContext) {
      dispatch(ControlActions.setContext(id));
    }

    // might need to set a link, so better get all the anchor modules now
    if (!reloadAnchors) {
      void dispatch(fetchAnchors());
    }
  } catch (e) {
    dispatch(ControlActions.lockPage(null));
    dispatch(StatusActions.setErrorNotification(e));

    // if it fails, try and relinquish the locks that were already registered
    for (const entityId of editingEntityIds) {
      dispatch(stopEditingEntity(entityId));
    }
  }
};

export const deletedEntity: ThunkCreator =
  (payload: { entity: Entity; origPayload: DeleteEntityPayload }) => (dispatch, getState) => {
    const { origPayload, entity } = payload;
    const { dontShowAlert, parentContext, fieldName, id } = origPayload;
    const title = getTitle(entity);
    const isPage = isNodePageEntity(entity);

    if (!dontShowAlert) {
      dispatch(StatusActions.setSuccessNotification(`Deleted ${title}!`));
    }

    if (isPage) {
      dispatch(navigateToDefault());
    }

    // if we provided these two parameters delete references to this entity in the parent entity
    // Examples:
    // parentContext: '201d42f4-7830-43d5-abb2-32e5a9c6462c'
    // fieldName: 'relationships.field_modules.data'
    if (parentContext && fieldName) {
      const entities = selectEntitiesWithChanges(getState());
      const parentEntity = entities[parentContext];
      const references: IEntityReferenceSingle | IEntityReferenceSingle[] = _get(parentEntity, fieldName);
      if (!references) {
        return;
      }
      const referencesWithoutDeletedEntity = Array.isArray(references)
        ? references.filter(ref => ref.id !== id)
        : references.id === id
        ? ({} as IEntityReferenceSingle)
        : references;

      // DK NOTE: If we are in editing mode we should not trigger any save function,
      // if it's an temp entity because if we do, the save function will end the editing mode.
      // Thats a problem when dragging a new module over e.g. the page
      // but dropping the new module outside of the page to remove it again.
      // In this situation we want to stay in the editing mode to drag/drop
      // other modules.
      //
      // isTempEntity(id) should be enough to skip save, because temp entities should
      // only exist when editing something, but for safety we also check if we are
      // in editing mode
      const isEditing = selectSessionIsEditing(getState());

      dispatch(
        changeEntityAndSave({
          id: parentContext,
          changeSet: {
            fieldName,
            value: referencesWithoutDeletedEntity,
          },
          skipSave: isEditing && isTempEntity(id),
        }),
      );
    }

    // remove active page lock
    if (selectLockedPageId(getState())) {
      dispatch(ControlActions.lockPage(null));
    }
  };
