import { useContext } from 'react';

import { TopicsContext } from '~context/Topics';

export default function useTopics() {
  const context = useContext(TopicsContext);
  if (!context) {
    throw new Error(`useTopics must be used within a TopicsProvider`);
  }
  return context;
}
