import { URL_REPLACEMENT } from '$const';
import { Config } from '~extension/tank-labelling/typings/config';
import { get } from '~services/api/methods';

// list config
export const getConfig = async () => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/tanklabelling/api/config`;

  return await get<Config>(requestUrl, { useBearerToken: true });
};
