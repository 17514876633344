import { DeleteIcon } from '~components/icons';
import styles from './Meta.module.css';

export function ClearMetaImageButton({ onClick }: { onClick: () => void }) {
  const label = 'Clear: Remove image';
  return (
    <div
      className={`${styles.clearMetaImageButton} flex items-center cursor-pointer transition`}
      title={label}
      onClick={onClick}
    >
      <DeleteIcon className="w-4 h-4" />
    </div>
  );
}
