import { CroppedImage } from '~components/generic/image-crop/CroppedImage';
import { ThumbnailIcon } from '~components/icons';

type MetaBarThumbnailProps = {
  imageSrc?: string;
  crop: PixelCrop;
};

export function MetaBarThumbnail({ imageSrc, crop }: MetaBarThumbnailProps) {
  if (imageSrc) {
    return <CroppedImage src={imageSrc} crop={crop} alt="1:1 Format" className="object-scale-down w-full h-full" />;
  }

  return (
    <div className="text-black opacity-30">
      <ThumbnailIcon />
    </div>
  );
}
