import { get } from '~services/api/methods';
import { getLangFromLocation } from '~services/location-helpers';
import log from '~services/log';
import delay from '~utils/delay';
import { hasOwnProperty } from '~utils/object';

export const fetchSiteInfo = async (backendUrl: string) => {
  const lang = getLangFromLocation();

  // Here we can NOT use the URL_REPLACEMENT enum, because at this point in the app we do not have a redux store, where the replacement is coming from
  const requestUrl = `${backendUrl}${lang ? `/${lang}` : ''}/api/bgapi/siteinfo`;

  try {
    const response = await get<SiteInfoDto>(requestUrl, { withCredentials: true });
    return response;
  } catch (e) {
    // Check if its a siteinfo error
    const error = e as object;
    if (
      hasOwnProperty(error, 'status') &&
      error.status === 404 &&
      hasOwnProperty(error, 'url') &&
      (error.url as string).endsWith('/siteinfo')
    ) {
      // this happens when in the url there ist language prefix e.g. `/en/` but we have only one language
      const newPath = window.location.pathname.replace(/^\/.{2}\//, '/');
      log('Redirect', { from: window.location.pathname, to: newPath }, 'log');
      window.location.href = newPath;
      await delay(2000);
    }
  }
};
