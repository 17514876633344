import { ReactNode, useState } from 'react';

import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import useLabels from '~hooks/useLabels';
import { ModalActions } from '~store/actions';
import { selectAddonsIsFavouriteEnabled, selectConfigSiteAccess } from '~store/selectors';
import { deleteAssetFavourite, deleteContentFavourite, postAssetFavourite, postContentFavourite } from '../api/api';
import FavouriteContext from './FavouriteContext';

export type ProviderProps = {
  children?: ReactNode;
};

const Provider = ({ children }: ProviderProps) => {
  const { msgConfirmRemoveFavourite, btnModalRemoveFavouriteYes, btnModalRemoveFavouriteNo } = useLabels([
    'msgConfirmRemoveFavourite',
    'btnModalRemoveFavouriteYes',
    'btnModalRemoveFavouriteNo',
  ]);
  const dispatch = useAppDispatch();

  const isFavouriteEnabled = useAppSelector(selectAddonsIsFavouriteEnabled);
  const siteAccess = useAppSelector(selectConfigSiteAccess)?.key;

  const [showFavouritesToggle, setShowFavouritesToggle] = useState<boolean>(true);

  const onSetShowFavouritesToggle = (show: boolean) => {
    setShowFavouritesToggle(show);
  };

  const addContentFavourite = async (key: string) => {
    const formData = new FormData();
    formData.append('key', key);
    return await postContentFavourite(formData);
  };

  const removeContentFavourite = async (key: string) => {
    return new Promise<Awaited<ReturnType<typeof deleteContentFavourite>>>(res => {
      dispatch(
        ModalActions.show({
          type: 'confirm',
          headline: '',
          text: msgConfirmRemoveFavourite,
          labelYes: btnModalRemoveFavouriteYes,
          labelNo: btnModalRemoveFavouriteNo,
          width: '460px',
          height: '260px',
          onConfirmationClick: () => {
            void deleteContentFavourite(key).then(response => {
              res(response);
            });
          },
          onCancelClick: () => {
            res(null);
          },
          onCloseClick: () => {
            res(null);
          },
        }),
      );
    });
  };

  const addAssetFavourite = async (id: string) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('date', `${new Date().getTime() / 1000}`);
    if (siteAccess) {
      formData.append('siteaccess', siteAccess);
    }
    return await postAssetFavourite(formData);
  };

  const removeAssetFavourite = async (id: string) => {
    return new Promise<Awaited<ReturnType<typeof deleteAssetFavourite>>>((res, rej) => {
      dispatch(
        ModalActions.show({
          type: 'confirm',
          text: msgConfirmRemoveFavourite,
          labelYes: btnModalRemoveFavouriteYes,
          labelNo: btnModalRemoveFavouriteNo,
          width: '460px',
          height: '260px',
          onConfirmationClick: () => {
            void deleteAssetFavourite(id, siteAccess).then(response => {
              res(response);
            });
          },
          onCancelClick: rej,
          onCloseClick: rej,
        }),
      );
    });
  };

  return (
    <FavouriteContext.Provider
      value={{
        isFavouriteAddonEnabled: () => isFavouriteEnabled, // TODO: move to dedicated addon-service?
        onSetShowFavouritesToggle,
        showFavouritesToggle: showFavouritesToggle,
        addContentFavourite,
        removeContentFavourite,
        addAssetFavourite,
        removeAssetFavourite,
      }}
    >
      {children}
    </FavouriteContext.Provider>
  );
};

export default Provider;
