import type { ReactNode } from 'react';
import { createContext, useMemo } from 'react';

import useAppSelector from '~hooks/useAppSelector';
import { useTopicsQuery } from '~services/api/hooks/topics.hooks';
import { selectAddonsIsMetaEnabled, selectUserIsLoggedIn } from '~store/selectors';

export const TopicsContext = createContext<
  | {
      byId: Record<TopicId, Topic>;
      allIds: TopicId[];
      rootIds: TopicId[];
      hasError: boolean;
      isLoading: boolean;
      getTopicById: (id: TopicId) => Topic | undefined;
    }
  | undefined
>(undefined);

type TopicsProviderProps = {
  children: ReactNode;
};

export function TopicsProvider({ children }: TopicsProviderProps) {
  const isMetaEnabled = useAppSelector(selectAddonsIsMetaEnabled);
  const isUserLoggedIn = useAppSelector(selectUserIsLoggedIn);
  const { dataTopics, isFetchedTopics, isErrorTopics } = useTopicsQuery(isMetaEnabled && isUserLoggedIn);

  const value = useMemo(() => {
    const byId = dataTopics?.byId || {};
    const allIds = dataTopics?.allIds || [];
    const rootIds = dataTopics?.rootIds || [];
    const hasError = isErrorTopics;
    const isLoading = !isFetchedTopics;
    const getTopicById = (id: number) => byId[id];
    return {
      byId,
      allIds,
      rootIds,
      isLoading,
      hasError,
      getTopicById,
    };
  }, [dataTopics?.allIds, dataTopics?.byId, dataTopics?.rootIds, isErrorTopics, isFetchedTopics]);
  return <TopicsContext.Provider value={value}>{children}</TopicsContext.Provider>;
}
