import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useCallback, useRef, useState } from 'react';

import { Button as EditorButton } from '~components/generic/editor/Button/Button';
import { CropIcon } from '~components/icons';
import useLabels from '~hooks/useLabels';
import { ImageCrop } from '~modules/media-library/view/CropModal/ImageCrop';
import { DEFAULT_ASPECT_RATIO_OPTIONS, findClosestAspectRatio } from '~utils/imageCrop';
import stylesCropper from './Cropper.module.css';

type ImageCropDialogProps = {
  open: boolean;
  imgSrc: string;
  aspectRatioOptions?: false | Record<string, number>;
  onCrop: (crop?: PixelCrop) => void;
  crop: PixelCrop;
  defaultAspectRatio?: number;
};

const DIALOG_ID = 'image-crop-dialog';

export function ImageCropDialog({
  open,
  imgSrc,
  defaultAspectRatio = 0,
  aspectRatioOptions = DEFAULT_ASPECT_RATIO_OPTIONS,
  onCrop,
  crop,
}: ImageCropDialogProps) {
  const { hlImageCropDialog, btnImageCropDialogCancel, btnImageCropDialogConfirm } = useLabels([
    'hlImageCropDialog',
    'btnImageCropDialogCancel',
    'btnImageCropDialogConfirm',
  ]);

  const imgRef = useRef<HTMLImageElement>(null);
  const [croppedArea, setCroppedArea] = useState<PixelCrop>();

  const [aspect, setAspect] = useState<number | undefined>(() => {
    // If there is only one option then use this
    if (aspectRatioOptions && Object.entries(aspectRatioOptions).length === 1) {
      return Object.values(aspectRatioOptions)[0];
    }
    // If a crop is given, then find the most fitting aspectRatio
    if (aspectRatioOptions && crop) {
      return findClosestAspectRatio(aspectRatioOptions, crop.width / crop.height);
    }
    // Otherwise set default
    return defaultAspectRatio;
  });

  const handleConfirm = useCallback(() => {
    onCrop?.(croppedArea);
  }, [onCrop, croppedArea]);

  const defaultCoordinates = {
    top: crop?.y,
    left: crop?.x,
    width: crop?.width,
    height: crop?.height,
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        onClose={() => {
          /* This prevents the dialog from closing when clicking outside */
        }}
        className={DIALOG_ID}
      >
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-50"
              leave="ease-in duration-200"
              leaveFrom="opacity-50"
              leaveTo="opacity-0"
            >
              <div aria-hidden="true" className={clsx(`${DIALOG_ID}--overlay`, 'fixed inset-0 bg-black opacity-50')} />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              key={'dialog-prepare-publishing'}
            >
              <Dialog.Panel
                className={clsx(
                  `${DIALOG_ID}--panel`,
                  'inline-block w-full max-w-3xl p-4 my-4 bg-white overflow-hidden text-left align-middle transition-all transform shadow-xl rounded',
                )}
              >
                <Dialog.Title as="h3" className={clsx(`${DIALOG_ID}--title`, 'mt-0')}>
                  {hlImageCropDialog}
                </Dialog.Title>

                <div className={clsx(`${DIALOG_ID}--content`, 'mt-4')}>
                  <div className="my-4">
                    {Object.entries(aspectRatioOptions).map(([label, aspectRatio]) => {
                      return (
                        <button type="button" key={label} onClick={() => setAspect(aspectRatio)}>
                          {label}
                        </button>
                      );
                    })}
                  </div>
                  <div className={clsx(stylesCropper.container, 'relative flex justify-center bg-gray')}>
                    <ImageCrop
                      defaultCoordinates={defaultCoordinates}
                      className="meta-cropper"
                      cropperRef={imgRef}
                      aspect={aspect}
                      setCroppedArea={setCroppedArea}
                      imgSrc={imgSrc}
                    />
                  </div>
                </div>

                <div className={clsx(`${DIALOG_ID}--actions`, 'mt-4 flex justify-end')}>
                  <EditorButton label={btnImageCropDialogCancel} onClick={() => onCrop()} />
                  <div className="mx-2" />
                  <EditorButton label={btnImageCropDialogConfirm} onClick={handleConfirm} icon={<CropIcon />} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
