import type { Describe } from 'superstruct';
import { is, literal, number, optional, type } from 'superstruct';

export const PercentCropValidator: Describe<PercentCrop> = type({
  unit: literal('%'),
  x: number(),
  y: number(),
  width: number(),
  height: number(),
});
export const isPercentCrop = (value: unknown): value is PercentCrop => is(value, PercentCropValidator);

export const PixelCropValidator: Describe<PixelCrop> = type({
  unit: literal('px'),
  x: number(),
  y: number(),
  width: number(),
  height: number(),
});
export const isPixelCrop = (value: unknown): value is PixelCrop => is(value, PixelCropValidator);

export const ImageCropsValidator: Describe<ImageCrops> = type({
  rect: optional(PixelCropValidator),
  square: optional(PixelCropValidator),
});
