import type { SVGProps } from 'react';
export const ThumbnailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m1.3438 2c-0.19748 0.03825-0.3473 0.2334-0.34375 0.4375v11.125c0 0.23 0.212 0.438 0.4375 0.438h13.125c0.22555-0.000006 0.43749-0.20846 0.4375-0.4375v-10.953c-0.0005-0.33655-0.2645-0.61145-0.5155-0.6095zm0.65619 0.99994 12 0.0000068v5.0001l-1-1-3 4-3-3-4 4h-1zm2.5001 1c-0.82845 0-1.5 0.67155-1.5 1.5s0.67155 1.5 1.5 1.5 1.5-0.67155 1.5-1.5-0.67155-1.5-1.5-1.5z" />
  </svg>
);
