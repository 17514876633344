import { lookupSelector } from '~store/lookupSelector';
import { selectors as WidgetsFooterSelectors } from '~store/slices/widgetsFooter';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectWidgetsFooterState = lookupSelector(selectRootState, state => state.widgetsFooter);

// Slide: WidgetFooter Selectors
export const selectWidgetsFooterContent = lookupSelector(selectWidgetsFooterState, WidgetsFooterSelectors.content);
export const selectWidgetsFooterFooter = lookupSelector(selectWidgetsFooterState, WidgetsFooterSelectors.footer);
