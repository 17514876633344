import { CSSProperties, FC, useEffect, useState } from 'react';

const stylesContainer = {
  color: '#333',
  margin: 0,
  padding: 0,
  border: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
} as CSSProperties;

const stylesPositioner = {
  width: 100,
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
} as CSSProperties;

const stylesSpinner = {
  border: '0px solid transparent',
  width: 1,
  height: 1,
  borderRadius: '50%',
  transition: 'all 0s ease 0s',
  opacity: 1,
} as CSSProperties;

const stylesAnimation = {
  border: '1px solid #333',
  width: 100,
  height: 100,
  transition: 'all 1s ease 0s',
  opacity: 0,
} as CSSProperties;

export const InitializationLoader: FC = () => {
  const [anim, setAnim] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      setAnim(!anim);
    }, 1000);
    return () => clearInterval(interval);
  }, [anim]);

  const spin = { ...stylesSpinner, ...(anim ? stylesAnimation : {}) };

  return (
    <div style={stylesContainer}>
      <div style={stylesPositioner}>
        <div style={spin} />
      </div>
    </div>
  );
};
