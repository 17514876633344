import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'paragraph--anchor',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      default_langcode: true,
      field_hide_title: false,
      field_hide_in_menu: false,
      field_title: { value: 'I am an Anchor Module', format: 'rich_text' },
    },
    relationships: {},
    links: {},
    log: [],
    permissions: {
      update: true,
      create: true,
      delete: true,
    },
  },
  included: [],
});
