import { useCallback, useMemo, useState } from 'react';

import { postChangelogItem } from '~extension/changelog/api/calls/postChangelogItem';
import { ChangelogType } from '~extension/changelog/model/ChangelogType';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { selectCurrentPageId, selectEntityWithChangesById } from '~store/selectors';
import { publishDraft } from '~store/thunks/draft';

export default function usePublishing() {
  const dispatch = useAppDispatch();
  const currentPageId = useAppSelector(selectCurrentPageId);
  const pageData = useAppSelector(state => selectEntityWithChangesById(state, currentPageId));
  const [isPublishing, setIsPublishingDraft] = useState(false);

  const onPublish = useCallback(
    async ({ msg, type = ChangelogType.NONE }: { msg?: string; type?: ChangelogType }) => {
      try {
        const success = await dispatch(
          publishDraft({ nodeId: pageData.attributes.nid, uuid: pageData.attributes.uuid }),
        ).unwrap();

        if (success) {
          if (type === ChangelogType.NEW || type === ChangelogType.UPDATE) {
            // post to api
            await postChangelogItem({
              nodeId: pageData.attributes.nid,
              description: msg || '',
              newContent: type === ChangelogType.NEW || false,
            });
          }

          // update processing state
          setIsPublishingDraft(false);
        }

        return success;
      } catch (_e) {
        // ToDo: show error message
        setIsPublishingDraft(false);
      }
    },
    [dispatch, pageData.attributes.nid, pageData.attributes.uuid],
  );

  return useMemo(
    () => ({
      isPublishing,
      onPublish,
    }),
    [isPublishing, onPublish],
  );
}
