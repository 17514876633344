import { ReactNode, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import useAppSelector from '~hooks/useAppSelector';
import de from '~lang/de';
import en from '~lang/en';
import { selectContextCurrentAppLanguage, selectTranslations } from '~store/selectors';

type LanguageProviderProps = {
  children: ReactNode;
};

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const locale = useAppSelector(selectContextCurrentAppLanguage);

  const customTranslations = useAppSelector(selectTranslations);

  let messages = useMemo(() => {
    switch (locale) {
      case 'de':
        return de;
      case 'en':
        return en;
      default:
        return en;
    }
  }, [locale]);

  if (customTranslations) {
    messages = { ...messages, ...customTranslations };
  }

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      {children}
    </IntlProvider>
  );
};
