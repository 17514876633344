import { createSelector } from '@reduxjs/toolkit';

import { lookupSelector } from '~store/lookupSelector';
import { selectors as UserSelectors } from '~store/slices/user';
import { capitalizeFirstLetter } from '~utils/string';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectUserState = lookupSelector(selectRootState, state => state.user);

// Slice: User Selectors
export const selectUserStateIset = lookupSelector(selectUserState, UserSelectors.isSet);
export const selectUserCountry = lookupSelector(selectUserState, UserSelectors.country);
export const selectUserCurrentLanguage = lookupSelector(selectUserState, UserSelectors.currentLanguage);
export const selectUserEmail = lookupSelector(selectUserState, UserSelectors.email);
export const selectUserFirstName = lookupSelector(selectUserState, UserSelectors.firstName);
export const selectUserId = lookupSelector(selectUserState, UserSelectors.id);
export const selectUserIsValid = lookupSelector(selectUserState, UserSelectors.isValid);
export const selectUserLastName = lookupSelector(selectUserState, UserSelectors.lastName);
export const selectUserRoles = lookupSelector(selectUserState, UserSelectors.roles);
export const selectUserTracking = lookupSelector(selectUserState, UserSelectors.tracking);
export const selectUserTrackingCompany = lookupSelector(selectUserState, UserSelectors.trackingCompany);
export const selectUserTrackingDivision = lookupSelector(selectUserState, UserSelectors.trackingDivision);
export const selectUserTrackingName = lookupSelector(selectUserState, UserSelectors.trackingName);
export const selectUserTrackingRoles = lookupSelector(selectUserState, UserSelectors.trackingRoles);
export const selectUserUuid = lookupSelector(selectUserState, UserSelectors.uuid);
export const selectUserPermissions = lookupSelector(selectUserState, UserSelectors.permissions);
export const selectUserSiteAccesses = lookupSelector(selectUserState, UserSelectors.siteAccesses);

// Slice: User Custom Selectors
export const selectUserIsLoggedIn = createSelector([selectUserId], id => Boolean(id || 0));
export const selectUserName = createSelector([selectUserFirstName, selectUserLastName], (firstName, lastName) => {
  if (firstName && lastName) {
    const first = capitalizeFirstLetter(firstName);
    const last = capitalizeFirstLetter(lastName);
    return `${first} ${last}`;
  }
  return null;
});

export const selectUserTrackingData = createSelector(
  [
    selectUserCountry,
    selectUserTrackingCompany,
    selectUserTrackingDivision,
    selectUserTrackingName,
    selectUserTrackingRoles,
  ],
  (country, company, division, name, roles) => {
    const t = {} as {
      user_id?: string;
      user_roles?: string;
      user_country?: string;
      user_company?: string;
      user_division?: string;
    };
    if (name) {
      t.user_id = name;
    }
    if (roles) {
      t.user_roles = `#${roles.join('##')}#`;
    }
    if (country) {
      t.user_country = country;
    }
    if (company) {
      t.user_company = company;
    }
    if (division) {
      t.user_division = division;
    }
    return t;
  },
);
