import { URL_REPLACEMENT } from '$const';
import { get, post } from '~services/api/methods';

export const login = async ({ name, pass }: { name: string; pass: string }) => {
  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/user/login?_format=json`;
  const response = await post<ServerSession, string>(
    requestUrl,
    JSON.stringify({
      name,
      pass,
    }),
  );
  // ToDo: add validation! see api/validators/*
  // return create(response, ServerSession);
  return response;
};

export function registerSLOHandlers() {
  if (window.ebm?.instances?.SLO) {
    // run initialize method of SLO again, when the app is ready rendered
    // it will register proper handlers
    window.ebm.instances.SLO.initialize();
  }
}

/**
 * Validates if the user is logged in, if not redirect to login Page
 * Note: this will only be used when process.env.NODE_ENV_LOGIN is false -> drupal login
 */
export const verifyLoginStatus = async () => {
  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/user/login_status?_format=json`;
  return await get<boolean>(requestUrl, { noRedirect: true });
};

export const redirectToLogin = (backendUrl: string) => {
  const curUrl = encodeURIComponent(window.location.pathname);
  const redirectUrl = `${backendUrl}/user/login?destination=${curUrl}`;
  window.location.href = redirectUrl;
};

export const redirectToSamlLogin = (backendUrl: string) => {
  const curUrl = encodeURIComponent(window.location.pathname);
  const redirectUrl = `${backendUrl}/saml/login?destination=${curUrl}`;
  window.location.href = redirectUrl;
};
