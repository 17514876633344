import { Input } from '~components/generic/elements/Input/Input';
import { SidebarContextSettingsFieldInputProps } from '~components/sidebar/context/SidebarContextSettingsField';

export const SidebarContextSettingsText = ({ data, fieldDef, onChange }: SidebarContextSettingsFieldInputProps) => {
  return (
    <div className="c-settings__field">
      <Input type="text" label={fieldDef.options.label} onChange={onChange} value={data} className="u-full-width" />
    </div>
  );
};

export default SidebarContextSettingsText;
