import clsx from 'clsx';

import { FavouriteButton } from '~extension/favourite/components/FavouriteButton';
import useFavouriteService from '~extension/favourite/hook/useFavouriteService';
import useAppSelector from '~hooks/useAppSelector';
import { selectCurrentPageId, selectDocumentTitle, selectSidebarIsCollapsed } from '~store/selectors';
import { AnchorSelect } from './AnchorSelect';
import styles from './ToolsNav.module.css';

export type ToolsNavProps = {
  title?: string;
};

export function ToolsNav({ title }: ToolsNavProps) {
  const favouriteService = useFavouriteService();
  const sidebarCollapsed = useAppSelector(selectSidebarIsCollapsed);
  const documentTitle = useAppSelector(selectDocumentTitle);
  const currentPageId = useAppSelector(selectCurrentPageId);
  const isContentPage = currentPageId !== null;

  return (
    <div className={clsx(styles.nav, 'tools-nav', sidebarCollapsed && 'sidebar-open')}>
      <p>{title || documentTitle}</p>
      {isContentPage && (
        <div className="system-tools">
          <AnchorSelect />
          {favouriteService.isFavouriteAddonEnabled() && <FavouriteButton />}
        </div>
      )}
    </div>
  );
}
