/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { URL_REPLACEMENT } from '$const';
import { redirectToSamlLogin } from '~services/api/calls/user';
import { ApiErrorDescriptor, getResponseError } from '~services/api/helpers';
import { getConfigBackendUrl } from '~store/getter';
import { ellipsisifyRight } from '~utils/ellipsisify';
import noop from '~utils/noop';
import { removeUndefined } from '~utils/object';
import replaceUrl from '~utils/replaceUrl';
import stripHtml from '~utils/stripHtml';

const sanitizeUrl = (url: string) => encodeURI(replaceUrl(url));

type RequestConfigOptions = {
  useXwwwFormUrlencoded?: boolean;
  useBearerToken?: boolean;
  extraHeaders?: Record<string, string> | Record<string, number>;
  noRedirect?: boolean;
  onDownloadProgress?: AxiosRequestConfig['onDownloadProgress'];
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  withCredentials?: AxiosRequestConfig['withCredentials'];
  withResponseType?: boolean;
};

function makeRequestConfig(url: string, options?: RequestConfigOptions): AxiosRequestConfig {
  const useBearerToken = options?.useBearerToken || url.includes(URL_REPLACEMENT.SYMFONY_URL) || false;
  const useXwwwFormUrlencoded = options?.useXwwwFormUrlencoded || false;
  const extraHeaders = options?.extraHeaders || {};
  const noRedirect = options?.noRedirect || false;
  let withCredentials =
    options?.withCredentials ||
    [URL_REPLACEMENT.API_BASE, URL_REPLACEMENT.BACKEND_URL].some(u => url.includes(u)) ||
    false;
  const onDownloadProgress = options?.onDownloadProgress || noop;
  const onUploadProgress = options?.onUploadProgress || noop;

  // Read Auth Token if required
  let Authorization: string;
  if (useBearerToken) {
    try {
      const { oauthTokenManager } = window.ebm.instances;
      const bearerToken = oauthTokenManager.getAccessToken();
      if (!bearerToken) {
        throw new Error('No Bearer Token found.');
      }
      Authorization = `Bearer ${bearerToken}`;
    } catch (error) {
      const errorMessage = (error as Error)?.message || error.toString() || error;
      console.groupCollapsed(
        '%c API Config Error %c %s',
        'background-color: red; color: white; font-weight: bold;',
        'color: red;',
        errorMessage,
      );
      console.log('%c --- Request ---', 'color: blue;');
      console.log('URL: %s', url);
      console.groupEnd();
    }
  }

  // Compose headers object
  const headers = removeUndefined({
    'Content-Type': useXwwwFormUrlencoded ? 'application/x-www-form-urlencoded' : 'application/json',
    ...(Authorization && { Authorization }),
    ...extraHeaders,
  });

  // Use withCredentials (add Cookies to request) where there is no 'Authorization' Header AND the requested URL contains known parts (eg. BackendURL)
  withCredentials = !Authorization && withCredentials;

  // Do not allow redirect if noRedirect is set
  const maxRedirects = noRedirect ? 0 : Infinity;

  const responseType = options?.withResponseType ? 'arraybuffer' : null;

  return {
    headers,
    responseType,
    withCredentials,
    maxRedirects,
    onDownloadProgress,
    onUploadProgress,
  };
}

const sanitizeData = <T>(data: T, useXwwwFormUrlencoded = false) => {
  if (useXwwwFormUrlencoded) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(data)) {
      params.append(key, value as string);
    }
    return params;
  }
  return data;
};

const getErrorMessage = (response: AxiosResponse): string => {
  const { data } = response;
  if (data) {
    if (typeof data === 'string') {
      return data;
    } else if (Object.prototype.hasOwnProperty.call(data, 'message')) {
      return data.message;
    } else if (Object.prototype.hasOwnProperty.call(data, 'error')) {
      if (typeof data.error === 'string') {
        return data.error;
      } else if (Object.prototype.hasOwnProperty.call(data.error, 'info')) {
        return data.error.info;
      } else {
        try {
          const j = JSON.stringify(data.error);
          return j;
        } catch (_) {
          /* Do nothing */
        }
      }
    } else if (
      Object.prototype.hasOwnProperty.call(data, 'errors') &&
      Array.isArray(data.errors) &&
      data.errors.length
    ) {
      return `${data.errors[0].title as string}: ${stripHtml(data.errors[0].detail)}`;
    } else if (Object.prototype.hasOwnProperty.call(data, 'info')) {
      return data.info;
    }
  }
  return response.statusText;
};

const getErrorDescription = (response: AxiosResponse): string => {
  const { data } = response;
  if (data) {
    if (Object.prototype.hasOwnProperty.call(data, 'error_description')) {
      return data.error_description;
    }
  }
  return '';
};

export const handleAxiosError = (error: Error | AxiosError): never => {
  if (axios.isAxiosError(error)) {
    let errorMessage = 'Unknwon API Error';
    let errorDescription = '';
    let responseData: unknown, status: number, statusText: string;

    const { url, method, data: requestData } = error.config;
    if (error.response) {
      responseData = error.response.data;
      status = error.response.status;
      statusText = error.response.statusText;
      errorMessage = getErrorMessage(error.response);
      errorDescription = getErrorDescription(error.response);
    }

    console.groupCollapsed(
      '%c API Error %c %s',
      'background-color: red; color: white; font-weight: bold;',
      'color: red;',
      ellipsisifyRight(errorMessage, 80),
    );
    console.log('%c --- Request ---', 'color: blue;');
    console.log('METHOD: %s', method);
    console.log('URL: %s', url);
    console.log('DATA: %o', requestData);
    console.log('%c --- Response ---', 'color: yellow;');
    console.log('STATUS: %d', status);
    console.log('STATUSTEXT: %s', statusText);
    console.log('DATA: %o', responseData);

    // Handle network / timeout errors
    if (!error.response) {
      // We fake a 504 Gateway timeout error
      status = 504;
      statusText = 'Gateway timeout';
      errorMessage = 'Connection timed out';
      console.log('%c --- Interpreted ---', 'color: green;');
      console.log('STATUS: %d', status);
      console.log('STATUSTEXT: %s', statusText);
    }

    // If the status code indicates 401, signifying unauthorized access,
    // it is necessary to initiate a redirection to the saml login page.
    if (status === 401 && errorDescription === 'OAuth2 authentication required') {
      redirectToSamlLogin(getConfigBackendUrl());
    }

    console.groupEnd();
    throw new ApiErrorDescriptor(errorMessage, status, url);
  } else {
    // Just a stock error
    console.error(error);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = (error as any)?.response?.data;
    const errorMsg = getResponseError(data);
    throw new Error(errorMsg);
  }
};

export const get = async <T>(url: string, options?: RequestConfigOptions) => {
  const response = await axios.get<T>(sanitizeUrl(url), makeRequestConfig(url, options)).catch(handleAxiosError);
  return response.data;
};

export const post = async <T, S extends {} = {}>(url: string, data: S, options?: RequestConfigOptions) => {
  const response = await axios
    .post<T>(sanitizeUrl(url), sanitizeData(data, options?.useXwwwFormUrlencoded), makeRequestConfig(url, options))
    .catch(handleAxiosError);
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patch = async <T, S = undefined | {}>(url: string, data?: S, options?: RequestConfigOptions) => {
  const response = await axios
    .patch<T>(
      sanitizeUrl(url),
      sanitizeData(data ? data : undefined, options?.useXwwwFormUrlencoded),
      makeRequestConfig(url, options),
    )
    .catch(handleAxiosError);
  return response.data;
};

export const patchMultiple = async <T extends any[]>(
  ...patchConfigs: { url: string; data?: any; options?: RequestConfigOptions }[]
) => {
  const response = await axios.all(patchConfigs.map(config => patch(config.url, config.data, config.options))).then(
    axios.spread((...data) => {
      return data;
    }),
  );
  return response as T;
};

export const remove = async <T = undefined>(url: string, options?: RequestConfigOptions) => {
  const response = await axios.delete<T>(sanitizeUrl(url), makeRequestConfig(url, options)).catch(handleAxiosError);
  return response.data;
};
