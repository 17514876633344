import 'react-app-polyfill/stable';
// Initializing locale data for 'en' and 'de'
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/de'; // Add locale data for de
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de'; // Add locale data for de

// import { StrictMode } from 'react';
import { render } from 'react-dom';

import { Auth as App } from './Auth';

render(<App />, document.getElementById('root'));

/*
render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
*/
