import { useMemo } from 'react';

import SidebarContextSettingsField from '~components/sidebar/context/SidebarContextSettingsField';
import { IEntitySettingSectionDefinition } from '~services/entity-definitions';

type SidebarContextSettingsSectionProps = {
  data: Entity;
  sectionDef: IEntitySettingSectionDefinition;
  onChange: (name: string, val: ChangesetValueType) => void;
  onUpload: (payload: UploadFilesPayload) => void;
  isPdfEnabled: boolean;
};

export const SidebarContextSettingsSection = ({
  data,
  sectionDef: { fields, title },
  onChange,
  onUpload,
  isPdfEnabled,
}: SidebarContextSettingsSectionProps) => {
  const dataAttributeKeys = useMemo(() => Object.keys(data.attributes), [data.attributes]);
  const filteredFieldNames = useMemo(
    () =>
      Object.keys(fields)
        .filter(fieldName => {
          // if pdf is disabled via the config api then
          // dont show the pdf settings fields
          if (!isPdfEnabled && fieldName.indexOf('pdf') !== -1) {
            return false;
          }

          // only add option if attribute is defined in data
          if (
            fieldName.startsWith('attributes.field_') &&
            !dataAttributeKeys.includes(fieldName.replace('attributes.', '').replace('.value', ''))
          ) {
            return false;
          }

          return true;
        })
        .sort((a, b) => {
          return +(fields[a].weight || 0) - +(fields[b].weight || 0);
        }),
    [dataAttributeKeys, fields, isPdfEnabled],
  );

  return (
    <div className="c-settings__section u-margin-bottom-md">
      <h5 className="u-color-white">{title}</h5>
      {filteredFieldNames.map(fieldName => {
        const fieldDef = fields[fieldName];
        return (
          <SidebarContextSettingsField
            key={fieldName}
            onChange={onChange}
            onUpload={onUpload}
            fieldDef={fieldDef}
            fieldName={fieldName}
            data={data}
            dependency={fieldDef.dependency}
          />
        );
      })}
    </div>
  );
};

export default SidebarContextSettingsSection;
