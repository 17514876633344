import clsx from 'clsx';
import { Component, lazy, Suspense } from 'react';

import { contentClassesBase, sidebarClassesBase, wrapperClassesBase } from '~components/app/app-definitions';
import Loader from '~components/generic/loader/Loader';
import JiraRequestWidget from '~components/jira-request-widget/JiraRequestWidget';
import Notifications from '~components/notifications/Notifications';
import { Sidebar } from '~components/sidebar/Sidebar';
import { SidebarExpander } from '~components/sidebar-expander/SidebarExpander';
import { ProviderBag } from '~context/ProviderBag';
import SiteAccessSwitch from '~extension/siteaccess-switch';
import { TankLabellingPage } from '~pages/TankLabellingPage';
import { TruckLabelingPage } from '~pages/TruckLabelingPage';
import { registerSLOHandlers } from '~services/api/calls/user';
import { RouterActions } from '~store/actions';
import appStyles from '~components/app/styles.css';
import globalStyles from '~styles/GlobalStyles.module.css';

const ChangelogPage = lazy(() => import('~pages/ChangelogPage'));
const ContentPage = lazy(() => import('~pages/ContentPage'));
const DowloadRequestPage = lazy(() => import('~pages/DownloadRequestPage'));
const EditComponentsPage = lazy(() => import('~pages/EditComponentsPage'));
const EditNavigationPage = lazy(() => import('~pages/EditNavigationPage'));
const EditUsergroupsPage = lazy(() => import('~pages/EditUsergroupsPage'));
const FavouritesPage = lazy(() => import('~pages/FavouritesPage'));
const ForbiddenPage = lazy(() => import('~pages/ForbiddenPage'));
const IconLibraryPage = lazy(() => import('~pages/IconLibraryPage'));
const LoginPage = lazy(() => import('~pages/LoginPage'));
const MyFilesPage = lazy(() => import('~pages/MyFilesPage'));
const NotFoundPage = lazy(() => import('~pages/NotFoundPage'));
const SearchPage = lazy(() => import('~pages/SearchPage'));
const TimeoutPage = lazy(() => import('~pages/TimeoutPage'));
const Web2PrintPage = lazy(() => import('~pages/Web2PrintPage'));

export interface IAppProps {
  isRouterReady: boolean;
  isLoggedIn: boolean;
  isMenuEditing: boolean;
  currentPageId?: string;
  documentTitle: string;
  showEmptyPage: boolean;
  emptyPageTemporaryEntityId?: string;
  collapsedSidebar: boolean;
  showEditLoading: boolean;
  pageType: string;
  isPageNotFound: boolean;
  isPageForbidden: boolean;
  isPageLoading: boolean;
  isPageLoaded: boolean;
  isPageTimedOut: boolean;
  isInAppPage: boolean;
}

export class AppClass extends Component<IAppProps> {
  public static setDocumentTitle(title: string) {
    document.title = title;
  }

  componentDidMount() {
    // when the app is ready rendered, run initialize method of SLO again
    registerSLOHandlers();
  }

  componentDidUpdate() {
    AppClass.setDocumentTitle(this.props.documentTitle);
  }

  renderInAppPage() {
    const { pageType, currentPageId } = this.props;

    switch (pageType) {
      case RouterActions.navigateToSearch.type:
        return <SearchPage />;
      case RouterActions.navigateToLogin.type:
        return <LoginPage />;
      case RouterActions.navigateToPage.type:
      case RouterActions.navigateToPageWithAnchor.type:
        return <ContentPage entityId={currentPageId} />;
      case RouterActions.navigateToIconLibrary.type:
        return <IconLibraryPage />;
      case RouterActions.navigateToEditComponents.type:
        return <EditComponentsPage />;
      case RouterActions.navigateToEditNavigation.type:
        return <EditNavigationPage />;
      case RouterActions.navigateToEditUsergroups.type:
        return <EditUsergroupsPage />;
      case RouterActions.navigateToFavourites.type:
        return <FavouritesPage />;
      case RouterActions.navigateToMyfiles.type:
        return <MyFilesPage />;
      case RouterActions.navigateToChangelog.type:
        return <ChangelogPage />;
      case RouterActions.navigateToWeb2Print.type:
        return <Web2PrintPage />;
      case RouterActions.navigateToTruckLabeling.type:
        return <TruckLabelingPage />;
      case RouterActions.navigateToTankLabeling.type:
        return <TankLabellingPage />;
      case RouterActions.navigateToDownloadRequest.type:
        return <DowloadRequestPage />;
      default:
        return null;
    }
  }

  render() {
    const {
      isRouterReady,
      isLoggedIn,
      showEmptyPage,
      emptyPageTemporaryEntityId,
      collapsedSidebar,
      isPageNotFound,
      isPageForbidden,
      isPageLoading,
      isPageLoaded,
      isPageTimedOut,
      showEditLoading,
      currentPageId,
      isInAppPage,
    } = this.props;

    const wrapperClasses = clsx({
      ...wrapperClassesBase,
      [appStyles.wrapperSidebarCollapsed]: collapsedSidebar,
    });
    const sidebarClasses = clsx({
      ...sidebarClassesBase,
      'c-app__sidebar_expanded': this.props.isMenuEditing,
    });
    const contentClasses = clsx({
      ...contentClassesBase,
    });

    // Early exit if router is not finished processing url data
    if (!isRouterReady) return null;

    return (
      <ProviderBag>
        <div className={wrapperClasses}>
          {isLoggedIn && (
            <>
              <JiraRequestWidget />

              <aside className={sidebarClasses}>
                <Sidebar />
              </aside>
            </>
          )}
          {isLoggedIn && <SidebarExpander collapsed={collapsedSidebar} />}
          <div className={contentClasses}>
            <main className={clsx(appStyles.main, 'c-main', globalStyles.globalTailwindClasses)}>
              <Suspense fallback={<Loader absoluteCenter={true} />}>
                {isPageLoading && <Loader absoluteCenter={true} />}
                {showEditLoading && <Loader absoluteCenter={true} transparentBG={true} />}
                {isPageForbidden && <ForbiddenPage />}
                {isPageNotFound && <NotFoundPage />}
                {isPageTimedOut && <TimeoutPage />}
                {showEmptyPage && <ContentPage entityId={emptyPageTemporaryEntityId} />}
                {!!currentPageId && isPageLoaded && <ContentPage entityId={currentPageId} />}
                {isInAppPage && this.renderInAppPage()}
              </Suspense>
            </main>
          </div>
          <Notifications />
        </div>
        <SiteAccessSwitch />
      </ProviderBag>
    );
  }
}

export default AppClass;
