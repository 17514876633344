import type { TypedStartListening } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '$store';
import {
  navigatedToDefault,
  navigatedToLogin,
  navigatedToLogout,
  navigatedToNotFound,
  navigatedToPage,
} from './thunks/navigation';
import { RouterActions } from './actions';

export const RoutingMiddleware = createListenerMiddleware();
const startListening = RoutingMiddleware.startListening as TypedStartListening<RootState, AppDispatch>;

startListening({
  type: RouterActions.notFound.type,
  effect: async (_action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    await listenerApi.dispatch(navigatedToNotFound());
  },
});

startListening({
  type: RouterActions.navigateToLogin.type,
  effect: async (_action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    await listenerApi.dispatch(navigatedToLogin());
  },
});

startListening({
  type: RouterActions.navigateToLogout.type,
  effect: async (_action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    await listenerApi.dispatch(navigatedToLogout());
  },
});

startListening({
  predicate: action => [RouterActions.navigateStart.type, RouterActions.navigateToDefault.type].includes(action.type),
  effect: async (_action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    await listenerApi.dispatch(navigatedToDefault());
  },
});

startListening({
  predicate: action =>
    [
      RouterActions.navigateToPage.type,
      RouterActions.navigateToPageWithAnchor.type,
      RouterActions.navigateToPageWithAsset.type,
    ].includes(action.type),
  effect: async (_action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    await listenerApi.dispatch(navigatedToPage());
  },
});
