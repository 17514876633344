import AnchorLinkWidgetContainer from '~components/generic/elements/AnchorLinkSelector/AnchorLinkWidgetContainer';
import { SidebarContextSettingsFieldInputProps } from '~components/sidebar/context/SidebarContextSettingsField';

export const SidebarContextSettingsLink = ({
  parentContext,
  fieldDef: { options },
}: SidebarContextSettingsFieldInputProps) => {
  return (
    <div className="c-settings__field">
      <AnchorLinkWidgetContainer entityId={parentContext} {...options} />
    </div>
  );
};

export default SidebarContextSettingsLink;
