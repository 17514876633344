import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';

export const XlSettings = {
  blockType: 'type-xl',
  className: 'u-size-xl',
  element: 'p',
};

export const XlButton = createBlockStyleButton({
  blockType: XlSettings.blockType,
  children: 'XL',
});
