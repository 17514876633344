import { create } from 'superstruct';

import { URL_REPLACEMENT } from '$const';
import { get } from '~services/api/methods';
import { FetchTopicsValidator } from '~services/api/validators/Topics';

export enum TopicsApiKeysEnum {
  FetchTopics = 'fetchTopics',
}

export async function fetchTopics() {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/../ebm/meta/topics`;
  let response;
  try {
    response = await get(requestUrl);
  } catch (_e) {
    response = {};
  }
  return create(response, FetchTopicsValidator);
}
