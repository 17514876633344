import { useCallback, useState } from 'react';

import { Button as EditorButton } from '~components/generic/editor/Button/Button';
import { ButtonGroup as EditorButtonGroup } from '~components/generic/editor/ButtonGroup/ButtonGroup';
import { InputText } from '~components/generic/user-input/InputText';
import { InputTextArea } from '~components/generic/user-input/InputTextArea';
import { CheckIcon, CloseIcon } from '~components/icons';
import useLabels from '~hooks/useLabels';
import { ImageSelector } from './ImageSelector';
import { TagSelector } from './TagSelector';

type MetaPanelProps = {
  onSave: (options) => PromiseLike<boolean>;
  saving: boolean;
  onClose: () => void;
  title: string;
  description: string;
  topics: Topic[];
  image: MetaImage;
  crops: ImageCrops;
};

export function MetaPanel({ onSave, onClose, title, description, topics, saving, crops, image }: MetaPanelProps) {
  const {
    labelMetaDescription,
    labelMetaTitle,
    hlMetaPanel,
    msgMetaPanel,
    btnMetaInformation,
    msgCancelMetaHint,
    msgSaveMetaHint,
    placeholderMetaDescription,
    placeholderMetaTitle,
  } = useLabels([
    'labelMetaDescription',
    'labelMetaTitle',
    'hlMetaPanel',
    'msgMetaPanel',
    'btnMetaInformation',
    'msgCancelMetaHint',
    'msgSaveMetaHint',
    'placeholderMetaDescription',
    'placeholderMetaTitle',
  ]);

  // state
  const [txtTitle, setTxtTitle] = useState(title);
  const [txtDescription, setTxtDescription] = useState(description);
  const [selectedTopics, setSelectedTopics] = useState(topics);
  const [selectedCrops, setSelectedCrops] = useState(crops);
  const [selectedImage, setSelectedImage] = useState(image);

  const handleImageChange = useCallback((image: MetaImage) => {
    setSelectedImage(image);
    // whenever the image changes, crops have to be reset
    setSelectedCrops(undefined);
  }, []);

  function handleCancelClick() {
    onClose?.();
  }
  async function handleSaveClick() {
    await onSave?.({
      title: txtTitle,
      description: txtDescription,
      topics: selectedTopics,
      crops: selectedCrops,
      image: selectedImage,
    });
    onClose?.();
  }

  return (
    <div className="relative mt-3 mb-2">
      <div className="bg-white rounded shadow-md p-2 font-sans">
        <div className="flex">
          <div className="flex-1 pl-2 pt-2 pb-2">
            <div className="text-xl font-bold">{hlMetaPanel}</div>
            <div className="text-base my-2">{msgMetaPanel}</div>
          </div>
          <div className="border-gray border-r border-solid mx-3 self-stretch" />
          <div className="w-8" />
          <div className="w-1/3 flex justify-end">
            <div className="mt-2 mr-1">
              <EditorButtonGroup preIcon="text" label={btnMetaInformation}>
                <EditorButton
                  title={msgCancelMetaHint}
                  icon={<CloseIcon />}
                  onClick={handleCancelClick}
                  disabled={saving}
                />
                <EditorButton
                  title={msgSaveMetaHint}
                  icon={<CheckIcon />}
                  onClick={handleSaveClick}
                  processing={saving}
                />
              </EditorButtonGroup>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 pl-2 pb-2">
            <ImageSelector
              crops={selectedCrops}
              onChangeCrops={setSelectedCrops}
              image={selectedImage}
              onChangeImage={handleImageChange}
            />
            <div className="mt-3">
              <InputText
                label={labelMetaTitle}
                defaultValue={txtTitle}
                placeholder={placeholderMetaTitle}
                onDebouncedChange={setTxtTitle}
                hasChange={title !== txtTitle}
              />
            </div>
            <div className="mt-3">
              <InputTextArea
                label={labelMetaDescription}
                defaultValue={txtDescription}
                placeholder={placeholderMetaDescription}
                onDebouncedChange={setTxtDescription}
                hasChange={description !== txtDescription}
              />
            </div>
          </div>
          <div className="border-gray border-r border-solid mx-3 self-stretch" />
          <div className="w-1/3 pb-4">
            <TagSelector topics={selectedTopics} onChange={setSelectedTopics} saving={saving} />
          </div>
          <div className="w-8 h-8" />
        </div>
      </div>
    </div>
  );
}
