export function findMenuElementByAlias(elements: MenuElement[] = [], alias: string, exact = true): MenuElement {
  for (const element of elements) {
    if (exact && element.alias === alias) {
      return element;
    } else if (element.alias.indexOf(alias) !== -1) {
      return element;
    }
    const childElement = findMenuElementByAlias(element.children, alias, exact);
    if (childElement) {
      return childElement;
    }
  }
  return;
}

export function findMenuElementsByAlias(elements: MenuElement[], alias: string, exact = true): MenuElement[] {
  let foundMenuElements: MenuElement[] = [];
  elements.forEach(element => {
    if (exact && element.alias === alias) {
      foundMenuElements.push(element);
    }
    if (!exact && element.alias.indexOf(alias) !== -1) {
      foundMenuElements.push(element);
    }
    if (element.children) {
      const foundChildElements = findMenuElementsByAlias(element.children, alias, exact);
      foundMenuElements = [...foundMenuElements, ...foundChildElements];
    }
  });
  return foundMenuElements;
}

export function findMenuElementById(elements: MenuElement[] = [], id: string): MenuElement | undefined {
  // ToDo: The recursive 'findMenuElementById' would be obsolete if the menu state was normalized
  for (const element of elements) {
    if (element.id === id) {
      return element;
    }
    const childElement = findMenuElementById(element.children, id);
    if (childElement) {
      return childElement;
    }
  }
  return undefined;
}
