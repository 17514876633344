import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ModuleTypes } from '$const';
import makeSlug from '~utils/makeSlug';
import stripHtml from '~utils/stripHtml';

// State
export type EntitiesState = Record<string, EntityInformation>;

// Initial State
export const initialState: EntitiesState = {};

// Reducers
const setEntity: CaseReducer<EntitiesState, PayloadAction<EntityInformation>> = (state, action) => {
  if (action.payload) {
    const eInfo = action.payload;

    // make correct draft data
    eInfo.status = {
      ...state[eInfo.id]?.status,
      ...eInfo.status,
    };
    eInfo.permissions = eInfo.permissions || undefined;

    // create raw_field_title
    if (eInfo.data?.attributes?.field_title?.value) {
      eInfo.data.attributes.raw_field_title = stripHtml(eInfo.data.attributes.field_title?.value || '');
    }

    // prepare anchors
    if (eInfo.type === ModuleTypes.anchorLink) {
      eInfo.data.attributes.slug = makeSlug(eInfo.data.attributes.field_title?.value || '');
    }

    state[eInfo.id] = eInfo;
  }
};
const setEntities: CaseReducer<EntitiesState, PayloadAction<EntityInformation[]>> = (state, action) => {
  if (action.payload) {
    action.payload.forEach(eInfo => {
      // create raw_field_title
      if (eInfo.data?.attributes?.field_title?.value) {
        eInfo.data.attributes.raw_field_title = stripHtml(eInfo.data.attributes.field_title?.value || '');
      }

      // prepare anchors
      if (eInfo.type === ModuleTypes.anchorLink) {
        eInfo.data.attributes.slug = makeSlug(eInfo.data.attributes.field_title?.value || '');
      }

      state[eInfo.id] = eInfo;
    });
  }
};
const unsetEntity: CaseReducer<EntitiesState, PayloadAction<string>> = (state, action) => {
  if (action.payload) {
    delete state[action.payload];
  }
};
const setEntityCompleted: CaseReducer<EntitiesState, PayloadAction<Partial<EntityInformation>>> = (state, action) => {
  if (action.payload?.id) {
    const { id, type } = action.payload;
    state[id] = {
      id,
      type,
      ...state[id],
      status: {
        ...(state[id] ? state[id].status : {}),
        isComplete: true,
      },
    };
  }
};
const unsetEntityCompleted: CaseReducer<EntitiesState, PayloadAction<Partial<EntityInformation>>> = (state, action) => {
  if (action.payload?.id) {
    const { id, type } = action.payload;
    state[id] = {
      id,
      type,
      ...state[id],
      status: {
        ...(state[id] ? state[id].status : {}),
        isComplete: false,
      },
    };
  }
};
const setEntityFailed: CaseReducer<EntitiesState, PayloadAction<{ id: string; message: string }>> = (state, action) => {
  if (action.payload?.id && action.payload?.message) {
    state[action.payload.id].status.isFetching = false;
    state[action.payload.id].status.error = action.payload.message;
  }
};

// Slice
const slice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setEntities,
    setEntity,
    setEntityCompleted,
    setEntityFailed,
    unsetEntity,
    unsetEntityCompleted,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: EntitiesState) => state;
export const selectors = {
  self: selectSelf,
  entities: (state: EntitiesState) => state,
};
