import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { fetchTopics, TopicsApiKeysEnum } from '~services/api/calls/topics';
import { createTopicStore, EMPTY_TOPIC_STORE } from '~services/api/factories/createTopicStore';

async function fetchAndNormalizeTopics() {
  const fetchedTopics = await fetchTopics();
  const topicStore = createTopicStore(fetchedTopics);
  return topicStore;
}

export function useTopicsQuery(enabled: boolean) {
  const [dataTopics, setDataTopics] = useState<TopicStore>();
  const [isFetchedTopics, setIsFetchedTopics] = useState(false);
  const [isErrorTopics, setIsErrorTopics] = useState(false);
  const { data, isFetched, isError } = useQuery([TopicsApiKeysEnum.FetchTopics], fetchAndNormalizeTopics, {
    enabled,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    placeholderData: EMPTY_TOPIC_STORE,
  });

  useDeepCompareEffect(() => {
    setDataTopics(data);
    setIsFetchedTopics(isFetched);
    setIsErrorTopics(isError);
  }, [data, isFetched, isError]);

  return { dataTopics, isFetchedTopics, isErrorTopics };
}
