import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type ContextState = ApplicationContext;

// Initial State
export const initialState: ContextState = {} as ContextState;

// Reducers
const init: CaseReducer<ContextState, PayloadAction<ApplicationContext>> = (_state, action) => action.payload;
const setLanguage: CaseReducer<ContextState, PayloadAction<string>> = (state, action) => {
  if (action.payload) {
    state.currentAppLanguage = action.payload;
  }
};

// Slice
const slice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    init,
    setLanguage,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: ContextState) => state;
export const selectors = {
  self: selectSelf,
  currentAppLanguage: (state: ContextState) => state.currentAppLanguage,
};
