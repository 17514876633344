import { FC } from 'react';

import Logo from '~components/generic/logo/Logo';
import { IEntityComponentProps } from '~interfaces/props';
import { getEntityDefinition } from '~services/entity-definitions';
import { getConfigAppName } from '~store/getter';

export interface ISidebarHeaderProps extends IEntityComponentProps {
  showSettings: boolean;
  onCloseSidebar: () => void;
}

const SidebarHeader: FC<ISidebarHeaderProps> = ({ showSettings, onStopEdit, onCloseSidebar, data }) => {
  const entityDef = data && getEntityDefinition(data.type);
  const title = entityDef?.meta.name || 'Entity';

  return (
    <div className="c-sidebar__header px-0">
      <div className="row">
        <div className="col col-sm-3 navigation-button">
          {showSettings ? (
            <a onClick={onStopEdit} style={{ textDecoration: 'none' }}>
              <i className="c-icon-custom c-icon-custom--vertical-centered c-icon-custom--arrow-left u-size-lg" />
              <span className="u-sr-only">Stop edit</span>
            </a>
          ) : (
            <a onClick={onCloseSidebar} className="nav-sidebar-icon" style={{ textDecoration: 'none' }}>
              <i className="c-icon-custom c-icon-custom--vertical-centered c-icon-custom--burger u-size-lg" />
              <span className="u-sr-only">Collapse sidebar</span>
            </a>
          )}
        </div>
        <div className="col col-sm-9 u-margin-bottom-lg u-color-white o-text--right">
          {showSettings ? (
            <div className="">
              <h4>{title}</h4>
            </div>
          ) : (
            <a href="/" className="u-block">
              <div className="u-sr-only">Back to the main page</div>
              <div className="c-logo">
                <div className="c-logo__image">
                  <Logo />
                </div>
                <h2 className="c-logo__text">{getConfigAppName()}</h2>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarHeader;
