import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState, ThunkCreator } from '$store';
import { requestAnchors } from '~services/api/calls/anchors';
import log from '~services/log';
import { ControlActions, DraftEntitiesActions, EntitiesActions } from '~store/actions';
import { selectAnchorElementsByName } from '~store/selectors/custom';
import { selectMenuElementsByName } from '~store/selectors/menu';

export const fetchAnchors = createAsyncThunk<void, void, { state: RootState }>(
  'anchors/fetch',
  async (_, { dispatch }) => {
    try {
      const anchorsResponse = await requestAnchors();
      if (anchorsResponse.published) {
        const allEntityInfos = anchorsResponse.published.map(entity => ({
          id: entity.id,
          type: entity.type,
          status: { isFetching: false, isComplete: true },
          data: entity,
        }));
        dispatch(EntitiesActions.setEntities(allEntityInfos));
      }
      if (anchorsResponse.draft) {
        const allDraftEntityInfos = anchorsResponse.draft.map(entity => ({
          id: entity.id,
          type: entity.type,
          status: { isFetching: false, isComplete: true },
          data: entity,
        }));
        dispatch(DraftEntitiesActions.setDraftEntities(allDraftEntityInfos));
      }
    } catch (e) {
      log('Error in fetchAnchors thunk!', e, 'error');
    }
  },
);

export const getAnchorLinkSuggestions: ThunkCreator = (query: string) => (dispatch, getState) => {
  try {
    const anchorsResponse = selectAnchorElementsByName(getState(), query);
    const menuResponse = selectMenuElementsByName(getState(), query);

    const internalLinks = (menuResponse || []).map(menuElement => ({
      alias: menuElement.alias,
      id: menuElement.id,
    }));

    const maxItems = 30;
    const returnObj = {
      anchors: anchorsResponse.slice(0, maxItems),
      links: internalLinks.slice(0, maxItems),
    };

    dispatch(ControlActions.triggerAnchorLinkAutosuggestSearchSuccess(returnObj));
  } catch (e) {
    log('Error in getAnchorLinkSuggestions thunk!', e, 'error');
  }
};
