import { lookupSelector } from '~store/lookupSelector';
import { selectors as LoginSelectors } from '~store/slices/login';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectLoginState = lookupSelector(selectRootState, state => state.login);

// Slice: Login Selectors
export const selectLoginText = lookupSelector(selectLoginState, LoginSelectors.text);
export const selectLoginTitle = lookupSelector(selectLoginState, LoginSelectors.title);
