import { EBM_INTERN_COOKIE_CONSENT } from 'env';

import useAppSelector from '~hooks/useAppSelector';
import { selectCookieModalButtons, selectCookieModalTexts } from '~store/selectors';
import { CookieHint } from './CookieHint';

export function CookieConsent() {
  const cookieTexts = useAppSelector(selectCookieModalTexts);
  const cookieButtons = useAppSelector(selectCookieModalButtons);

  if (EBM_INTERN_COOKIE_CONSENT && cookieTexts && cookieTexts.length && cookieTexts[0]) {
    return <CookieHint texts={cookieTexts} buttons={cookieButtons} />;
  }

  return null;
}
