import appStyles from './styles.css';

export const wrapperClassesBase = {
  'o-text': true,
  'c-app': true,
  'd-flex': true,
  'o-grid--fit': true,
  [appStyles.wrapper]: true,
};

export const sidebarClassesBase = {
  'c-app__sidebar': true,
  'o-panel-container': true,
  'o-grid__cell': true,
  'o-grid__cell--width-fixed': true,
  'px-0': true,
};

export const contentClassesBase = {
  'c-app__content': true,
  'o-panel-container': true,
  'flex-item': true,
  'o-grid__cell': true,
  'o-grid__cell--restrict-width': true,
  'u-margin-horizontal-none': true,
  [appStyles.content]: true,
};
