import { coerce, defaulted, Describe, is, nullable, number, optional, string, type } from 'superstruct';

export const IconLibraryValidator: Describe<IconLibraryDto> = defaulted(
  type({
    iconLibrarySvgFillColors: optional(nullable(string())),
    iconLibraryDefaultSvgFillColor: optional(nullable(string())),
    iconLibrarySvgBackgroundColors: optional(nullable(string())),
    iconLibraryDefaultSvgBackgroundColor: optional(nullable(string())),
    iconLibrarySearchCategories: optional(nullable(string())),
    iconLibraryListItemStyles: optional(nullable(string())),
    iconLibraryPngOutputSizes: optional(nullable(coerce(string(), number(), val => `${val}`))),
  }),
  {
    iconLibrarySvgFillColors: '',
    iconLibraryDefaultSvgFillColor: '',
    iconLibrarySvgBackgroundColors: '',
    iconLibraryDefaultSvgBackgroundColor: '',
    iconLibrarySearchCategories: '',
    iconLibraryListItemStyles: '',
    iconLibraryPngOutputSizes: '16,32,64,128,256,512',
  },
);
export const isIconLibrary = (obj: unknown) => is(obj, IconLibraryValidator);
