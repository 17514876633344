import { array, boolean, coerce, create, date, Describe, Infer, nullable, string, type } from 'superstruct';

export const FavouriteValidator = type({
  brandguideLinkId: string(),
  uuid: string(),
  key: string(),
  title: string(),
  type: string(),
  url: string(),
  description: nullable(string()),
  published: boolean(),
  modifiedAt: coerce(date(), string(), value => new Date(value)),
});

type FavouriteDto = {
  brandguideLinkId: string;
  uuid: string;
  key: string;
  title: string;
  type: string;
  url: string;
  description: string | null;
  published: boolean;
  modifiedAt: Date;
};

export const FavouriteListValidator: Describe<FavouriteDto[]> = array(FavouriteValidator);

export function makeFavouriteList(raw: unknown) {
  let list: Infer<typeof FavouriteListValidator> = [];
  try {
    list = create(raw, FavouriteListValidator);
  } catch (err) {
    console.error(err);
  }
  return list;
}
