import { createSelector } from '@reduxjs/toolkit';

import { lookupSelector } from '~store/lookupSelector';
import { stringSelector } from '~store/paramSelector';
import { selectors as EntitiesSelectors } from '~store/slices/entities';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectEntitiesState = lookupSelector(selectRootState, state => state.entities);

// Slice: Entities Selectors
export const selectEntities = lookupSelector(selectEntitiesState, EntitiesSelectors.entities);

// Custom selectors
export const selectEntityById = createSelector(
  [selectEntitiesState, stringSelector],
  (entities, id) => entities[id]?.data || null,
);
export const selectEntityStatusById = createSelector(
  [selectEntitiesState, stringSelector],
  (entities, id) => entities[id]?.status,
);
