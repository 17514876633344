import _get from 'lodash.get';

import type { ThunkCreator } from '$store';
import { IEntityReferenceSingle } from '~interfaces/api';
import { insertItemIntoArrayAtPosition, moveItemInsideArray } from '~services/immutable';
import log from '~services/log';
import { selectEntitiesWithChanges } from '~store/selectors';
import { changeWorkableEntity } from './entity';

export const moveModule: ThunkCreator = (payload: MoveModulePayload) => (dispatch, getState) => {
  try {
    const { fieldName, parentContext, targetIndex, id } = payload;
    const _id = id;
    const entities = selectEntitiesWithChanges(getState());
    const parentEntity = entities[parentContext];
    const oldValue: IEntityReferenceSingle[] = _get(parentEntity, fieldName, []);

    // if no targetIndex is specified, just stick it on the end
    const _targetIndex = targetIndex !== undefined ? targetIndex : oldValue ? oldValue.length : 0;

    // get the previous index of the module with this id
    let previousIndex: number = null;
    oldValue.forEach((ref, i) => {
      if (ref.id === _id) {
        previousIndex = i;
      }
    });

    let newValue: ChangesetValueType;
    // the module is new, we didn't find an id
    if (previousIndex === null) {
      // insert it
      newValue = insertItemIntoArrayAtPosition(
        oldValue,
        {
          id: _id,
          type: entities[_id].type,
        },
        _targetIndex,
      );
    } else {
      // just move a module by the previous and next index
      newValue = moveItemInsideArray(oldValue, previousIndex, _targetIndex);
    }

    dispatch(
      changeWorkableEntity({
        id: parentEntity.id,
        changeSet: {
          fieldName,
          value: newValue,
        },
      }),
    );
  } catch (e) {
    log('Error in moveModule thunk!', e, 'error');
  }
};
