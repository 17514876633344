import { ThunkCreator } from '$store';
import { useAppLogin } from '~services/env';
import { selectSessionName } from '~store/selectors';
import { navigateToLogin } from '~store/thunks/navigation';
import delay from '~utils/delay';

export const authCheck: ThunkCreator = () => async (dispatch, getState) => {
  if (useAppLogin) {
    // need to do a timeout, because redux storage state load only happens after
    // routing
    await delay(0);
    const userName = selectSessionName(getState());

    if (userName) {
      return true;
    }

    dispatch(navigateToLogin());
    return false;
  }

  // need to do a timeout, because redux storage state load only happens after
  // routing
  await delay(0);
  return true;
};
