export const MIN_THRESHOLD_CENTER = 5;
export const MIN_THRESHOLD_LEFT = 4;
export const MIN_THRESHOLD_RIGHT = 4;

export function ellipsisifyCenter(string: string, threshold: number) {
  if (!string) {
    return '';
  }

  const length = string.length;

  if (!threshold || threshold >= length) {
    return string;
  }

  const mid = threshold / 2;

  if (threshold < MIN_THRESHOLD_CENTER) {
    throw { message: `minimum threshold should be ${MIN_THRESHOLD_CENTER}` };
  }

  return [string.substring(0, mid - 1), '…', string.substring(length - (mid + 1) + 1, length)].join('');
}

export function ellipsisifyLeft(string: string, threshold: number) {
  if (!string) {
    return '';
  }

  const length = string.length;

  if (!threshold || threshold >= length) {
    return string;
  }

  if (threshold < MIN_THRESHOLD_LEFT) {
    throw { message: `minimum threshold should be ${MIN_THRESHOLD_LEFT}` };
  }

  return ['…', string.substring(length - (threshold - 1), length)].join('');
}

export function ellipsisifyRight(string: string, threshold: number) {
  if (!string) {
    return '';
  }

  const length = string.length;

  if (!threshold || threshold >= length) {
    return string;
  }

  if (threshold < MIN_THRESHOLD_RIGHT) {
    throw { message: `minimum threshold should be ${MIN_THRESHOLD_RIGHT}` };
  }

  return [string.substring(0, threshold - 1), '…'].join('');
}

const out = {
  center: ellipsisifyCenter,
  left: ellipsisifyLeft,
  right: ellipsisifyRight,
};

export default out;
