import type { ForwardedRef } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { assert } from 'superstruct';

import useEffectWhen from '~hooks/useEffectWhen';
import log from '~services/log';
import { isPixelCrop, makeDefaultCrop, PixelCropValidator } from '~utils/imageCrop';
import { imgToDataURL } from './cropper.utils';

export type CroppedImageProps = {
  src: string;
  crop: string | PixelCrop | PercentCrop;
  className?: string;
  alt?: string;
};

export const CroppedImage = forwardRef(function CroppedImage(
  { src, crop, className, alt }: CroppedImageProps,
  ref: ForwardedRef<HTMLImageElement>,
) {
  const refImgOriginal = useRef<HTMLImageElement>();
  const [cropPixel, setCropPixel] = useState<PixelCrop>();
  const [imgBase64Data, setImgBase64Data] = useState<string>();

  function handleImageOnLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;

    if (isPixelCrop(crop)) {
      setCropPixel(crop);
      return;
    }

    if (typeof crop === 'string') {
      try {
        const parsedCrop = JSON.parse(crop);
        assert(parsedCrop, PixelCropValidator);
        setCropPixel(parsedCrop);
        return;
      } catch (error) {
        log('Can not parse image crop. Fallback to default.', { error, crop }, 'warning');
      }
    }

    // no cropping but we still need the crop object
    setCropPixel(makeDefaultCrop(width, height));
  }

  useEffectWhen(
    () => {
      if (cropPixel) {
        if (isPixelCrop(crop)) {
          setCropPixel(crop);
          return;
        }
      }
    },
    [crop, cropPixel],
    [crop],
  );

  useEffect(() => {
    // whenever the crop
    async function generateImage() {
      const imgSrc = await imgToDataURL(refImgOriginal.current, cropPixel);
      setImgBase64Data(imgSrc);
    }
    if (cropPixel?.width && cropPixel?.height && refImgOriginal.current) {
      void generateImage();
    }
  }, [cropPixel]);

  return (
    <>
      <div className="sr-only">
        <img
          crossOrigin="anonymous"
          src={src}
          ref={refImgOriginal}
          alt={`Original ${alt}`}
          onLoad={handleImageOnLoad}
        />
      </div>
      {Boolean(imgBase64Data) && (
        <img
          ref={ref}
          src={imgBase64Data}
          width={cropPixel.width}
          height={cropPixel.height}
          className={`crisp-edges ${className}`}
          alt={alt}
        />
      )}
    </>
  );
});
