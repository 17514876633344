import _get from 'lodash.get';

import { convertToRawValue } from '~services/data-conversion';
import { objectSet, removeItemFromArrayAtPosition } from '~services/immutable';
import log from '~services/log';
import makeSlug from '~utils/makeSlug';
import { isNumber } from '~utils/number';
import operateOnItemOrArray from '~utils/operateOnItemOrArray';
import stripHtml from '~utils/stripHtml';
import { getEntityDefinition } from './entity-definitions';

// find references to a temporary entity in a list of entities and return an
// array of reference descriptions
export const findEntityReferences = (id: string, entities: Entity[] = []) => {
  const res: TemporaryEntityReference[] = [];
  entities.map(entity =>
    Object.keys(entity.relationships || {}).map(relKey =>
      operateOnItemOrArray(entity.relationships[relKey].data, rel => {
        if (rel.id === id) {
          res.push({
            parentId: entity.id,
            fieldName: `relationships.${relKey}.data`,
            tempId: id,
          });
        }
      }),
    ),
  );
  return res;
};

export function getRelatedEntityIds(entity: Partial<Entity>, relationshipNameOrFieldName?: string): string[] {
  try {
    const relationshipName = relationshipNameOrFieldName
      .split('.')
      .filter(key => key !== 'relationships' && key !== 'data')[0];
    if (!relationshipName) {
      log(
        `Invalid relationship name ${relationshipName} calculated from ${relationshipNameOrFieldName}!`,
        null,
        'error',
      );
      return [];
    }

    // not loaded yet, simply return empty array
    if (!entity) {
      return [];
    }
    const rel = entity.relationships[relationshipName];
    if (!rel) {
      return [];
    }
    const relData = rel.data;
    // get the actual value and build the descriptor object
    // necessary data is non-existant, return false
    if (!relData) {
      return [];
    }

    return operateOnItemOrArray(
      relData,
      item => {
        if (!item) {
          return log(`Entity reference in ${relationshipName} is missing!`, null, 'error');
        }
        if (!item.id) {
          return log(`Entity referenced in ${relationshipName} does not have an id: `, item, 'error');
        }
        return item.id;
      },
      true,
    ) as string[];
  } catch (e) {
    log('Error in getRelatedEntityIds', e, 'error');
    log('Parameters where: ', { entity, relationshipNameOrFieldName }, 'error');
  }
}

export function getFieldValue<T>(entity: Entity, fieldAddress: string, def: T = null): T {
  try {
    return _get(entity, fieldAddress, def) as T;
  } catch (e) {
    log(
      `Error getting data of ${fieldAddress} in entity of type ${entity.type} with ID ${entity.id}!`,
      ...(e as any),
      'error',
    );
    return null;
  }
}

export function getTitle(entity: Entity, defaultTitle?: string): string {
  const fieldTitle = entity?.attributes?.title || entity?.attributes?.field_title?.value;

  if (fieldTitle) {
    return stripHtml(convertToRawValue(fieldTitle, 'html'));
  }
  if (defaultTitle) {
    return defaultTitle;
  }

  const entityDef = getEntityDefinition(entity.type);
  return entityDef.meta.name;
}

export interface IChangeEntityPayload {
  entity: Entity;
  changeSet: EntityChangeset;
}

// ToDo: get rid of 'changeEntity'
export function changeEntity(payload: IChangeEntityPayload) {
  const { entity, changeSet } = payload;
  const { fieldName, value, push, splice } = changeSet;
  const oldValue = _get(entity, fieldName, {});
  let newValue: typeof oldValue;
  if (typeof value === 'function') {
    const fn = value as (old: typeof oldValue) => typeof oldValue;
    newValue = fn(oldValue);
  } else if (push) {
    if (Array.isArray(oldValue)) {
      newValue = [...oldValue, value];
    } else {
      newValue = [value];
    }
  } else if (isNumber(splice) && Array.isArray(oldValue)) {
    newValue = removeItemFromArrayAtPosition(oldValue, splice);
  } else {
    newValue = value;
  }

  if (newValue === oldValue) {
    return entity;
  }

  return objectSet(entity, fieldName, newValue);
}

export function isTempEntity(entityId: string): boolean {
  return entityId.substr(0, 5) === 'temp_';
}

const moduleTypePrefix = 'paragraph--';
export function isModuleEntity(entityType: string): boolean {
  return entityType.substr(0, moduleTypePrefix.length) === moduleTypePrefix;
}

export function entityIsOfType(entity: Partial<Entity>, type: string | string[]): boolean {
  if (Array.isArray(type)) {
    return type.filter(t => entity.type === t).length !== 0;
  }
  return entity.type === type;
}

export function entityTypeIsOfType(entityType: string, type: string | string[]): boolean {
  if (type.constructor === Array) {
    return type.filter(t => entityType === t).length !== 0;
  }
  return entityType === type;
}

export function entityOrEntityTypeIsOfType(entity: Partial<Entity> | string, type: string | string[]): boolean {
  return typeof entity === 'string' ? entityTypeIsOfType(entity, type) : entityIsOfType(entity, type);
}

export function isAnchorEntity(entity: Partial<Entity> | string) {
  return entityOrEntityTypeIsOfType(entity, 'paragraph--anchor');
}

export function isNodePageEntity(entity: Partial<Entity> | string) {
  return entityOrEntityTypeIsOfType(entity, 'node--page');
}

export function isWizardEntity(entity: Partial<Entity> | string) {
  return entityOrEntityTypeIsOfType(entity, 'paragraph--wizard');
}

export function makeSlugForAnchorEntity(entity: Partial<Entity>): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return makeSlug(convertToRawValue<string>(entity.attributes.field_title.value, 'html'));
}
