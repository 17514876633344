import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export enum MenuStatus {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Editing = 'EDITING',
  Saving = 'SAVING',
}

// State
export type MenuState = {
  elements: MenuElement[];
  unfilteredElements: MenuElement[];
  editable: boolean;
  status: MenuStatus;
};

// Initial State
export const initialState: MenuState = {
  elements: [],
  unfilteredElements: [],
  editable: true,
  status: MenuStatus.Loading,
};

const recurseMenuElementExpandById = (element: MenuElement, id: string) => {
  let hasId = element.id === id;
  if (element.children && element.children.length) {
    element.children = element.children.map(child => {
      const { element: childElement, hasId: childHasId } = recurseMenuElementExpandById(child, id);
      hasId = childHasId || hasId;
      return childElement;
    });
  }
  element.expanded = hasId;
  return { element, hasId };
};

const recurseMenuElementToggleExpandById = (element: MenuElement, id: string) => {
  if (element.id === id) {
    element.expanded = !element.expanded;
  } else {
    element.children = element.children.map(child => recurseMenuElementToggleExpandById(child, id));
  }
  return element;
};

const recurseMenuElementExpandByAlias = (element: MenuElement, alias: string) => {
  let hasAlias = element.alias === alias;
  if (element.children && element.children.length) {
    element.children = element.children.map(child => {
      const { element: childElement, hasAlias: childHasAlias } = recurseMenuElementExpandByAlias(child, alias);
      hasAlias = childHasAlias || hasAlias;
      return childElement;
    });
  }
  element.expanded = hasAlias;
  return { element, hasAlias: hasAlias };
};

const recurseMenuElementUpdateState = (
  element: MenuElement,
  searchPath: string,
  isPublished: boolean,
  hasDraft: boolean,
): MenuElement => {
  if (element.path === searchPath) {
    element.isPublished = isPublished;
    element.hasDraft = hasDraft;
  } else {
    element.children = element.children.map(child =>
      recurseMenuElementUpdateState(child, searchPath, isPublished, hasDraft),
    );
  }
  return element;
};

// Reducers
const rootMenuFetched: CaseReducer<MenuState, PayloadAction<MenuElement[]>> = (state, action) => {
  if (action.payload) {
    state.elements = action.payload;
  } else {
    state.elements = initialState.elements;
  }
};
const unfilteredMenuFetched: CaseReducer<MenuState, PayloadAction<MenuElement[]>> = (state, action) => {
  if (action.payload) {
    state.unfilteredElements = action.payload;
  }
};
const elementClicked: CaseReducer<MenuState, PayloadAction<MenuElement>> = (state, action) => {
  if (action.payload) {
    state.elements = state.elements.map(menuElement => {
      const { element } = recurseMenuElementExpandById(menuElement, action.payload.id);
      return element;
    });
  }
};
const toggleSubmenuById: CaseReducer<MenuState, PayloadAction<string>> = (state, action) => {
  try {
    state.elements = state.elements.map(menuElement => {
      return recurseMenuElementToggleExpandById(menuElement, action.payload);
    });
  } catch (_) {
    // do nothing
  }
};

const updateMenuElementState: CaseReducer<
  MenuState,
  PayloadAction<{ path: string; isPublished: boolean; hasDraft: boolean }>
> = (state, action) => {
  if (action.payload) {
    const { path, isPublished, hasDraft } = action.payload;
    state.elements = state.elements.map(element => recurseMenuElementUpdateState(element, path, isPublished, hasDraft));
  }
};

const openMenuByAlias: CaseReducer<MenuState, PayloadAction<string>> = (state, action) => {
  if (action.payload) {
    state.elements = state.elements.map(menuElement => {
      const { element } = recurseMenuElementExpandByAlias(menuElement, action.payload);
      return element;
    });
  }
};
const setMenuStatus: CaseReducer<MenuState, PayloadAction<MenuStatus>> = (state, action) => {
  if (action.payload) {
    state.status = action.payload;
  }
};

// Slice
const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    rootMenuFetched,
    unfilteredMenuFetched,
    elementClicked,
    toggleSubmenuById,
    updateMenuElementState,
    openMenuByAlias,
    setMenuStatus,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: MenuState) => state;
export const selectors = {
  self: selectSelf,
  elements: (state: MenuState) => state.elements,
  unfilteredElements: (state: MenuState) => state.unfilteredElements,
  isEditable: (state: MenuState) => state.editable,
  status: (state: MenuState) => state.status,
};
