import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import log from '~services/log';
import { getConfig } from '../api/config';

export const fetchConfig = createAsyncThunk('config/fetch', async _ => {
  try {
    const res = await getConfig();
    return res;
  } catch (e) {
    log('Error in fetch configs thunk!', e, 'error');
  }
});

const ConfigSlice = createSlice({
  name: 'config',
  initialState: {
    brandings: {},
    mixerTypes: {},
    tankerTypes: {},
    volumeUnits: {},
    lengthUnits: {},
    sectionPropertyTypes: {},
    matchTolerance: {},
    pagingSize: 10,
    countries: {},
    trucks: {},
    orderPriorities: {},
    workflowStates: {},
    uploadTypes: {},
    isLoading: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchConfig.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(fetchConfig.fulfilled, (state, action: any) => {
      state.brandings = action?.payload?.brandings;
      state.mixerTypes = action?.payload?.mixer_types;
      state.tankerTypes = action?.payload?.tanker_types;
      state.volumeUnits = action?.payload?.volume_units;
      state.lengthUnits = action?.payload?.length_units;
      state.sectionPropertyTypes = action?.payload?.section_property_types;
      state.matchTolerance = action?.payload?.match_tolerance;
      state.pagingSize = action?.payload?.paging_size;
      state.countries = action?.payload?.countries;
      state.trucks = action?.payload?.trucks;
      state.orderPriorities = action?.payload?.order_priorities;
      state.workflowStates = action?.payload?.workflow_states;
      state.uploadTypes = action?.payload?.upload_types;
      state.isLoading = false;
    });
  },
});

export default ConfigSlice.reducer;
