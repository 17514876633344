import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type ModalState = ModalConfig;

// Initial State
export const initialState: ModalState = {
  type: 'default',
  headline: '',
  isVisible: false,
  text: '',
  width: '200px',
  height: '160px',
};

// Reducers
const show: CaseReducer<ModalState, PayloadAction<ModalConfig>> = (state, action) => ({
  ...state,
  ...action.payload,
  isVisible: true,
});
const hide: CaseReducer<ModalState> = () => ({
  ...initialState,
});

// Slice
const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    show,
    hide,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: ModalState) => state;
export const selectors = {
  self: selectSelf,
  modal: (state: ModalState) => state,
};
