import { ComponentType, MouseEvent, PureComponent, ReactNode } from 'react';

import { LinkSettings } from '~components/generic/editable-field/LinkSettings';
import Button from '~components/generic/elements/Button';

export type LinkButtonProps = {
  onOverrideContent: (content: ReactNode | ComponentType | undefined) => void;
  theme: {
    buttonWrapper: string;
    button: string;
    active: string;
  };
};

export class LinkButton extends PureComponent<LinkButtonProps> {
  // When using a click event inside overridden content, mouse down
  // events needs to be prevented so the focus stays in the editor
  // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
  onMouseDown = (event: MouseEvent<HTMLDivElement>) => event.preventDefault();

  onClick = () => {
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    this.props.onOverrideContent(LinkSettings);
  };

  render() {
    return (
      <div className={this.props.theme.buttonWrapper} onMouseDown={this.onMouseDown}>
        <Button iconName="anchor" iconSize="lg" className={this.props.theme.button} onClick={this.onClick} />
      </div>
    );
  }
}

export default LinkButton;
