import { get } from '~services/api/methods';

/**
 * fetch image for image cropper
 * @return Fetch response
 */
export const fetchImage = async (url?: string) => {
  const response = await get(url, { withCredentials: true, withResponseType: true });
  return response;
};
