import clsx from 'clsx';
import { ReactNode } from 'react';

import { ModalContainer } from '~components/generic/modal/ModalContainer';
import Footer from '~components/page/Footer';
import { ToolsNav } from '~components/page/ToolsNav/ToolsNav';
import useAppSelector from '~hooks/useAppSelector';
import { selectCurrentPageId, selectSessionIsEditing, selectUserIsLoggedIn } from '~store/selectors';
import { EditmodeHeader } from './EditmodeHeader/Header';
import { CookieConsent } from './CookieConsent';
import { PDFDownloadContainer } from './PDFDownloadContainer';
import { ScrollPane } from './ScrollPane';
import styles from './PageLayout.module.css';

export function PageLayout({
  children,
  title,
  className,
  noHeader = false,
}: {
  children: ReactNode;
  title?: string;
  className?: string;
  noHeader?: boolean;
}) {
  const isLoggedIn = useAppSelector(selectUserIsLoggedIn);
  const isEditmodeActive = useAppSelector(selectSessionIsEditing);
  const currentPageId = useAppSelector(selectCurrentPageId);

  const isContentPage = currentPageId !== null;
  const showEditmodeHeader = isLoggedIn && isContentPage && isEditmodeActive;

  return (
    <div className={clsx('c-app_page', className, styles.page)}>
      {showEditmodeHeader && (
        <div className={styles.headerContainer}>
          <EditmodeHeader />
        </div>
      )}
      {!noHeader && <ToolsNav title={title} />}
      <ScrollPane>
        <div className={clsx(styles.pageContent, 'page-content')}>{children}</div>
        <PDFDownloadContainer type="section-info" />
        <Footer />
      </ScrollPane>
      <CookieConsent />
      <ModalContainer />
    </div>
  );
}
