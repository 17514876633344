import clsx from 'clsx';
import { useState } from 'react';

import { Button as EditorButton } from '~components/generic/editor/Button/Button';
import { ButtonGroup as EditorButtonGroup } from '~components/generic/editor/ButtonGroup/ButtonGroup';
import { CheckIcon, CloseIcon, EditIcon, LayersIcon, PaperPlaneIcon } from '~components/icons';
import { MetaHeader } from '~components/page/Meta/MetaHeader';
import useAppSelector from '~hooks/useAppSelector';
import useLabels from '~hooks/useLabels';
import { selectAddonsIsChangelogEnabled, selectAddonsIsMetaEnabled } from '~store/selectors';
import { DeleteDraftPopover } from './DeleteDraftPopover';
import { PublishDialog } from './PublishDialog';
import useDraft from './useDraft';
import useEditModules from './useEditModules';
import usePublishing from './usePublishing';
import styles from './Header.module.css';

export function EditmodeHeader() {
  const {
    btnPublishPageChanges,
    btnEditPage,
    msgSaveModulesHint,
    msgCancelModulesHint,
    btnManageModules,
    msgManageModulesHint,
  } = useLabels([
    'btnPublishPageChanges',
    'btnEditPage',
    'msgSaveModulesHint',
    'msgCancelModulesHint',
    'btnManageModules',
    'msgManageModulesHint',
  ]);
  const [isPublishingModalOpen, setIsPublishingModalOpen] = useState(false);
  const isChangelogEnabled = useAppSelector(selectAddonsIsChangelogEnabled);
  const isMetaEnabled = useAppSelector(selectAddonsIsMetaEnabled);
  const { isPublishing, onPublish } = usePublishing();

  function closePublishingModal() {
    setIsPublishingModalOpen(false);
  }

  function openPublishingModal() {
    setIsPublishingModalOpen(true);
  }

  const {
    canEdit: canEditModules,
    onEdit: onEditModules,
    isEditing: isEditingModules,
    isSaving: isSavingModules,
    onSave: onSaveModules,
    onDiscard: onDiscardModules,
  } = useEditModules();

  const {
    canCreateDraft,
    canDeleteDraft,
    canPublishDraft,
    isCreatingDraft,
    isDeletingDraft,
    onCreateDraft,
    onDeleteDraft,
    hasUnpublishedChanges,
  } = useDraft();

  return (
    <div className={clsx(styles.header, 'editmode-header', 'relative bg-gray')}>
      <div className="flex justify-between">
        <div className="flex">
          {isEditingModules ? (
            <EditorButtonGroup preIcon={<LayersIcon />} label={btnManageModules}>
              <EditorButton
                title={msgCancelModulesHint}
                icon={<CloseIcon />}
                onClick={onDiscardModules}
                disabled={isSavingModules}
              />
              <EditorButton
                title={msgSaveModulesHint}
                icon={<CheckIcon />}
                onClick={onSaveModules}
                processing={isSavingModules}
              />
            </EditorButtonGroup>
          ) : (
            <EditorButton
              onClick={onEditModules}
              title={msgManageModulesHint}
              label={btnManageModules}
              preIcon={<LayersIcon />}
              disabled={!canEditModules}
            />
          )}
        </div>

        <div className="flex items-center">
          {canCreateDraft ? (
            <EditorButton
              onClick={onCreateDraft}
              label={btnEditPage}
              icon={<EditIcon />}
              processing={isCreatingDraft}
            />
          ) : (
            <>
              {hasUnpublishedChanges && (
                <div className={clsx(styles.dividerHorizontal, 'editmode-header--divider-horizontal')}>
                  Unpublished changes // TODO
                </div>
              )}
              <div className={clsx(styles.dividerHorizontal, 'editmode-header--divider-horizontal')} />
              <DeleteDraftPopover isDeleting={isDeletingDraft} onDelete={onDeleteDraft} disabled={!canDeleteDraft} />
              <div className={clsx(styles.dividerHorizontal, 'editmode-header--divider-horizontal')} />
              {isChangelogEnabled ? (
                <EditorButton
                  label={btnPublishPageChanges}
                  icon={<PaperPlaneIcon />}
                  onClick={openPublishingModal}
                  disabled={!canPublishDraft}
                />
              ) : (
                <EditorButton
                  label={btnPublishPageChanges}
                  icon={<PaperPlaneIcon />}
                  onClick={() => onPublish({})}
                  processing={isPublishing}
                  disabled={!canPublishDraft}
                />
              )}
            </>
          )}
        </div>
      </div>
      {isChangelogEnabled && <PublishDialog open={isPublishingModalOpen} onClose={closePublishingModal} />}
      {isMetaEnabled && <MetaHeader />}
    </div>
  );
}
