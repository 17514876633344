import { createAsyncThunk } from '@reduxjs/toolkit';

import { getPDFFileUrl as getPDFFileUrlCall } from '~services/api/calls/file';
import log from '~services/log';
import { ModalActions } from '~store/actions';

export const generatePdf = createAsyncThunk<void, string, any>('pdf/generate', async (id, { dispatch }) => {
  try {
    const modalSettings = {
      type: 'loading',
      headline: 'Please wait while the PDF file is being generated',
      text: 'The time this process takes depends on the size of the file and your internet connection.',
      width: '70%',
      height: '500px',
    };

    dispatch(ModalActions.show(modalSettings));
    const pdfResponse = await getPDFFileUrlCall(id);
    window.open(pdfResponse);
    dispatch(ModalActions.hide());
  } catch (e) {
    log('Error in generatePdf thunk!', e, 'error');
  }
});
