import { coerce, Describe, lazy, number, optional, record, string, type } from 'superstruct';

export type FetchTopic = {
  tid: string;
  name: string;
  description: string | undefined;
  children: Record<string, FetchTopic> | undefined;
};
export const FetchTopicValidator: Describe<FetchTopic> = type({
  tid: coerce(string(), number(), val => `${val}`),
  name: string(),
  description: optional(string()),
  children: optional(
    record(
      string(),
      lazy(() => FetchTopicValidator),
    ),
  ),
});

export type FetchTopics = Record<string, FetchTopic>;
export const FetchTopicsValidator: Describe<FetchTopics> = record(string(), FetchTopicValidator);
