import type { ThunkCreator } from '$store';
import { useAppLogin } from '~services/env';
import { ControlActions, SessionActions } from '~store/actions';
import { selectWorkableEntitiesIds } from '~store/selectors/control';
import { selectSessionName } from '~store/selectors/session';
import { stopEditingEntity } from '~store/thunks/entity';
import { isLessThanMedium } from '~utils/browser';

export const resize: ThunkCreator = () => (dispatch, getState) => {
  // indicates if the app should use it's own build in login
  const isLoggedIn = useAppLogin ? selectSessionName(getState()) : true;

  if (isLessThanMedium() && isLoggedIn) {
    dispatch(ControlActions.closeSidebar());
    dispatch(SessionActions.disableEditing());
    dispatch(ControlActions.setContext());

    const workableEntityIds = selectWorkableEntitiesIds(getState());
    for (const workableEntityId of workableEntityIds) {
      dispatch(stopEditingEntity(workableEntityId));
    }
  }
};
