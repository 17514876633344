import { URL_REPLACEMENT } from '$const';
import { HolcimConfig } from '~extension/truck-labeling/typings/Config';
import { get } from '~services/api/methods';

// list config
export const getConfig = async () => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/config`;

  return await get<HolcimConfig>(requestUrl, { useBearerToken: true });
};
