import { URL_REPLACEMENT } from '$const';
import getStore from '$store';
import { EntityLockDto, IJsonApiResponseSingle, ReadPageDto } from '~interfaces/api';
import { get, patch, post, remove } from '~services/api/methods';
import { selectApiLanguagePrefix, selectCurrentPageId } from '~store/selectors';

const getEntityTypeAndParagraph = (entity: Partial<Entity>) => {
  const [type, paragraph] = entity.type.split('--', 2);
  if (!(type && paragraph)) {
    throw new Error(
      `Malformed entity type ${entity.type}! [${type},${paragraph}](Or you need to refactor the code. duh.)`,
    );
  }
  return [type, paragraph];
};

/**
 * Create a new entity, return a promise with the entity (including the new ID),
 * otherwise throw an ApiError which needs to be handled by the callee.
 */
export const createEntity = async (entity: Partial<Entity>) => {
  const [type, paragraph] = getEntityTypeAndParagraph(entity);
  const nodeUID = selectCurrentPageId(getStore().getState());
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/${type}/${paragraph}?_format=api_json`;
  const response = await post<IJsonApiResponseSingle, string>(requestUrl, JSON.stringify({ data: entity, nodeUID }));
  // ToDo: add validation! see api/validators/*
  // return create(response, IJsonApiResponseSingle);
  return response;
};

/**
 * This function uses a new page api to request all content of a page. (new api)
 * (A new page api was build to make fewer requests on app startup.
 */
export const readPage = async ({ query }: { query?: Record<string, any> }) => {
  let langPrefix = selectApiLanguagePrefix(getStore().getState());
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  let curPath: string = query.filter.field_path.value;

  if (langPrefix) {
    langPrefix = `/${langPrefix}`;
    // Note: we can not support urls contains '/'!
    curPath = curPath.replace('/', '');
  }
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/node.json?nodeURL=${langPrefix}${curPath}`;
  const response = await get<ReadPageDto>(requestUrl);
  // ToDo: add validation! see api/validators/*
  // return create(response, ReadPageDto);
  return response;
};

/**
 * Update an entity, return the new entity, otherwise throw an ApiError which
 * needs to be handled by the callee.
 */
export const updateEntity = async (entity: Partial<Entity>) => {
  const [type, paragraph] = getEntityTypeAndParagraph(entity);
  const nodeId = selectCurrentPageId(getStore().getState());
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/${type}/${paragraph}/${entity.id}?_format=api_json`;
  const response = await patch<IJsonApiResponseSingle, string>(requestUrl, JSON.stringify({ data: entity, nodeId }));
  // ToDo: add validation! see api/validators/*
  // return create(response, IJsonApiResponseSingle);
  return response;
};

/**
 * Delete an entity, return nothing or throw an ApiError which needs to be
 * handled by the callee.
 */
export const removeEntity = async ({ type, id }: { type: string; id: string }) => {
  type = type.replace('--', '/');
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/${type}/${id || ''}?_format=api_json`;
  await remove<boolean>(requestUrl);
};

/**
 * Checks if the entity with the id is currently locked for editing
 * @param  id - UUID of the entity
 * @return Fetch response
 */
export const isLocked = async (id: string) => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/lock?nodeUID=${id}`;
  const response = await get<EntityLockDto>(requestUrl);
  // ToDo: add validation! see api/validators/*
  // return create(response, EntityLockDto);
  return response;
};
