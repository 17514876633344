import { BASE_PATH } from '$env';

/**
 * Prefixes a given relative link with the brandguide URL prefix.
 * Example:
 * link = '/en/downloads';
 * prefix = 'brandguide';
 * returns '/en/brandguide/downloads';
 */
export function addPrefixToRelativeLink(link?: string): string {
  if (!link) {
    return null;
  }
  // get path prefix without trailing slash
  const prefix = `/${BASE_PATH}`;

  // if link begins with a language code, remove it and put prefix before rest of link
  // this also filters duplicate /brandguide from the link, preventing duplicate /brandguide/brandguide/...
  const reg = new RegExp(`^/([a-z]{2})(/${BASE_PATH})?(/.+)$`, 'i');
  const matches = reg.exec(link);
  if (matches) {
    // 0: /de/brandguide/downloads
    // 1: de
    // 2: /brandguide
    // 3: /downloads
    return `/${matches[1]}${prefix}${matches[3]}`;
  }

  // prefix link
  return `${prefix}${link}`;
}

export function getModuleUrlIdFromData(id: string, type: string) {
  return `${id}~${type}`;
}

export function getDataFromModuleUrlId(identifier: string) {
  const idArr = identifier.split('~');
  if (idArr.length !== 2) {
    throw new Error(
      `Error in getDataFromModuleUrlId, identifier ${identifier} is malformed! Expected format is id~type!`,
    );
  }
  return {
    id: idArr[0],
    type: idArr[1],
  };
}

export function getDataFromInternalUrl(url: string) {
  if (url.indexOf('#') >= 0) {
    const tmp = url.split('#');
    return getDataFromModuleUrlId(tmp.length ? tmp[1] : '');
  }
  return getDataFromModuleUrlId(url);
}

export function getLabelFromInternalUrl(url: string) {
  if (url && url.indexOf('#') >= 0) {
    const tmp = url.split('#');
    return tmp.length ? tmp[0] : url;
  }
  return url;
}

/**
 * Get language identifier from 'window.location'.
 */
export function getLangFromLocation() {
  // get language key from url -> needed here for the router!
  let lang = window.location.pathname.substring(1).split('/')[0];
  if (!lang || lang.length !== 2) {
    lang = '';
  }
  return lang;
}
