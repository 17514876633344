import { useMemo } from 'react';

import { SidebarContextSettingsSection } from '~components/sidebar/context/SidebarContextSettingsSection';
import { IEntityComponentProps } from '~interfaces/props';
import { getEntityDefinition } from '~services/entity-definitions/helpers';
import { IEntitySettingSectionDefinition } from '~services/entity-definitions/interface';

const sortByWeight = (a: IEntitySettingSectionDefinition, b: IEntitySettingSectionDefinition): number => {
  const weightA = a.weight || 0;
  const weightB = b.weight || 0;
  return weightA - weightB;
};

export const SidebarContextSettings = ({ data, onUpload, isPdfEnabled, onChange }: IEntityComponentProps) => {
  const sectionDefs = useMemo<IEntitySettingSectionDefinition[]>(() => {
    const entityDef = getEntityDefinition(data.type);
    if (entityDef && entityDef.settings) {
      return Object.values(entityDef.settings).filter(Boolean).sort(sortByWeight);
    }
    return [];
  }, [data.type]);

  const sectionsFiltered = sectionDefs.filter(item => item.title !== 'Modules');

  return (
    <div className="c-context__settings c-settings u-margin-bottom-xl">
      {sectionsFiltered.map((sectionDef, i) => (
        <SidebarContextSettingsSection
          key={sectionDef.title || `section_${i}`}
          data={data}
          sectionDef={sectionDef}
          onChange={onChange}
          onUpload={onUpload}
          isPdfEnabled={isPdfEnabled}
        />
      ))}
    </div>
  );
};

export default SidebarContextSettings;
