import clsx from 'clsx';
import type { MouseEvent } from 'react';

import { ModuleTypes } from '$const';
import { Link } from '~components/generic/elements/Link/Link';

type NavigationProps = {
  activeAlias: string;
  elements: MenuElement[];
  onElementToggle: (elementId: string) => void;
};

export function Navigation({ elements, activeAlias, onElementToggle }: NavigationProps) {
  return (
    <div className="rst__tree navi--root">
      {elements.map(element => (
        <NavigationElement element={element} key={element.id} activeAlias={activeAlias} onToggle={onElementToggle} />
      ))}
    </div>
  );
}

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

type NavigationElementProps = {
  level?: number;
  element: ArrayElement<NavigationProps['elements']>;
  activeAlias: NavigationProps['activeAlias'];
  onToggle: NavigationProps['onElementToggle'];
};

export function NavigationElement({ element, activeAlias, level = 0, onToggle }: NavigationElementProps) {
  const handleToggle = (evt: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    onToggle(element.id);
  };

  const clsRow = clsx('rst__row', level > 0 && `sub-menu-item level-${level}`, {
    active: activeAlias === element.alias,
    unpublished: !element.isPublished,
    'has-draft': element.isPublished && element.hasDraft,
  });

  const ROW_HEIGHT = 42;
  const BLOCK_WIDTH = 25;

  return (
    <>
      <div className="rst__node navi--element" style={{ height: ROW_HEIGHT }}>
        <div
          className="rst__lineBlock rst__lineHalfHorizontalRight rst__lineHalfVerticalBottom"
          style={{ width: BLOCK_WIDTH }}
        />
        <div className="rst__nodeContent" style={{ left: BLOCK_WIDTH }}>
          <div style={{ height: '100%' }}>
            <div>{element.expanded && <div className="rst__lineChildren" style={{ width: BLOCK_WIDTH }} />}</div>
            <div className="rst__rowWrapper">
              <div className={clsRow} style={{ opacity: 1 }}>
                <div className="rst__rowContents rst__rowContentsDragDisabled">
                  <div className="rst__rowLabel">
                    {element.type === 'page' && (
                      <Link className="rst__rowTitle" id={`${element.alias}#${element.id}~${ModuleTypes.internalLink}`}>
                        {element.title}
                      </Link>
                    )}
                    {element.type === 'link' && (
                      <a href="#" className="rst__rowTitle" onClick={handleToggle}>
                        {element.title}
                      </a>
                    )}
                  </div>
                  <div className="rst__rowToolbar">
                    {element.children.length > 0 && (
                      <div className="rst__toolbarButton" onClick={handleToggle}>
                        <span
                          className={clsx('p42-first-level-nav c-icon-custom--caret-fl', element.expanded && 'open')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {element.expanded &&
        element.children.map(childElement => (
          <NavigationElement
            element={childElement}
            key={childElement.id}
            activeAlias={activeAlias}
            level={level + 1}
            onToggle={onToggle}
          />
        ))}
    </>
  );
}
