export function unique<T>(array: Iterable<T>) {
  return [...new Set(array)];
}

export const removeDuplicates = unique;

export function flattenChildren<T extends object & { children?: T[] }>(item: T): T[] {
  return [item, ...(item?.children || []).flatMap(flattenChildren)] as T[];
}

export function flattenArrayWithChildren<T extends object & { children?: T[] }>(items: T[]) {
  return items.flatMap(flattenChildren);
}

export function getLastItem<T>(array: T[]) {
  const l = array.length;
  if (l === 0) return undefined;
  return array[l - 1];
}

/**
 * Walk through items tree and count all items that have the attribute `selected` set to `true`.
 * @param items
 * @returns a number
 */
export function countItems<T>(items: T[], predicate: (item: T) => boolean, childAttribute = 'children') {
  return items.reduce((count, current) => {
    const itemCount = predicate(current) ? 1 : 0;
    const childCount = countItems(current[childAttribute] || [], predicate);
    return count + itemCount + childCount;
  }, 0);
}

export function filterUndefined<T>(items: T[]) {
  return (items || []).filter(Boolean);
}
