import { any, coerce, Describe, is, number, optional, string, type } from 'superstruct';

export const TrackingClientValidator: Describe<TrackingClient> = type({
  name: string(),
  siteId: coerce(string(), number(), value => value.toString()),
  label: optional(string()),
  trackingDomain: optional(string()),
  trackingUrl: optional(string()),
  dimensionsMap: optional(any()),
  dimensionID: optional(
    type({
      module: optional(number()),
      user_company: optional(number()),
      user_country: optional(number()),
      user_division: optional(number()),
      user_roles: optional(number()),
    }),
  ),
});

export const isTrackingClient = (obj: unknown) => is(obj, TrackingClientValidator);

export const TrackingClientsValidator = type({
  matomo: optional(TrackingClientValidator),
  gtm: optional(TrackingClientValidator),
  gtag: optional(TrackingClientValidator),
  'google-analytics': optional(TrackingClientValidator),
});

export const isTrackingClients = (obj: unknown) => is(obj, TrackingClientsValidator);
