import { any, array, coerce, Describe, is, string, type } from 'superstruct';

export const SiteAccessValidator: Describe<SiteAccessDto> = type({
  key: string(),
  name: string(),
  url: string(),
  theme: coerce(string(), any(), value => (value as string).replaceAll('_', '-')),
  role: string(),
  pageTitle: string(),
  frontPage: string(),
});

export const isSiteAccess = (obj: unknown) => is(obj, SiteAccessValidator);

export const SiteAccessesValidator = array(SiteAccessValidator);
export const isSiteAccesses = (obj: unknown) => is(obj, SiteAccessesValidator);
