import { lookupSelector } from '~store/lookupSelector';
import { selectors as SessionSelectors } from '~store/slices/session';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectSessionState = lookupSelector(selectRootState, state => state.session);

// Slice: Addons Selectors
export const selectSessionName = lookupSelector(selectSessionState, SessionSelectors.name);
export const selectSessionPass = lookupSelector(selectSessionState, SessionSelectors.pass);
export const selectSessionIsEditing = lookupSelector(selectSessionState, SessionSelectors.isEditing);
export const selectSessionServerSession = lookupSelector(selectSessionState, SessionSelectors.serverSession);
export const selectSessionOptions = lookupSelector(selectSessionState, session => {
  return {
    ...(session || {}),
  };
});
