import { create, Describe, string, type } from 'superstruct';

import { URL_REPLACEMENT } from '$const';
import { SetOrRemoveFavouriteApiResponse } from '~extension/favourite/model/SetOrRemoveFavourite';
import { get, post, remove } from '~services/api/methods';
import { makeFavouriteList } from '../model/FavouriteFactory';

const SetOrRemoveFavouriteApiResponseValidator: Describe<SetOrRemoveFavouriteApiResponse> = type({
  message: string(),
});

export const getFavourites = async () => {
  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/api/ebm_favourites/list`;
  const response = await get<Array<unknown>>(requestUrl);
  return makeFavouriteList(response);
};

export const postContentFavourite = async (formData: FormData) => {
  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/api/ebm_favourites/add`;
  const response = await post<Array<unknown>>(requestUrl, formData);
  return makeFavouriteList(response);
};

export const deleteContentFavourite = async (key: string) => {
  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/api/ebm_favourites/delete/${key}`;
  const response = await remove<Array<unknown>>(requestUrl);
  return makeFavouriteList(response);
};

export const postAssetFavourite = async (formData: FormData) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/favourites/set`;
  const response = await post<void>(requestUrl, formData);
  return create(response, SetOrRemoveFavouriteApiResponseValidator);
};

export const deleteAssetFavourite = async (id: string, siteAccess?: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/favourites/remove/${id}${siteAccess ? `/${siteAccess}` : ''}`;
  const response = await remove<void>(requestUrl);
  return create(response, SetOrRemoveFavouriteApiResponseValidator);
};
