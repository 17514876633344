import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type TranslationsState = Record<string, string>;

// Initial State
export const initialState: TranslationsState = {};

// Reducers
const set: CaseReducer<TranslationsState, PayloadAction<Record<string, string>>> = (state, action) => {
  if (action.payload) {
    Object.keys(action.payload).forEach(function (key) {
      state[key] = action.payload[key];
    });
  }
};

// Slice
const slice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    set,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: TranslationsState) => state;
export const selectors = {
  self: selectSelf,
  translations: (state: TranslationsState) => state,
};
