import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';

import { SvgTextAlignCenter } from '../SvgTextAlign';

export const AlignTextCenterSettings = {
  blockType: 'text-center',
  className: 'u-text-center',
  element: 'p',
};

export const AlignTextCenterButton = createBlockStyleButton({
  blockType: AlignTextCenterSettings.blockType,
  children: <SvgTextAlignCenter />,
});
