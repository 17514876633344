import { URL_REPLACEMENT } from '$const';
import { get, patch, post } from '~services/api/methods';

export const getMatchingTemplates = async (id: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates/matching_order/${id}`;
  return await get<MatchingTemplates>(requestUrl, { useBearerToken: true });
};

// get all templates
export const getTemplates = async () => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates`;
  return await get<MatchingTemplates>(requestUrl, { useBearerToken: true });
};

// search template by name
export const getTemplateByName = async (name: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates/matching_order/${name}`;
  return await get(requestUrl);
};

// search template
export const searchTemplates = async (queryString: any) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates/search?${queryString}`;
  return await get(requestUrl, null);
};

// create template
export const createTemplate = async (data: any) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates`;
  return await post(requestUrl, data, { useBearerToken: true });
};

// set template
export const setTemplate = async (order_id: any, asset_id: number) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/orders/set_template/${order_id}`;
  return await patch(requestUrl, { template_asset_id: asset_id }, { useBearerToken: true });
};

// invalidate template
export const invalidateTemplate = async (asset_id: number) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates/invalidate/${asset_id}`;
  return await patch(requestUrl, null, { useBearerToken: true });
};

// validate template
export const validateTemplate = async (asset_id: number) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates/invalidate/${asset_id}`;
  return await patch(
    requestUrl,
    {
      template_invalid_state: 0,
    },
    { useBearerToken: true },
  );
};
