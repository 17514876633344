import { ThunkCreator } from '$store';
import { ControlActions } from '~store/actions';
import { selectEntityWithChangesById } from '~store/selectors';
import { changeWorkableEntity, saveEntityWorker } from './entity';

type ChangeMetaPayload = {
  id: string;
  changes: {
    fieldName: string;
    value: ChangesetValueType;
  }[];
};

export const changeMeta: ThunkCreator = (payload: ChangeMetaPayload) => async (dispatch, getState) => {
  const entity = selectEntityWithChangesById(getState(), payload.id);
  dispatch(ControlActions.setWorkableEntity(entity));

  for (const changeSet of payload.changes) {
    dispatch(changeWorkableEntity({ id: payload.id, changeSet }));
  }

  await dispatch(saveEntityWorker({ entityId: payload.id }));
};
