import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';

import { SvgTextAlignRight } from '../SvgTextAlign';

export const AlignTextRightSettings = {
  blockType: 'text-right',
  className: 'u-text-right',
  element: 'p',
};

export const AlignTextRightButton = createBlockStyleButton({
  blockType: AlignTextRightSettings.blockType,
  children: <SvgTextAlignRight />,
});
