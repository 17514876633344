import { create } from 'superstruct';

import { URL_REPLACEMENT } from '$const';
import { get, post } from '~services/api/methods';
import { MenuDtoValidator, MenuLockValidator, MenuPermissionsValidator } from '~services/api/validators/Menu';

export enum MenuApiKeysEnum {
  GetMenuLock = 'getMenuLock',
  GetMenuForUpdate = 'getMenuForUpdate',
  GetMenuPermissions = 'getMenuPermissions',
}

function patchPublishState(element: MenuElementDto) {
  element.isPublished = Object.prototype.hasOwnProperty.call(element, 'isPublished') ? element.isPublished : true;
  element.hasDraft = Object.prototype.hasOwnProperty.call(element, 'hasDraft') ? element.hasDraft : false;
  return element;
}
function patchType(element: MenuElementDto) {
  return {
    ...element,
    type: element.alias === 'route:<nolink>' ? 'link' : 'page',
  };
}
function patchAlias(element: MenuElementDto) {
  return {
    ...element,
    fetchAlias: element.alias,
    alias: element.alias.replace('/page', ''),
  };
}

function recurseMenuElementSetPermissions(
  element: MenuElementDto,
  menuPermissions: MenuPermissions,
  parentPermissionBag: MenuPermission,
) {
  // patch menu element with required fields
  const patchedElement = patchAlias(patchType(patchPublishState(element)));

  // see if there are specific permissions for this element
  const explicitPermissionBag = menuPermissions.find(mp => mp.id === element.id);

  // define permissions to be set on this element (either specific or parents)
  const elementPermissionBag = {
    ...parentPermissionBag,
    ...(explicitPermissionBag
      ? explicitPermissionBag
      : {
          // View permission hack - needed because backend can not filter unpublished menu elements
          view: patchedElement.isPublished || parentPermissionBag.view,
        }),
  };

  // define permissions to pass down to children
  const childPermissionBag = elementPermissionBag.scope === 'subtree' ? elementPermissionBag : parentPermissionBag;

  // extract permissions only
  const { id: _id, scope: _scope, ...permissionsOnly } = elementPermissionBag;

  return {
    ...patchedElement,
    permissions: {
      ...permissionsOnly,
    },
    children: element.children.map(child =>
      recurseMenuElementSetPermissions(child, menuPermissions, childPermissionBag),
    ),
  };
}

export function hydrateMenuDataWithPermissions(menuData: MenuDto, permissionsData: MenuPermissionsDto): MenuElement[] {
  const permissions: MenuPermissions = permissionsData.map(p => ({
    id: p.id,
    scope: p.scope,
    view: true,
    update: p.permissions.includes('update'),
    create: p.permissions.includes('create'),
    delete: p.permissions.includes('delete'),
    publish: p.permissions.includes('publish'),
    set_grants: p.permissions.includes('set_grants'),
    view_draft: p.permissions.includes('view_draft'),
  }));
  const defaultPermission: MenuPermission = {
    id: 'DEFAULT',
    scope: 'subtree',
    view: false,
    update: false,
    create: false,
    delete: false,
    publish: false,
    set_grants: false,
    view_draft: false,
  };
  return menuData.map(menuElement => recurseMenuElementSetPermissions(menuElement, permissions, defaultPermission));
}

export const getMenu = async () => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/getMenu`;
  const response = await get<MenuDto>(requestUrl);
  return create(response, MenuDtoValidator);
};

export const getMenuPermissions = async () => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/menu_permissions`;
  const response = await get<MenuPermissionsDto>(requestUrl);
  return create(response, MenuPermissionsValidator);
};

export const getMenuForUpdate = async () => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/getmenu_for_update`;
  let response: MenuDto = [];
  try {
    response = await get<MenuDto>(requestUrl);
  } catch (_error) {
    /* Do nothing */
    // Silently catch error. This only happens if BE answers with 401 or 403.
    // In that case the app should just continue with an empty array (= no extra data from this call).
  }
  return create(response, MenuDtoValidator);
};

export const getMenuLock = async () => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/lockMenu`;
  const response = await get<{ status: string; info: string }>(requestUrl);
  return create(response, MenuLockValidator);
};

export const updateMainMenu = async (changes: MenuElementChange[]) => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/saveMenu`;

  /* Correction
   * For 'move' changes the backend expects a parameter called 'newparentId', while in frontend we use 'newParentId'.
   * Since we don't want to change our frontend code to continue this spelling mistake we change it here, right before
   * sending it to the backend.
   */
  let modifiedChanges = changes;
  try {
    modifiedChanges = JSON.parse(JSON.stringify(changes).replaceAll('newParentId', 'newparentId'));
  } catch (_) {
    /* Ignore */
  }

  const response = await post<{ status?: string; info?: string }>(requestUrl, modifiedChanges);
  return response;
};
