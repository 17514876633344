import type { TypedStartListening } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '$store';
import { delayedRemoveNotifcation } from './thunks/notification';
import { StatusActions } from './actions';

export const NotificationMiddleware = createListenerMiddleware();
const startListening = NotificationMiddleware.startListening as TypedStartListening<RootState, AppDispatch>;

startListening({
  predicate: action =>
    [
      StatusActions.setNotification.type,
      StatusActions.setInfoNotification.type,
      StatusActions.setSuccessNotification.type,
      StatusActions.setErrorNotification.type,
    ].includes(action.type),
  effect: async (_action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    await listenerApi.dispatch(delayedRemoveNotifcation());
  },
});
