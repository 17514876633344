import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash.get';
import { createCachedSelector } from 're-reselect';

import type { RootState } from '$store';
import {
  getFieldValue,
  getRelatedEntityIds,
  getTitle,
  isAnchorEntity,
  makeSlugForAnchorEntity,
} from '~services/entity-helpers';
import { getModuleUrlIdFromData } from '~services/location-helpers';
import { getPageByPath } from '~services/selector-helpers';
import { lookupSelector } from '~store/lookupSelector';
import { stringSelector } from '~store/paramSelector';
import stripHtml from '~utils/stripHtml';
import { selectConfigAppDocTitle, selectConfigLanguages, selectConfigPagePrefix } from './config';
import { selectContextCurrentAppLanguage } from './context';
import { selectWorkableEntities } from './control';
import { selectDraftEntitiesState } from './draft-entities';
import { selectEntitiesState } from './entities';
import { selectLocationState } from './location';
import { selectMenuElements } from './menu';
import { selectSessionIsEditing } from './session';
import { selectStatusPageLoading } from './status';
import { selectUserPermissions } from './user';

export const selectLocationPath = createSelector(
  [selectLocationState, selectConfigPagePrefix],
  (location, pagePrefix) => {
    if (!location || !location.payload.pageAlias) {
      return null;
    }
    const prefix = pagePrefix ? `/${pagePrefix}/` : '/';
    return `${prefix}${location.payload.pageAlias as string}`;
  },
);

function regExpQuote(str: string) {
  return str ? str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1') : '';
}

const getAliasFromPath = (path: string, pagePrefix: string) => {
  if (!path) {
    return;
  }

  // a path can be in form: /<lang>/<prefix>/<alias>
  // where lang and prefix are optional
  // Linde fix: The paths from linde always have /page/ as path prefix,
  // which should not be shown in the URL

  const regexQuery = `^/?([a-z]{2}/)?(${regExpQuote(pagePrefix)}/|page/)?(.*)$`;
  const re = new RegExp(regexQuery, 'i');
  // Use RegExp.exec(string) instead of string.match(RegExp)
  // const matches = path.match(re);
  const matches = re.exec(path);

  let alias: string;
  if (matches) {
    alias = matches[3];
  }
  if (!alias) {
    return;
  }
  return alias;
};

export const selectAliasFromPath = createSelector([selectConfigPagePrefix, stringSelector], (prefix, path) =>
  getAliasFromPath(path, prefix),
);

const aliasPathSelector = createSelector(
  selectConfigPagePrefix,
  prefix => (path: string) => getAliasFromPath(path, prefix),
);

export const selectMenuStartPageAlias = createSelector(
  [selectMenuElements, aliasPathSelector],
  (elements, $aliasPathSelector) => {
    let firstItemAlias: string;
    elements.some(item => {
      const path = item.alias;
      const alias = $aliasPathSelector(path);
      // this item is a page with a path, use that and stop iterating
      if (alias && item.path) {
        firstItemAlias = alias;
        return true;
      }
      return false;
    });
    return firstItemAlias;
  },
);

/**
 * Merge the entities state slice with the workable entities state slice
 */
export const selectEntitiesWithChanges = createSelector(
  [selectEntitiesState, selectDraftEntitiesState, selectWorkableEntities, selectSessionIsEditing],
  (entities, draftEntities, workableEntities, isEditing) => {
    const result: Record<string, Entity> = {};

    Object.keys(entities).forEach(id => {
      if (workableEntities && workableEntities[id]) {
        result[id] = workableEntities[id];
      } else {
        result[id] = entities[id].data;
      }
    });

    if (isEditing) {
      Object.keys(draftEntities).forEach(id => {
        if (workableEntities && workableEntities[id]) {
          result[id] = workableEntities[id];
        } else if (draftEntities[id]) {
          result[id] = draftEntities[id].data;
        }
      });
    }

    return result;
  },
);

// We're not using the workableEntities state slice because that would change
// too much. The uuid doesn't change anyway.
export const selectCurrentPageId = createSelector(
  [selectLocationPath, selectEntitiesState, selectDraftEntitiesState],
  (path, entities, draftEntities) => {
    if (!path) {
      return null;
    }
    const pageId = getPageByPath(entities, path);
    if (pageId != null) return pageId;

    return getPageByPath(draftEntities, path);
  },
);

export const selectPageIdByPath = createSelector(
  [stringSelector, selectEntitiesState, selectDraftEntitiesState],
  (path, entities, draftEntities) => {
    if (!path) {
      return null;
    }
    const pageId = getPageByPath(entities, path);
    if (pageId != null) return pageId;

    return getPageByPath(draftEntities, path);
  },
);

export const selectCurrentPageEntity = createSelector([selectCurrentPageId, selectEntitiesState], (id, entities) => {
  if (!id || !entities[id]) {
    return null;
  }
  return entities[id].data;
});

export const selectCurrentPageWithChanges = createSelector(
  [selectCurrentPageId, selectEntitiesWithChanges],
  (id, entities) => {
    if (!id || !entities[id]) {
      return null;
    }
    return entities[id];
  },
);

export const selectCurrentPageModuleIds = createSelector(
  [selectCurrentPageWithChanges],
  (page: Entity) => getRelatedEntityIds(page, 'field_modules') || [],
);

export const selectCurrentPageAnchors = createSelector(
  [selectCurrentPageModuleIds, selectEntitiesWithChanges],
  (moduleIds, entities) =>
    moduleIds.map(id => entities[id]).filter(module => module && module.type === 'paragraph--anchor'),
);

export const selectCurrentPageModuleMap = createSelector(
  [selectCurrentPageModuleIds, selectEntitiesWithChanges],
  (moduleIds, entities) =>
    moduleIds
      .map(id => entities[id])
      .filter(entity => entity)
      .map(entity => ({
        id: getModuleUrlIdFromData(entity.id, entity.type),
        entity: entity,
        slug: isAnchorEntity(entity) && makeSlugForAnchorEntity(entity),
      })),
);

export const selectCurrentPageNid = lookupSelector(selectCurrentPageWithChanges, pageEntity => {
  if (!pageEntity) {
    return null;
  }
  return pageEntity.attributes.nid || false;
});

export const selectCurrentPageLoadingState = createSelector(
  [selectStatusPageLoading, selectLocationPath],
  (pageLoading, currentPath) => pageLoading[currentPath],
);

export const selectPageLoadingStateByAlias = createSelector(
  [selectStatusPageLoading, stringSelector],
  (pageLoading, alias) => pageLoading[alias],
);

export const selectApiLanguagePrefix = createSelector(
  [selectConfigLanguages, selectContextCurrentAppLanguage],
  (languages, lang) => languages.filter(langItem => langItem.langcode === lang).map(langItem => langItem.prefix)[0],
);

export const selectUrlLanguagePrefix = createSelector(
  [selectConfigLanguages, selectContextCurrentAppLanguage],
  (languages, lang): string | undefined => {
    if (!languages) return;
    if (languages.length === 1) return;
    const prefix = languages.filter(langItem => langItem.langcode === lang).map(langItem => langItem.prefix)[0];
    return prefix.replace(/\//g, '');
  },
);

export const selectDocumentTitle = createSelector(
  [selectCurrentPageWithChanges, selectConfigAppDocTitle],
  (entity, appDocTitle) => (entity && getTitle(entity)) || appDocTitle,
);

export const selectCurrentPagePermissions = createSelector(
  [selectCurrentPageId, selectEntitiesState],
  (id, entities) => {
    const permission: EntityInformationPermissions = {
      edit: false,
      publish: false,
    };
    if (!id || !entities[id] || !entities[id].permissions) {
      return permission;
    }
    return { ...permission, ...entities[id].permissions };
  },
);

export const selectCurrentDraftPermissions = createSelector(
  [selectCurrentPageId, selectDraftEntitiesState],
  (id, entities) => {
    const permission: EntityInformationPermissions = {
      edit: false,
      publish: false,
    };
    if (!id || !entities[id] || !entities[id].permissions) {
      return permission;
    }
    return { ...permission, ...entities[id].permissions };
  },
);

export const selectUserCanEditPage = createSelector(
  [selectUserPermissions, selectCurrentPagePermissions, selectCurrentDraftPermissions],
  (userPermissions, currentPagePermissions, currentDraftPermissions) =>
    userPermissions.rootAccess || currentPagePermissions?.edit || currentDraftPermissions?.edit || false,
);

export const selectShowEmptyPage = createSelector(
  [selectCurrentPageNid, selectCurrentPageModuleIds, selectSessionIsEditing],
  (nid, currentPageModuleIds, isEditing) => nid !== null && currentPageModuleIds.length === 0 && !isEditing,
);

export const selectAllAnchorElements = createSelector([selectEntitiesWithChanges], entities =>
  Object.values(entities).filter((entity: Entity) => entity && entity.type === 'paragraph--anchor'),
);

export const selectAnchorElementsByName = createSelector([selectAllAnchorElements, stringSelector], (anchors, name) => {
  const searchText = name.toLowerCase();

  return anchors.filter((anchor: Entity) => {
    let path = getFieldValue<string>(anchor, 'attributes.page_path');
    let title = stripHtml(anchor.attributes.field_title?.value || false);

    if (!path || !title) {
      return false;
    }

    path = path.toLowerCase();
    title = title.toLowerCase();

    return path.indexOf(searchText) !== -1 || title.indexOf(searchText) !== -1;
  });
});

export const selectEntityWithChangesById = createCachedSelector(
  selectEntitiesWithChanges,
  stringSelector,
  (entities, id) => entities[id],
)(stringSelector);

export const selectEntityType = createSelector(
  [selectEntitiesState, selectDraftEntitiesState, stringSelector],
  (entities, draftEntities, id) => entities[id]?.type || draftEntities[id]?.type,
);

// only one level
const getReferencedEntity = (ref: Entity, state: RootState) => selectEntityWithChangesById(state, ref.id);

const makeGetReferencedEntityWithChanges = (id: string, fieldName: string) =>
  createSelector([(state: RootState) => state], (state: RootState) => {
    if (!id) {
      return null;
    }
    const entity = selectEntityWithChangesById(state, id);
    if (!entity) {
      return null;
    }
    const resultRef: Entity | Entity[] = _get(entity, fieldName);

    if (!resultRef) {
      return null;
    }

    if (Array.isArray(resultRef)) {
      return resultRef.map(ref => getReferencedEntity(ref, state));
    } else {
      return getReferencedEntity(resultRef, state);
    }
  });

// gets an entity at a specified fieldName, fieldName can be an array to get
// deeply nested entities once they become available
export const makeGetDeeplyReferencedEntityWithChanges = (id: string | string[], fieldNames: string[]) =>
  // sorry for what follows in terms of readability. I didn't mean to.
  createSelector([(state: RootState) => state], state => {
    const fieldNamesLen = fieldNames.length;
    let i = 0;
    let entityIdOrIds = id;
    let entity: Entity | Entity[];
    if (!entityIdOrIds) {
      return null;
    }
    for (; i < fieldNamesLen; i++) {
      const currFieldName = fieldNames[i];

      if (Array.isArray(entityIdOrIds)) {
        entity = entityIdOrIds.map(id => makeGetReferencedEntityWithChanges(id, currFieldName)(state)).flat();
      } else {
        entity = makeGetReferencedEntityWithChanges(entityIdOrIds, currFieldName)(state);
      }

      if (!entity) {
        return;
      }

      entityIdOrIds = Array.isArray(entity) ? entity.map(_entity => _entity && _entity.id) : entity.id;
    }

    return entity;
  });

// helper to get all related entities of a specified entity id
export const makeGetAllRelatedEntityIds = (id: string) =>
  createSelector([(state: RootState) => state], (state: RootState) => {
    if (!id) {
      return null;
    }
    const entity = selectEntityWithChangesById(state, id);
    if (!entity) {
      return null;
    }

    return Object.keys(entity.relationships)
      .filter(rel => rel !== 'uid' && rel !== 'parent')
      .map(rel => getRelatedEntityIds(entity, rel))
      .reduce((flat, toFlatten) => flat.concat(toFlatten), [])
      .map(relatedEntityId => [relatedEntityId, ...(makeGetAllRelatedEntityIds(relatedEntityId)(state) || [])])
      .reduce((flat, toFlatten) => flat.concat(toFlatten), []);
  });

function filterMenuElements(array: MenuElement[], fn: (arg: MenuElement) => boolean) {
  return array.reduce<MenuElement[]>((r, o) => {
    const children = o.children && o.children.length ? filterMenuElements(o.children, fn) : [];
    if (fn(o)) {
      r.push({
        ...o,
        children,
      });
    }
    return r;
  }, []);
}

/**
 * Selects Menu Elements to show in Sidebar
 *
 * Normal users:
 * - Editmode: menu elements where they have 'view' permissions
 * - View: menu elements where they have 'view' permission AND that are published
 * Editors:
 * - Editmode: ALL menu elements
 * - View: menu elements that are published
 */
export const selectMenuElementsForSidebar = createSelector(
  [selectMenuElements, selectSessionIsEditing, selectUserPermissions],
  (menuElements, isEditing, permissions) => {
    if (permissions.manageMenuElements) {
      return isEditing ? menuElements : filterMenuElements(menuElements, menuElement => menuElement.isPublished);
    } else {
      return isEditing
        ? filterMenuElements(menuElements, menuElement => menuElement.permissions.view)
        : filterMenuElements(menuElements, menuElement => menuElement.isPublished && menuElement.permissions.view);
    }
  },
);
