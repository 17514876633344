import { useMemo } from 'react';

import useAppSelector from '~hooks/useAppSelector';
import useTopics from '~hooks/useTopics';
import { getRelatedEntityIds } from '~services/entity-helpers';
import { selectCurrentPageId, selectDraftEntityById, selectEntityById, selectSessionIsEditing } from '~store/selectors';
import { parseImageCrops } from '~utils/imageCrop';

export default function usePageMeta() {
  const topics = useTopics();

  const isEditmodeActive = useAppSelector(selectSessionIsEditing);
  const currentPageId = useAppSelector(selectCurrentPageId);

  // page
  const pageEntity = useAppSelector(state => selectEntityById(state, currentPageId));
  const pageDraftEntity = useAppSelector(state => selectDraftEntityById(state, currentPageId));
  // While in editmode use draft entity if available
  let entity = pageEntity;
  if (isEditmodeActive && pageDraftEntity) {
    entity = pageDraftEntity;
  }

  // meta image - resolve relation and get image entity
  const metaImageId = getRelatedEntityIds(entity, 'field_meta_image')[0];
  const metaImageEntity = useAppSelector(state => selectEntityById(state, metaImageId));
  const metaImageDraftEntity = useAppSelector(state => selectDraftEntityById(state, metaImageId));
  // While in editmode use draft entity if available
  let imageEntity = metaImageEntity;
  if (isEditmodeActive && metaImageDraftEntity) {
    imageEntity = metaImageDraftEntity;
  }

  return useMemo(() => {
    // topics - filter and hydrate with data from topic endpoint
    const pageTopics = (entity?.attributes?.field_topics || [])
      .map(({ target_id }) => {
        try {
          const topic: Topic = topics.byId[target_id];
          return topic;
        } catch (_) {
          return;
        }
      })
      .filter(Boolean);

    // image - get url from entity
    let metaImage: MetaImage | undefined;
    if (imageEntity) {
      metaImage = {
        id: imageEntity.id,
        url: imageEntity.meta?.derivatives?.original || imageEntity.attributes?.url,
      };
    }

    // crops
    const imageCrops = parseImageCrops(entity?.attributes?.field_meta_image_crops);

    return {
      pageId: currentPageId,
      title: entity?.attributes?.field_meta_title || '',
      description: entity?.attributes?.field_meta_description || '',
      topics: pageTopics,
      image: metaImage,
      imageCrops,
    };
  }, [entity, topics.byId, imageEntity, currentPageId]);
}
