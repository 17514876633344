export enum BREAKPOINTS {
  extraSmall = 375,
  small = 768,
  medium = 1024,
  large = 1440,
}

export function getWindowsDimensions() {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { height, width };
}

export function isLess(bp: BREAKPOINTS) {
  const { width } = getWindowsDimensions();
  return width < bp;
}

export function isLessThanExtraSmall() {
  return isLess(BREAKPOINTS.extraSmall);
}
export function isLessThanSmall() {
  return isLess(BREAKPOINTS.small);
}

export function isLessThanMedium() {
  return isLess(BREAKPOINTS.medium);
}

export function isLessThanLarge() {
  return isLess(BREAKPOINTS.large);
}
