import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type SessionState = Session;

// Initial State
export const initialState: SessionState = {
  name: null,
  pass: null,
  isEditing: false,
  serverSession: null,
};

// Reducers
const load: CaseReducer<SessionState, PayloadAction<Partial<Session>>> = (state, action) => {
  if (action.payload) {
    return Object.assign({}, state, action.payload);
  }
};
const setEditing: CaseReducer<SessionState, PayloadAction<boolean>> = (state, action) => {
  state.isEditing = action.payload === true ? true : false;
};
const enableEditing: CaseReducer<SessionState> = state => {
  state.isEditing = true;
};
const disableEditing: CaseReducer<SessionState> = state => {
  state.isEditing = false;
};
const login: CaseReducer<SessionState, PayloadAction<Partial<Session>>> = (state, action) => {
  if (action.payload) {
    return {
      ...state,
      ...action.payload,
    };
  }
};
const logout: CaseReducer<SessionState> = () => initialState;

// Slice
const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    load,
    login,
    logout,
    setEditing,
    enableEditing,
    disableEditing,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: SessionState) => state;
export const selectors = {
  self: selectSelf,
  name: (state: SessionState) => state.name,
  pass: (state: SessionState) => state.pass,
  isEditing: (state: SessionState) => state.isEditing,
  serverSession: (state: SessionState) => state.serverSession,
};
