import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import log from '~services/log';
import { getConfig } from '../api/config';
import { Config } from '../typings/config'; // Import the Config interface

export const fetchConfig = createAsyncThunk('config/fetch', async () => {
  try {
    const res = await getConfig();
    return res;
  } catch (e) {
    log('Error in fetch configs thunk!', e, 'error');
    throw e; // Rethrow the error to be handled by the rejected action
  }
});

export interface ConfigState {
  isLoading: boolean;
  data: Config | null;
  error: string | null;
}

const initialState: ConfigState = {
  isLoading: false,
  data: {
    tank_image: '',
    tanks: {
      vertical: {
        type: '',
        label: '',
        pdf_template: '',
        image_coords: [],
        params: [],
      },
      horizontal: {
        type: '',
        label: '',
        pdf_template: '',
        image_coords: [],
        params: [],
      },
      coldbox: {
        type: '',
        label: '',
        pdf_template: '',
        image_coords: [],
        params: [],
      },
      'flat-bottom': {
        type: '',
        label: '',
        pdf_template: '',
        image_coords: [],
        params: [],
      },
    },
    form: {
      title: '',
      headline: '',
      description: '',
      steps: {
        select_tank: {
          headline: '',
          description: '',
        },
        dimension: {
          headline: '',
          description: '',
        },
        download: {
          headline: '',
          description: '',
        },
      },
    },
  },
  error: null,
};

const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchConfig.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.data = action.payload as Config;
        state.isLoading = false;
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Unknown error occurred';
      });
  },
});

export default ConfigSlice.reducer;
