import { connect } from 'react-redux';

import { ModuleTypes } from '$const';
import type { RootState } from '$store';
import AnchorLinkSelector, {
  IAnchorLinkSelectorProps,
} from '~components/generic/elements/AnchorLinkSelector/AnchorLinkSelector';
import { getFieldValue, makeSlugForAnchorEntity } from '~services/entity-helpers';
import { getDataFromInternalUrl } from '~services/location-helpers';
import { selectAnchorLinkAutosuggestSearchResult } from '~store/selectors/control';
import { selectAliasFromPath, selectEntityWithChangesById } from '~store/selectors/custom';
import { selectMenuElementById } from '~store/selectors/menu';
import { getAnchorLinkSuggestions } from '~store/thunks/anchor';

export interface IAnchorLinkSelectorContainerProps {
  theme?: 'dark' | 'light';
  onSelectedValueChange: (id: string, type: string) => void;
  value: string;
  label?: string;
}

export type StateProps = Pick<IAnchorLinkSelectorProps, 'anchors' | 'links' | 'selectedValue'>;
const mapStateToProps = (state: RootState, ownProps: IAnchorLinkSelectorContainerProps): StateProps => {
  const { anchors, links } = selectAnchorLinkAutosuggestSearchResult(state);

  let selectedValue = '';

  if (ownProps.value) {
    const urlData = getDataFromInternalUrl(ownProps.value);

    if (urlData.type === ModuleTypes.anchorLink) {
      const anchorEntity = selectEntityWithChangesById(state, urlData.id);
      const alias = selectAliasFromPath(state, getFieldValue<string>(anchorEntity, 'attributes.page_path'));
      const slug = makeSlugForAnchorEntity(anchorEntity);
      selectedValue = `/${alias}/${slug}`;
    } else if (urlData.type === ModuleTypes.internalLink) {
      const menuElement = selectMenuElementById(state, urlData.id);
      if (menuElement) {
        selectedValue = menuElement.alias;
      }
    }
  }

  return {
    anchors,
    links,
    selectedValue,
  };
};

export type DispatchProps = Pick<IAnchorLinkSelectorProps, 'onSearchChange'>;
const mapDispatchToProps: DispatchProps = {
  onSearchChange: getAnchorLinkSuggestions,
};

export const AnchorLinkSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(AnchorLinkSelector);
export default AnchorLinkSelectorContainer;
