import { connect } from 'react-redux';

import { ROUTER_PAGE, ROUTER_PAGE_WITH_ANCHOR, ROUTER_PAGE_WITH_ASSET } from '$const';
import type { RootState } from '$store';
import App, { IAppProps } from '~components/app/App';
import { useAppLogin } from '~services/env';
import {
  selectConfigFallbackForbiddenUrl,
  selectContext,
  selectCurrentPageId,
  selectCurrentPageLoadingState,
  selectDocumentTitle,
  selectEmptyPageTemporaryEntityId,
  selectLocationReady,
  selectLocationType,
  selectPageIdByPath,
  selectPageLoadingStateByAlias,
  selectSessionName,
  selectShowEmptyPage,
  selectSidebarIsCollapsed,
  selectStatusShowEditLoading,
} from '~store/selectors';

function getIsInAppPage(type: string) {
  if (type === ROUTER_PAGE) return false;
  if (type === ROUTER_PAGE_WITH_ANCHOR) return false;
  if (type === ROUTER_PAGE_WITH_ASSET) return false;
  return true;
}

type StateProps = Pick<
  IAppProps,
  | 'isRouterReady'
  | 'isLoggedIn'
  | 'isMenuEditing'
  | 'currentPageId'
  | 'documentTitle'
  | 'showEmptyPage'
  | 'emptyPageTemporaryEntityId'
  | 'collapsedSidebar'
  | 'showEditLoading'
  | 'pageType'
  | 'isPageLoaded'
  | 'isPageLoading'
  | 'isPageForbidden'
  | 'isPageNotFound'
  | 'isPageTimedOut'
  | 'isInAppPage'
>;

const mapStateToProps = (state: RootState): StateProps => {
  const context = selectContext(state);
  const isRouterReady = selectLocationReady(state);
  const pageType = selectLocationType(state);
  const isInAppPage = getIsInAppPage(pageType);
  let currentPageId = selectCurrentPageId(state);
  const pageLoadingState = selectCurrentPageLoadingState(state);
  let isPageLoaded = pageLoadingState?.hasLoaded || false;
  let isPageLoading = pageLoadingState?.isLoading || false;
  let isPageForbidden = !pageLoadingState?.isLoading && !!pageLoadingState?.forbidden;
  let isPageNotFound = !pageLoadingState?.isLoading && !!pageLoadingState?.notFound;
  let isPageTimedOut = !pageLoadingState?.isLoading && !!pageLoadingState?.timeout;

  // Handle forbidden pages with fallback URL
  if (pageLoadingState?.forbidden) {
    // check if a fallback forbidden url is given
    const fallbackForbiddenUrl = selectConfigFallbackForbiddenUrl(state);
    if (!!fallbackForbiddenUrl) {
      // read the loading state of the fallback page (this should) already have been loaded in the fetchPage thunk
      const fallbackLoadingState = selectPageLoadingStateByAlias(state, fallbackForbiddenUrl);

      // override loading and error props
      isPageLoaded = fallbackLoadingState?.hasLoaded || false;
      isPageLoading = fallbackLoadingState?.isLoading || false;
      isPageForbidden = !fallbackLoadingState?.isLoading && !!fallbackLoadingState?.forbidden;
      isPageNotFound = !fallbackLoadingState?.isLoading && !!fallbackLoadingState?.notFound;
      isPageTimedOut = !fallbackLoadingState?.isLoading && !!fallbackLoadingState?.timeout;

      // override currentPageId so that the content page can render content of the fallback page
      currentPageId = selectPageIdByPath(state, fallbackForbiddenUrl);

      // We don't have to override pageType because the requested page was a content page
      // pageType = RouterActions.navigateToPage.type
    }
  }

  return {
    isRouterReady,
    isLoggedIn: useAppLogin ? Boolean(selectSessionName(state)) : true,
    isMenuEditing: context === 'main-navigation',
    currentPageId,
    documentTitle: selectDocumentTitle(state),
    showEmptyPage: selectShowEmptyPage(state),
    emptyPageTemporaryEntityId: selectEmptyPageTemporaryEntityId(state),
    collapsedSidebar: selectSidebarIsCollapsed(state),
    showEditLoading: selectStatusShowEditLoading(state),
    pageType,
    isPageLoaded,
    isPageLoading,
    isPageForbidden,
    isPageNotFound,
    isPageTimedOut,
    isInAppPage,
  };
};

export default connect(mapStateToProps)(App);
