import { PureComponent } from 'react';

import { AnchorLinkSelectorContainer } from '~components/generic/elements/AnchorLinkSelector/AnchorLinkSelectorContainer';
import Button from '~components/generic/elements/Button';
import { Input } from '~components/generic/elements/Input/Input';
import { IEntityComponentProps } from '~interfaces/props';
import { ILinkWidgetFieldDef } from '~services/entity-definitions';
import { getFieldValue } from '~services/entity-helpers';
import { getUrlFromTextLinkData } from '~services/text-editor-utils';

export interface IAnchorLinkWidgetProps extends IEntityComponentProps {
  labelOptions?: ILinkWidgetFieldDef;
  externalOptions?: ILinkWidgetFieldDef;
  internalOptions?: ILinkWidgetFieldDef;
}

interface IAnchorLinkWidgetState {
  useExternal: boolean;
}

export class AnchorLinkWidget extends PureComponent<IAnchorLinkWidgetProps, IAnchorLinkWidgetState> {
  state: IAnchorLinkWidgetState = {
    useExternal: false,
  };

  handleLabelChange = (val: string) => {
    const { onChange, labelOptions } = this.props;
    onChange(labelOptions.fieldName, val);
  };

  handleExternalChange = (val: string) => {
    const { onChange, externalOptions } = this.props;
    onChange(externalOptions.fieldName, val);
  };

  handleInternalChange = (val: string, type: string) => {
    const { onChange, internalOptions } = this.props;
    const url = getUrlFromTextLinkData({
      id: val,
      type,
    });
    onChange(internalOptions.fieldName, url, false, 0, '');
  };

  handleUseExternalLink = () => {
    const { onChange, internalOptions } = this.props;
    this.setState({
      useExternal: true,
    });
    onChange(internalOptions.fieldName, '');
  };

  handleUseInternalLink = () => {
    const { onChange, externalOptions } = this.props;
    this.setState({
      useExternal: false,
    });
    onChange(externalOptions.fieldName, '');
  };

  render() {
    const { useExternal } = this.state;
    const { data, labelOptions, externalOptions, internalOptions } = this.props;
    const internalLinkValue = getFieldValue<string>(data, internalOptions.fieldName);
    // let internalLinkValue = getFieldValue<string>(data, internalOptions.fieldName);
    // if (internalLinkValue) {
    //   internalLinkValue = internalLinkValue.startsWith('/page/') ? internalLinkValue.substr(5) : internalLinkValue;
    // }
    const externalLinkValue = getFieldValue<string>(data, externalOptions.fieldName);
    const _useExternal = Boolean(externalLinkValue) || useExternal;

    return (
      <div>
        <Input
          label={labelOptions.label}
          type="text"
          value={getFieldValue<string>(data, labelOptions.fieldName)}
          onChange={this.handleLabelChange}
        />
        {_useExternal ? (
          <div>
            <Input
              label={externalOptions.label}
              type="text"
              value={externalLinkValue}
              onChange={this.handleExternalChange}
            />
            <Button
              className="u-size-xs c-button c-button--dark"
              onClick={this.handleUseInternalLink}
              label="Use internal link"
            />
          </div>
        ) : (
          <div>
            <AnchorLinkSelectorContainer
              label={internalOptions?.label}
              value={internalLinkValue}
              onSelectedValueChange={this.handleInternalChange}
            />
            <Button
              className="u-size-xs c-button c-button--dark"
              onClick={this.handleUseExternalLink}
              label="Use URL value"
            />
          </div>
        )}
      </div>
    );
  }
}

export default AnchorLinkWidget;
