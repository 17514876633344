import { array, Describe, is, nullable, optional, string, type } from 'superstruct';

export const CookieModalButtonValidator = type({
  label: string(),
  link: string(),
});
export const isCookieModalButton = (obj: unknown) => is(obj, CookieModalButtonValidator);

export const CookieModalValidator: Describe<CookieModalDto> = type({
  cookieText: optional(string()),
  cookieTexts: optional(array(nullable(string()))),
  buttons: array(CookieModalButtonValidator),
});
export const isCookieModal = (obj: unknown) => is(obj, CookieModalValidator);
