/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import type { DependencyList, EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

/**
 * The 'useEffectWhen' hooks works exactly like the 'useEffect' hook with the additional option to specify the dependencies on which the effect should run.
 * @param effect Function to be executed
 * @param deps List of dependencies for the effect function
 * @param whenDeps List of dependencies that trigger the effect
 * @returns
 */
export default function useEffectWhen(effect: EffectCallback, deps: DependencyList, whenDeps: DependencyList) {
  const whenRef = useRef(whenDeps || []);
  const initial = whenRef.current === whenDeps;
  const whenDepsChanged = initial || !whenRef.current.every((w, i) => w === whenDeps[i]);
  whenRef.current = whenDeps;
  const nullDeps = deps.map(() => null);

  return useEffect(whenDepsChanged ? effect : () => {}, whenDepsChanged ? deps : nullDeps);
}
