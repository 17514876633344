import {
  array,
  boolean,
  coerce,
  defaulted,
  Describe,
  enums,
  lazy,
  nullable,
  optional,
  string,
  type,
  unknown,
} from 'superstruct';

export const MenuElementValidator: Describe<MenuElement> = type({
  // alias: coerce(string(), string(), (value: string) => (value.startsWith('/page/') ? value.substr(5) : value)),
  alias: string(),
  fetchAlias: defaulted(string(), ''),
  children: lazy(() => array(MenuElementValidator)),
  expanded: defaulted(boolean(), false),
  hasDraft: defaulted(boolean(), false),
  id: string(),
  isPublished: defaulted(boolean(), true),
  parent: defaulted(nullable(string()), null),
  path: coerce(nullable(string()), unknown(), value => value || null),
  title: string(),
  type: enums(['page', 'link']),
  permissions: type({
    view: boolean(),
    update: boolean(),
    create: boolean(),
    delete: boolean(),
    publish: boolean(),
    set_grants: boolean(),
    view_draft: boolean(),
  }),
});

export const MenuElementsValidator = array(MenuElementValidator);

export const MenuPermissionValidator: Describe<MenuPermissionDto> = type({
  id: string(),
  scope: enums(['subtree', 'node']),
  permissions: array(enums(['view', 'update', 'create', 'delete', 'publish', 'set_grants', 'view_draft'])),
});

export const MenuPermissionsValidator = array(MenuPermissionValidator);

// Success: {"status":"done"}
// Fail: {"status":"locked","info":"Locked from: philipp.laube@peter-schmidt-group.de"}
// Not allowed: Status 403
export const MenuLockValidator = type({
  status: enums(['done', 'locked']),
  info: optional(string()),
});

export const MenuElementDtoValidator: Describe<MenuElementDto> = type({
  alias: string(),
  children: lazy(() => array(MenuElementDtoValidator)),
  hasDraft: defaulted(boolean(), false),
  id: string(),
  isPublished: defaulted(boolean(), true),
  parent: defaulted(nullable(string()), null),
  path: coerce(nullable(string()), unknown(), value => value || null),
  title: string(),
});

export const MenuDtoValidator = array(MenuElementDtoValidator);
