import useAppSelector from '~hooks/useAppSelector';
import { selectWidgetsFooterContent, selectWidgetsFooterFooter } from '~store/selectors';

export default function Footer() {
  const content = useAppSelector(selectWidgetsFooterContent);
  const footer = useAppSelector(selectWidgetsFooterFooter);

  return (
    <div id="footer" className="c-footer">
      <div className="container px-0">
        <div className="row">
          {content &&
            content.map((item, i) => {
              if (item.data) {
                return (
                  <div key={i} className="col col-12 col-xl-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.type === 'html' ? item.data : '',
                      }} //TODO check for type json
                    />
                  </div>
                );
              }
              return;
            })}
        </div>
      </div>
      <div className="footer-border">
        <div className="container">
          <div className="row">
            <div className="col col-12 footer-bottom-menu">
              {footer && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: footer.type === 'html' ? footer.data : '',
                  }} // TODO check for type json
                />
              )}
              <span className="p42-footer-copyright">{new Date().getFullYear()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
