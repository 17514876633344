import clsx from 'clsx';
import { FC } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';

import whichOs from '~utils/whichOs';
import uploadFilesStyles from './uploadFilesStyles.css';

export type UploadRejection = FileRejection;

interface IUploadInputProps {
  progress?: number;
  onUpload: (files: File[], fileRejections: UploadRejection[], event: DropEvent) => void;
  onUploadRejected?: (fileRejections: UploadRejection[], event: DropEvent) => void;
  theme?: 'dark' | 'light';
  options?: {
    mimeTypes?: string[][];
  };
  multiple: boolean;
}

const UploadInput: FC<IUploadInputProps> = ({
  onUpload,
  onUploadRejected,
  progress,
  theme,
  multiple,
  options,
}: IUploadInputProps) => {
  const classes = clsx({
    [uploadFilesStyles.overlay]: true,
    [uploadFilesStyles.overlayWithProgress]: Boolean(progress),
    'u-absolute': true,
    'u-absolute--fill': true,
  });
  const outerClasses = clsx({
    relative: true,
    'u-image-ratio--4-3': true,
  });
  const dropdownAreaClasses = clsx({
    'u-padding-vertical-lg': true,
    'u-padding-horizontal-md': true,
    'u-color-white': theme === 'dark',
  });

  let allowedMimeTypes = '';

  if (options && options.mimeTypes) {
    allowedMimeTypes = options.mimeTypes.map(mimeType => mimeType[0]).join(', ');

    if (whichOs() === 'Windows') {
      // NOTE: if we are on windows check only the file extensions, not mime types
      // there are mime type mismatchs between platforms
      allowedMimeTypes = options.mimeTypes
        .map(mimeType => {
          return mimeType.length > 1 ? mimeType[1] : mimeType[0];
        })
        .join(', ');
      // Fix, because in definitions some extensions are defined like "'.jpg/.jpeg'".
      allowedMimeTypes = allowedMimeTypes.replace('/', ', ');
    }
  }

  // if uploading, show the upload progress
  if (progress) {
    return (
      <div className={outerClasses}>
        <div className={classes}>
          <div className={uploadFilesStyles.overlayContent}>
            <div className={uploadFilesStyles.overlayContentInner}>
              <div>
                <strong>Uploading …</strong>
              </div>
              <div>{progress} %</div>
              <div className="c-progress c-progress--thin">
                <div className="c-progress__bar" style={{ width: `${progress}%` }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // return the normal dropzone
  return (
    <div className={outerClasses}>
      <Dropzone
        onDrop={onUpload}
        multiple={multiple}
        accept={allowedMimeTypes}
        onDropRejected={onUploadRejected}
        noDragEventsBubbling={true}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div {...getRootProps()} className={clsx(classes, 'dropzone', { 'dropzone--isActive': isDragActive })}>
              <div className={uploadFilesStyles.overlayHeader} />
              <div className={uploadFilesStyles.overlayContent}>
                <div className={uploadFilesStyles.overlayContentInner}>
                  <div className={dropdownAreaClasses}>
                    <input {...getInputProps()} />
                    <i className="c-icon-custom c-icon-custom--ic-upload u-size-lg u-block u-padding-bottom-xs" />
                    <div>
                      <strong>Drag & Drop</strong>
                    </div>
                    <div className="u-size-sm">a file to upload (or click to select)</div>
                  </div>
                </div>
              </div>
              <div className={uploadFilesStyles.overlayFooter}>
                <div className="o-text--right o-text--quiet u-size-sm u-margin-right-sm u-margin-bottom-xs">
                  {options &&
                    options.mimeTypes &&
                    options.mimeTypes
                      .map(mimeType => mimeType[1])
                      .filter(val => typeof val !== 'undefined')
                      .join(', ')}
                </div>
              </div>
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default UploadInput;
