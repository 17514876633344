import { selectUnit } from '@formatjs/intl-utils';
import { ReactNode } from 'react';
import { FormattedRelativeTime } from 'react-intl';

import ErrorBoundary from '~components/generic/error-boundary/ErrorBoundary';

/**
 * Lock to build a React component with the lock rendered correctly. We do it
 * like this to use the localisation power of ReactIntl. This isn't a standard
 * React component
 *
 * Locking messages have the format `${user} - ${Date()}`
 *
 * @param lock {string}
 */
export function lockErrorRenderer(lock: string): ReactNode {
  const lockError = lock.split(' - ');

  if (lockError.length === 2) {
    const { value: relTimeValue, unit: relTimeUnit } = selectUnit(new Date(lockError[1]), Date.now());

    return (
      <span>
        Locked by {`${lockError[0]}`}
        <FormattedRelativeTime value={relTimeValue} unit={relTimeUnit} />!
      </span>
    );
  }

  return <span>{JSON.stringify(lock)}</span>;
}

export default function errorRenderer(
  prefix: string,
  verb: string,
  subject: string,
  message: string,
  status: number,
  customRenderFn?: (prefix: string, verb: string, subject: string, message: string, status: number) => ReactNode,
): ReactNode {
  const messageInfo = status === 409 ? lockErrorRenderer(message) : message;
  // customisation
  if (typeof customRenderFn === 'function') {
    return customRenderFn(prefix, verb, subject, message, status);
  }

  return (
    <ErrorBoundary>
      <span>
        {prefix} {verb}
        {subject && <em> {subject}</em>}:
        <br />
        {messageInfo}
      </span>
    </ErrorBoundary>
  );
}
