import clsx from 'clsx';
import { Children, cloneElement, isValidElement, ReactNode } from 'react';

import styles from '../Editor.module.css';

export type ButtonGroupProps = {
  label?: string;
  preIcon?: string | ReactNode;
  children: ReactNode;
  vertical?: boolean;
  small?: boolean;
};

export const ButtonGroup = ({ children, preIcon, label, vertical = false, small = false }: ButtonGroupProps) => {
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { size: small ? 'small' : child.props.size || undefined });
    }
    return child;
  });

  return (
    <div className={clsx(styles.buttonGroup, vertical && styles.buttonGroupVertical, small && styles.small)}>
      {(!!preIcon || !!label) && (
        <div className={styles.buttonGroupInner}>
          {!!preIcon &&
            (typeof preIcon === 'string' ? (
              <div className={styles.buttonGroupPreIcon}>
                <i className={`c-icon-custom c-icon-custom--vertical-centered c-icon-custom--${preIcon}`} />
              </div>
            ) : (
              <div className={styles.buttonGroupPreIconComponent}>{preIcon}</div>
            ))}
          {!!label && <div className={styles.buttonGroupLabel}>{label}</div>}
        </div>
      )}
      <div className={styles.buttonGroupButtons}>{childrenWithProps}</div>
    </div>
  );
};
