import ReactPiwik from 'react-piwik';
import { createMiddleware, EventDefinition, Target } from 'redux-beacon';
import Cookies from 'universal-cookie';

import type { RootState } from '$store';
import { ControlActions, RouterActions } from '~store/actions';
import { selectTrackingMatomoDimensionsMap, selectUserTrackingData } from '~store/selectors';

// to avoid multiple calls to selectTrackingMatomodimensionsMap method, init dimmensionMap with null
let dimensionsMap = null;

// additional user info
const trackUserData = (state: RootState) => {
  const trackingUserParams = selectUserTrackingData(state);

  // custom dimensions must be already set in Matomo
  // currently used ones:
  //  user_country: 1,
  //  user_company: 2,
  //  user_division: 3,
  //  user_roles: 4,
  if (dimensionsMap === null) {
    dimensionsMap = selectTrackingMatomoDimensionsMap(state) || {};
  }

  for (const [key, value] of Object.entries(trackingUserParams)) {
    if (key === 'user_id') {
      ReactPiwik.push(['setUserId', value]);
    } else if (dimensionsMap[key] !== undefined) {
      ReactPiwik.push(['setCustomDimension', dimensionsMap[key], value]);
    }
  }
};

const trackPageView =
  (
    eventDef: EventDefinition<{
      title?: string;
      location?: string;
    }>,
  ): EventDefinition =>
  (action, prevState, nextState) => {
    const event = eventDef(action, prevState, nextState);
    return {
      type: 'page',
      page_title: event.title,
      page_location: event.location,
    };
  };

const trackDownload =
  (
    _eventDef: EventDefinition<{
      filename?: string;
      description?: string;
    }>,
  ): EventDefinition =>
  action => {
    // @FIXME - different format than GTAG
    return {
      type: 'download',
      download_filename: action.payload.filename,
      download_description: action.payload.description,
    };
  };

const trackSearch =
  (
    _eventDef: EventDefinition<{
      keyword?: string;
    }>,
  ): EventDefinition =>
  action => {
    return {
      type: 'search',
      search_keyword: action.payload.query,
    };
  };

const trackPlayVideo =
  (
    _eventDef: EventDefinition<{
      currentSrc?: string;
      currentTime?: number;
      duration?: number;
    }>,
  ): EventDefinition =>
  (action, _prevState, nextState) => {
    const pageTitle = nextState.location.payload.pageAlias;
    const pagePath = nextState.location.pathname;
    // @FIXME - different format than GTAG
    return {
      type: 'playVideo',
      video_current_src: action.payload.currentSrc,
      video_current_time: action.payload.currentTime,
      video_duration: action.payload.duration,
      page_title: pageTitle,
      page_path: pagePath,
    };
  };

const initializeMatomo = (matomoUrl: string, matomoSiteId: number) => {
  const matomo = new ReactPiwik({
    url: matomoUrl,
    siteId: matomoSiteId,
    // trackErrors: true, // invalid PiwikOption
    enableLinkTracking: false,
    trackDocumentTitle: true,
  });

  // if no Matomo consent cookie is present, first disallow tracking
  const matomoCookie = new Cookies().get('mtm_consent');
  if (!matomoCookie) {
    ReactPiwik.push(['optUserOut']);
  }

  if (typeof matomo === 'undefined') {
    console.error('Matomo tracking could not be initialized! Please check Matomo Url and SiteId');
  }
};

// Calling Matomo via ReactPiwik
function matomoPageView(location: string, title: string) {
  ReactPiwik.push(['setCustomUrl', location]);
  ReactPiwik.push(['setDocumentTitle', title]);
  ReactPiwik.push(['trackPageView']);
}

function matomoDownload(filename: string, _description: string) {
  ReactPiwik.push(['trackLink', filename, 'download']);
}

function matomoSearch(keyword: string) {
  ReactPiwik.push(['trackSiteSearch', keyword, 'Overall Search']);
}

function matomoPlayVideo(
  currentSrc: string,
  _currentTime: number,
  _duration: number,
  pageTitle: string,
  _pagePath: string,
) {
  //ReactPiwik.push(['trackEvent', 'Video', 'play', currentSrc]);
  ReactPiwik.push(['trackEvent', 'Click', 'Video Start', `${pageTitle} | ${currentSrc}`]);
}

function matomoStartWizard(pageTitle: string) {
  ReactPiwik.push(['trackEvent', 'Click', 'Wizard Start', pageTitle]);
}

const targetFunction = events => {
  const pageTracking = events.filter(event => event.type === 'page');
  const downloadTracking = events.filter(event => event.type === 'download');
  const searchTracking = events.filter(event => event.type === 'search');
  const playVideoTracking = events.filter(event => event.type === 'playVideo');
  const startWizardTracking = events.filter(event => event.type === 'startWizard');
  pageTracking.forEach(event => {
    matomoPageView(event.page_location, event.page_title);
  });
  downloadTracking.forEach(event => {
    matomoDownload(event.download_filename, event.download_description);
  });
  searchTracking.forEach(event => {
    matomoSearch(event.search_keyword);
  });
  playVideoTracking.forEach(event => {
    matomoPlayVideo(
      event.video_current_src,
      event.video_current_time,
      event.video_duration,
      event.page_title,
      event.page_path,
    );
  });
  startWizardTracking.forEach(event => {
    matomoStartWizard(event.page_title);
  });
  return;
};

function matomoTarget(): Target {
  return targetFunction;
}

const pageView = trackPageView((action, prevState, nextState) => {
  trackUserData(nextState);
  const location = action.meta.location.current;
  return {
    title: location.payload.pageAlias,
    location: location.pathname,
  };
});

const download = trackDownload((action, prevState, nextState) => {
  trackUserData(nextState);
  // @FIXME - different format than GTAG
  return {
    filename: action.payload.filename,
    description: action.payload.description,
  };
});

const search = trackSearch((action, prevState, nextState) => {
  trackUserData(nextState);
  return {
    keyword: action.payload.query,
  };
});

const playVideo = trackPlayVideo((action, prevState, nextState) => {
  trackUserData(nextState);
  // @FIXME - different format than GTAG
  const { payload } = action as ReturnType<typeof ControlActions.playVideo>;
  return {
    currentSrc: payload.currentSrc,
    currentTime: payload.currentTime,
    duration: payload.duration,
  };
});

// map events to actions
const eventsMap = {
  [RouterActions.navigateToDefault.type]: pageView,
  [RouterActions.navigateToPage.type]: pageView,
  [RouterActions.navigateToPageWithAnchor.type]: pageView,
  [ControlActions.downloadFile.type]: download,
  [RouterActions.navigateToSearch.type]: search,
  [ControlActions.playVideo.type]: playVideo,
};

const createMatomo = (matomoUrl: string, matomoSiteId: number) => {
  if (matomoUrl && matomoSiteId) {
    matomoUrl = matomoUrl.replace(/\/$/, '');
    initializeMatomo(matomoUrl, matomoSiteId);
    return createMiddleware(eventsMap, matomoTarget());
  }
  return false;
};

export default createMatomo;
