import clsx from 'clsx';
import SimpleBar from 'simplebar-react';

import styles from './Scrollbar.module.css';

export type ScrollbarProps = SimpleBar.Props & {
  disableHorizontalScrolling?: boolean;
  disableVerticalScrolling?: boolean;
};

export function Scrollbar({ children, ...props }: ScrollbarProps) {
  const { disableHorizontalScrolling, disableVerticalScrolling, className, ...restProps } = props;
  return (
    <SimpleBar
      {...restProps}
      className={clsx(
        styles.scrollbar,
        disableHorizontalScrolling && styles.disableHorizontalScrolling,
        disableVerticalScrolling && styles.disableVerticalScolling,
        className,
      )}
    >
      {children}
    </SimpleBar>
  );
}
