import { lookupSelector } from '~store/lookupSelector';
import { selectors as StatusSelectors } from '~store/slices/status';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectStatusState = lookupSelector(selectRootState, state => state.status);

// Slice: Status Selectors
export const selectStatusNotification = lookupSelector(selectStatusState, StatusSelectors.notification);
export const selectStatusPageLoading = lookupSelector(selectStatusState, StatusSelectors.pageLoading);
export const selectStatusShowEditLoading = lookupSelector(selectStatusState, StatusSelectors.showEditLoading);
