import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createDraft as createDraftCall,
  deleteDraft as deleteDraftCall,
  publishDraft as publishDraftCall,
} from '~services/api/calls/page-draft';
import log from '~services/log';
import { DraftEntitiesActions, EntitiesActions, MenuActions, StatusActions } from '~store/actions';
import { makeGetAllRelatedEntityIds } from '~store/selectors';

export const createDraft = createAsyncThunk('page/create-draft', async (nodeId: string, { dispatch }) => {
  try {
    // show loading spinner
    dispatch(StatusActions.showEditLoading());

    // create draft in backend
    const response = await createDraftCall(nodeId);

    // create draft entities
    const { data, field_modules, included } = response;
    const entities = [...field_modules, data, ...included];
    const entitiesInfos = entities.map(entity => ({
      id: entity.id,
      type: entity.type,
      status: { isFetching: false, isComplete: true },
      data: entity,
    }));
    dispatch(DraftEntitiesActions.setDraftEntities(entitiesInfos));

    // update menu tree
    dispatch(
      MenuActions.updateMenuElementState({
        path: `node/${nodeId}`,
        isPublished: true,
        hasDraft: true,
      }),
    );

    // hide loading spinnder
    dispatch(StatusActions.hideEditLoading());

    return true;
  } catch (e) {
    log('Error in createPageDraftThunk!', e, 'error');
    return false;
  }
});

export const deleteDraft = createAsyncThunk(
  'page/delete-draft',
  async ({ nodeId, uuid }: { nodeId: string; uuid: string }, { dispatch, getState }) => {
    try {
      // show loading spinner
      dispatch(StatusActions.showEditLoading());

      // delete draft from backend
      await deleteDraftCall(nodeId);

      // unset entities from store
      const relatedEntitiyIds = makeGetAllRelatedEntityIds(uuid)(getState());
      dispatch(DraftEntitiesActions.unsetDraftEntity([uuid, ...relatedEntitiyIds]));

      // update menu tree
      dispatch(
        MenuActions.updateMenuElementState({
          path: `node/${nodeId}`,
          isPublished: true,
          hasDraft: false,
        }),
      );

      // hide loading spinner
      dispatch(StatusActions.hideEditLoading());

      return true;
    } catch (e) {
      log('Error in deletePageDraftThunk!', e, 'error');
      return false;
    }
  },
);

export const publishDraft = createAsyncThunk(
  'page/publish-draft',
  async ({ nodeId, uuid }: { nodeId: string; uuid: string }, { dispatch, getState }) => {
    try {
      // show loading spinner
      dispatch(StatusActions.showEditLoading());

      // publish draft in backend
      const response = await publishDraftCall(nodeId);

      // create entities in store
      const { data, field_modules, included } = response;
      const entities = [...field_modules, data, ...included];
      const entitiesInfos = entities.map(entity => ({
        id: entity.id,
        type: entity.type,
        status: { isFetching: false, isComplete: true },
        data: entity,
      }));
      dispatch(EntitiesActions.setEntities(entitiesInfos));

      // unset draft entities from store
      const relatedEntitiyIds = makeGetAllRelatedEntityIds(uuid)(getState());
      dispatch(DraftEntitiesActions.unsetDraftEntity([uuid, ...relatedEntitiyIds]));

      // update menu tree
      dispatch(
        MenuActions.updateMenuElementState({
          path: `node/${nodeId}`,
          isPublished: true,
          hasDraft: false,
        }),
      );

      // hide loading spinner
      dispatch(StatusActions.hideEditLoading());

      return true;
    } catch (e) {
      log('Error in publishPageDraftThunk!', e, 'error');
      return false;
    }
  },
);
