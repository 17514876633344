import { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';

import useAppSelector from '~hooks/useAppSelector';
import { selectConfigCustomJs } from '~store/selectors';

export default function CustomJsExecutor() {
  const customJs = useAppSelector(selectConfigCustomJs);
  const ref = useRef<HTMLDivElement>();
  const [isInjected, setIsInjected] = useState(false);

  useEffect(() => {
    if (!isInjected && ref.current && customJs) {
      const scriptTag = document.createElement('script');
      scriptTag.innerHTML = customJs;
      ref.current.appendChild(scriptTag);
      setIsInjected(true);
    }
  }, [isInjected, customJs]);

  if (customJs) {
    return (
      <Portal>
        <div className="ebm-custom-js" ref={ref} />
      </Portal>
    );
  }

  return null;
}
