import { ReactNode, useMemo } from 'react';
import { createContext } from 'react';

import useAppSelector from '~hooks/useAppSelector';
import {
  selectAddonsIsPublishingEnabled,
  selectContext,
  selectCurrentDraftPermissions,
  selectCurrentPageEntity,
  selectCurrentPageId,
  selectCurrentPagePermissions,
  selectDraftEntityById,
  selectSessionIsEditing,
  selectUserPermissions,
  selectUserStateIset,
} from '~store/selectors';

type HasPermissions = {
  hasEditCurrentPagePermission: boolean;
  hasManageChangelogPermission: boolean;
  hasManageMenuElementsPermission: boolean;
  hasManageMenuGrantsPermission: boolean;
  hasManageUsergroupsPermission: boolean;
  hasPublishCurrentPagePermission: boolean;
  hasPublishPermission: boolean;
  hasRootAccessPermission: boolean;
  hasSendChangelogNewsletterPermission: boolean;
  hasToggleEditmodePermission: boolean;
  hasViewChangelogPermission: boolean;
  hasViewMyFilesPermission: boolean;
  hasViewTruckLabelingOrdersPermission: boolean;
  hasManageTruckLabelingOrdersPermission: boolean;
  hasApproveNewTruckLabelingOrders: boolean;
  hasPassMaintenanceModePermission: boolean;
};
type CanPermissions = {
  canCreateDraft: boolean;
  canDeleteDraft: boolean;
  canEditPageMeta: boolean;
  canManageModules: boolean;
  canPublishDraft: boolean;
  canViewTruckLabelingOrder: boolean;
};

export const PermissionsContext = createContext<(HasPermissions & CanPermissions) | undefined>(undefined);

type PermissionsProviderProps = {
  children: ReactNode;
};

export function PermissionsProvider({ children }: PermissionsProviderProps) {
  // select app status
  const userIsSet = useAppSelector(selectUserStateIset);
  const userPermissions = useAppSelector(selectUserPermissions);
  const pagePermissions = useAppSelector(selectCurrentPagePermissions);
  const draftPermissions = useAppSelector(selectCurrentDraftPermissions);
  const context = useAppSelector(selectContext);
  const currentPageId = useAppSelector(selectCurrentPageId);
  const isEditmodeActive = useAppSelector(selectSessionIsEditing);
  const isPublishingEnabled = useAppSelector(selectAddonsIsPublishingEnabled);
  const pageEntity = useAppSelector(selectCurrentPageEntity);
  const pageDraftEntity = useAppSelector(state => selectDraftEntityById(state, currentPageId));

  // derived values
  const isEditingPageModules = currentPageId === context;
  const isEditingContentModule = !isEditingPageModules && context !== null;
  const hasPagePublished = pageEntity != null;
  const hasPageDraft = pageDraftEntity != null;

  // momoize context
  const memoContext = useMemo<HasPermissions & CanPermissions>(() => {
    const hasPermissions = {
      hasEditCurrentPagePermission:
        userPermissions.rootAccess || pagePermissions.edit || draftPermissions.edit || false,
      hasPublishCurrentPagePermission:
        userPermissions.publish || pagePermissions.publish || draftPermissions.publish || false,
      hasManageMenuElementsPermission: userPermissions.manageMenuElements || false,
      hasManageMenuGrantsPermission: userPermissions.manageMenuGrants || false,
      hasManageUsergroupsPermission: userPermissions.manageUsergroups || false,
      hasPublishPermission: userPermissions.publish || false,
      hasRootAccessPermission: userPermissions.rootAccess || false,
      hasToggleEditmodePermission: userPermissions.toggleEditmode || false,
      hasViewChangelogPermission: userPermissions.viewChangelog || false,
      hasViewMyFilesPermission: userPermissions.viewMyFiles || false,
      hasManageChangelogPermission: userPermissions.manageChangelog || false,
      hasSendChangelogNewsletterPermission: userPermissions.sendChangelogNewsletter || false,
      hasViewTruckLabelingOrdersPermission: userPermissions.viewTruckLabelingOrders || false,
      hasManageTruckLabelingOrdersPermission: userPermissions.manageTruckLabelingOrders || false,
      hasApproveNewTruckLabelingOrders: userPermissions.approveNewTruckLabelingOrders || false,
      hasPassMaintenanceModePermission: userPermissions.passMaintenanceMode || false,
    };

    return {
      ...hasPermissions,
      canViewTruckLabelingOrder: hasPermissions.hasViewTruckLabelingOrdersPermission,
      canEditPageMeta:
        isEditmodeActive &&
        hasPermissions.hasEditCurrentPagePermission &&
        (!isPublishingEnabled || hasPageDraft) &&
        !isEditingPageModules,
      canCreateDraft:
        isEditmodeActive &&
        !isEditingPageModules &&
        !isEditingContentModule &&
        !hasPageDraft &&
        hasPagePublished &&
        hasPermissions.hasEditCurrentPagePermission,
      canPublishDraft:
        isEditmodeActive &&
        !isEditingPageModules &&
        !isEditingContentModule &&
        (!isPublishingEnabled || hasPageDraft) &&
        hasPermissions.hasPublishCurrentPagePermission,
      canDeleteDraft:
        isEditmodeActive &&
        !isEditingPageModules &&
        !isEditingContentModule &&
        (!isPublishingEnabled || hasPageDraft) &&
        hasPagePublished &&
        hasPermissions.hasEditCurrentPagePermission,
      canManageModules:
        isEditmodeActive &&
        hasPermissions.hasEditCurrentPagePermission &&
        (!isPublishingEnabled || hasPageDraft) &&
        !isEditingPageModules &&
        !isEditingContentModule,
    };
  }, [
    userPermissions.viewMyFiles,
    userPermissions.rootAccess,
    userPermissions.publish,
    userPermissions.manageMenuElements,
    userPermissions.manageMenuGrants,
    userPermissions.manageUsergroups,
    userPermissions.toggleEditmode,
    userPermissions.viewChangelog,
    userPermissions.manageChangelog,
    userPermissions.sendChangelogNewsletter,
    userPermissions.viewTruckLabelingOrders,
    userPermissions.manageTruckLabelingOrders,
    userPermissions.approveNewTruckLabelingOrders,
    userPermissions.passMaintenanceMode,
    pagePermissions.edit,
    pagePermissions.publish,
    draftPermissions.edit,
    draftPermissions.publish,
    isEditmodeActive,
    isPublishingEnabled,
    hasPageDraft,
    isEditingPageModules,
    isEditingContentModule,
    hasPagePublished,
  ]);

  return <PermissionsContext.Provider value={memoContext}>{userIsSet && children}</PermissionsContext.Provider>;
}
