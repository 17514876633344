import { useContext } from 'react';

import FavouriteContext from '../context/FavouriteContext';

export default function useFavouriteService() {
  const context = useContext(FavouriteContext);
  if (!context) {
    throw new Error(`use useFavouriteService must be used within a FavouritesProvider`);
  }
  return context;
}
