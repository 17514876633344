import { PureComponent } from 'react';

import UploadFilesContainer from '~components/generic/elements/Upload/UploadFilesContainer';
import UploadInput, { UploadRejection } from '~components/generic/elements/Upload/UploadInput';
import { IEntityReference, IEntityReferenceSingle } from '~interfaces/api';
import operateOnItemOrArray from '~utils/operateOnItemOrArray';

export interface IUploadProps {
  label?: string;
  value: IEntityReferenceSingle | IEntityReferenceSingle[];
  onChange: (value: IEntityReference | IEntityReferenceSingle[]) => void;
  fieldName: string;
  onUpload: (payload: UploadFilesPayload) => void;
  theme?: 'dark' | 'light';
  hideUpload?: boolean;
  options?: {
    mimeTypes?: string[][];
  };
  multiple: boolean;
}

interface IUploadState {
  progress?: number;
}

export default class Upload extends PureComponent<IUploadProps, IUploadState> {
  state: IUploadState = {
    progress: null,
  };

  handleUploadProgress = (progress: number) => {
    this.setState({
      progress,
    });
  };

  handleRemove = entityId => {
    const { value, onChange } = this.props;
    if (Array.isArray(value)) {
      const newValue = [...value].filter(ref => ref.id !== entityId);
      onChange(newValue);
      return;
    }
    onChange(null);
  };

  handleUpload = (files: File[]) => {
    const { onUpload, value, onChange } = this.props;
    this.setState({
      progress: 0,
    });
    onUpload({
      files,
      progressCb: this.handleUploadProgress,
      completeCb: (ids: string[]) => {
        this.setState({
          progress: null,
        });
        const files = operateOnItemOrArray(value, file => file, true) as IEntityReferenceSingle[];
        const newValue = (files ? [...files] : []).concat(ids.map(id => ({ id, type: 'file--file' })));
        onChange(newValue);
      },
    });
  };

  handleUploadRejected = (fileRejections: UploadRejection[]) => {
    const errorMsg = 'The following files and/or file extensions are not allowed: \n';

    // get all extensions from rejected files (without duplicates)
    const extensions = fileRejections
      .map(fileRejection => fileRejection.file.name.split('.').pop())
      .filter((value, index, self) => self.indexOf(value) === index);

    alert(errorMsg + extensions.join(', '));
  };

  render() {
    const { progress } = this.state;
    const { label, value, hideUpload, theme, options, multiple } = this.props;
    const fileIds = operateOnItemOrArray(value, ref => ref.id, true) as string[];

    return (
      <div>
        {label && <label className="c-label u-size-sm">{label}</label>}
        {fileIds &&
          fileIds.map(id => (
            <UploadFilesContainer key={`file_${id}`} entityId={id} onRemove={this.handleRemove} theme={theme} />
          ))}
        {!hideUpload && (
          <UploadInput
            theme={theme}
            progress={progress}
            onUpload={this.handleUpload}
            multiple={multiple}
            options={options}
            onUploadRejected={this.handleUploadRejected}
          />
        )}
      </div>
    );
  }
}
