import { array, Describe, is, string, type } from 'superstruct';

export const FooterWidgetValidator: Describe<FooterWidget> = type({
  data: string(),
  type: string(),
});
export const isFooterWidget = (obj: unknown) => is(obj, FooterWidgetValidator);

export const WidgetsFooterValidator: Describe<WidgetsFooterDto> = type({
  content: array(FooterWidgetValidator),
  footer: FooterWidgetValidator,
});
export const isWidgetsFooter = (obj: unknown) => is(obj, WidgetsFooterValidator);
