export default {
  btnLabelLogout: 'Abmelden',
  btnLabelLogin: 'Anmelden',
  btnLabelBack: 'Zurück',
  hlSearchResult: 'Suche',
  searchResultError: 'Die Suchergebnisse konnten nicht geladen werden',
  SearchResults: 'Suchergebnisse',
  labelSearchResults: 'Suchergebnisse',
  labelMediaLibraryResults: 'Media Library Ergebnisse',
  labelSearchResultsFound: '{numFound} Seiten gefunden',
  paginationLinkLabelPrevious: 'Vorhergehende Seite',
  paginationLinkLabelNext: 'Nächste Seite',
  paginationLinkTitlePrevious: 'Zurück zur vorhergehenden Seite',
  paginationLinkTitleNext: 'Vor zur nächsten Seite',
  linkTitleDownload: 'Herunterladen',
  linkTitleAddMyfiles: 'Zu MyFiles hinzufügen',
  hlIconLibrary: 'Icon Library',
  msgNoPreviewAvailable: 'Keine Vorschau verfügbar',

  hlPageForbidden: 'Nicht autorisiert',
  hlPageNotFound: 'Nicht gefunden',
  hlPageTimeout: 'Timeout',
  hlPageTruckLabeling: 'Order Requests',
  labelTruckBrandingSortBy: 'Sortierung',
  labelTruckBrandingSortOrder: 'Reihenfolge',
  msgPageForbidden: 'Zugriff auf diese Seite ist eingeschränkt.',
  msgPageNotFound: 'Diese Seite existiert nicht. Bitte überprüfen Sie die URL auf eventuelle Tippfehler.',
  msgPageTimeout:
    'Die Netzerkverbindung zum Server ist unterbrochen. Bitte prüfen Sie ihre Internetverbindung und versuchen Sie es in ein paar Minuten erneut.',
  msgPageNotFoundRedirect: 'Sie werden in {timeLeft} Sekunden weitergeleitet.',

  btnEditUserProfile: 'Benutzerprofil',
  btnEditNavigation: 'Navigation bearbeiten',
  btnEditUsergroups: 'Gruppenverwaltung',
  btnChangelog: 'Newsfeed',

  hlEditNavigation: 'Navigation bearbeiten',
  hlEditNavigationLock: 'Navigation gesperrt',
  msgEditNavigationLockedByUser:
    'Die Navigation wurde für Sie gesperrt. Das heißt, dass kein anderer Benutzer die Navigation bearbeiten kann, so lange Sie auf dieser Seite sind.',
  msgEditNavigationLockedByDifferentUser:
    'Die navigation wurde durch einen anderen Benutzer gesperrt. Das heißt, dass Sie keine Änderungen vornehmen können. Sobald die Sperre durch den anderen Benutzer aufgehoben wird, aktualisiert sich diese Seite automatisch.',
  msgEditNavigationWaitingForLock: '...warten',
  btnResetNavigation: 'Änderungen verwerfen',
  btnSaveNavigation: 'Speichern',
  msgNavigationSaveSuccessful: 'Änderungen gespeichert',
  msgNavigationSaveFailed: 'Fehler beim Speichern',
  labelMenuElementIsChanged: 'Neu / verändert',
  labelMenuElementHasDraft: 'Entwurf',
  labelMenuElementIsUnpublished: 'Unveröffentlicht',

  btnEditPage: 'Bearbeiten',
  btnPublishPageChanges: 'Veröffentlichen',
  btnDiscardPageChanges: 'Änderungen verwerfen',
  msgDiscardPageChanges: 'Sind Sie sicher, dass Sie die unveröffentlichten Änderungen verwerfen möchten?',
  btnDiscardPageChangesConfirm: 'Ja, verwerfen',
  btnDiscardPageChangesDeny: 'Nein, behalten',
  btnManageModules: 'Module bearbeiten',
  msgManageModulesHint: 'Module bearbeiten: Füge Content-Module hinzu oder ändere die Reihenfolge der Content-Module',
  msgCancelModulesHint: 'Abbrechen: Änderungen an Content-Modulen verwerfen',
  msgSaveModulesHint: 'Speichern',

  hlPublishDialog: 'Änderungen veröffentlichen',
  msgPublishDialog:
    'Bitte eine der folgenden Optionen auswählen: "Neuer Inhalt" und "Aktualisierter Inhalt" erzeugen einen Eintrag im Changelog, zusammen mit der individuellen Beschreibung.',
  btnPublishDialogCancel: 'Abbrechen',
  btnPublishDialogConfirm: 'Speichern und veröffentlichen',
  placeholderPublishDialogCustomMessage: 'Beschreibung der Änderung (benötigt):',

  hlPublishOptionNew: 'Neuer Inhalt',
  msgPublishOptionNew: 'Auswählen um Benutzer über neuen Inhalt zu informieren.',
  hlPublishOptionUpdate: 'Aktualisierter Inhalt',
  msgPublishOptionUpdate: 'Auswählen um Benutzer über aktualisierten Inhalt zu informieren.',
  hlPublishOptionNone: 'Marginale Änderungen',
  msgPublishOptionNone: 'Auswählen um Benutzer NICHT über Änderungen zu informieren.',

  btnEditModule: 'Bearbeiten',
  msgEditModuleHint: 'Modul bearbeiten',
  btnCancelModule: 'Abbrechen',
  msgCancelModuleHint: 'Abbrechen: Änderungen verwerfen',
  btnDeleteModule: 'Löschen',
  msgDeleteModuleHint: 'Löschen: Modul entfernen',
  btnSaveModule: 'Speichern',
  msgSaveModuleHint: 'Speichern: Änderungen speichern',
  btnMoveUpModule: 'Hoch',
  msgMoveUpModuleHint: 'Hoch: Modul um eine Position nach oben/links bewegen',
  btnMoveDownModule: 'Runter',
  msgMoveDownModuleHint: 'Runter: Modul um eine Position nach unten/rechts bewegen',

  labelImageModule: 'Bild',
  btnDownloadImage: 'Download',
  msgDownloadImageHint: 'Download: Bild herunterladen',
  btnClearImage: 'Löschen',
  msgClearImageHint: 'Löschen: Bild entfernen',
  hlFavourites: 'Favoriten',
  hlFavouritesContent: 'Content Favoriten',
  msgFavouritesContent:
    'Hier können Sie Ihre bevorzugten Seiten im Brand Guide sehen. Scrollen Sie nach unten für Ihre Beobachtungsliste in der Media Library.',
  hlFavouritesAsset: 'Asset Favoriten',
  msgFavouritesAsset:
    'Hier können Sie die Assets anzeigen, die Sie zu Ihren Favoriten in der Media Library hinzugefügt haben. Klicken Sie auf das Herzsymbol, um Assets von Ihrer Beobachtungsliste zu entfernen.',
  msgFavouritesAssetEmpty: 'Sie haben keine Favoriten gesetzt.',
  hlFavouritesTitle: 'Title',
  hlFavouritesLastUpdated: 'Zuletzt geändert',
  msgFavouritesEmpty: 'Sie haben keine Favoriten gesetzt.',

  hlManagePermissions: 'Berechtigungen verwalten',
  btnResetPermissions: 'Änderungen verwerfen',
  btnSavePermissions: 'Speichern',
  msgPermissionsSaveSuccessful: 'Änderungen gespeichert',
  msgPermissionsSaveFailed: 'Fehler beim Speichern',
  tableHeaderPermissionsRole: 'Gruppe / Benutzer',
  tableHeaderPermissionsView: 'Ansicht',
  tableHeaderPermissionsUpdate: 'Bearbeiten',
  tableHeaderPermissionsCreate: 'Hinzufügen',
  tableHeaderPermissionsDelete: 'Löschen',
  tableHeaderPermissionsViewDraft: 'Entwurf',
  tableHeaderPermissionsPublish: 'Publizieren',
  tableHeaderPermissionsSetGrants: 'Rechte',
  labelAddUser: 'Benutzer hinzufügen',
  placeholderSearchUser: 'Suche nach Benutzername / E-Mail',
  labelSearchUserUsername: 'Username',
  labelSearchUserMail: 'E-Mail',

  optionScopeInherit: 'Vererbte Berechtigungen',
  optionScopeSubtree: 'Subtree Berechtigungen',
  optionScopeNode: 'Node Berechtigungen',

  btnEditMenuElementOpen: 'Seite öffnen',
  btnEditMenuElementRename: 'Umbenennen',
  btnEditMenuElementAddPage: 'Seite hinzufügen',
  btnEditMenuElementAddGroup: 'Seitengruppe hinzufügen',
  btnEditMenuElementManagePermissions: 'Berechtigungen verwalten',
  btnEditMenuElementDelete: 'Löschen',

  // Media library Asset Permissions
  btnAssetPermissions: 'Berechtigungen',
  btnAssetPermissionsCancel: 'Abbrechen',
  btnAssetPermissionsSave: 'Speichern',

  hlEditUsergroups: 'Benutzer- / Gruppenverwaltung',
  labelAddUsergroup: 'Gruppe hinzufügen',
  placeholderAddUsergroup: 'Gruppename eingeben um neue Gruppe zu erstellen ...',
  placeholderAddUser: 'Nach Benutzername / E-Mail suchen um Benutzer hinzuzufügen ...',
  btnEditUsergroupAddUsers: 'Benutzer hinzufügen (erweitert)',
  btnEditUsergroupRename: 'Umbenennen',
  btnEditUsergroupDelete: 'Löschen',
  msgNoUsergroups: 'Keine Gruppen vorhanden.',
  msgNoUsersInUsergroup: 'Keine Benutzer in dieser Grupper.',
  labelUsergroupName: 'Gruppername',

  btnSelectPlaceholder: 'Auswählen...',

  hlAddMultipleUsers: 'Benutzer hinzufügen',
  placeholderAddMultipleUsers: 'Nach Benutzern suchen ...',
  btnSaveAddUsers: 'Hinzufügen',
  msgAddMultipleUsersSaveSuccessful: 'Benutzer hinzugefügt',
  msgAddMultipleUsersSaveFailed: 'Fehler beim Hinzufügen',
  msgAddMultipleUsersNothingFound: 'Keine passenden Benutzer gefunden.',
  tableHeaderUserSelected: ' ',
  tableHeaderUserUid: 'ID',
  tableHeaderUserUsername: 'Benutzername',
  tableHeaderUserFullname: 'Name',
  tableHeaderUserMail: 'E-Mail',
  tableHeaderUserCompany: 'Firma',
  tableHeaderUserDepartment: 'Abteilung',

  labelModuleTitleSketchLibrary: 'Sketch Bibliothek',
  labelModuleAttributeSketchLibraryName: 'Name der Bibliothek',
  placeholderModuleAttributeSketchLibraryName: 'Meine Sketch Bibliothek',
  labelModuleAttributeSketchLibraryVersion: 'Version',
  labelModuleAttributeSketchLibraryDescription: 'Beschreibung',
  placeholderModuleAttributeSketchLibraryDescription:
    'Hier beschreiben Sie beispielsweise den beabsichtigten Verwendungszweck...',

  btnModuleSketchLibraryInstall: 'Installieren',
  btnModuleSketchLibraryInstallDescription:
    'Hier klicken um die Bibliothek herunterzuladen und in Sketch zu installieren',
  msgModuleSketchLibraryImageProcessing:
    'Das Vorschaubild ist noch in Bearbeitung und wird erst beim erneuten Laden dieser Seite angezeigt.',

  tabFavourites: 'Favoriten',
  tabNotifications: 'Benachrichtigungen',

  btnSiteAccessSwitchSelectBrand: 'Marke auswählen',

  labelModuleAttributeIframeSrc: 'IFrame URL',
  placeholderModuleAttributeIframeSrc: 'https://www.beispiel-domain...',
  labelModuleIframeAutomaticResizing: 'Automatische IFrame Höhenanpassung',
  statusModuleIframeAutomaticResizingDetecting: 'Erkennung...',
  statusModuleIframeAutomaticResizingGood: 'Aktiviert',
  statusModuleIframeAutomaticResizingBad: 'Nicht Aktiviert',
  msgModuleIframeAutomaticResizingDescription:
    'Um die automatische IFrame Höhenanpassung zu aktivieren, muss das iframe-resizer Skript in den HTML Code von {src} eingesetzt werden.',

  labelModuleAttributeIconLibraryDefaultSvgBackgroundColor: 'Standard SVG Hintergrundfarbe',
  placeholderModuleAttributeIconLibraryDefaultSvgBackgroundColor: '#ffff00',
  msgModuleAttributeIconLibraryDefaultSvgBackgroundColorHelp:
    'Valide CSS Farbe (muss eine der SVG Hintergrundfarben sein).',
  labelModuleAttributeIconLibraryDefaultSvgFillColor: 'Standard SVG Farbe',
  placeholderModuleAttributeIconLibraryDefaultSvgFillColor: '#ffff00',
  msgModuleAttributeIconLibraryDefaultSvgFillColorHelp: 'Valide CSS Farbe (muss eine der SVG Farben sein).',
  labelModuleAttributeIconLibraryListItemStyles: 'Listenelement CSS Styles',
  placeholderModuleAttributeIconLibraryListItemStyles: ' ',
  msgModuleAttributeIconLibraryListItemStylesHelp:
    'Spezifische CSS Styles. z.B. um SVGs einzufärben (CSS filter https://codepen.io/sosuke/pen/Pjoqqp).',
  labelModuleAttributeIconLibraryPngOutputSizes: 'PNG Ausgabegrößen',
  placeholderModuleAttributeIconLibraryPngOutputSizes: '16,32,64',
  msgModuleAttributeIconLibraryPngOutputSizesHelp: 'Kommagetrennte Liste an Pixelgrößen.',
  labelModuleAttributeIconLibraryRealm: 'Realm',
  placeholderModuleAttributeIconLibraryRealm: 'icon_library',
  msgModuleAttributeIconLibraryRealmHelp: 'Standard `icon_library`. Nicht ändern, es sei denn man weiß warum.',
  labelModuleAttributeIconLibrarySearchCategories: 'Vordefinierte Such-Kategorien',
  placeholderModuleAttributeIconLibrarySearchCategories: 'Test,Marketing,Design',
  msgModuleAttributeIconLibrarySearchCategoriesHelp: 'Kommagetrente Liste an Kategorien.',
  labelModuleAttributeIconLibrarySvgBackgroundColors: 'SVG Hintergrundfarben',
  placeholderModuleAttributeIconLibrarySvgBackgroundColors: 'red|#ffff00|rgb(200,30,167)',
  msgModuleAttributeIconLibrarySvgBackgroundColorsHelp: 'Valide CSS Farben, getrennt durch "|".',
  labelModuleAttributeIconLibrarySvgFillColors: 'SVG Farben',
  placeholderModuleAttributeIconLibrarySvgFillColors: 'red|#ffff00|rgb(200,30,167)',
  msgModuleAttributeIconLibrarySvgFillColorsHelp: 'Valide CSS Farben, getrennt durch "|".',

  msgAnchorModuleHeadlineHidden: 'Diese Überschrift ist nur während der Bearbeitung der Seite sichtbar.',

  msgDeleteChecklistItemHint: 'Löschen: Eintrag entfernen',
  msgMoveUpChecklistItemHint: 'Hoch: Eintrag um eine Position nach oben/links bewegen',
  msgMoveDownChecklistItemHint: 'Runter: Eintrag um eine Position nach unten/rechts bewegen',
  labelModuleChecklistNewItem: 'Neuer Eintrag',

  hlModalConfirm: 'Bitte bestätigen',
  labelModalConfirmYes: 'Ja',
  labelModalConfirmNo: 'Nein',
  msgConfirmRemoveFavourite: 'Sind Sie sicher, dass Sie den Link aus Ihren Favoriten entfernen möchten?',
  btnModalRemoveFavouriteYes: 'Entfernen',
  btnModalRemoveFavouriteNo: 'Abbrechen',
  btnFavouriteSetHint: 'Zur Favouritenliste hinzufügen',
  btnFavouriteUnsetHint: 'Von Favouritenliste entfernen',

  msgModuleFileEmpty: 'Keine Datei ausgewählt.',

  hlMyFiles: 'MyFiles',
  hlMyFilesSub: 'Verwalte und teile Assets',
  msgMyFilesDescription:
    'Die Funktion MyFiles ist wie ein kollaborativer Arbeitsbereich, in dem Sie Dateien aus der Medienbibliothek oder Ihrem Desktop sammeln können. Sie können Ihre bevorzugten Assets verwalten, indem Sie sie in hierarchischen Ordnern gruppieren. Ähnlich wie bei einer Dropbox können Sie dann mit MyFiles einen Link für ausgewählte Elemente oder ganze Ordner an Kollegen oder Partner senden. Einzelne Dateien können eine maximale Größe von 2 GB haben. Das gesamte Speichervolumen für Ihren MyFiles-Ordner ist auf 5 GB begrenzt.',

  hlChangelog: 'Newsfeed',
  btnChangelogItemLink: 'Zur Seite',
  btnChangelogItemEdit: 'Kommentar bearbeiten',
  btnChangelogItemDelete: 'Eintrag löschen',
  btnChangelogItemSave: 'Kommentar speichern',
  btnChangelogItemCancel: 'Abbrechen',
  msgChangelogEmpty: 'Keine Einträge vorhanden.',
  msgChangelogLoading: 'Einträge werden geladen...',
  msgChangelogItemAlreadySent: '(bereits verschickt)',
  msgChangelogItemDelete: 'Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?',
  btnChangelogItemDeleteConfirm: 'Ja, löschen',
  btnChangelogItemDeleteCancel: 'Nein',
  btnChangelogLoadMore: 'Mehr laden',

  btnDelete: 'Eintrag löschen',
  btnDeleteConfirm: 'Ja, löschen',
  btnDeleteCancel: 'Nein',
  msgItemDelete: 'Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?',

  hlNewsletterDialog: 'Newsletter-Versand',
  msgNewsletterDialog: 'Bitte formulieren Sie hier den Intro-Text für Ihren Newsletter.',
  btnNewsletterDialogSend: 'Jetzt abschicken',
  btnNewsletterDialogCancel: 'Abbrechen',
  btnNewsletterNew: 'Neuer Newsletter',
  msgNewsletterChooseItems: 'Wählen Sie die Einträge aus, die im Newsletter aufgeführt werden sollen.',
  btnNewsletterChooseItemsCancel: 'Abbrechen',
  btnNewsletterChooseItemsConfirm: 'Newsletter erstellen',
  hlImageCropDialog: 'Bildausschnitt',
  btnImageCropDialogConfirm: 'Ausschnitt speichern',
  btnImageCropDialogCancel: 'Abbrechen',
  btnImageCropDialogReset: 'Einstellungen zurücksetzen',
  labelImageCropDialogOriginalSize: 'Original Größe',
  labelImageCropDialogSelectPreset: 'Format wählen',
  labelImageCropDialogCustomFormat: 'Benutzerdefiniertes Format',
  labelAspectRatioFree: 'Frei',
  labelAspectRatio1to1: '1:1 Format',
  labelAspectRatio2to1: '2:1 Format',
  labelAspectRatio4to3: '4:3 Format',
  labelAspectRatio16to9: '16:9 Format',

  btnMetaInformation: 'Meta-Information',
  msgCancelMetaHint: 'Änderungen verwerfen',
  msgSaveMetaHint: 'Geänderte Meta-Informationen speichern',
  labelMetaTitle: 'Seitentitel',
  labelMetaDescription: 'Beschreibung',
  placeholderMetaTitle: 'Gib deiner Seite einen passenden Titel',
  placeholderMetaDescription: 'Ergänze die Meta-Informationen um eine kurze Beschreibung der Inhalte',
  hlMetaPanel: 'Meta-Informationen',
  msgMetaPanel:
    'Bereite die Seite für die Dastellung in Teasern vor. Füge ein Vorschaubild für kleine und große Teaser hinzu, gib einen Seitentitel und eine kurze Beschreibung des Inhalts an.',
  labelMetaImageAspectRatioSquare: '1:1 Format',
  labelMetaImageAspectRatioRect: '2:1 Format',
  msgMetaTopics:
    'Ordne über die Tags die Seiten den entsprechenden Themen zu: ein Hauptthema und ein optionales Unterthema.',
  hlMainTopic: 'Hauptthema',
  hlSubTopic: 'Unterthema',

  btnNewsfeedCustomize: 'Personalisieren',
  hlNewsfeedCustomizeDialog: 'Personalisiere deine Startseite',
  msgNewsfeedCustomizeDialog: 'Wähle mindestens drei Themen, die dich interessieren.',
  msgNewsfeedCustomizeRefine: 'Verfeinere deine Auswahl für {topicName}',
  btnNewsfeedCustomizeDialogCancel: 'Mache ich später',
  btnNewsfeedCustomizeDialogReset: 'Zurücksetzen',
  btnNewsfeedCustomizeDialogConfirm: 'Personalisierung abschließen',
  btnNewsfeedCustomizeDialogClose: 'Fenster schließen',
  hlNewsfeedCustomizeConfirm: 'Die Personalisierung deiner Startseite wurde abgeschlossen.',
  msgNewsfeedCustomizeConfirm: 'Viel Spaß!',
  msgNewsfeedCustomizeConsent:
    'Mit der Abfrage deiner Interessen gibst du uns die Möglichkeit, für dich interessante Inhalte übersichtlich und direkt in deinem Newsfeed anzuzeigen. Du kannst deine angegebenen Interessen jederzeit ändern oder löschen.',
  hlNewsfeedFeed: 'Neues auf der Basis deiner Auswahl',
  labelModuleNewsfeedTitle: 'Newsfeed Titel',
  placeholderModuleNewsfeedTitle: 'z.B. Neuigkeiten zu deiner Themenauswahl',
  btnNewsfeedLink: 'Zur Seite',

  hlFrontpageHeroSalutation: 'Hallo {name}',
  msgFrontpageHeroGreeting: 'Schön, dass du da bist!',

  msgFilterSelectCount: '{count} von {max} Filtern ausgewählt',
  btnMediaLibraryActionDetails: 'Details',
  btnMediaLibraryActionDownload: 'Download',
  btnMediaLibraryActionAddToMyfiles: 'Zu MyFiles hinzufügen',
  btnMediaLibraryActionUseWeb2Print: 'Zu Web2Print wechseln',
  btnMediaLibraryActionCopyId: 'ID kopieren',
  btnMediaLibraryActionCopyLink: 'Link kopieren',
  btnMediaLibraryActionCropImage: 'Bild zuschneiden',
  btnMediaLibraryActionZoom: 'Vergrößern',
  btnMediaLibraryActionOpenCollection: 'Kollektion öffnen',
  btnMediaLibraryActionPlay: 'Video abspielen',
  btnMediaLibraryActionMore: 'Mehr Aktionen ...',
  msgMediaLibraryResultCount: '{count} Assets',
  btnMediaLibraryFavouritesOnly: 'Meine Favoriten',
  labelMediaLibrarySortBy: 'Sortierung',
  labelMediaLibrarySortByDefault: 'Relevanz',
  labelMediaLibrarySortOrder: 'Reihenfolge',
  labelMediaLibraryViewSlider: 'Ansicht',
  hlMediaLibraryDownloadModal: 'Download',
  hlMediaLibraryAddToMyFilesModal: 'Zu MyFiles hinzufügen',
  btnMediaLibraryResetFilter: 'Zurücksetzen',
  btnMediaLibraryFilterSelectOpen: 'Filter hinzufügen',
  btnMediaLibraryFilterSelectClose: 'Speichern',
  btnMediaLibraryResetTags: 'Alle zurücksetzen',
  labelMediaLibraryTags: 'Gefiltert nach:',
  labelMediaLibraryLoadingError: 'Es gab einen Fehler beim Laden der Media-Library.',
  msgMediaLibraryCopyId: 'ID in die Zwischenablage kopiert.',
  msgMediaLibraryCopyLink: 'Link zum Asset in die Zwischenablage kopiert.',
  btnMediaLibraryCloseCollection: 'Zurück zur Übersicht',
  labelMediaLibraryCollectionDescription: 'Beschreibung',
  labelMediaLibraryCollectionCreator: 'Ersteller',
  labelMediaLibraryCollectionId: 'ID',
  labelMediaLibraryCollectionLink: 'Link',
  msgMediaLibraryAssetRestricted:
    'Dieses Asset unterliegt eingeschränkten Nutzungsrechten. Ich habe diese Einschränkungen gelesen und akzeptiere sie.',

  btnMediaLibraryActionUseRaiseRequestAssets: 'Anfrage stellen',
  mediaLibraryRaiseRequestModalHeadline: 'Anfrage erfolgreich übermittelt',
  mediaLibraryRaiseRequestDescription:
    'Ihre Gehaltserhöhung wurde erfolgreich beantragt. Wir werden sie überprüfen und uns in Kürze bei Ihnen melden.',
  btnMediaLibraryRaiseRequestClose: 'Schließen',
  btnMediaLibraryRaiseRequestOK: 'OK',
  btnMediaLibraryRaiseRequestCancel: 'Abbrechen',

  hlMediaLibrary: 'Media Library',
  hlMediaGallery: 'Media Gallery',
  labelModuleAttributeMediaLibraryRealm: 'Realm',
  placeholderModuleAttributeMediaLibraryRealm: 'z.B. "asset_library"',
  msgModuleAttributeMediaLibraryRealmHelp: 'Standard `asset_library`. Nicht ändern, es sei denn man weiß warum.',
  labelModuleAttributeMediaLibraryQuery: 'Query',
  placeholderModuleAttributeMediaLibraryQuery: 'z.B. "sort=title_en&order=asc"',
  msgModuleAttributeMediaLibraryQueryHelp: 'Vordefiniertes Such-Query.',
  msgMediaLibraryMaintenance:
    'Das Brand Portal wird heute aktualisiert.<br>Während dieser Zeit ist die Media Library nicht zugänglich.<br><br>Bitte entschuldigen Sie eventuelle Unannehmlichkeiten.',

  labelContentSearchEmpty: 'Hier gibt es keine Ergebnisse, aber schauen Sie sich die Ergebnisse in der Mediathek an.',
  labelMediaLibrarySearchEmpty:
    'Hier gibt es keine Ergebnisse, aber schauen Sie sich die Ergebnisse im Brand Guide an.',
};
