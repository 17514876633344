import _get from 'lodash.get';

import { SidebarContextSettingsCheckbox } from '~components/sidebar/context/fields/SidebarContextSettingsCheckbox';
import { SidebarContextSettingsLink } from '~components/sidebar/context/fields/SidebarContextSettingsLink';
import { SidebarContextSettingsNumber } from '~components/sidebar/context/fields/SidebarContextSettingsNumber';
import { SidebarContextSettingsRadio } from '~components/sidebar/context/fields/SidebarContextSettingsRadio';
import { SidebarContextSettingsSelect } from '~components/sidebar/context/fields/SidebarContextSettingsSelect';
import { SidebarContextSettingsText } from '~components/sidebar/context/fields/SidebarContextSettingsText';
import { SidebarContextSettingsUpload } from '~components/sidebar/context/fields/SidebarContextSettingsUpload';
import { IEntityFieldDefinition } from '~services/entity-definitions';
import { hasSpecialPrefix } from '~services/entity-definitions/helpers';
import log from '~services/log';

const settingFieldComponents = {
  checkbox: SidebarContextSettingsCheckbox,
  radio: SidebarContextSettingsRadio,
  select: SidebarContextSettingsSelect,
  text: SidebarContextSettingsText,
  upload: SidebarContextSettingsUpload,
  link: SidebarContextSettingsLink,
  number: SidebarContextSettingsNumber,
  mamIds: SidebarContextSettingsText, // ToDo
};

export type SidebarContextSettingsFieldProps = {
  data: any;
  fieldDef: IEntityFieldDefinition;
  fieldName: string;
  onChange: (name: string, val: ChangesetValueType) => void;
  onUpload: (payload: UploadFilesPayload) => void;
  dependency?: string;
};

export type SidebarContextSettingsFieldInputProps = SidebarContextSettingsFieldProps & {
  onChange: (val: ChangesetValueType) => void;
  parentContext: string;
  dependency?: string;
  dependencyValue?: number;
};

const SidebarContextSettingsField = ({
  fieldName,
  fieldDef,
  data,
  onChange,
  onUpload,
  dependency,
}: SidebarContextSettingsFieldProps) => {
  const FieldComponent = settingFieldComponents[fieldDef.type];
  // if the first two chars of the field key are dollar signs the key will not
  // be used to select the data, this must be handled by the field component
  // itself
  const isNonFieldSelector = hasSpecialPrefix(fieldName);
  const fieldData = isNonFieldSelector ? null : _get(data, fieldName);
  if (!FieldComponent) {
    log(`No settings field component found for settings type ${fieldDef.type}!`, null, 'error');
    return null;
  }

  let dependencyValue = null;
  if (dependency) {
    dependencyValue = _get(data, dependency);
  }

  return (
    <div>
      {fieldDef.label && <h6>{fieldDef.label}</h6>}
      <FieldComponent
        parentContext={data.id}
        data={fieldData}
        fieldDef={fieldDef}
        onChange={(val: ChangesetValueType) => onChange(fieldName, val)}
        onUpload={onUpload}
        fieldName={fieldName}
        dependencyValue={dependencyValue}
      />
    </div>
  );
};

export default SidebarContextSettingsField;
