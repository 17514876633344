import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';

import { SvgTextAlignLeft } from '../SvgTextAlign';

export const AlignTextLeftSettings = {
  blockType: 'text-left',
  className: 'u-text-left',
  element: 'p',
};

export const AlignTextLeftButton = createBlockStyleButton({
  blockType: AlignTextLeftSettings.blockType,
  children: <SvgTextAlignLeft />,
});
