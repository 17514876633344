import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  approveOrderRequestBranding,
  approveOrderRequestSupplier,
  createOrder,
  getListOfOrders,
  getListOfOrdersFiltered,
  rejectOrderRequestBranding,
  rejectOrderRequestSupplier,
  setOrderInProgress,
  setOrderPriority,
  setResponsibleForOrder,
} from '~extension/truck-labeling/api/orders';
import log from '~services/log';

// create order
export const createOrderRequest = createAsyncThunk('create/order', async (data: any, { dispatch }) => {
  try {
    const res = await createOrder(data);
    await dispatch(fetchListOfOrders());

    return res;
  } catch (err) {
    return err;
  }
});

const makeQueryString = filter => {
  const queryStringArray = [];

  Object.entries(filter).forEach(([key, value]) => {
    queryStringArray.push(`${key}=${value}`);
  });

  // Join all key-value pairs using the ampersand as the separator
  const queryString = queryStringArray.join('&');
  return queryString;
};

export const fetchListOfOrdersByFilter = createAsyncThunk('orders/filter', async (filter: any = {}) => {
  const queryString = makeQueryString(filter);

  try {
    const res = await getListOfOrdersFiltered(queryString);
    return {
      filter,
      res,
      offset: filter.offset,
    };
  } catch (e) {
    log('Error in fetch list of orders thunk!', e, 'error');
  }
});

export const fetchListOfOrdersOnScroll = createAsyncThunk('orders/fetch/params', async (filter: any = {}) => {
  const queryString = makeQueryString(filter);

  try {
    const res = await getListOfOrdersFiltered(queryString);
    return res;
  } catch (e) {
    log('Error in fetch list of orders thunk!', e, 'error');
  }
});

export const fetchListOfOrders = createAsyncThunk('orders/fetch', async () => {
  try {
    const res = await getListOfOrders();
    return res;
  } catch (e) {
    log('Error in fetch list of orders thunk!', e, 'error');
  }
});

export const approveOrderBranding = createAsyncThunk('orders/approve/branding', async (id: number) => {
  try {
    const res = await approveOrderRequestBranding(id);
    return res;
  } catch (e) {
    log('Error in approve order thunk!', e, 'error');
  }
});

export const updateResponsibleForOrder = createAsyncThunk('orders/set_responsible', async (data: any) => {
  try {
    const res = await setResponsibleForOrder(data.id, data.name);
    return res;
  } catch (e) {
    log('Error in set responsible for order thunk!', e, 'error');
  }
});

export const rejectOrderBranding = createAsyncThunk(
  'orders/reject/branding',
  async (order_id: string, { getState }) => {
    const state: any = getState();
    const comment = state.truckLabelingOrders.comment;

    try {
      const res = await rejectOrderRequestBranding(order_id, comment ? comment : null);
      return res;
    } catch (e) {
      log('Error in reject order thunk!', e, 'error');
    }
  },
);

export const approveOrderSupplier = createAsyncThunk('orders/approve/supplier', async (id: number) => {
  try {
    const res = await approveOrderRequestSupplier(id);
    return res;
  } catch (e) {
    log('Error in approve order thunk!', e, 'error');
  }
});

export const setOrderPrio = createAsyncThunk('orders/priority', async (data: any) => {
  try {
    const res = await setOrderPriority(data.id, data.priority);
    return res;
  } catch (e) {
    log('Error setting priority order thunk!', e, 'error');
  }
});

export const setOrderToInProgress = createAsyncThunk('orders/priority', async (id: number) => {
  try {
    const res = await setOrderInProgress(id);
    return res;
  } catch (e) {
    log('Error setting priority order thunk!', e, 'error');
  }
});

export const rejectOrderSupplier = createAsyncThunk(
  'orders/reject/supplier',
  async (order_id: string, { getState }) => {
    try {
      const state: any = getState();
      const comment = state.truckLabelingOrders.comment;
      const res = await rejectOrderRequestSupplier(order_id, comment ? comment : null);
      return res;
    } catch (e) {
      log('Error in reject order thunk!', e, 'error');
    }
  },
);

const OrdersSlice = createSlice({
  name: 'config',
  initialState: {
    totalCount: 0,
    orderList: [],
    message: null,
    limit: 20,
    offset: 1,
    createOrderFail: '',
    showCreateOrderForm: false,
    showAlert: false,
    createOrderSuccess: '',
    comment: '',
  },
  reducers: {
    setShowAlert: (state, action) => {
      state.showAlert = action.payload;
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    resetErrors: (state, action) => {
      state.createOrderFail = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(createOrderRequest.rejected, (state, action: any) => {
      state.createOrderFail = action.payload;
    });

    builder.addCase(createOrderRequest.fulfilled, (state, action: any) => {
      if (action?.payload?.error) {
        state.createOrderFail = action.payload.error;
        return;
      }

      if (action.payload.startsWith('Created')) {
        state.createOrderSuccess = action?.payload;
        state.showCreateOrderForm = false;
        state.createOrderFail = '';
      }
    });

    builder.addCase(fetchListOfOrdersOnScroll.fulfilled, (state, action: any) => {
      state.totalCount = action?.payload?.total_count;
      state.orderList = [...state.orderList, ...action?.payload?.order_list];
      state.limit = action.payload?.order_list.length;
    });

    builder.addCase(fetchListOfOrdersByFilter.fulfilled, (state, action: any) => {
      state.orderList = action?.payload?.res?.order_list;
      state.totalCount = action?.payload?.res?.total_count;
      state.limit = action.payload?.order_list?.res?.length;
    });

    builder.addCase(fetchListOfOrders.fulfilled, (state, action: any) => {
      state.totalCount = action?.payload?.total_count;
      state.orderList = action?.payload?.order_list;
    });
    builder.addCase(approveOrderBranding.fulfilled, (state, action: any) => {
      state.message = action?.payload;
    });
    builder.addCase(rejectOrderBranding.fulfilled, (state, action: any) => {
      state.message = action?.payload;
    });
    builder.addCase(approveOrderSupplier.fulfilled, (state, action: any) => {
      state.message = action?.payload;
    });
    builder.addCase(rejectOrderSupplier.fulfilled, (state, action: any) => {
      state.message = action?.payload;
    });
  },
});

export const { resetErrors, setComment } = OrdersSlice.actions;

export default OrdersSlice.reducer;
