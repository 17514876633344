import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';

import { Button as EditorButton } from '~components/generic/editor/Button/Button';
import { Hint } from '~components/generic/editor/Hint/Hint';
import { DeleteIcon } from '~components/icons';
import useLabels from '~hooks/useLabels';
import styles from './DeleteDraftPopover.module.css';

type DeleteDraftPopoverProps = {
  isDeleting?: boolean;
  onDelete: () => void;
  disabled?: boolean;
};

export function DeleteDraftPopover({ isDeleting = false, onDelete, disabled = false }: DeleteDraftPopoverProps) {
  const { btnDiscardPageChanges, btnDiscardPageChangesDeny, btnDiscardPageChangesConfirm, msgDiscardPageChanges } =
    useLabels([
      'btnDiscardPageChanges',
      'btnDiscardPageChangesDeny',
      'btnDiscardPageChangesConfirm',
      'msgDiscardPageChanges',
    ]);

  return (
    <Popover className={clsx(styles.popover, 'delete-draft-popover', 'relative inline-flex')}>
      {({ open, close }) => (
        <>
          <Popover.Button
            as={EditorButton}
            label={btnDiscardPageChanges}
            preIcon={<DeleteIcon />}
            disabled={disabled || open}
            processing={isDeleting}
          />
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150 delay-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel
              as={Hint}
              className={clsx(
                styles.panel,
                'delete-draft-popover--panel',
                'absolute z-50 transform right-0',
                isDeleting && 'disabled',
              )}
            >
              <div className="block">
                <div className="leading-normal">{msgDiscardPageChanges}</div>
                <div className="mt-4 flex justify-end">
                  <EditorButton
                    icon={<DeleteIcon />}
                    processing={isDeleting}
                    label={btnDiscardPageChangesConfirm}
                    onClick={onDelete}
                  />
                  <span className="w-4 block" />
                  <EditorButton disabled={isDeleting} label={btnDiscardPageChangesDeny} onClick={() => close()} />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
