import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type AddonsState = Addons;

// Initial State
export const initialState: AddonsState = {
  isPdfEnabled: false,
  isPublishingEnabled: false,
  paragraphModules: [],
  isIconLibraryEnabled: false,
  isFavouriteEnabled: false,
  favouritesDefaultLibraryLink: null,
  iconLibraryConfig: {},
  isChangelogEnabled: false,
  isMetaEnabled: false,
  isNewsfeedEnabled: false,
  isTruckLabelingEnabled: false,
  isTankLabellingEnabled: false,
  jiraRequestWidget: {
    status: 0,
    src: '',
    'data-key': '',
    'data-base-url': '',
  },
  myFiles: {},
  newsfeedConfig: {
    count: 7,
    teaserShow: false,
    teaserTitle: null,
    teaserText: null,
    teaserImage: null,
  },
  isWeb2PrintEnabled: false,
  isWeb2PrintMaintenance: false,
};

// Reducers
const set: CaseReducer<AddonsState, PayloadAction<AddonsDto>> = (state, action) => {
  if (action.payload) {
    const {
      publishing,
      pdf,
      paragraph,
      iconLibrary,
      favourites,
      changelog,
      newsfeed,
      meta,
      web2print,
      truckLabeling,
      tankLabelling,
      jiraRequestWidget,
      myFiles,
    } = action.payload;

    // Publishing
    state.isPublishingEnabled = Boolean(publishing.status);

    // MyFiles
    state.myFiles = myFiles;

    // PDF
    state.isPdfEnabled = Boolean(pdf.status);

    // Paragraph types / Modules
    state.paragraphModules = paragraph.modules;

    // Icon Library
    state.isIconLibraryEnabled = Boolean(iconLibrary.status);

    // Favourite
    state.isFavouriteEnabled = Boolean(favourites.status);
    state.favouritesDefaultLibraryLink = favourites.defaultLibraryLink;

    // Changelog
    state.isChangelogEnabled = Boolean(changelog?.status);

    // Meta
    state.isMetaEnabled = Boolean(meta?.status);

    // Newsfeed
    state.isNewsfeedEnabled = Boolean(newsfeed?.status);
    if (state.isNewsfeedEnabled) {
      state.newsfeedConfig.count = newsfeed.news_count;
      state.newsfeedConfig.teaserShow = Boolean(newsfeed.teaser?.show_teaser);
      state.newsfeedConfig.teaserTitle = newsfeed.teaser?.teaser_title;
      state.newsfeedConfig.teaserText = newsfeed.teaser?.teaser_text;
      state.newsfeedConfig.teaserImage = newsfeed.teaser?.teaser_image;
    }

    // TruckLabeling
    state.isTruckLabelingEnabled = Boolean(truckLabeling?.status);

    // TankLabelling
    state.isTankLabellingEnabled = Boolean(tankLabelling?.status);

    // Jira Request Widget
    state.jiraRequestWidget = jiraRequestWidget;

    // Web2Print
    state.isWeb2PrintEnabled = Boolean(web2print.status);
    state.isWeb2PrintMaintenance = Boolean(web2print.maintenance);
  }
};

const setIconLibraryConfig: CaseReducer<AddonsState, PayloadAction<IconLibraryDto>> = (state, action) => {
  if (action.payload) {
    state.iconLibraryConfig = {};
    Object.entries(action.payload).forEach(([key, value]) => {
      // remove 'iconLibrary' from key and
      key = key.replace('iconLibrary', '');
      // make sure first letter is lowercase
      key = `${key.charAt(0).toLowerCase()}${key.slice(1)}`;
      state.iconLibraryConfig[key] = value;
    });
  }
};

const setFavouritesConfig: CaseReducer<AddonsState, PayloadAction<FavouritesDto>> = (state, action) => {
  if (action.payload && action.payload.defaultLibraryLink) {
    state.favouritesDefaultLibraryLink = action.payload.defaultLibraryLink;
  }
};
// Slice
const slice = createSlice({
  name: 'addons',
  initialState,
  reducers: {
    set,
    setIconLibraryConfig,
    setFavouritesConfig,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: AddonsState) => state;
export const selectors = {
  self: selectSelf,
  paragraphModules: (state: AddonsState) => state.paragraphModules,
  isPublishingEnabled: (state: AddonsState) => state.isPublishingEnabled,
  isPdfEnabled: (state: AddonsState) => state.isPdfEnabled,
  isIconLibraryEnabled: (state: AddonsState) => state.isIconLibraryEnabled,
  iconLibraryConfig: (state: AddonsState) => state.iconLibraryConfig,
  isFavouriteEnabled: (state: AddonsState) => state.isFavouriteEnabled,
  favouritesDefaultLibraryLink: (state: AddonsState) => state.favouritesDefaultLibraryLink,
  isChangelogEnabled: (state: AddonsState) => state.isChangelogEnabled,
  isMetaEnabled: (state: AddonsState) => state.isMetaEnabled,
  isNewsfeedEnabled: (state: AddonsState) => state.isNewsfeedEnabled,
  newsfeedConfig: (state: AddonsState) => state.newsfeedConfig,
  isTruckLabelingEnabled: (state: AddonsState) => state.isTruckLabelingEnabled,
  isTankLabellingEnabled: (state: AddonsState) => state.isTankLabellingEnabled,
  jiraRequestWidget: (state: AddonsState) => state.jiraRequestWidget,
  myFiles: (state: AddonsState) => state.myFiles,
  isWeb2PrintEnabled: (state: AddonsState) => state.isWeb2PrintEnabled,
  isWeb2PrintMaintenance: (state: AddonsState) => state.isWeb2PrintMaintenance,
};
