import { createSelector } from '@reduxjs/toolkit';

import { lookupSelector } from '~store/lookupSelector';
import { stringSelector } from '~store/paramSelector';
import { selectors as DraftEntitiesSelectors } from '~store/slices/draft-entities';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectDraftEntitiesState = lookupSelector(selectRootState, state => state.draftEntities);

// Slice: DraftEntities Selectors
export const selectDraftEntities = lookupSelector(selectDraftEntitiesState, DraftEntitiesSelectors.draftEntities);

// Custom selectors
export const selectDraftEntityById = createSelector(
  [selectDraftEntitiesState, stringSelector],
  (draftEntities, id) => draftEntities[id]?.data || null,
);
export const selectDraftEntityStatusById = createSelector(
  [selectDraftEntitiesState, stringSelector],
  (draftEntities, id) => draftEntities[id]?.status,
);
