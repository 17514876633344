import { connect } from 'react-redux';

import type { RootState } from '$store';
import { SidebarContent, SidebarContentProps } from '~components/sidebar/content/SidebarContent';
import { ControlActions } from '~store/actions';
import { selectAddonsIsPdfEnabled, selectSessionIsEditing, selectWidgetsSidebar } from '~store/selectors';
import { selectContext, selectContextEntityDefinition } from '~store/selectors/control';

export type StateProps = Pick<
  SidebarContentProps,
  'context' | 'editing' | 'hideSidebarSettings' | 'sidebarWidgets' | 'isPdfEnabled'
>;
const mapStateToProps = (state: RootState): StateProps => {
  const contextEntityDef = selectContextEntityDefinition(state);
  const context = selectContext(state);
  return {
    context: context,
    editing: selectSessionIsEditing(state),
    hideSidebarSettings: (contextEntityDef && contextEntityDef.meta.hideSettings) || context === 'main-navigation',
    sidebarWidgets: selectWidgetsSidebar(state),
    isPdfEnabled: selectAddonsIsPdfEnabled(state),
  };
};

export type DispatchProps = Pick<SidebarContentProps, 'onCloseSidebar'>;
const mapDispatchToProps: DispatchProps = {
  onCloseSidebar: () => ControlActions.closeSidebar(),
};

export const ConnectedSidebarContent = connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
export default ConnectedSidebarContent;
