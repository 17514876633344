import { ThunkCreator } from '$store';
import errorRenderer from '~components/generic/errorRenderer';
import { ApiError, isApiError } from '~services/error-helpers';
import log from '~services/log';
import { StatusActions } from '~store/actions';
import { selectConfigNotificationTimeout } from '~store/selectors';
import delay from '~utils/delay';
import jsxToText from '~utils/jsxToText';

export const delayedRemoveNotifcation: ThunkCreator = () => async (dispatch, getState) => {
  const timeout = selectConfigNotificationTimeout(getState());
  await delay(timeout);
  dispatch(StatusActions.unsetNotification());
};

/**
 * Generically handle errors returned from API handling sagas and show an
 * appropriate error message, could be a network, a backend or a local error
 *
 * @param payload {Error|IApiError}
 */
export const apiErrorNotification: ThunkCreator = (payload: Error | ApiError) => dispatch => {
  // handle locally produced errors within the wrapping function (i.e. this
  // isn't even a locally produced ApiError, this is just an Error)
  if (!isApiError(payload)) {
    dispatch(StatusActions.setErrorNotification(`There was an API error: ${payload.message}`));
    log('Error in API request!', payload, 'error');
    return;
  }

  if (Object.prototype.hasOwnProperty.call(payload, 'info')) {
    const { info } = payload as ApiError;
    const { type, title, status, error } = info;

    const verbMap = {
      login: 'logging in',
      create: 'creating',
      save: 'saving',
      read: 'reading',
      delete: 'deleting',
      lock: 'locking',
    };
    const messagePrefix = 'There was an error';
    const verb = verbMap[type];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorText: any = error instanceof Error ? error.message : error; // ToDo: how can errorText be an object

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const message = errorRenderer(
      messagePrefix,
      verb,
      title,
      errorText ? errorText.info || errorText : 'Unknown error',
      status,
    );

    dispatch(StatusActions.setErrorNotification(message));
    log(jsxToText(message), payload, 'error');
  } else {
    dispatch(StatusActions.setErrorNotification(payload.message || payload));
    log('Unexpected Error', payload, 'error');
  }
};
