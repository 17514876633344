import { createQueryKeyStore } from '@lukemorales/query-key-factory';

import { getFavourites } from '~extension/favourite/api/api';
import { makeFavouriteList } from '~extension/favourite/model/FavouriteFactory';

export const queries = createQueryKeyStore({
  favourites: {
    getFavourites: () => ({
      queryKey: ['ebm.favourites'],
      queryFn: async () => makeFavouriteList(await getFavourites()),
    }),
  },
});
