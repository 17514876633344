import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { selectCurrentPageId, selectStatusShowEditLoading } from '~store/selectors';
import { changeMeta } from '~store/thunks/meta';

function changeTitle(value: string) {
  return { fieldName: 'attributes.field_meta_title', value };
}
function changeDescription(value: string) {
  return { fieldName: 'attributes.field_meta_description', value };
}
function changeTopics(value: Topic[]) {
  return { fieldName: 'attributes.field_topics', value: value?.map(topic => ({ target_id: topic.id })) };
}
function changeCrops(value: ImageCrops) {
  return { fieldName: 'attributes.field_meta_image_crops', value: JSON.stringify(value || {}) };
}
function changeImage(value: MetaImage) {
  const id = value?.id;
  const fieldValue = id ? { data: [{ id, type: 'file--file' }] } : null;
  return { fieldName: 'relationships.field_meta_image', value: fieldValue };
}

export default function useEditPageMeta() {
  const dispatch = useAppDispatch();
  const currentPageId = useAppSelector(selectCurrentPageId);
  const isProcessing = useAppSelector(selectStatusShowEditLoading);
  const prevIsProcessing = usePrevious(isProcessing);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (prevIsProcessing && !isProcessing && isSaving) {
      // at this point the actual saving was successfull
      // resolve
      bag.current.saveResolve?.(true);
      // reset bag
      bag.current = undefined;
      // update processing state
      setIsSaving(false);
    }
  }, [isProcessing, prevIsProcessing, isSaving]);

  // temporary data bag
  const bag = useRef<{
    pageId: string;
    saveResolve: (value: boolean | PromiseLike<boolean>) => void;
  }>();

  const handleSavePageMeta = useCallback(
    ({
      title,
      description,
      topics,
      crops,
      image,
    }: {
      title: string;
      description: string;
      topics: Topic[];
      crops: ImageCrops;
      image: MetaImage;
    }) => {
      setIsSaving(true);
      return new Promise<boolean>(resolve => {
        bag.current = { pageId: currentPageId, saveResolve: resolve };
        dispatch(
          changeMeta({
            id: currentPageId,
            changes: [
              changeTitle(title),
              changeDescription(description),
              changeTopics(topics),
              changeCrops(crops),
              changeImage(image),
            ].filter(Boolean),
          }),
        );
      });
    },
    [currentPageId, dispatch],
  );

  return useMemo(
    () => ({
      save: handleSavePageMeta,
      isSaving,
    }),
    [handleSavePageMeta, isSaving],
  );
}
