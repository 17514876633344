import prefixHttps from '~utils/prefixHttps';

export function refreshFavicon(backendUrl: string, theme: string) {
  try {
    const linkEl = document.getElementById('linkFavicon') as HTMLLinkElement;
    if (linkEl) {
      linkEl.href = prefixHttps(`${backendUrl || ''}/themes/custom/${theme}/favicon.ico`);
    }
  } catch (_) {
    /* silently ignore errors - nothing to do */
  }
}
