import { ComponentType, PureComponent } from 'react';

import HeadlineFiveButton from '~components/generic/editable-field/HeadlineFiveButton';
import HeadlineFourButton from '~components/generic/editable-field/HeadlineFourButton';
import HeadlineOneButton from '~components/generic/editable-field/HeadlineOneButton';
import HeadlineSixButton from '~components/generic/editable-field/HeadlineSixButton';
import HeadlineThreeButton from '~components/generic/editable-field/HeadlineThreeButton';
import HeadlineTwoButton from '~components/generic/editable-field/HeadlineTwoButton';

export type HeadlinesPickerProps = {
  onOverrideContent?: (content: ComponentType | undefined) => void;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
};

export class HeadlinesPicker extends PureComponent<HeadlinesPickerProps> {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    this.props.onOverrideContent(undefined);

  render() {
    return (
      <>
        {this.props.h1 && <HeadlineOneButton {...this.props} />}
        {this.props.h2 && <HeadlineTwoButton {...this.props} />}
        {this.props.h3 && <HeadlineThreeButton {...this.props} />}
        {this.props.h4 && <HeadlineFourButton {...this.props} />}
        {this.props.h5 && <HeadlineFiveButton {...this.props} />}
        {this.props.h6 && <HeadlineSixButton {...this.props} />}
      </>
    );
  }
}
