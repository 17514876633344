import { lookupSelector } from '~store/lookupSelector';
import { selectors as CookieModalSelectors } from '~store/slices/cookieModal';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectCookieModalState = lookupSelector(selectRootState, state => state.cookieModal);

// Slice: CookieModal Selectors
export const selectCookieModalTexts = lookupSelector(selectCookieModalState, CookieModalSelectors.texts);
export const selectCookieModalButtons = lookupSelector(selectCookieModalState, CookieModalSelectors.buttons);
