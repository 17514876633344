import GoogleAnalyticsGtag, {
  //  trackPageView,
  trackEvent,
} from '@redux-beacon/google-analytics-gtag';
import logger from '@redux-beacon/logger';
import { createMiddleware, EventDefinition } from 'redux-beacon';

import { ControlActions, RouterActions } from '~store/actions';
import {
  selectTrackingGaDimensionsMap,
  selectTrackingGtagDimensionsMap,
  selectUserTrackingData,
} from '~store/selectors';

declare let gtag: any;

let userParamsSet = false;

// to avoid multiple calls to getCustomap method, init customMap with null
let customMap = null;

function getCustomMap(state) {
  // custom dimensions must be already set in Google Analytics
  // (Admin -> Properties -> Custom Definitions -> Custom Dimensions)
  // currently used ones:
  //  user_country: 1,
  //  user_company: 2,
  //  user_division: 3,
  //  user_roles: 4,

  customMap = {};
  const dimensionsMap = selectTrackingGtagDimensionsMap(state) || selectTrackingGaDimensionsMap(state);
  if (dimensionsMap) {
    for (const [dimVar, dimIndex] of Object.entries(dimensionsMap)) {
      customMap[`dimension${dimIndex}`] = dimVar;
    }
  }
  return customMap;
}

// this is the same definition as in google-analytics-gtag, but it returns additional
// custom_map param to track custom dimension on each page view
// after a pageView event, custom events will contain custom dimension too
const trackPageView =
  (
    eventDef: EventDefinition<{
      title?: string;
      location?: string;
      path?: string;
    }>,
    ...trackers: string[]
  ): EventDefinition =>
  (action, prevState, nextState) => {
    const event = eventDef(action, prevState, nextState);

    return {
      type: 'page',
      trackingId: trackers,
      page_title: event.title,
      page_location: event.location,
      page_path: event.path,
      custom_map: customMap ?? getCustomMap(nextState),
    };
  };

const addGAScript = (gaId: string) => {
  const gtagScript = document.createElement('script');
  gtagScript.type = 'text/javascript';
  gtagScript.setAttribute('async', 'true');
  gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gaId}`);
  document.head.appendChild(gtagScript);

  const initScript = document.createElement('script');
  initScript.innerText =
    "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date());";
  document.head.appendChild(initScript);
};

// additional user info
function trackUserData(state) {
  if (typeof (window as any).gtag === 'function' && !userParamsSet) {
    const trackingUserParams = selectUserTrackingData(state);
    for (const [key, value] of Object.entries(trackingUserParams)) {
      gtag('set', key, value);
    }
    // This should happen only once.
    // We cannot set these vars in addGAScript, because at that point
    // user context and store are not set yet.
    userParamsSet = true;
  }
}

const pageView = trackPageView((action, _, nextState) => {
  trackUserData(nextState);
  const location = action.meta.location.current;
  return {
    title: location.payload.pageAlias,
    location: window.location.href,
    path: location.pathname,
  };
});

const download = trackEvent((action, _, nextState) => {
  const pageTitle: string = nextState.location.payload.pageAlias;
  const fileUrl: string = action.payload.filename;
  return {
    action: 'Asset Download',
    category: 'Download',
    label: `${pageTitle} | ${fileUrl}`,
  };
});

const search = trackEvent(action => {
  return {
    action: 'Overall Search',
    category: 'Search',
    label: action.payload.query,
  };
});

const playVideo = trackEvent((action, _, nextState) => {
  const pageTitle: string = nextState.location.payload.pageAlias;
  const fileUrl: string = action.payload.currentSrc;
  return {
    action: 'Video Start',
    category: 'Click',
    label: `${pageTitle} | ${fileUrl}`,
  };
});

// google-analytics-gtag sets param send_page_view = false,
// disabling auto-sending of page views, so we have trigger
// this event ourself by generating events of type 'page'
export const eventsMap = {
  [RouterActions.navigateToDefault.type]: pageView,
  [RouterActions.navigateToPage.type]: pageView,
  [RouterActions.navigateToPageWithAnchor.type]: pageView,
  [ControlActions.downloadFile.type]: download,
  [RouterActions.navigateToSearch.type]: search,
  [ControlActions.playVideo.type]: playVideo,
};

const createGoogleAnalyticsGtag = (gaId: string) => {
  if (gaId) {
    addGAScript(gaId);
    return createMiddleware(eventsMap, GoogleAnalyticsGtag(gaId), {
      logger,
    });
  }
  return false;
};

export default createGoogleAnalyticsGtag;
