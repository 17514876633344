import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'paragraph--text_image',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      default_langcode: true,
      field_hide_title: false,
      field_image_pos: 1,
      field_image_proportions: 0,
      field_image_size: 1,
      field_link_external: null,
      field_link_module: null,
      field_link_text: null,
      field_search_text: null,
      field_text: {
        value:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.',
        format: null,
      },
      field_title: { value: 'I am a Text Image Module', format: 'rich_text' },
    },
    relationships: {
      field_assets: {
        data: [],
      },
      field_image: {},
      field_tags: {
        data: [],
      },
    },
    links: {},
    log: [],
    permissions: {
      update: true,
      create: true,
      delete: true,
    },
  },
  included: [],
});
