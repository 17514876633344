import { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ReactQueryWrapper } from '~components/generic/ReactQueryWrapper';
import { AssetsProvider } from '~context/Assets';
import FavouriteProvider from '~extension/favourite/context/Provider';
import { LanguageProvider } from './LanguageProvider';
import { PermissionsProvider } from './Permissions';
import { TopicsProvider } from './Topics';

type ProviderBagProps = {
  children: ReactNode;
};

export const ProviderBag = ({ children }: ProviderBagProps) => {
  return (
    <ReactQueryWrapper>
      <LanguageProvider>
        <PermissionsProvider>
          <AssetsProvider>
            <TopicsProvider>
              <FavouriteProvider>
                <DndProvider backend={HTML5Backend}>{children}</DndProvider>
              </FavouriteProvider>
            </TopicsProvider>
          </AssetsProvider>
        </PermissionsProvider>
      </LanguageProvider>
    </ReactQueryWrapper>
  );
};
