import { FC } from 'react';

import { SidebarUserContainer } from '~components/sidebar/user/SidebarUserContainer';

export const SidebarFooter: FC = () => {
  return (
    <div className="c-sidebar__footer">
      <SidebarUserContainer />
    </div>
  );
};

export default SidebarFooter;
