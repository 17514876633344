import { lazy, Suspense, useEffect } from 'react';

import { PageLayout } from '~components/page/PageLayout';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import useLabels from '~hooks/useLabels';
import usePermissions from '~hooks/usePermissions';
import { selectAddonsIsTruckLabelingEnabled } from '~store/selectors';
import { navigateToDefault } from '~store/thunks/navigation';

const TruckLabelingExtension = lazy(() => import('~extension/truck-labeling/TruckLabeling'));

export const TruckLabelingPage = () => {
  const dispatch = useAppDispatch();
  const { hlPageTruckLabeling } = useLabels(['hlPageTruckLabeling']);

  // read store values
  const isEnabled = useAppSelector(selectAddonsIsTruckLabelingEnabled); //
  const { hasViewTruckLabelingOrdersPermission } = usePermissions();
  const hasPermission = hasViewTruckLabelingOrdersPermission;

  useEffect(() => {
    if (!isEnabled || !hasPermission) {
      dispatch(navigateToDefault());
    }
  }, [dispatch, hasPermission, hasViewTruckLabelingOrdersPermission, isEnabled]);

  return (
    <PageLayout title={hlPageTruckLabeling} className="page--truck-labeling">
      <div className="c-view__content relative flex-grow overflow-hidden">
        <div className="h-full">
          <Suspense fallback="loading">
            <TruckLabelingExtension />
          </Suspense>
        </div>
      </div>
    </PageLayout>
  );
};
