import { ReactNode } from 'react';

import Loader from '~components/generic/loader/Loader';

export type LoadingBlockerProps = {
  loading: boolean;
  children?: ReactNode;
};

const LoadingBlocker = ({ loading, children }: LoadingBlockerProps) => {
  return (
    <div className="loading-frame">
      {loading && <Loader absoluteCenter={true} transparentBG={true} />}
      {children}
    </div>
  );
};

export default LoadingBlocker;
