import { createAsyncThunk } from '@reduxjs/toolkit';

import { isLocked } from '~services/api/calls/entity';
import { ApiError } from '~services/error-helpers';

export const lockPage = createAsyncThunk<boolean, string>('page/lock', async (pageId, { rejectWithValue }) => {
  try {
    const response = await isLocked(pageId);

    if (response.status === 'locked') {
      return rejectWithValue(response.info);
    }

    if (response.status === 'done') {
      return true;
    }

    throw new Error(`Unhandled lock response: ${JSON.stringify(response)}`);
  } catch (e) {
    throw new ApiError({
      type: 'lock',
      title: `Can not lock page with ID: '${pageId}'`,
      ...(e as any),
    });
  }
});
