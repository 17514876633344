export interface IApiError {
  type: 'login' | 'create' | 'save' | 'read' | 'delete' | 'lock' | 'unlock';
  title?: string;
  error: string | Error;
  // if this is a locally produced error error will be an Error instance and the
  // status and url will be missing
  status?: number;
  url?: string;
}

const apiErrorName = 'APIError';

export function isApiError(obj: Error | ApiError): boolean {
  return obj && obj.name && obj.name === apiErrorName;
}

/**
 * Custom error which is thrown if an API-request fails. This needs to be caught
 * and can be passed to apiErrorNotification to render a proper error message
 */
export class ApiError extends Error {
  info: IApiError;

  constructor(error: IApiError, ...params) {
    super(...params);
    this.name = apiErrorName;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
    this.info = error;
  }
}
