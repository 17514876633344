import { createSelector } from 'reselect';

import { lookupSelector } from '~store/lookupSelector';
import { selectors as AddonsSelectors } from '~store/slices/addons';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectAddonsState = lookupSelector(selectRootState, state => state.addons);

// Slice: Addons Selectors
export const selectAddonsParagraphModules = lookupSelector(selectAddonsState, AddonsSelectors.paragraphModules);
export const selectAddonsIsPdfEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isPdfEnabled);
export const selectAddonsIsPublishingEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isPublishingEnabled);
export const selectAddonsIsIconLibraryEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isIconLibraryEnabled);
export const selectAddonsIconLibraryConfig = lookupSelector(selectAddonsState, AddonsSelectors.iconLibraryConfig);
export const selectAddonsIsFavouriteEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isFavouriteEnabled);
export const selectAddonsDefaultLibraryLink = lookupSelector(
  selectAddonsState,
  AddonsSelectors.favouritesDefaultLibraryLink,
);
export const selectAddonsIsChangelogEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isChangelogEnabled);
export const selectAddonsIsMetaEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isMetaEnabled);
export const selectAddonsIsNewsfeedEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isNewsfeedEnabled);
export const selectAddonsNewsfeedConfig = lookupSelector(selectAddonsState, AddonsSelectors.newsfeedConfig);
export const selectAddonsIsTruckLabelingEnabled = lookupSelector(
  selectAddonsState,
  AddonsSelectors.isTruckLabelingEnabled,
);
export const selectAddonsIsTankLabellingEnabled = lookupSelector(
  selectAddonsState,
  AddonsSelectors.isTankLabellingEnabled,
);
export const selectAddonsJiraRequestWidget = lookupSelector(selectAddonsState, AddonsSelectors.jiraRequestWidget);
export const selectAddonsMyFiles = lookupSelector(selectAddonsState, AddonsSelectors.myFiles);
export const selectAddonsIsWeb2PrintEnabled = lookupSelector(selectAddonsState, AddonsSelectors.isWeb2PrintEnabled);
export const selectAddonsIsWeb2PrintMaintenance = lookupSelector(
  selectAddonsState,
  AddonsSelectors.isWeb2PrintMaintenance,
);

export const selectAddonsIsMediaLibraryEnabled = createSelector(selectAddonsParagraphModules, paragraphModules =>
  paragraphModules.includes('media_library'),
);
