import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from '$store';
import { createFile as createFileCall, CreateFileResponse } from '~services/api/calls/file';
import { ApiError } from '~services/error-helpers';
import log from '~services/log';
import { EntitiesActions } from '~store/actions';
import { apiErrorNotification } from './notification';

export const uploadFiles = createAsyncThunk<void, UploadFilesPayload, { state: RootState }>(
  'files/upload',
  async (payload, { dispatch }) => {
    try {
      const { files, completeCb, progressCb } = payload;
      const responses: CreateFileResponse[] = await Promise.all(files.map(file => createFileCall(file, progressCb)));
      const ids: string[] = responses.map(response => response.data.id);
      const entityInfos: EntityInformation[] = responses.map(
        (entity): EntityInformation => ({
          id: entity.data.id,
          type: entity.data.type,
          status: { isFetching: false, isComplete: true },
          data: entity.data,
        }),
      );
      dispatch(EntitiesActions.setEntities(entityInfos));
      completeCb?.(ids, entityInfos);
    } catch (e) {
      log('Error in uploadFiles thunk!', e, 'error');
      dispatch(
        apiErrorNotification(
          new ApiError({
            type: 'create',
            title: 'Files',
            ...(e as any),
          }),
        ),
      );
    }
  },
);
