import type { ThunkCreator } from '$store';
import { stopEditingEntity } from './entity';
import { navigateToSearch } from './navigation';

export const goToSearchPage: ThunkCreator = (searchQuery?: string) => dispatch => {
  if (!searchQuery) {
    return;
  }
  dispatch(stopEditingEntity());

  dispatch(navigateToSearch(searchQuery));
};
