import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback } from 'react';
import { usePreviousDistinct } from 'react-use';

import Button from '~components/generic/elements/Button';
import Loader from '~components/generic/loader/Loader';
import useLabels from '~hooks/useLabels';
import modalStyles from '~components/generic/modal/modalStyles.css';

export type ModalProps = {
  modalConfig: ModalConfig;
  closeModal: () => void;
};

export function Modal({ modalConfig, closeModal }: ModalProps) {
  const { labelModalConfirmYes, labelModalConfirmNo } = useLabels(['labelModalConfirmNo', 'labelModalConfirmYes']);

  const handleConfirmClick = useCallback(
    e => {
      e.preventDefault();
      modalConfig.onConfirmationClick?.();
      closeModal();
    },
    [modalConfig, closeModal],
  );
  const handleCancelClick = useCallback(
    e => {
      e.preventDefault();
      modalConfig.onCancelClick?.();
      closeModal();
    },
    [modalConfig, closeModal],
  );
  const handleCloseClick = useCallback(
    e => {
      e.preventDefault();
      modalConfig.onCloseClick?.();
      closeModal();
    },
    [modalConfig, closeModal],
  );
  const handleAwayClick = useCallback(() => {
    modalConfig.onCloseClick?.();
    closeModal();
  }, [modalConfig, closeModal]);

  // With this little tweak the dialog continues to show the data of the modalConfig while fading away.
  // Without these the modal would instantly render the default state while fading away, which is ugly af.
  const isVisible = modalConfig?.isVisible || false;
  const prevModalConfig = usePreviousDistinct(modalConfig);
  const modalConfigToUse = !isVisible && prevModalConfig ? prevModalConfig : modalConfig;
  const { type, headline, text, width, height, labelNo, labelYes, children } = modalConfigToUse;

  return (
    <Transition appear show={isVisible} as={Fragment}>
      <Dialog as="div" className="relative z-50 max-h-2.5" onClose={handleAwayClick}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-80"
          leave="ease-in duration-100"
          leaveFrom="opacity-80"
          leaveTo="opacity-0"
        >
          <div aria-hidden className="fixed inset-0 bg-black opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 translate-y-4"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95 translate-y-4"
            >
              <Dialog.Panel
                className="modal-app relative transform overflow-y-auto rounded-lg bg-white shadow-xl"
                style={{ width, height }}
              >
                <div>
                  <div>
                    <div className={modalStyles.content}>
                      <Dialog.Title as="h3">{headline}</Dialog.Title>
                      {!!text && <Dialog.Description as="p">{text}</Dialog.Description>}
                      {children}
                    </div>

                    <div className={modalStyles.iconBlock}>
                      <div>
                        <a href="#" onClick={handleCloseClick}>
                          <i className="c-icon-custom c-icon-custom--vertical-centered c-icon-custom--close u-size-lg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {type === 'default' && (
                    <div className={modalStyles.actionButtonsContainer}>
                      <Button label="Ok" onClick={handleCloseClick} />
                    </div>
                  )}
                  {type === 'confirm' && (
                    <div className={modalStyles.actionButtonsContainer}>
                      <Button
                        className="c-button c-button--dark c-button--margin"
                        label={labelYes || labelModalConfirmYes}
                        onClick={handleConfirmClick}
                      />
                      <span>{'\u00A0'}</span>
                      <Button
                        className="c-button c-button--dark c-button--margin"
                        label={labelNo || labelModalConfirmNo}
                        onClick={handleCancelClick}
                      />
                    </div>
                  )}
                  {type === 'loading' && (
                    <div className={modalStyles.actionButtonsContainer}>
                      <Loader />
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
