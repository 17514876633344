import { connect } from 'react-redux';

import type { RootState } from '$store';
import { ISidebarMenuProps, SidebarMenu } from '~components/sidebar/menu/SidebarMenu';
import { MenuActions } from '~store/actions';
import { selectLocationPath, selectMenuElementsForSidebar, selectSessionIsEditing } from '~store/selectors';

export type StateProps = Pick<ISidebarMenuProps, 'menuElements' | 'activePath' | 'isEditMode'>;
const mapStateToProps = (state: RootState): StateProps => ({
  isEditMode: selectSessionIsEditing(state),
  menuElements: selectMenuElementsForSidebar(state),
  activePath: selectLocationPath(state),
});
export type DispatchProps = Pick<ISidebarMenuProps, 'onToggleMenu'>;
const mapDispatchToProps: DispatchProps = {
  onToggleMenu: MenuActions.toggleSubmenuById,
};

export const SidebarMenuContainer = connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
export default SidebarMenuContainer;
