import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type LoginState = Login;

// Initial State
export const initialState: LoginState = {
  title: '',
  text: '',
};

// Reducers
const set: CaseReducer<LoginState, PayloadAction<LoginInfoDto>> = (state, action) => {
  if (action.payload) {
    state.text = action.payload.loginText;
    state.title = action.payload.loginTitle;
  }
};

// Slice
const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    set,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: LoginState) => state;
export const selectors = {
  self: selectSelf,
  title: (state: LoginState) => state.title,
  text: (state: LoginState) => state.text,
};
