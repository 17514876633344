import { useCallback, useState } from 'react';

import { EditablePictureEditor } from '~components/generic/editable-picture/EditablePictureEditor';
import useAppDispatch from '~hooks/useAppDispatch';
import log from '~services/log';
import { uploadFiles } from '~store/thunks/file';

const META_IMAGE_MIMETYPES = 'image/png, image/jpeg';

type MetaImageUploaderProps = {
  onChange: (metaImage: MetaImage) => void;
};

export function MetaImageUploader({ onChange }: MetaImageUploaderProps) {
  const dispatch = useAppDispatch();
  const [uploadProgress, setUploadProgress] = useState(-1);

  const handleUpload = useCallback(
    (files: File[]) => {
      setUploadProgress(0);
      void dispatch(
        uploadFiles({
          files,
          progressCb: setUploadProgress,
          completeCb: (_fileIds, entityInfos) => {
            try {
              const metaImageEntity = entityInfos[0].data;
              onChange({
                id: metaImageEntity.id,
                url: metaImageEntity.meta?.derivatives?.original || metaImageEntity.attributes?.url,
              });
            } catch (err) {
              log('Fileupload failed', err, 'error');
            }
          },
        }),
      );
    },
    [dispatch, onChange],
  );

  return (
    <EditablePictureEditor
      onUploadImage={handleUpload}
      uploadProgress={uploadProgress}
      acceptMimeTypes={META_IMAGE_MIMETYPES}
    />
  );
}
