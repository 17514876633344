import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type PageLoadingState = {
  isLoading: boolean;
  hasLoaded: boolean;
  hasError: boolean;
  notFound?: boolean | string;
  forbidden?: boolean | string;
  timeout?: boolean | string;
};

// State
export type StatusState = {
  notification: StatusNotification;
  pageLoading: Record<string, PageLoadingState>;
  showEditLoading: boolean;
};

// Initial State
export const initialState: StatusState = {
  notification: null,
  pageLoading: {},
  showEditLoading: false,
};

// Reducers
const startLoadingPage: CaseReducer<StatusState, PayloadAction<string>> = (state, action) => {
  if (action.payload) {
    state.pageLoading[action.payload] = {
      isLoading: true,
      hasLoaded: false,
      hasError: false,
    };
  }
};
const stopLoadingPage: CaseReducer<
  StatusState,
  PayloadAction<Pick<PageLoadingState, 'forbidden' | 'notFound' | 'timeout'> & { page: string }>
> = (state, action) => {
  if (action.payload) {
    const { page, forbidden, notFound, timeout } = action.payload;
    const hasError = !!forbidden || !!notFound || !!timeout;
    state.pageLoading[page] = {
      isLoading: false,
      hasLoaded: !hasError,
      hasError,
      forbidden,
      notFound,
      timeout,
    };
  }
};
const showEditLoading: CaseReducer<StatusState> = state => {
  state.showEditLoading = true;
};
const hideEditLoading: CaseReducer<StatusState> = state => {
  state.showEditLoading = false;
};
const setNotification: CaseReducer<StatusState, PayloadAction<StatusNotification>> = (state, action) => {
  state.notification = action.payload || initialState.notification;
};
const setErrorNotification: CaseReducer<StatusState, PayloadAction<StatusNotificationMessage>> = (state, action) => {
  state.notification = {
    type: 'error',
    message: action.payload,
  };
};
const setSuccessNotification: CaseReducer<StatusState, PayloadAction<StatusNotificationMessage>> = (state, action) => {
  state.notification = {
    type: 'success',
    message: action.payload,
  };
};
const setInfoNotification: CaseReducer<StatusState, PayloadAction<StatusNotificationMessage>> = (state, action) => {
  state.notification = {
    type: 'info',
    message: action.payload,
  };
};
const unsetNotification: CaseReducer<StatusState> = state => {
  state.notification = initialState.notification;
};

// Slice
const slice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    startLoadingPage,
    stopLoadingPage,
    showEditLoading,
    hideEditLoading,
    setNotification,
    setErrorNotification,
    setSuccessNotification,
    setInfoNotification,
    unsetNotification,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: StatusState) => state;
export const selectors = {
  self: selectSelf,
  notification: (state: StatusState) => state.notification,
  pageLoading: (state: StatusState) => state.pageLoading,
  showEditLoading: (state: StatusState) => state.showEditLoading,
};
