import slugify from 'slugify';

import stripHtml from './stripHtml';

export default function makeSlug(string: string): string {
  return slugify(stripHtml(string), {
    lower: true,
    remove: /[$*_+~.()'"?!\-:@]/g,
  });
}
