import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Portal } from 'react-portal';

import useAppSelector from '~hooks/useAppSelector';
import useLabels from '~hooks/useLabels';
import { selectConfigSiteAccess, selectSessionIsEditing, selectUserSiteAccesses } from '~store/selectors';

const SiteAccessSwitch = () => {
  const isEditmode = useAppSelector(selectSessionIsEditing);
  const currentSiteKey = useAppSelector(selectConfigSiteAccess)?.key;
  const availableSites = useAppSelector(selectUserSiteAccesses);
  const [isOpen, setIsOpen] = useState(false);
  const { btnSiteAccessSwitchSelectBrand } = useLabels(['btnSiteAccessSwitchSelectBrand']);

  const handleButtonClick = useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  if (!isEditmode && currentSiteKey && availableSites && availableSites.length > 1) {
    return (
      <Portal>
        <div className={clsx('siteaccess-switch', isOpen && 'open')}>
          <button className="c-button c-button--light" onClick={handleButtonClick}>
            <i className="c-icon-custom u-vertical-middle c-icon-custom--burger c-icon-custom--vertical-centered" />
            <span>{btnSiteAccessSwitchSelectBrand}</span>
          </button>
          <ul>
            {availableSites.map(site => (
              <li key={site.key} className={clsx(site.key === currentSiteKey && 'selected')}>
                <a href={`//${site.url}`}>{site.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </Portal>
    );
  }

  return null;
};

export default SiteAccessSwitch;
