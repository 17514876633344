import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice, original } from '@reduxjs/toolkit';

import { ModuleTypes } from '$const';
import { mergeDeep } from '~services/immutable';
import makeSlug from '~utils/makeSlug';
import stripHtml from '~utils/stripHtml';

// State
export type DraftEntitiesState = Record<string, EntityInformation>;

// Initial State
export const initialState: DraftEntitiesState = {};

// Reducers
const setDraftEntity: CaseReducer<DraftEntitiesState, PayloadAction<EntityInformation>> = (state, action) => {
  if (action.payload) {
    const staleEInfo = state[action.payload.id] ? original(state[action.payload.id]) : {};
    const eInfo = mergeDeep(staleEInfo as EntityInformation, action.payload);

    // create raw_field_title
    if (eInfo.data?.attributes?.field_title?.value) {
      eInfo.data.attributes.raw_field_title = stripHtml(eInfo.data.attributes.field_title?.value || '');
    }

    // prepare anchors
    if (eInfo.type === ModuleTypes.anchorLink) {
      eInfo.data.attributes.slug = makeSlug(eInfo.data.attributes.field_title?.value || '');
    }
    state[eInfo.id] = eInfo;
  }
};
const setDraftEntities: CaseReducer<DraftEntitiesState, PayloadAction<EntityInformation[]>> = (state, action) => {
  if (action.payload) {
    action.payload.forEach(eInfo => {
      // create raw_field_title
      if (eInfo.data?.attributes?.field_title?.value) {
        eInfo.data.attributes.raw_field_title = stripHtml(eInfo.data.attributes.field_title?.value || '');
      }

      // prepare anchors
      if (eInfo.type === ModuleTypes.anchorLink) {
        eInfo.data.attributes.slug = makeSlug(eInfo.data.attributes.field_title?.value || '');
      }
      state[eInfo.id] = eInfo;
    });
  }
};
const unsetDraftEntity: CaseReducer<DraftEntitiesState, PayloadAction<string | string[]>> = (state, action) => {
  if (action.payload) {
    if (Array.isArray(action.payload)) {
      for (const id of action.payload) {
        delete state[id];
      }
    } else {
      delete state[action.payload];
    }
  }
};
const setDraftEntityCompleted: CaseReducer<DraftEntitiesState, PayloadAction<Partial<EntityInformation>>> = (
  state,
  action,
) => {
  if (action.payload?.id) {
    state[action.payload.id].status.isComplete = true;
  }
};
const unsetDraftEntityCompleted: CaseReducer<DraftEntitiesState, PayloadAction<Partial<EntityInformation>>> = (
  state,
  action,
) => {
  if (action.payload?.id) {
    state[action.payload.id].status.isComplete = false;
  }
};

// Slice
const slice = createSlice({
  name: 'draft-entities',
  initialState,
  reducers: {
    setDraftEntity,
    setDraftEntities,
    unsetDraftEntity,
    setDraftEntityCompleted,
    unsetDraftEntityCompleted,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: DraftEntitiesState) => state;
export const selectors = {
  self: selectSelf,
  draftEntities: (state: DraftEntitiesState) => state,
};
