import { URL_REPLACEMENT } from '$const';
import { PageDto } from '~interfaces/api';
import { patch, post, remove } from '~services/api/methods';

/**
 * Create a new draft for entity, return a promise with the entity (including the new ID),
 * otherwise throw an ApiError which needs to be handled by the callee.
 */
export const createDraft = async (nodeId: string) => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/node/draft?nodeId=${nodeId}`;
  const response = await post<PageDto>(requestUrl, {});
  // ToDo: add validation! see api/validators/*
  // return create(response, PageDtoValidator);
  return response;
};

/**
 * Publish the draft of an entity, return a promise with the entity (including the new ID),
 * otherwise throw an ApiError which needs to be handled by the callee.
 */
export const publishDraft = async (nodeId: string) => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/node/draft/publish?nodeId=${nodeId}`;
  const response = await patch<PageDto>(requestUrl, { nid: nodeId });
  // ToDo: add validation! see api/validators/*
  // return create(response, PageDtoValidator);
  return response;
};

/**
 * Deletes the draft of an entity, return a promise with the entity (including the new ID),
 * otherwise throw an ApiError which needs to be handled by the callee.
 */
export const deleteDraft = async (nodeId: string) => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/node/draft?nodeId=${nodeId}`;
  const response = await remove<boolean>(requestUrl);
  return response;
};
