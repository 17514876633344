import { ComponentType, FC, useEffect } from 'react';

import { AlignTextCenterButton } from '../AlignTextCenter/AlignTextCenter';
import { AlignTextLeftButton } from '../AlignTextLeft/AlignTextLeft';
import { AlignTextRightButton } from '../AlignTextRight/AlignTextRight';

export type AlignPickerProps = {
  onOverrideContent?: (content: ComponentType | undefined) => void;
};

export const AlignPicker: FC<AlignPickerProps> = props => {
  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', onWindowClick);
      return () => {
        window.removeEventListener('click', onWindowClick);
      };
    });
  });

  const onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    props.onOverrideContent(undefined);

  return (
    <>
      {<AlignTextLeftButton {...props} />}
      {<AlignTextCenterButton {...props} />}
      {<AlignTextRightButton {...props} />}
    </>
  );
};
