import clsx from 'clsx';
import parse from 'html-react-parser';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { getContrastColor } from './MetaTag.utils';
import styles from './MetaTag.module.css';

type MetaTagProps = {
  color?: string;
  selected?: boolean;
  className?: string;
  onClick?: () => void;
  child?: boolean;
  label: string;
};

export const MetaTag = forwardRef(function MetaTag(
  { color = 'white', selected, className, child = false, onClick, label }: MetaTagProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const textColor = 'white' || getContrastColor(color);
  const isInteractive = typeof onClick !== 'undefined';

  return (
    <div
      className={clsx(
        'meta-tag',
        child && styles.child,
        child && 'meta-tag--child',
        isInteractive && styles.interactive,
        isInteractive ? 'meta-tag--interactive cursor-pointer' : 'cursor-default',
        selected && styles.selected,
        selected && 'meta-tag--selected',
        'inline-flex items-center justify-center transition rounded p-2 select-none relative overflow-hidden bg-white h-6',
        className,
      )}
      ref={ref}
      onClick={onClick}
      style={{ color: textColor }}
    >
      <div
        className={clsx(styles.background, 'meta-tag--background', 'absolute inset-0 transition')}
        style={{ backgroundColor: color, color: textColor }}
      />
      <span
        className={clsx(styles.label, 'meta-tag--label', 'relative transition whitespace-nowrap text-sm leading-none')}
      >
        {!!label && parse(label)}
      </span>
    </div>
  );
});
