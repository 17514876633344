import { lookupSelector } from '~store/lookupSelector';
import { selectConfigTrackingClients } from './config';

// Slice: Tracking Selectors

export const selectTrackingClientMatomo = lookupSelector(
  selectConfigTrackingClients,
  trackingClients => trackingClients.matomo || null,
);

export const selectTrackingClientGa = lookupSelector(
  selectConfigTrackingClients,
  trackingClients => trackingClients['google-analytics'] || null,
);

export const selectTrackingClientGtag = lookupSelector(
  selectConfigTrackingClients,
  trackingClients => trackingClients.gtag || null,
);

export const selectTrackingClientGtm = lookupSelector(
  selectConfigTrackingClients,
  trackingClients => trackingClients.gtm || null,
);

export const selectTrackingMatomoDimensionsMap = lookupSelector(
  selectTrackingClientMatomo,
  trackingClient => (trackingClient && trackingClient.dimensionsMap) || null,
);

export const selectTrackingGaDimensionsMap = lookupSelector(
  selectTrackingClientGa,
  trackingClient => (trackingClient && trackingClient.dimensionsMap) || null,
);

export const selectTrackingGtagDimensionsMap = lookupSelector(
  selectTrackingClientGtag,
  trackingClient => (trackingClient && trackingClient.dimensionsMap) || null,
);
