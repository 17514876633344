import { Select } from '~components/generic/elements/Select';
import { SidebarContextSettingsFieldInputProps } from '~components/sidebar/context/SidebarContextSettingsField';
import { IValueOptionsShape } from '~services/entity-definitions/interface';

export const SidebarContextSettingsSelect = ({ data, fieldDef, onChange }: SidebarContextSettingsFieldInputProps) => {
  return (
    <div className="c-settings__field">
      <Select
        onChange={onChange}
        value={data}
        defaultValue={fieldDef.options.defaultValue}
        values={fieldDef.options.values as IValueOptionsShape}
        label={fieldDef.options.label}
        coerceNumber={fieldDef.options.coerceNumber}
        className="u-block u-full-width"
      />
    </div>
  );
};

export default SidebarContextSettingsSelect;
