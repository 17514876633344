import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'paragraph--video',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      default_langcode: true,
      field_hide_title: false,
      field_search_text: null,
      field_text: {
        value:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.',
        format: null,
      },
      field_title: { value: 'I am a Video Module', format: 'rich_text' },
      field_full_width: false,
    },
    relationships: {
      field_assets: {
        data: [],
      },
      field_tags: {
        data: [],
      },
      field_image: {},
      field_mpeg4: {},
      field_webm: {},
    },
    links: {},
    log: [],
    permissions: {
      update: true,
      create: true,
      delete: true,
    },
  },
  included: [],
});
