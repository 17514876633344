import clsx from 'clsx';
import { Component, ReactNode } from 'react';

import Button from '~components/generic/elements/Button';

export interface IDropdownProps {
  size?: string;
  className?: string;
  buttonClassName?: string;
  iconName?: string;
  iconSize?: string;
  iconPosition?: string;
  iconClassName?: string;
  buttonLabel?: string;
  children?: ReactNode;
}

interface IDropdownState {
  isOpen: boolean;
}

export class Dropdown extends Component<IDropdownProps, IDropdownState> {
  _el: HTMLElement;
  _looseFocusListener: EventListener;

  constructor(props: IDropdownProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this._looseFocusListener = e => {
      if (!this._el || !this._el.contains(e.target as Node)) {
        this.handleToggle(false);
      }
    };
  }

  handleToggle = (open: boolean) => {
    if (open) {
      document.addEventListener('click', this._looseFocusListener);
    } else {
      document.removeEventListener('click', this._looseFocusListener);
    }

    this.setState({
      isOpen: open,
    });
  };

  handleOnClick = e => {
    e.stopPropagation();
    this.handleToggle(!this.state.isOpen);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this._looseFocusListener);
  }

  render() {
    const { isOpen } = this.state;
    const { size, className, buttonClassName, buttonLabel, children, iconName, iconClassName, iconSize, iconPosition } =
      this.props;
    const classes = clsx(
      {
        'c-dropdown': true,
        ['u-size-' + size]: Boolean(size),
        's-dropdown-open': isOpen,
      },
      className,
    );

    return (
      <div className={classes} ref={ref => (this._el = ref)}>
        <Button
          label={buttonLabel}
          iconName={iconName}
          iconClassName={iconClassName}
          iconSize={iconSize}
          onClick={this.handleOnClick}
          className={buttonClassName}
          iconPosition={iconPosition || 'left'}
        />
        <div onClick={e => e.stopPropagation()} className="c-dropdown__content" aria-hidden={!isOpen}>
          {children}
        </div>
      </div>
    );
  }
}

export default Dropdown;
