import { URL_REPLACEMENT } from '$const';
import getStore from '$store';
import { get, post } from '~services/api/methods';
import { StatusActions } from '~store/actions';
import { selectCurrentPageId } from '~store/selectors/custom';

export type CreateFileResponse = {
  data: Entity;
};

export function createFile(file: File, progressCb?: (progress: number) => void): Promise<CreateFileResponse> {
  const formData = new FormData();
  formData.append('data', file);
  const currentPageId = selectCurrentPageId(getStore().getState());
  formData.append('nodeUID', currentPageId.toString());

  const requestUrl = `${URL_REPLACEMENT.API_BASE}/upload-file?_format=api_json`;
  const onUploadProgress = progressEvent => {
    if (typeof progressCb !== 'function') {
      return;
    }
    const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total); // do whatever you like with the percentage complete
    progressCb(percentCompleted);
  };
  return post(requestUrl, formData, { onUploadProgress });
}

/*
 * Share file to MyFiles
 */
export function shareFileCms(shareUrl: string) {
  const formData = new FormData();
  formData.append('path', shareUrl);

  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/tools/myfiles/api/share`;
  void post<{ message?: string; error?: unknown }>(requestUrl, formData)
    .then(res => {
      if (res && !res.error) {
        getStore().dispatch(StatusActions.setSuccessNotification(res.message));
      } else {
        getStore().dispatch(
          StatusActions.setErrorNotification(res.message ? res.message : 'Could not add file to MyFiles!'),
        );
      }
    })
    .catch(() => {
      getStore().dispatch(StatusActions.setErrorNotification('Could not add file to MyFiles!'));
    });
}

export function shareFileMam(assetId: number | string, assetType?: string, format?: string) {
  const formData = new FormData();
  formData.append('assetIds', `${assetId}`);
  if (!!assetType) {
    formData.append('assetType', assetType);
  }
  if (!!format) {
    formData.append('format', format);
  }

  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/myfiles/api/add`;
  return new Promise<void>(resolve => {
    void post<{ message?: string; error?: unknown }>(requestUrl, formData)
      .then(res => {
        if (res && !res.error) {
          getStore().dispatch(StatusActions.setSuccessNotification(res.message));
        } else {
          getStore().dispatch(
            StatusActions.setErrorNotification(res.message ? res.message : 'Could not add file to MyFiles!'),
          );
        }
      })
      .catch(() => {
        getStore().dispatch(StatusActions.setErrorNotification('Could not add file to MyFiles!'));
      })
      .finally(() => {
        resolve();
      });
  });
}

/*
 * Get the PDF file
 */
export const getPDFFileUrl = async (pdfId: string) => {
  const requestUrl = `${URL_REPLACEMENT.BACKEND_URL}/print/pdf/node/${pdfId}`;
  const response = await get<string>(requestUrl);
  return response;
};
