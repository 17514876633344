import clsx from 'clsx';
import { useCallback, useMemo } from 'react';

import { Dropdown } from '~components/generic/elements/Dropdown';
import useAppDispatch from '~hooks/useAppDispatch';
import useAppSelector from '~hooks/useAppSelector';
import { selectActiveAnchor, selectCurrentPageAnchors } from '~store/selectors';
import { setSlug } from '~store/thunks/navigation';

export function AnchorSelect() {
  // read from store
  const pageAnchors = useAppSelector(selectCurrentPageAnchors);
  const activeAnchor = useAppSelector(selectActiveAnchor);

  // prepare handler
  const dispatch = useAppDispatch();
  const handleAnchorChange = useCallback(
    (slug: string) => {
      if (slug !== activeAnchor) {
        dispatch(setSlug(slug));
      }
    },
    [dispatch, activeAnchor],
  );

  // derived values
  const activeAnchorLabel = useMemo(
    () => pageAnchors.find(anchor => anchor.attributes.slug === activeAnchor)?.attributes.raw_field_title,
    [activeAnchor, pageAnchors],
  );

  return (
    <div>
      {pageAnchors && !!pageAnchors.length && (
        <>
          <Dropdown
            buttonLabel={activeAnchorLabel}
            buttonClassName="c-button c-button--light"
            iconName={'burger'}
            iconPosition="right"
          >
            <ul className="c-nav c-nav--lightest">
              {pageAnchors.map(anchor => (
                <li
                  key={anchor.id}
                  className={clsx({ selected: anchor.attributes.slug === activeAnchor })}
                  onClick={() => handleAnchorChange(anchor.attributes.slug)}
                  dangerouslySetInnerHTML={{ __html: anchor.attributes.raw_field_title }}
                ></li>
              ))}
            </ul>
          </Dropdown>
        </>
      )}
    </div>
  );
}
