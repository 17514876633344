import clsx from 'clsx';
import { ForwardedRef, forwardRef, MouseEvent, ReactNode, useCallback } from 'react';

import { Spinner } from '~components/generic/spinner/Spinner';
import styles from '../Editor.module.css';

export type ButtonProps = {
  onClick?: (event?: MouseEvent<HTMLElement>) => void;
  label?: string;
  title?: string;
  preIcon?: string | ReactNode;
  icon?: string | ReactNode;
  disabled?: boolean;
  processing?: boolean;
  size?: 'small' | 'medium' | 'large';
  splitIcon?: boolean;
  unflashy?: boolean;
  className?: string;
  _forceIdleState?: boolean;
  _forceActiveState?: boolean;
  _forceDisabledState?: boolean;
  _forceHoverState?: boolean;
  _forceProcessingState?: boolean;
};

export const Button = forwardRef(
  (
    {
      onClick,
      title,
      icon,
      disabled,
      processing,
      preIcon,
      label,
      size = 'medium',
      splitIcon = false,
      unflashy,
      className,
      _forceIdleState,
      _forceActiveState,
      _forceDisabledState,
      _forceHoverState,
      _forceProcessingState,
      ...passThroughProps
    }: ButtonProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const handleOnClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        if (!disabled && !processing && onClick) {
          onClick(e);
        }
      },
      [disabled, onClick, processing],
    );

    const clsButton = clsx(
      _forceIdleState && styles.buttonIdle,
      _forceActiveState && styles.buttonActive,
      _forceDisabledState && styles.buttonDisabled,
      _forceHoverState && styles.buttonHover,
      _forceProcessingState && styles.buttonProcessing,
      !(_forceIdleState || _forceActiveState || _forceDisabledState || _forceHoverState || _forceProcessingState) && {
        [styles.buttonDefault]: !disabled && !processing,
        [styles.buttonDisabled]: disabled,
        [styles.buttonProcessing]: !disabled && processing,
      },
      size === 'small' && styles.buttonSizeSmall,
      unflashy && styles.buttonUnflashy,
      className,
    );

    return (
      <div className={clsButton} title={title} onClick={handleOnClick} ref={ref} {...passThroughProps}>
        <div className={styles.buttonInner}>
          {!!preIcon &&
            (typeof preIcon === 'string' ? (
              <div className={styles.buttonPreIcon}>
                <i className={`c-icon-custom c-icon-custom--vertical-centered c-icon-custom--${preIcon}`} />
              </div>
            ) : (
              <div className={styles.buttonPreIconComponent}>{preIcon}</div>
            ))}
          {!!label && <div className={styles.buttonLabel}>{label}</div>}
          {!!splitIcon && !!icon && <div className={styles.buttonIconSplitter} />}
          {!!icon &&
            (typeof icon === 'string' ? (
              <div className={styles.buttonIcon}>
                <i className={`c-icon-custom c-icon-custom--vertical-centered c-icon-custom--${icon}`} />
              </div>
            ) : (
              <div className={styles.buttonIconComponent}>{icon}</div>
            ))}
        </div>
        <div className={styles.buttonProcessingIndicator}>
          <Spinner className={styles.spinner} />
        </div>
      </div>
    );
  },
);

Button.displayName = 'Button';
