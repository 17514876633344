import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => {
  return {
    data: {
      type: 'paragraph--wizard',
      id: createTemporaryEntityId(),
      attributes: {
        langcode: getEntityTemplateLang(),
        status: true,
        default_langcode: true,
        field_hide_title: false,
        field_level_def: '',
        field_title: { value: 'Wizard', format: 'rich_text' },
      },
      relationships: {
        field_modules: {
          data: [],
        },
        field_level: {
          data: [],
        },
      },
      permissions: {
        update: true,
        create: true,
        delete: true,
      },
    },
  };
};
