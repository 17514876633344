import { URL_REPLACEMENT } from '$const';
import { ReadAnchorsDto } from '~interfaces/api';
import { get } from '~services/api/methods';

/**
 * Request anchors by searchvalue
 * @param string 'query' - the search value
 * @return Fetch response
 */
export const requestAnchors = async (query?: string) => {
  const requestUrl = `${URL_REPLACEMENT.API_BASE}/getAnchors${query ? `?anchor=${query}` : ''}`;
  const response = await get<ReadAnchorsDto>(requestUrl);
  // ToDo: add validation! see api/validators/*
  // return create(response, PageDtoValidator);
  return response;
};
