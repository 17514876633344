import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from '$store';
import { PageDto, PagePermissions } from '~interfaces/api';
import { readPage as readPageCall } from '~services/api/calls/entity';
import { ApiErrorDescriptor } from '~services/api/helpers';
import { ApiError } from '~services/error-helpers';
import log from '~services/log';
import { DraftEntitiesActions, EntitiesActions, StatusActions } from '~store/actions';
import { selectConfigFallbackForbiddenUrl } from '~store/selectors';
import { selectMenuElementByAlias, selectMenuStatusIsIdle } from '~store/selectors/menu';
import delay from '~utils/delay';
import { hasOwnProperty, isObject } from '~utils/object';
import { navigateToDefault } from './navigation';
import { apiErrorNotification } from './notification';

async function waitForMenuElements(getState: () => RootState) {
  while (true) {
    if (selectMenuStatusIsIdle(getState())) return;
    await delay(50);
  }
}

export const fetchPage = createAsyncThunk<void, { page: string; pageId?: string }, { state: RootState }>(
  'page/fetch',
  async ({ page, pageId }, { dispatch, getState }) => {
    // wait for menu elements to load
    await waitForMenuElements(getState);

    // get menu element with matching pagePath == alias
    const menuElement = selectMenuElementByAlias(getState(), page);

    try {
      // if this page is already in store don't load it again
      if (pageId) {
        return;
      }

      dispatch(StatusActions.startLoadingPage(page));

      const parameters = {
        type: 'node/page',
        query: {
          filter: {
            field_path: {
              value: menuElement?.fetchAlias || page,
            },
          },
        },
      };
      const response = await readPageCall(parameters);
      const { published, draft, permissions } = response;

      if (!published && !draft) {
        dispatch(navigateToDefault());
        dispatch(StatusActions.setErrorNotification('Page not found!'));
        return;
      }

      // if published is available add them to store
      if (published) {
        const publishedEntities = collectAllEntities(published, permissions);
        dispatch(EntitiesActions.setEntities(publishedEntities));
      }

      // if draft is available for editors add them to store
      if (draft) {
        const draftEntities = collectAllEntities(draft, permissions);
        dispatch(DraftEntitiesActions.setDraftEntities(draftEntities));
      }

      dispatch(StatusActions.stopLoadingPage({ page }));
    } catch (e) {
      // Handle forbidden
      if (e instanceof ApiErrorDescriptor && (e.status === 401 || e.status === 403)) {
        // loading fallback page
        const fallbackForbiddenPage = selectConfigFallbackForbiddenUrl(getState());
        if (!!fallbackForbiddenPage) {
          await dispatch(fetchPage({ page: fallbackForbiddenPage }));
        }

        dispatch(StatusActions.stopLoadingPage({ page, forbidden: e.error }));
        return;
      }
      // Handle not found
      if (e instanceof ApiErrorDescriptor && e.status === 404) {
        dispatch(StatusActions.stopLoadingPage({ page, notFound: e.error }));
        return;
      }
      // Handle timeout
      if (e instanceof ApiErrorDescriptor && (e.status === 408 || e.status === 504)) {
        dispatch(StatusActions.stopLoadingPage({ page, timeout: e.error }));
        return;
      }

      log('Error in fetchPage thunk!', e, 'error');
      dispatch(
        apiErrorNotification(
          new ApiError({
            type: 'read',
            title: 'Entity',
            ...(e as any),
          }),
        ),
      );
      dispatch(
        EntitiesActions.setEntityFailed({
          id: pageId,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          message: (e as Error).message,
        }),
      );

      // Redirect to default page on error 500 (server-error)
      if (isObject(e) && hasOwnProperty(e, 'status') && e.status === 500) {
        dispatch(navigateToDefault());
      }
    }
  },
);

/**
 * Helper to collect all entities from given data object and prepair them before push them to store.
 *
 * @param dataObject Data form backend result
 * @param permissions User permissions for current page
 *
 * @return array
 */
function collectAllEntities(dataObject: PageDto, permissions: PagePermissions) {
  const { data, field_modules, included } = dataObject;

  let allEntities: Entity[] = field_modules ? field_modules : [];
  if (included) {
    allEntities = [...allEntities, ...included];
  }
  allEntities.push(data);

  const result = allEntities.map(entity => ({
    id: entity.id,
    type: entity.type,
    status: { isFetching: false, isComplete: true },
    data: entity,
    permissions: permissions,
  }));

  return result;
}
