import _merge from 'lodash.merge';

import getStore from '$store';
import { IJsonApiResponseSingle } from '~interfaces/api';
import log from '~services/log';
import { selectAddonsParagraphModules, selectContextCurrentAppLanguage } from '~store/selectors';
import { entityDefinitions } from './definitions';
import { IEntityDefinition } from './interface';

const entityTemplates = {};
function importAll(require) {
  require.keys().forEach(key => {
    const sanitisedKey = key.replace(/(^\.\/|\.(j|t)s$)/g, '');
    entityTemplates[sanitisedKey] = require(key).default;
  });
}
importAll(require.context('./templates/', true, /\.(j|t)s$/));

// build an array of entity types from templates
const entityTemplateTypes = Object.keys(entityTemplates);
// build an array of entity types from the definitions
const entityDefinitionTypes = Object.keys(entityDefinitions).filter(isNotWildcardType);

export function isNotWildcardType(type: string): boolean {
  return type.indexOf('*') === -1;
}

export function hasSpecialPrefix(str: string): boolean {
  return str.substr(0, 2) === '$$';
}

export function removeSpecialPrefix(str: string): string {
  return str.substr(2);
}

// used to auto-include files which are referenced, files don't have a
// field_path field so we need this approach to get the ones for a particular
// page
export const fileFieldNames = [
  'field_image',
  'field_assets',
  'field_downloads',
  'field_webm',
  'field_mpeg4',
  'field_bg_image',
  'field_file',
];

/**
 * Use this to get an array of entity types of entities which are a member of a
 * certain category
 */
export function getEntityTypesByCategory(cat: string): string[] {
  const res = [];
  entityDefinitionTypes.forEach(type => {
    const def = getEntityDefinition(type);
    const categories = def.meta.categories || {};
    if (categories[cat]) {
      res.push(type);
    }
  });
  return res;
}

/**
 * Get the definition for an entity using the wildcard keys in the
 * entityDefinitions object
 *
 * @param {string} entityType
 */
export function getEntityDefinition(entityType: string): IEntityDefinition {
  if (!entityType) {
    throw new Error('getEntityDefinition was called with invalid entityType!');
  }
  const res = {
    meta: {
      name: 'No name!',
    },
  };
  const def = entityDefinitions[entityType];
  Object.keys(entityDefinitions)
    // only use the keys with * in them
    .filter(key => key.search(/\*/) !== -1)
    // use the keys as regex
    .filter(key => entityType.search(new RegExp(key)) !== -1)
    .forEach(key => {
      const conf = entityDefinitions[key];
      _merge(res, conf);
    });

  if (def) {
    _merge(res, def);
  }

  return res;
}

/**
 * Get the the definition for a specific field of an entity
 *
 * @param {string} entityType
 * @param {string} fieldName
 */
export function getEntityFieldDefinition(entityType: string, fieldName: string) {
  const entityDef = getEntityDefinition(entityType);
  const def = entityDef.fields[fieldName];

  if (!def) {
    throw new Error(`No field definition found for entity type "${entityType}" and field name "${fieldName}"!`);
  }
  return def;
}

/**
 * Expand special type selectors for categories into the proper types. This has
 * to be done here, otherwise there is a circular dependency between the helpers
 * and the definitions.
 *
 * So by calling this function an array like this:
 * ['paragraph--type_a', 'paragraph--type_b', '$$categoryName', 'paragraph--type_c']
 *
 * … will be expanded into this:
 * ['paragraph--type_a', 'paragraph--type_b', 'paragraph--entity_with_categoryName',
 * ... other entities of this category, paragraph--type_c']
 */
export function expandCategoryEntityTypeSelectors(selectorArray: string[]) {
  let res = [];
  selectorArray.forEach(sel => {
    if (!hasSpecialPrefix(sel)) {
      res = [...res, sel];
    } else {
      const selectedTypes = getEntityTypesByCategory(removeSpecialPrefix(sel));
      res = [...res, ...selectedTypes];
    }
  });
  return res;
}

/**
 * Get a list of valid entity types from an include/exclude option, by default,
 * all will be returned
 *
 * @param options
 */
export function getValidEntityTypes(options: {
  typesName?: 'templates' | 'definitions';
  exclude?: string[];
  include?: string[];
}): string[] {
  const { typesName, include, exclude } = options;
  // by default use the template types
  const _entities = typesName === 'definitions' ? entityDefinitionTypes : entityTemplateTypes;
  // by default use all
  let res = _entities;
  // if include is defined, reset to only include those
  if (include) {
    res = expandCategoryEntityTypeSelectors(include);
  }
  // if exclude, remove those from the list
  if (exclude) {
    const _exclude = expandCategoryEntityTypeSelectors(exclude);
    res = res.filter(type => _exclude.indexOf(type) === -1);
  }

  return res;
}

/**
 * Get a list of valid modules that can be placed on a page
 * The allowed modules are configured via the api and loaded
 * with the configuration on startup of the app
 *
 * DK NOTE: 1xInternet used the getValidEntityTypes function to filter
 * valid modules. This function extends the getValidEntityTypes
 * function and filters after getValidEntityTypes based on the allowed
 * modules from the api. This function needs access to the Store
 *
 * DK NOTE: Should be a pure function. Try to get the allowedModules
 * via argument instead from the store here.
 *
 * @param options
 */
export function getAllowedModules(options: {
  typesName?: 'templates' | 'definitions';
  exclude?: string[];
  include?: string[];
}): string[] {
  const modules = getValidEntityTypes(options);
  const allowedModules = selectAddonsParagraphModules(getStore().getState());

  return modules.filter(f => allowedModules.includes(f.replace('paragraph--', '')));
}

/**
 * Get a template for a specific entity type
 *
 * @param {string} type
 */
export function getEntityTemplate(type: string, options?): IJsonApiResponseSingle {
  const template = entityTemplates[type](options);
  if (!template) {
    log(`Entity template for ${type} is not defined!`, null, 'error');
  }

  return _merge({}, template);
}

/**
 * Get language code from store for the entity templates
 * @return {string} language code
 */
export function getEntityTemplateLang() {
  return selectContextCurrentAppLanguage(getStore().getState());
}
