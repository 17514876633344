import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => {
  const mainColorId = createTemporaryEntityId();
  const shadeId = createTemporaryEntityId();
  return {
    data: {
      type: 'paragraph--palette_item',
      id: createTemporaryEntityId(),
      attributes: {
        langcode: getEntityTemplateLang(),
        status: true,
        default_langcode: true,
        field_search_text: null,
      },
      relationships: {
        field_main_color: {
          data: {
            type: 'paragraph--color',
            id: mainColorId,
          },
        },
        field_shades: {
          data: [
            {
              type: 'paragraph--color',
              id: shadeId,
            },
          ],
        },
        field_tags: {
          data: [],
        },
      },
      links: {},
      permissions: {
        update: true,
        create: true,
        delete: true,
      },
    },
    included: [
      {
        type: 'paragraph--color',
        id: mainColorId,
        attributes: {
          langcode: getEntityTemplateLang(),
          status: true,
          default_langcode: true,
          field_cmyk: null,
          field_hex: '#999999',
          field_light_color: null,
          field_ncs: null,
          field_pantone: null,
          field_ral: null,
          field_rgb: null,
          field_scss_less: null,
          field_search_text: null,
          field_title: { value: 'I am a Main Color', format: 'rich_text' },
        },
        relationships: {
          field_tags: {
            data: [],
          },
        },
        links: {},
        permissions: {
          update: true,
          create: true,
          delete: true,
        },
      },
      {
        type: 'paragraph--color',
        id: shadeId,
        attributes: {
          langcode: getEntityTemplateLang(),
          status: true,
          default_langcode: true,
          field_cmyk: null,
          field_hex: '#bbbbbb',
          field_light_color: null,
          field_ncs: null,
          field_pantone: null,
          field_ral: null,
          field_rgb: null,
          field_scss_less: null,
          field_search_text: null,
          field_title: { value: 'I am a Shade', format: 'rich_text' },
        },
        relationships: {
          field_tags: {
            data: [],
          },
        },
        links: {},
        permissions: {
          update: true,
          create: true,
          delete: true,
        },
      },
    ],
  };
};
