import type { TypedStartListening } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '$store';
import { SessionActions } from './actions';
import { selectSessionState } from './selectors';

export const PersistenceMiddleware = createListenerMiddleware();
const startListening = PersistenceMiddleware.startListening as TypedStartListening<RootState, AppDispatch>;

const STORAGE_KEY = 'ebm.session';

startListening({
  predicate: action =>
    [
      SessionActions.login.type,
      SessionActions.logout.type,
      SessionActions.setEditing.type,
      SessionActions.enableEditing.type,
      SessionActions.disableEditing.type,
    ].includes(action.type),
  effect: (_action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    const session = selectSessionState(listenerApi.getState());
    localStorage.setItem(STORAGE_KEY, JSON.stringify(session));
  },
});

export const readStorage = () => {
  const raw = localStorage.getItem(STORAGE_KEY) || '{}';
  return JSON.parse(raw) as Partial<Session>;
};
