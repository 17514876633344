import { URL_REPLACEMENT } from '$const';
import { get, post, remove } from '~services/api/methods';

// Upload - Get ticket
export const getUploadTicket = async () => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/uploads/ticket`;
  return await get(requestUrl, { useBearerToken: true });
};

// Upload - Send file
export const sendFile = async (file: File, uploadUrl: string, progress: (progress: number) => void) => {
  const formData = new FormData();
  formData.append('data', file[0]);
  const onUploadProgress = progressEvent => {
    const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total); // do whatever you like with the percentage complete
    progress(percentCompleted);
  };
  return await post(uploadUrl, formData, { onUploadProgress });
};

// Upload - Commit
export const commitUpload = async (uploadTicket: string, fileName: string, type: string) => {
  const formData = new FormData();
  formData.append('upload_ticket', uploadTicket);
  formData.append('file_name', fileName);

  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/uploads/commit/${type}`;
  return await post(requestUrl, formData);
};

// Upload - Delete file
export const deleteFile = async (assetId, uploadTicket, type) => {
  const formData = new URLSearchParams();
  formData.append('upload_ticket', uploadTicket);

  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/uploads/${type}/${assetId}?${formData.toString()}`;
  return await remove(requestUrl, { useBearerToken: true });
};

// download file
export const downloadFile = async (order_id, hash) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/downloads/${order_id}/${hash}`;
  return await get(requestUrl, { useBearerToken: true });
};

// download template overview
export const downloadTemplateOverview = async (queryString: string) => {
  const requestUrl = `${URL_REPLACEMENT.SYMFONY_URL}/truck_labeling/templates/export?${queryString}`;
  return await get(requestUrl, { useBearerToken: true, withResponseType: true });
};
