import logger from '@redux-beacon/logger';
import TagManager from 'react-gtm-module';
import { createMiddleware, Target } from 'redux-beacon';

import getStore from '$store';
import { selectUserTrackingData } from '~store/selectors';
import { eventsMap } from './google-analytics-gtag';

let userParamsSet = false;

function GoogleTagManager(defaultTrackingId: string): Target {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }

  const tagManagerArgs = {
    gtmId: defaultTrackingId,
  };

  TagManager.initialize(tagManagerArgs);

  return function target(events) {
    // const pageTracking = events.filter(event => event.type === 'page');
    const eventTracking = events.filter(event => event.type === 'event');

    // To track user params with GTM we need to push these variables only once
    // to dataLayer. Then in GTM:
    // 1. Create user-defined variables of type "Data Layer": "userId", "user_country",
    //    "user_company", "user_division", "user_roles", "module"
    // 2. In variable "Google Analytics Settings" under "More Settings" and "Fields to Set"
    //    add new field with: Field Name "userId" and Value "{{userId}}"
    // 3. In variable "Google Analytics Settings" under "More Settings" add Custom Dimensions:
    //    for each rest vars from point 1 set index 1..5 (as in Google Analytics) and select
    //    corresponding variable
    if (!userParamsSet) {
      // additional user info
      const trackingUserParams = selectUserTrackingData(getStore().getState());

      TagManager.dataLayer({
        dataLayer: trackingUserParams,
      });

      // This should happen only once.
      // We cannot set these vars in TagManager.initialize, because at that point
      // user context and store are not set yet.
      userParamsSet = true;
    }

    // Page views in GTM are tracked not by call of gtag method or pushing to dataLayer,
    // but by one of following events:
    // - Page View - fires immediately when the web browser begins to load a page
    // - browser DOM Ready
    // - browser Window Loaded
    // To track page views in GTM:
    // 1. Create variable of type "Google Analytics Settings" and Tracking ID <your GA id>, cookie domain: "auto"
    // 2. Create a tag of type "Google Analytics: Universal Analytics",
    //    track type "Page View", Google Analytics Settings: {{Google Analytics Settings}},
    //    Triggering: "Page View|DOM Ready|Window Loaded"
    // 3. For single-page applications we need additional trigger:
    //    Create a trigger of type "History Change", that fires only on "Some History Changes"
    //    "Page Path" "start with" "/brandguide"
    // 4. Add the trigger from point 3 to the tag from point 2
    //pageTracking.forEach(event => {
    //  const { type, trackingId, ...params } = event;
    //  TagManager.dataLayer({
    //    dataLayer: params
    //  });
    //});

    // Unlike GA/GTAG to track custom events with GTM we have to do couple of additional settings in GTM:
    // 1. Create user-defined variables of type "Data Layer": "event_category", "event_label", "value"
    // 2. For each event to track create a trigger of type "Custom Event",
    //    event name as the event name in code, trigger on "All Custom Events'
    // 3. Create a new tag of type "Google Analytics: Universal Analytics", track type "Event",
    //    Category: {{event_category}}, Action: {{Event}}, Label: {{event_label}}, Value: {{value}}
    //    Google Analytics Settings: <use already existing var>, Triggering: <all events from step 2>
    eventTracking.forEach(event => {
      // GTM requires in dataLayer following keys: event, event_category, event_label, value
      // thanks to using eventsMap (and thus events-helper.ts from google-analytics-gtag)
      // we only need to rename action -> event
      const { action, ...params } = event;
      const tagManagerEventArgs = {
        event: action,
        ...params,
      };
      TagManager.dataLayer({
        dataLayer: tagManagerEventArgs,
      });
    });
  };
}

const createGoogleTagManager = (gtmId: string) => {
  if (gtmId) {
    // GTM events map is identical with map for GTAG
    return createMiddleware(eventsMap, GoogleTagManager(gtmId), {
      logger,
    });
  }
  return false;
};

export default createGoogleTagManager;
