import { useMemo } from 'react';

import { ellipsisifyRight } from '~utils/ellipsisify';

export type FileNameProps = {
  filename: string;
  characters?: number;
};

export const FileName = ({ filename, characters = 50 }: FileNameProps) => {
  const [name, type] = useMemo(() => {
    const fileNameRegex = /^(.*)\.(\w*)$/;
    const fileNameMatch = fileNameRegex.exec(filename);
    const shortenedFileName = ellipsisifyRight(fileNameMatch[1], characters);
    return [shortenedFileName, fileNameMatch[2]];
  }, [filename, characters]);

  return (
    <div className="c-filename" title={filename}>
      <span className="c-filename__name">{name}</span>
      <span className="c-filename__type">.{type}</span>
    </div>
  );
};

export default FileName;
