import { actions as AddonsActions } from './slices/addons';
import { actions as ConfigActions } from './slices/config';
import { actions as ContextActions } from './slices/context';
import { actions as ControlActions } from './slices/control';
import { actions as CookieModalActions } from './slices/cookieModal';
import { actions as DraftEntitiesActions } from './slices/draft-entities';
import { actions as EntitiesActions } from './slices/entities';
import { actions as LoginActions } from './slices/login';
import { actions as MenuActions } from './slices/menu';
import { actions as ModalActions } from './slices/modal';
import { actions as RouterActions } from './slices/router';
import { actions as SessionActions } from './slices/session';
import { actions as StatusActions } from './slices/status';
import { actions as TranslationsActions } from './slices/translations';
import { actions as UserActions } from './slices/user';
import { actions as WidgetsFooterActions } from './slices/widgetsFooter';
import { actions as WidgetsSidebarActions } from './slices/widgetsSidebar';

export { actions as AddonsActions } from './slices/addons';
export { actions as ConfigActions } from './slices/config';
export { actions as ContextActions } from './slices/context';
export { actions as ControlActions } from './slices/control';
export { actions as CookieModalActions } from './slices/cookieModal';
export { actions as DraftEntitiesActions } from './slices/draft-entities';
export { actions as EntitiesActions } from './slices/entities';
export { actions as LoginActions } from './slices/login';
export { actions as MenuActions } from './slices/menu';
export { actions as ModalActions } from './slices/modal';
export { actions as RouterActions } from './slices/router';
export { actions as SessionActions } from './slices/session';
export { actions as StatusActions } from './slices/status';
export { actions as TranslationsActions } from './slices/translations';
export { actions as UserActions } from './slices/user';
export { actions as WidgetsFooterActions } from './slices/widgetsFooter';
export { actions as WidgetsSidebarActions } from './slices/widgetsSidebar';

export const Actions = {
  addons: AddonsActions,
  config: ConfigActions,
  context: ContextActions,
  control: ControlActions,
  cookieModal: CookieModalActions,
  draftEntities: DraftEntitiesActions,
  entities: EntitiesActions,
  login: LoginActions,
  menu: MenuActions,
  modal: ModalActions,
  router: RouterActions,
  session: SessionActions,
  status: StatusActions,
  user: UserActions,
  widgetsFooter: WidgetsFooterActions,
  widgetsSidebar: WidgetsSidebarActions,
  translations: TranslationsActions,
};

export default Actions;
