import type { ThunkCreator } from '$store';
import { getRelatedEntityIds } from '~services/entity-helpers';
import log from '~services/log';
import { ControlActions } from '~store/actions';
import { selectEntityWithChangesById } from '~store/selectors/custom';
import {
  activateWorkableEntity,
  changeWorkableEntity,
  createTemporaryEntity,
  deactivateWorkableEntity,
  deleteEntity,
  saveEntityWorker,
} from '~store/thunks/entity';
import { moveModule } from '~store/thunks/module';

// returns a child entity id, in cases where only an index was specified
const getChildEntityId: ThunkCreator<string | false> = (payload: ChildAddress) => (dispatch, getState) => {
  if (payload.childId) {
    return payload.childId;
  }
  const entity = selectEntityWithChangesById(getState(), payload.id);
  const relIds: string[] = getRelatedEntityIds(entity, payload.fieldName);
  return relIds[payload.childIndex] || false;
};

export const addChild: ThunkCreator = (payload: AddChildPayload) => dispatch => {
  try {
    const { id, index, fieldName, templateType, activateWorkable } = payload;
    const tempId = dispatch(createTemporaryEntity({ type: templateType }));

    if (activateWorkable) {
      dispatch(activateWorkableEntity(tempId));
    }

    // move it into position (onto the end, if no index is specified)
    dispatch(
      moveModule({
        parentContext: id,
        id: tempId,
        targetIndex: index,
        fieldName,
      }),
    );
    // cache the temporary reference
    dispatch(
      ControlActions.setTemporaryEntityReference({
        parentId: id,
        fieldName,
        tempId,
      }),
    );
  } catch (e) {
    log('Error in addChild thunk!', e, 'error');
  }
};

export const deleteChild: ThunkCreator = (payload: DeleteChildPayload) => async dispatch => {
  try {
    const childId = dispatch(getChildEntityId(payload));
    if (!childId) {
      return;
    }
    await dispatch(
      deleteEntity({
        id: childId,
        parentContext: payload.id,
        fieldName: payload.fieldName,
        dontShowAlert: payload.dontShowAlert,
        dontClearContext: payload.dontClearContext,
      }),
    );
  } catch (e) {
    log('Error in deleteChild thunk!', e, 'error');
  }
};

export const cancelChild: ThunkCreator = (payload: CancelChildPayload) => dispatch => {
  try {
    const childId = dispatch(getChildEntityId(payload));
    if (childId) {
      dispatch(deactivateWorkableEntity(childId));
    }
  } catch (e) {
    log('Error in cancelChild thunk', e, 'error');
  }
};

export const saveChild: ThunkCreator = (payload: SaveChildPayload) => dispatch => {
  try {
    const childId = dispatch(getChildEntityId(payload));
    if (childId) {
      dispatch(
        saveEntityWorker({
          entityId: childId,
        }),
      );
    }
  } catch (e) {
    log('Error in saveChild thunk!', e, 'error');
  }
};

export const changeChild: ThunkCreator = (payload: ChangeChildPayload) => dispatch => {
  try {
    const childId = dispatch(getChildEntityId(payload));
    if (childId) {
      dispatch(
        changeWorkableEntity({
          id: childId,
          changeSet: payload.payload,
        }),
      );
    }
  } catch (e) {
    log('Error in changeChild thunk!', e, 'error');
  }
};
