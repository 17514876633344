import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// State
export type UserState = User;

// Initial State
export const initialState: User = {
  country: undefined,
  currentLanguage: undefined,
  email: undefined,
  firstName: undefined,
  id: undefined,
  isValid: true,
  lastName: undefined,
  permissions: {
    manageMenuElements: false,
    manageMenuGrants: false,
    manageUsergroups: false,
    publish: false,
    rootAccess: false,
    toggleEditmode: false,
    viewChangelog: false,
    viewMyFiles: false,
    manageChangelog: false,
    sendChangelogNewsletter: false,
    manageTruckLabelingOrders: false,
    viewTruckLabelingOrders: false,
    approveNewTruckLabelingOrders: false,
    passMaintenanceMode: false,
  },
  roles: [],
  siteAccesses: [],
  tracking: {
    company: undefined,
    division: undefined,
    name: undefined,
    roles: undefined,
  },
  uuid: undefined,
};

// Reducers
const set: CaseReducer<UserState, PayloadAction<UserInfoDto>> = (state, action) => {
  if (action.payload) {
    const dto = action.payload;
    const roles = dto.roles || [];
    const hasAdminRole = roles.includes('administrator');
    const hasEditorRole = roles.includes('editor');
    const hasPublisherRole = roles.includes('publisher');

    state.country = dto.country;
    state.currentLanguage = `${dto.current_language}`;
    state.email = dto.name; // email === username
    state.firstName = dto.first_name;
    state.id = dto.uid;
    state.isValid = Boolean(dto.user_valid ?? true);
    state.lastName = dto.last_name;
    state.permissions.manageMenuElements =
      hasAdminRole || hasEditorRole || dto.permissions?.manage_menu_elements || false;
    state.permissions.manageMenuGrants = hasAdminRole || dto.permissions?.manage_menu_grants || false;
    state.permissions.manageUsergroups = hasAdminRole || dto.permissions?.manage_user_groups || false;
    state.permissions.publish = hasAdminRole || hasPublisherRole;
    state.permissions.rootAccess = hasAdminRole || hasEditorRole;
    state.permissions.toggleEditmode = hasAdminRole || hasEditorRole || dto.permissions?.toggle_editmode || false;
    state.permissions.viewChangelog = dto.permissions?.view_changelog || false;
    state.permissions.viewMyFiles = dto.permissions?.view_myfiles || false;
    state.permissions.manageChangelog = dto.permissions?.manage_changelog || false;
    state.permissions.sendChangelogNewsletter = dto.permissions?.send_changelog_newsletter || false;
    state.permissions.manageTruckLabelingOrders = dto.permissions?.manage_truck_labeling_orders || false;
    state.permissions.viewTruckLabelingOrders = dto.permissions?.view_truck_labeling_orders || false;
    state.permissions.approveNewTruckLabelingOrders = dto.permissions?.approve_new_truck_labeling_orders || false;
    state.permissions.passMaintenanceMode = dto.permissions?.pass_maintenance_mode || false;
    state.roles = roles;
    state.siteAccesses = dto.siteaccesses || [];
    state.tracking.company = dto.tracking_company;
    state.tracking.division = dto.tracking_division;
    state.tracking.name = dto.tracking_name;
    state.tracking.name = dto.tracking_name;
    state.tracking.roles = dto.tracking_roles;
    state.uuid = dto.uuid;
  }
};

// Slice
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set,
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: UserState) => state;
export const selectors = {
  self: selectSelf,
  isSet: (state: UserState) => !isNaN(state.id),
  country: (state: UserState) => state.country,
  currentLanguage: (state: UserState) => state.currentLanguage,
  email: (state: UserState) => state.email,
  firstName: (state: UserState) => state.firstName,
  id: (state: UserState) => state.id,
  isValid: (state: UserState) => state.isValid,
  lastName: (state: UserState) => state.lastName,
  roles: (state: UserState) => state.roles,
  tracking: (state: UserState) => state.tracking,
  trackingCompany: (state: UserState) => state.tracking.company,
  trackingDivision: (state: UserState) => state.tracking.division,
  trackingName: (state: UserState) => state.tracking.name,
  trackingRoles: (state: UserState) => state.tracking.roles,
  uuid: (state: UserState) => state.uuid,
  permissions: (state: UserState) => state.permissions,
  siteAccesses: (state: UserState) => state.siteAccesses,
};
