import clsx from 'clsx';
import { EditorState, EntityInstance, RichUtils, SelectionState } from 'draft-js';
import { PureComponent, ReactNode } from 'react';

import { AnchorLinkSelectorContainer } from '~components/generic/elements/AnchorLinkSelector/AnchorLinkSelectorContainer';
import Button from '~components/generic/elements/Button';
import { Input } from '~components/generic/elements/Input/Input';
import { getModuleUrlIdFromData } from '~services/location-helpers';
import { getTextLinkDataFromUrl, getUrlFromTextLinkData, ITextLinkData } from '~services/text-editor-utils';
import cssStyles from './TextLinkWidget.css';

export interface ITextLinkWidgetProps {
  getEditorState: () => EditorState;
  setEditorState: (e: EditorState) => void;
  onOverrideContent: (x: ReactNode) => void;
}

interface ITextLinkWidgetState extends ITextLinkData {
  currentVal?: string;
  useExternal?: boolean;
  url?: string;
  id?: string;
  type?: string;
}

export class TextLinkWidget extends PureComponent<ITextLinkWidgetProps, ITextLinkWidgetState> {
  origData: string;

  constructor(props: ITextLinkWidgetProps) {
    super(props);

    this.state = {
      // url is the external url
      url: null,
      // id and type are decoded from the entity identifier (currentVal)
      id: null,
      type: null,
      // currentVal is the string value of the in page entity selector
      currentVal: null,
      // whether to switch to the external input
      useExternal: false,
    };
  }

  get selection(): SelectionState {
    return this.editorState.getSelection();
  }

  get editorState(): EditorState {
    const { getEditorState } = this.props;
    return getEditorState();
  }

  set editorState(editorState: EditorState) {
    const { setEditorState } = this.props;
    setEditorState(editorState);
  }

  get currentLink(): EntityInstance | void {
    if (this.selection.isCollapsed()) {
      return null;
    }
    const contentState = this.editorState.getCurrentContent();
    const startKey = this.selection.getStartKey();
    const startOffset = this.selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    if (!linkKey) {
      return null;
    }
    return contentState.getEntity(linkKey);
  }

  componentDidMount() {
    setTimeout(() => this.reposition());
    // if we're currently not selecting a link there is nothing else to do
    if (!this.currentLink) {
      return;
    }
    const currentLink = this.currentLink;
    const data = currentLink.getData();
    const { url } = data;
    if (typeof url === 'string') {
      // cache original data
      this.origData = url;
      const dataFromUrl = getTextLinkDataFromUrl(url);
      // if this is an internal link, also apply the correct currentVal
      const currentVal = dataFromUrl.id ? getModuleUrlIdFromData(dataFromUrl.id, dataFromUrl.type) : undefined;
      this.setState({
        useExternal: !dataFromUrl.id,
        ...dataFromUrl,
        currentVal,
      });
    }
  }

  /**
   * Apply the URL as a link to the current selection
   *
   * @param url
   */
  private applyLinkChanges(url: string): void {
    const contentState = this.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(this.editorState, {
      currentContent: contentStateWithEntity,
    });
    this.editorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
  }

  /**
   * Remove the currently selected link
   */
  private removeLink(): void {
    if (!this.selection.isCollapsed()) {
      this.editorState = RichUtils.toggleLink(this.editorState, this.selection, null);
    }
  }

  /**
   * Repositions the widget by reselecting the current text selection
   */
  private reposition() {
    // fake a selection change to trigger the repositioning of the inline
    // toolbar
    this.editorState = EditorState.forceSelection(this.editorState, this.selection);
  }

  handleDelete = () => {
    const { onOverrideContent } = this.props;
    this.removeLink();
    onOverrideContent(undefined);
  };

  handleSave = () => {
    const { onOverrideContent } = this.props;
    const urlFromData = getUrlFromTextLinkData(this.state);
    this.applyLinkChanges(urlFromData);
    onOverrideContent(undefined);
  };

  handleCancel = () => {
    const { onOverrideContent } = this.props;
    // this.applyLinkChanges(this.origData);
    onOverrideContent(undefined);
  };

  handleExternalChange = (val: string) => {
    this.setState({
      url: val,
    });
  };

  handleInternalChange = (id: string, type?: string) => {
    this.setState(
      {
        id,
        type,
        currentVal: getUrlFromTextLinkData({ id, type }),
        // for some reason the selection is broken at this point
      },
      () => this.reposition(),
    );
  };

  handleUseInternal = () => {
    this.setState(
      {
        useExternal: false,
      },
      () => this.reposition(),
    );
  };

  handleUseExternal = () => {
    this.setState(
      {
        useExternal: true,
      },
      () => this.reposition(),
    );
  };

  render() {
    const { currentVal, url, useExternal } = this.state;
    return (
      <div className={clsx(cssStyles.widget, 'u-padding-vertical-md', 'u-padding-horizontal-md')}>
        {useExternal ? (
          <span>
            <Input
              label="Link target (external)"
              type="text"
              value={url}
              onChange={this.handleExternalChange}
              className="u-block u-full-width"
              placeholder="https://google.de"
            />
            <Button
              className="u-size-xs c-button c-button--lightest"
              onClick={this.handleUseInternal}
              label="Use internal link target"
            />
          </span>
        ) : (
          <span>
            <AnchorLinkSelectorContainer
              label="Link target (internal)"
              onSelectedValueChange={this.handleInternalChange}
              value={currentVal}
              theme="light"
            />
            <Button
              className="u-size-xs c-button c-button--lightest"
              onClick={this.handleUseExternal}
              label="Use external link target"
            />
          </span>
        )}

        <div className="o-grid o-grid--unpad u-margin-top-lg">
          <div className="o-grid__cell">
            <Button
              iconName="trash"
              className="c-button c-button--lightest u-margin-right-md"
              onClick={this.handleDelete}
            />
          </div>
          <div className="o-grid__cell o-text--right">
            <Button
              label="Cancel"
              className="c-button c-button--lightest u-margin-right-md"
              onClick={this.handleCancel}
            />
            <Button label="Save" className="c-button" onClick={this.handleSave} />
          </div>
        </div>
      </div>
    );
  }
}

export default TextLinkWidget;
