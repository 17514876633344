import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'node--page',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      title: 'Empty page',
      promote: true,
      sticky: false,
      default_langcode: true,
    },
    relationships: {
      field_modules: {
        data: [],
      },
    },
    permissions: {
      update: true,
      create: true,
      delete: true,
    },
  },
});
