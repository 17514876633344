import clsx from 'clsx';
import { ChangeEvent, PureComponent } from 'react';

import { IValueOptionsShape } from '~services/entity-definitions/interface';

export interface ISelectProps {
  name?: string;
  value: string | number;
  defaultValue?: string | number | boolean;
  label?: string;
  values: IValueOptionsShape;
  className?: string;
  coerceNumber?: boolean;
  onChange: (value: string | number) => void;
  theme?: 'light' | 'dark';
}

export class Select extends PureComponent<ISelectProps> {
  handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { coerceNumber, onChange } = this.props;
    const val = coerceNumber ? parseInt(e.target.value, 10) : e.target.value;
    onChange(val);
  };

  render() {
    const { name, values, value, defaultValue, className, label } = this.props;
    const activeVal = value == null ? defaultValue?.toString() : value;

    return (
      <div className="c-form-element c-form-element--type-select">
        <div className="o-input o-input--select">
          {label && (
            <label className="c-label" htmlFor={name}>
              {label}
            </label>
          )}
          <select
            className={clsx(
              {
                'c-input': true,
                'c-input--select': true,
              },
              className,
            )}
            value={activeVal}
            onChange={this.handleChange}
          >
            {values.map((opt, i) => (
              <option key={i} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default Select;
