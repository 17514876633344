export type ButtonLanguageProps = {
  currentLanguage: string;
  languages: Language[];
};

export const ButtonLanguage = ({ currentLanguage, languages }: ButtonLanguageProps) => {
  if (languages.length === 1) {
    return null;
  }

  const langToChoose = languages.filter(lang => {
    return lang.langcode !== currentLanguage;
  });

  // TODO: If more than two languages are available it should render a
  // language chooser button or something like this -> for now it will
  // show just one of the not active languages as a text button
  const lang = langToChoose[0];

  return (
    <a key={`lang_key_item_${lang.name}`} href={`/${lang.prefix}brandguide`}>
      {lang.name}
    </a>
  );
};
