import { array, Describe, is, string, type } from 'superstruct';

export const SidebarWidgetValidator: Describe<WidgetSidebar> = type({
  data: string(),
  type: string(),
});
export const isSidebarWidget = (obj: unknown) => is(obj, SidebarWidgetValidator);

export const WidgetsSidebarValidator: Describe<WidgetsSidebarDto> = array(SidebarWidgetValidator);
export const isWidgetsSidebar = (obj: unknown) => is(obj, WidgetsSidebarValidator);
