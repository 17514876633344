import clsx from 'clsx';
import type { ChangeEvent, FocusEvent, ForwardedRef } from 'react';
import { forwardRef, useCallback } from 'react';

import useDebouncedCallback from '~hooks/useDebouncedCallback';
import styles from './UserInput.module.css';

type InputTextProps = {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onDebouncedChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  hasChange?: boolean;
};

export const InputText = forwardRef(function InputText(
  { defaultValue, label, placeholder, onChange, onBlur, onDebouncedChange, hasChange = false }: InputTextProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onBlur?.(event.target.value);
    },
    [onBlur],
  );

  const handleChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
    onDebouncedChange?.(event.target.value);
  }, 300);

  return (
    <label className="inline-block w-full text-left font-sans bg-gray rounded shadow px-3 py-2 relative">
      {Boolean(label) && <span className="text-xs opacity-70">{label}</span>}
      <input
        className={clsx(
          styles.textinput,
          'block appearance-none bg-transparent border-none outline-none text-base w-full font-sans p-0 m-0',
        )}
        defaultValue={defaultValue}
        placeholder={placeholder}
        ref={ref}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
      />
      <span
        className={clsx(
          'absolute rounded-full bg-red bottom-full left-full shadow-sm transition-all -mb-1 -ml-1 duration-100',
          hasChange ? 'w-2 h-2' : 'w-0 h-0',
        )}
      />
    </label>
  );
});
