import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createTemplate,
  getMatchingTemplates,
  getTemplateByName,
  getTemplates,
  invalidateTemplate,
  searchTemplates,
  setTemplate,
  validateTemplate,
} from '~extension/truck-labeling/api/templates';
import log from '~services/log';

// create template
export const uploadTemplate = createAsyncThunk('upload/template', async (data: any) => {
  try {
    const res = await createTemplate(data);
    return res;
  } catch (err) {
    return err;
  }
});

export const selectTemplate = createAsyncThunk('orders/select', async (data: any) => {
  try {
    const res = await setTemplate(data.order_id, data.asset_id);
    return res;
  } catch (e) {
    log('Error in selecting order thunk!', e, 'error');
  }
});

export const invalidateTheTemplate = createAsyncThunk('templates/invalidate', async (asset_id: any) => {
  try {
    const res = await invalidateTemplate(asset_id);
    return res;
  } catch (e) {
    log('Error in invalidating template thunk!', e, 'error');
  }
});

export const validateTheTemplate = createAsyncThunk('templates/invalidate', async (asset_id: any) => {
  try {
    const res = await validateTemplate(asset_id);
    return res;
  } catch (e) {
    log('Error in invalidating template thunk!', e, 'error');
  }
});

// fetch matching templates for selected order
export const fetchMatchingTemplates = createAsyncThunk('matching/templates/fetch', async (id: string) => {
  try {
    const res = await getMatchingTemplates(id);
    return res;
  } catch (e) {
    log('Error in fetch matching templates thunk!', e, 'error');
  }
});

// fetch matching templates for selected order
export const fetchTemplates = createAsyncThunk('templates/fetch', async () => {
  try {
    const res = await getTemplates();
    return res;
  } catch (e) {
    log('Error in fetch matching templates thunk!', e, 'error');
  }
});

// get template.ts by name
export const fetchTemplateByName = createAsyncThunk('searchTemplates/fetch', async (name: string) => {
  try {
    const res = await getTemplateByName(name);
    return res;
  } catch (e) {
    log('Error in fetch templates!', e, 'error');
  }
});

// filter templates
export const filterTemplates = createAsyncThunk('filterTemplates/fetch', async (queryString: any) => {
  try {
    const res = await searchTemplates(queryString);
    return res;
  } catch (e) {
    log('Error in fetch templates!', e, 'error');
  }
});

// const updateShowUploadTemplateForm = createAsyncThunk(
//   'set/show-upload-template-form',
//   async (showUploadTemplateForm, thunkAPI) => {
//     const { dispatch, getState } = thunkAPI;
//
//     dispatch(setShowUploadTemplateForm(showUploadTemplateForm));
//   },
// );
//
// const updateShowAlert = createAsyncThunk('set/show-upload-template-form', async (showAlert, thunkAPI) => {
//   const { dispatch } = thunkAPI;
//
//   dispatch(setShowAlert(showAlert));
// });

// // Create the async thunk to update selected order id
// export const updateSelectedOrderAsync = createAsyncThunk('selected/orderId', async (orderId, thunkAPI) => {
//   const { dispatch, getState } = thunkAPI;
//
//   dispatch(updateSelectedOrder(orderId));
// });

const Templates = createSlice({
  name: 'config',
  initialState: {
    totalCount: null,
    templateList: [],
    matchingTemplateList: [],
    selectedOrderId: null,
    selectedAssetId: null,
    uploadTemplateSuccess: '',
    uploadTemplateFail: '',
    showUploadTemplateForm: false,
    showCreateOrderForm: false,
    showTemplateOverview: false,
    loading: false,
    showAlert: false,
  },
  reducers: {
    updateTemplateList: (state, action) => {
      state.matchingTemplateList = action.payload;
    },
    updateSelectedOrder: (state, action) => {
      state.selectedOrderId = action.payload;
    },

    updateSelectedAssetId: (state, action) => {
      state.selectedAssetId = action.payload;
    },

    setShowAlert: (state, action) => {
      state.showAlert = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(uploadTemplate.rejected, (state, action: any) => {
      state.uploadTemplateFail = action.payload;
    });

    builder.addCase(uploadTemplate.fulfilled, (state, action: any) => {
      if (action?.payload?.error) {
        state.uploadTemplateFail = action.payload.error;
        return;
      }

      if (action.payload.startsWith('Saved')) {
        state.uploadTemplateSuccess = action?.payload;
        state.showAlert = true;
        state.uploadTemplateFail = '';
      }
    });
    builder.addCase(fetchTemplates.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchMatchingTemplates.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchTemplates.fulfilled, (state, action: any) => {
      state.loading = false;
      state.templateList = action?.payload.template_list;
    });
    builder.addCase(fetchMatchingTemplates.fulfilled, (state, action: any) => {
      state.loading = false;
      state.totalCount = action?.payload?.total_count;
      state.matchingTemplateList = action?.payload?.template_list;
    });
    builder.addCase(fetchTemplateByName.fulfilled, (state, action: any) => {
      state.totalCount = action?.payload?.total_count;
      state.matchingTemplateList = action?.payload;
    });
    builder.addCase(filterTemplates.fulfilled, (state, action: any) => {
      state.totalCount = action?.payload?.total_count;
      state.templateList = action?.payload?.template_list;
    });
  },
});

export const { updateSelectedOrder, setShowAlert, updateSelectedAssetId, updateTemplateList } = Templates.actions;

export default Templates.reducer;
