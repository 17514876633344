import { filterUndefined } from '~utils/array';

/**
 * Tells whether something is an object or not.
 * @param item Item to test
 */
export function isObject(item: unknown): item is Record<string, unknown> {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Checks if an object has given attribute.
 * @param obj The object to check
 * @param prop The attribute to look for
 */
export function hasOwnProperty<X extends object, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, any> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Checks if the object has any own properties.
 * @param obj The object to check
 */
export function isEmpty(obj: unknown) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Creates a clone
 * @param obj Object to clone
 */
export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Removes all properties from an object that are undefined.
 * @param obj Object
 */
export function removeUndefined<T>(obj: T) {
  const newObj: T = {} as T;
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      newObj[key] = filterUndefined(obj[key]);
    } else if (obj[key] === Object(obj[key])) {
      newObj[key] = removeUndefined(obj[key]);
    } else if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}
