import { createAsyncThunk } from '@reduxjs/toolkit';

import { ModuleTypes } from '$const';
import type { RootState, ThunkCreator } from '$store';
import changeSlug from '~services/change-slug';
import { isAnchorEntity, isModuleEntity, makeSlugForAnchorEntity } from '~services/entity-helpers';
import { getModuleUrlIdFromData } from '~services/location-helpers';
import log from '~services/log';
import { ControlActions, MenuActions, RouterActions, SessionActions, StatusActions } from '~store/actions';
import {
  selectAliasFromPath,
  selectCurrentPageId,
  selectEntityById,
  selectEntityType,
  selectLocationPageAlias,
  selectLocationPath,
  selectLocationSlug,
  selectLocationState,
  selectMenuStartPageAlias,
  selectUrlLanguagePrefix,
} from '~store/selectors';
import { selectMenuElementById, selectUnfilteredMenuElementById } from '~store/selectors/menu';
import { selectSessionName } from '~store/selectors/session';
import type { Action, Payload } from '~store/slices/router';
import { isLessThanMedium } from '~utils/browser';
import delay from '~utils/delay';
import { authCheck } from './auth';
import { fetchPage } from './page';

export const navigateToStart = createAsyncThunk<void, void, { state: RootState }>(
  'navigation/to/start',
  (_, { dispatch, getState }) => {
    const location = selectLocationState(getState());
    const lang = selectUrlLanguagePrefix(getState());

    if (location.type !== RouterActions.navigateToDefault.type) {
      if (location.type === RouterActions.navigateStart.type) {
        dispatch(navigateToDefault());
      } else if (location.payload.lang !== lang) {
        dispatch(navigateToSame(location.type, location.payload));
      }
      return;
    }

    const startPageAlias = selectMenuStartPageAlias(getState());
    if (!startPageAlias) {
      return;
    }
    dispatch(navigateToPage(startPageAlias));
  },
);

function makeRedirectAction(action: Action) {
  return RouterActions.redirect(action);
}

export const navigateToDefault: ThunkCreator = () => (dispatch, getState) => {
  const lang = selectUrlLanguagePrefix(getState());
  dispatch(makeRedirectAction(RouterActions.navigateToDefault({ lang })));
};

export const navigateToLogin: ThunkCreator = () => (dispatch, getState) => {
  const lang = selectUrlLanguagePrefix(getState());
  dispatch(makeRedirectAction(RouterActions.navigateToLogin({ lang })));
};

export const navigateToPage: ThunkCreator = (pageAlias: string) => (dispatch, getState) => {
  const lang = selectUrlLanguagePrefix(getState());
  dispatch(makeRedirectAction(RouterActions.navigateToPage({ pageAlias, lang })));
};

export const navigateToPageWithAnchor: ThunkCreator = (pageAlias: string, slug: string) => (dispatch, getState) => {
  const lang = selectUrlLanguagePrefix(getState());
  dispatch(makeRedirectAction(RouterActions.navigateToPageWithAnchor({ pageAlias, slug, lang })));
};

export const navigateToPageWithAsset: ThunkCreator = (pageAlias: string, assetId: string) => (dispatch, getState) => {
  const lang = selectUrlLanguagePrefix(getState());
  dispatch(makeRedirectAction(RouterActions.navigateToPageWithAsset({ pageAlias, assetId, lang })));
};

export const navigateToSame: ThunkCreator = (navigationType: string, payload: Payload) => (dispatch, getState) => {
  const { lang: _lang, ...restPayload } = payload;
  const lang = selectUrlLanguagePrefix(getState());
  dispatch(makeRedirectAction({ type: navigationType, payload: { ...restPayload, lang } }));
};

export const navigateToSearch: ThunkCreator = (query?: string) => (dispatch, getState) => {
  const lang = selectUrlLanguagePrefix(getState());
  dispatch(makeRedirectAction(RouterActions.navigateToSearch({ term: query, lang })));
};

export const setSlug: ThunkCreator = (slug: string) => (dispatch, getState) => {
  const pageAlias = selectLocationPageAlias(getState());
  changeSlug(pageAlias, slug);
  dispatch(ControlActions.setActiveAnchor(slug));
};

export const navigateToEntity = createAsyncThunk<void, { id: string; type?: string }, { state: RootState }>(
  'navigation/to/entity',
  async ({ id, type }, { dispatch, getState }) => {
    await delay(0);
    try {
      // if no type is provided as a payload, we need to find the entity type
      if (!type) {
        const entityType = selectEntityType(getState(), id);
        if (!entityType) {
          throw new Error(`Undefined type for entity with id: ${id}`);
        }
        type = entityType;
      }

      // in mobile view close sidebar
      if (isLessThanMedium()) {
        dispatch(ControlActions.closeSidebar());
      }

      if (type && isModuleEntity(type)) {
        dispatch(ControlActions.setTargetModule(getModuleUrlIdFromData(id, type)));
      }

      // read language
      const lang = selectUrlLanguagePrefix(getState());

      if (type === ModuleTypes.internalLink) {
        let element = selectMenuElementById(getState(), id);
        if (!element) {
          // element can be undefined if it is not visible in the navigation
          // then it has to be in the unfiltered menu tree
          element = selectUnfilteredMenuElementById(getState(), id);
        }

        if (!element) {
          throw new Error(`No Menu Element with id: ${id}`);
        }

        const pageAlias = selectAliasFromPath(getState(), element.alias);
        dispatch(RouterActions.navigateToPage({ pageAlias, lang }));
        dispatch(ControlActions.setActiveAnchor(''));
        dispatch(MenuActions.openMenuByAlias(element.alias));
        return;
      }

      if (type === ModuleTypes.anchorLink) {
        const entity = selectEntityById(getState(), id);
        if (!entity) {
          throw new Error(`No Entity found with id = ${id}`);
        }

        const entityPath = entity.attributes.page_path || entity.attributes.field_path;
        const slug = isAnchorEntity(entity) && makeSlugForAnchorEntity(entity);

        // use the page_path property of the anchor to redirect to the correct page,
        // if this does not exist this is a newly created anchor entity which the
        // backend hasn't properly tied to it's parent yet, so we will assume the
        // current page alias to be the correct parent
        const pageAlias = selectAliasFromPath(getState(), entityPath);
        const currPageAlias = selectLocationPageAlias(getState());
        const targetAlias = pageAlias || currPageAlias;

        if (!pageAlias) {
          throw new Error(`No path found for entity ${id}`);
        }

        if (pageAlias === currPageAlias) {
          // already on correct page -> just scroll to anchor
          dispatch(setSlug(slug));
          return;
        }

        dispatch(RouterActions.navigateToPageWithAnchor({ pageAlias: targetAlias, slug, lang }));
        dispatch(MenuActions.openMenuByAlias(`/${targetAlias}`));
        return;
      }
    } catch (e) {
      log('Error in navigateToEntity thunk!', e, 'error');
      dispatch(StatusActions.setErrorNotification('Page not found!'));
    }
  },
);

export const navigatedToPage: ThunkCreator = () => async (dispatch, getState) => {
  try {
    const auth = await dispatch(authCheck());
    const location = selectLocationState(getState());
    // if we are not logged in, or if the page that came before was not the same
    // as this one, fetch page and menu again
    if (
      !auth ||
      (location.prev &&
        location.prev.payload &&
        location.payload &&
        location.prev.payload.pageAlias === location.payload.pageAlias)
    ) {
      return;
    }
    // undo any active subnav
    dispatch(ControlActions.setActiveAnchor());
    dispatch(ControlActions.setActiveMenu(null));
    dispatch(ControlActions.setContext());

    // fetch page
    const pagePath = selectLocationPath(getState());
    const currentPageId = selectCurrentPageId(getState());
    await dispatch(fetchPage({ page: pagePath, pageId: currentPageId }));

    // check for slug
    const slug = selectLocationSlug(getState());
    if (slug) {
      dispatch(ControlActions.setActiveAnchor(slug));
    }
  } catch (e) {
    log('Error in navigatedToPage thunk!', e, 'error');
  }
};

export const navigatedToDefault: ThunkCreator = () => async dispatch => {
  try {
    dispatch(ControlActions.setContext());
    const auth = await dispatch(authCheck());
    if (!auth) {
      return;
    }
    void dispatch(navigateToStart());
  } catch (e) {
    log('Error in navigatedToSearch thunk!', e, 'error');
  }
};

export const navigatedToLogin: ThunkCreator = () => (dispatch, getState) => {
  try {
    const userName = selectSessionName(getState());
    if (!userName) {
      return;
    }
    void dispatch(navigateToDefault());
  } catch (e) {
    log('Error in navigatedToLogin thunk!', e, 'error');
  }
};

export const navigatedToLogout: ThunkCreator = () => dispatch => {
  try {
    dispatch(ControlActions.setContext());
    void dispatch(SessionActions.logout());
    dispatch(StatusActions.setSuccessNotification('Logged out succesfully!'));
    void dispatch(navigateToDefault());
  } catch (e) {
    log('Error in navigatedToLogout thunk!', e, 'error');
  }
};

export const navigatedToNotFound: ThunkCreator = () => dispatch => {
  try {
    dispatch(StatusActions.setErrorNotification('Page not found!'));
    void dispatch(navigateToDefault());
  } catch (e) {
    log('Error in navigatedToNotFound thunk!', e, 'error');
  }
};
