import { createAsyncThunk } from '@reduxjs/toolkit';
import { create } from 'superstruct';

import { ModuleTypes } from '$const';
import type { RootState, ThunkCreator } from '$store';
import {
  getMenu,
  getMenuForUpdate,
  getMenuPermissions,
  hydrateMenuDataWithPermissions,
} from '~services/api/calls/menu';
import { MenuElementsValidator } from '~services/api/validators/Menu';
import log from '~services/log';
import { MenuActions } from '~store/actions';
import { selectLocationPath, selectUserPermissions } from '~store/selectors';
import { MenuStatus } from '~store/slices/menu';
import { navigateToEntity, navigateToStart } from './navigation';
import { apiErrorNotification } from './notification';

export const fetchMenuElements = createAsyncThunk<void, void, { state: RootState }>(
  'menu/fetch-elements',
  async (_, { dispatch, getState }) => {
    try {
      // Load menu (menu elements) from API and hydrate it with menu_permissions
      const responseMenu = await getMenu();
      const responseMenuPermissions = await getMenuPermissions();
      const hydratedMenu = hydrateMenuDataWithPermissions(responseMenu, responseMenuPermissions);
      const rootMenu = create(hydratedMenu, MenuElementsValidator);
      dispatch(MenuActions.rootMenuFetched(rootMenu));

      // navigate app to default page = menu element
      const activePath = selectLocationPath(getState());
      dispatch(MenuActions.openMenuByAlias(activePath));
      dispatch(MenuActions.setMenuStatus(MenuStatus.Idle));
      await dispatch(navigateToStart());

      // Also load complete menu (with hidden files/folders) in background
      let responseMenuUnfiltered: MenuElementDto[] = [];
      const userPermissions = selectUserPermissions(getState());
      if (userPermissions.manageMenuElements || userPermissions.manageMenuGrants) {
        responseMenuUnfiltered = await getMenuForUpdate();
      }
      const hydratedMenuUnfiltered = hydrateMenuDataWithPermissions(responseMenuUnfiltered, responseMenuPermissions);
      const unfilteredMenu = create(hydratedMenuUnfiltered, MenuElementsValidator);
      dispatch(MenuActions.unfilteredMenuFetched(unfilteredMenu));
    } catch (e) {
      log('Error in fetchMenuElements thunk!', e, 'error');
    }
  },
);

export const clickMenuElement: ThunkCreator = (node: MenuElement) => dispatch => {
  try {
    dispatch(MenuActions.elementClicked(node));
    if (node.alias !== 'route:<nolink>') {
      void dispatch(navigateToEntity({ id: node.id, type: ModuleTypes.internalLink }));
    }
  } catch (e) {
    dispatch(apiErrorNotification(e));
  }
};
