import TextAreaField from '~components/generic/editable-field/TextareaField';

type Props = {
  label?: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  excludeControls?: string[];
  controls?: string[];
};

export const FormElementTextarea = (props: Props) => {
  return (
    <label>
      {Boolean(props.label) && (
        <div className="block text-left font-sans">
          <span className="text-darkgray text-sm">{props.label}</span>
        </div>
      )}
      <TextAreaField
        value={props.value}
        editing={true}
        onChange={props.onChange}
        excludeControls={props.excludeControls}
        controls={props.controls}
      />
    </label>
  );
};
