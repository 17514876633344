import Upload from '~components/generic/elements/Upload';
import { SidebarContextSettingsFieldInputProps } from '~components/sidebar/context/SidebarContextSettingsField';

export const SidebarContextSettingsUpload = ({
  data,
  fieldDef,
  onChange,
  onUpload,
  fieldName,
}: SidebarContextSettingsFieldInputProps) => {
  const hideUpload = !fieldDef.multiple && data && (data.id || data.length);
  return (
    <div className="c-settings__field">
      <Upload
        label={fieldDef.options.label}
        onChange={onChange}
        value={data}
        onUpload={onUpload}
        fieldName={fieldName}
        options={fieldDef.options}
        multiple={fieldDef.multiple}
        theme="dark"
        hideUpload={hideUpload}
      />
    </div>
  );
};

export default SidebarContextSettingsUpload;
