import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';

const queryClient = new QueryClient();

export type ReactQueryWrapperProps = {
  children: ReactNode;
};

export const ReactQueryWrapper = ({ children }: ReactQueryWrapperProps) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
