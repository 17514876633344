import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => ({
  data: {
    type: 'paragraph--image_link',
    id: createTemporaryEntityId(),
    attributes: {
      langcode: getEntityTemplateLang(),
      status: true,
      parent_type: 'paragraph',
      parent_field_name: 'field_modules',
      field_hide_title: false,
      field_link_external: null,
      field_search_text: null,
      field_text: { value: '', format: 'rich_text' },
      field_title: { value: 'I am a Image Module', format: 'rich_text' },
      field_full_width: false,
      field_caption: null,
      field_image_proportions: 0,
      field_hide_caption: false,
    },
    relationships: {
      field_image: {
        data: null,
      },
    },
  },
});
