import { MetaTag } from '~components/generic/MetaTag/MetaTag';

type TagPackProps = {
  mainTopic?: Topic;
  subTopic?: Topic;
};

export function TagPack({ mainTopic, subTopic }: TagPackProps) {
  return (
    <>
      {Boolean(mainTopic) && (
        <>
          <MetaTag color={mainTopic.color} label={mainTopic.label} />
          {Boolean(subTopic) && (
            <>
              <div className="inline-flex mr-2" />
              <MetaTag color={subTopic.color || mainTopic.color} label={subTopic.label} child />
            </>
          )}
        </>
      )}
    </>
  );
}
