import { Disclosure } from '@headlessui/react';
import { useEffect } from 'react';
import { usePrevious, useUnmount } from 'react-use';

import usePageMeta from '~hooks/usePageMeta';
import usePermissions from '~hooks/usePermissions';
import { MetaBar } from './MetaBar';
import { MetaBarThumbnail } from './MetaBarThumbnail';
import { MetaPanel } from './MetaPanel';
import useEditPageMeta from './useEditPageMeta';

function PanelCloser({ onClose, pageId }: { onClose: () => void; pageId: string }) {
  // Check if the pageId changes and if so then close the MetaPanel
  const prevPageId = usePrevious(pageId);
  useEffect(() => {
    if (!pageId) return;
    if (!prevPageId) return;
    if (pageId !== prevPageId) {
      onClose?.();
    }
  }, [pageId, prevPageId, onClose]);
  useUnmount(() => onClose?.());
  return null;
}

export function MetaHeader() {
  const meta = usePageMeta();
  const { save, isSaving } = useEditPageMeta();
  const { canEditPageMeta } = usePermissions();

  return (
    <Disclosure>
      {({ open, close }) =>
        canEditPageMeta && open ? (
          <Disclosure.Panel as="div">
            <MetaPanel
              onClose={close}
              description={meta.description}
              title={meta.title}
              topics={meta.topics}
              image={meta.image}
              crops={meta.imageCrops}
              onSave={save}
              saving={isSaving}
            />
            <PanelCloser onClose={close} pageId={meta.pageId} />
          </Disclosure.Panel>
        ) : (
          <Disclosure.Button as="div">
            <MetaBar
              openable={canEditPageMeta}
              description={meta.description}
              title={meta.title}
              topics={meta.topics}
              thumbnail={<MetaBarThumbnail imageSrc={meta.image?.url} crop={meta.imageCrops?.square} />}
            />
          </Disclosure.Button>
        )
      }
    </Disclosure>
  );
}
