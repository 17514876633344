import clsx from 'clsx';
import { memo } from 'react';
import Dropzone from 'react-dropzone';

import styles from './PictureStyles.module.css';

export type PictureDropzoneProps = {
  acceptMimeTypes?: string;
  onUpload: (files: File[]) => void;
};

export const PictureDropzone = ({ onUpload, acceptMimeTypes }: PictureDropzoneProps) => (
  <Dropzone onDrop={onUpload} accept={acceptMimeTypes} noDragEventsBubbling={true}>
    {({ getRootProps, getInputProps, isDragActive }) => {
      return (
        <div
          {...getRootProps()}
          className={clsx(
            'dropzone',
            isDragActive && 'dropzone--isActive',
            styles.dropzoneRoot,
            isDragActive && styles.dropzoneActive,
          )}
        >
          <div className={styles.dropzoneContent}>
            <div className="u-padding-vertical-lg u-padding-horizontal-md">
              <input {...getInputProps()} />
              <i className="c-icon-custom c-icon-custom--ic-upload u-size-lg u-block u-padding-bottom-xs" />
              <div>
                <strong>Drag &amp; Drop</strong>
              </div>
              <div className="u-size-sm">a media file to upload (or click to select)</div>
            </div>
          </div>
          <div className={styles.dropzoneFooter}>
            <div className="o-text--right o-text--quiet u-size-sm u-margin-right-sm u-margin-bottom-xs">
              {acceptMimeTypes}
            </div>
          </div>
        </div>
      );
    }}
  </Dropzone>
);

export const MemoizedPictureDropzone = memo(PictureDropzone);
