import { lookupSelector } from '~store/lookupSelector';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectLocationState = lookupSelector(selectRootState, state => state.location);

// Slice: Location Selectors
export const selectLocationPageAlias = lookupSelector(selectLocationState, state => state.payload.pageAlias as string);
export const selectLocationSlug = lookupSelector(selectLocationState, state => state.payload.slug);
export const selectLocationType = lookupSelector(selectLocationState, state => state.type);
export const selectLocationKind = lookupSelector(selectLocationState, state => state.kind);

// custom
export const selectLocationReady = lookupSelector(selectLocationKind, kind => !!kind);
