import { is, optional, record, string, type } from 'superstruct';

import { AddonsValidator } from './Addons';
import { ConfigValidator } from './Config';
import { CookieModalValidator } from './CookieModal';
import { FavouritesValidator } from './Favourites';
import { IconLibraryValidator } from './IconLibrary';
import { LoginInfoValidator } from './LoginInfo';
import { UserInfoValidator } from './UserInfo';
import { WidgetsFooterValidator } from './WidgetsFooter';
import { WidgetsSidebarValidator } from './WidgetsSidebar';

export const SiteInfoValidator = type({
  addons: AddonsValidator,
  configs: ConfigValidator,
  cookieModal: CookieModalValidator,
  favourites: FavouritesValidator,
  iconLibrary: IconLibraryValidator,
  loginInfo: LoginInfoValidator,
  userInfo: UserInfoValidator,
  widgetsFooter: WidgetsFooterValidator,
  widgetsSidebar: WidgetsSidebarValidator,
  translations: optional(record(string(), string())),
});

export const isSiteInfo = (obj: unknown) => is(obj, SiteInfoValidator);
