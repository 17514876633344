import { Component } from 'react';

import log from '~services/log';

interface IErrorBoundaryState {
  hasError: boolean;
}
export default class ErrorBoundary extends Component<unknown, IErrorBoundaryState> {
  state: IErrorBoundaryState = {
    hasError: false,
  };

  componentDidCatch(error: any, info: object) {
    this.setState({ hasError: true });
    log(error as string, info, 'error');
  }

  render() {
    if (this.state.hasError) {
      return <div className="o-text--quiet">Something went wrong!</div>;
    }
    return <>{this.props.children}</>;
  }
}
