import { saveAs } from 'file-saver';
import { useCallback } from 'react';

import { Button } from '~components/generic/editor/Button/Button';
import { ButtonGroup } from '~components/generic/editor/ButtonGroup/ButtonGroup';
import { DeleteIcon, DownloadIcon } from '~components/icons';
import useLabels from '~hooks/useLabels';
import styles from './PictureStyles.module.css';

export type PictureEditMenuProps = {
  onClear: () => void;
  downloadUrl?: string;
};

export const PictureEditMenu = ({ onClear, downloadUrl }: PictureEditMenuProps) => {
  const { msgDownloadImageHint, msgClearImageHint } = useLabels(['msgDownloadImageHint', 'msgClearImageHint']);

  const handleDownloadClick = useCallback(() => {
    saveAs(downloadUrl);
  }, [downloadUrl]);

  return (
    <div className={styles.menuRoot}>
      <ButtonGroup preIcon="image" label="Image">
        {Boolean(downloadUrl) && (
          <Button title={msgDownloadImageHint} icon={<DownloadIcon />} onClick={handleDownloadClick} />
        )}
        <Button title={msgClearImageHint} icon={<DeleteIcon />} onClick={onClear} />
      </ButtonGroup>
    </div>
  );
};
