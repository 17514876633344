import { assert } from 'superstruct';

import { ImageCropsValidator } from '~services/api/validators/ImageCrops';
import log from '~services/log';

export { isPercentCrop, isPixelCrop, PixelCropValidator } from '~services/api/validators/ImageCrops';

/**
 * Sanitizes a given PixelCrop by rounding the values.
 * @param crop PixelCrop
 * @returns PixelCrop
 */
export function sanitizePixelCrop(crop: PixelCrop) {
  return {
    ...crop,
    height: Math.round(crop.height),
    width: Math.round(crop.width),
    x: Math.round(crop.x),
    y: Math.round(crop.y),
  } as PixelCrop;
}

export function makeDefaultCrop(width = 0, height = 0): PixelCrop {
  return {
    unit: 'px',
    x: 0,
    y: 0,
    width,
    height,
  };
}

export function findClosestAspectRatio(options: Record<string, number>, goal: number) {
  const values = Object.values(options);
  const closest = values.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev));
  const goalToClosestDiff = Math.abs(closest - goal);
  const isValidClosest = goalToClosestDiff < 0.01;
  if (isValidClosest) {
    return closest;
  }
  return 0;
}

export const DEFAULT_ASPECT_RATIO_OPTIONS: Record<string, number> = {
  labelAspectRatioFree: 0, // 0 = Zero = represents no fixed aspect ratio
  labelAspectRatio1to1: 1,
  labelAspectRatio2to1: 2,
  labelAspectRatio4to3: 4 / 3,
  labelAspectRatio16to9: 16 / 9,
};

/**
 * Parse and validate stringified ImageCrops.
 * @param rawCrops string - strigified ImageCrops
 * @returns ImageCrops
 */
export function parseImageCrops(rawCrops: string) {
  if (typeof rawCrops === 'string' && rawCrops.length > 0) {
    try {
      const parsedCrops = JSON.parse(rawCrops);
      assert(parsedCrops, ImageCropsValidator);
      return parsedCrops;
    } catch (error) {
      log('Can not parse image crop. Fallback to _undefined_.', { error, rawCrops }, 'warning');
    }
  }
  return;
}
