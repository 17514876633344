import { createContext } from 'react';

import { SetOrRemoveFavouriteApiResponse } from '~extension/favourite/model/SetOrRemoveFavourite';
import FavouriteDto from '../model/FavouriteDto';

const FavouriteContext = createContext<{
  isFavouriteAddonEnabled: () => boolean;
  showFavouritesToggle: boolean;
  onSetShowFavouritesToggle: (show: boolean) => void;
  addContentFavourite: (id: string) => Promise<FavouriteDto[]>;
  removeContentFavourite: (id: string) => Promise<FavouriteDto[]>;
  addAssetFavourite: (id: string) => Promise<SetOrRemoveFavouriteApiResponse>;
  removeAssetFavourite: (id: string) => Promise<SetOrRemoveFavouriteApiResponse>;
}>(null);

export default FavouriteContext;
