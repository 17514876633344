import clsx from 'clsx';
import { CSSProperties, FC } from 'react';

import loaderStyles from '~components/generic/loader/loaderStyles.css';

export interface ILoaderProps {
  theme?: 'light' | 'dark';
  size?: string;
  absoluteCenter?: boolean;
  transparentBG?: boolean;
  className?: {
    [className: string]: boolean;
  };
  style?: CSSProperties;
}

const Loader: FC<ILoaderProps> = ({
  absoluteCenter,
  theme,
  size = 'md',
  className = {},
  style = {},
  transparentBG,
}) => (
  <>
    {transparentBG && <div className={loaderStyles.transparentBg} />}
    <div
      className={clsx({
        [loaderStyles.spinner]: true,
        [loaderStyles[theme]]: true,
        [loaderStyles.absoluteCenter]: absoluteCenter,
        [`u-size-${size}`]: true,
        ...className,
      })}
      style={style}
    >
      <div className={loaderStyles.bounceOne} />
      <div className={loaderStyles.bounceTwo} />
      <div />
    </div>
  </>
);

export default Loader;
