import clsx from 'clsx';
import { PureComponent } from 'react';

import getStore from '$store';
import { FileName } from '~components/generic/filename/FileName';
import { IEntityComponentProps } from '~interfaces/props';
import { getFieldValue } from '~services/entity-helpers';
import { getAbsoluteUrl } from '~store/getter';
import { selectCurrentPageId } from '~store/selectors/custom';

export interface IUploadFilesProps extends IEntityComponentProps {
  onRemove: (id: string) => void;
  theme: 'dark' | 'light';
}

export class UploadFiles extends PureComponent<IUploadFilesProps> {
  handleRemove = () => {
    const { onRemove, data } = this.props;
    onRemove(data.id);
  };

  render() {
    const { data, theme } = this.props;
    const fileMime = getFieldValue<string>(data, 'attributes.filemime');
    const fileName = getFieldValue<string>(data, 'attributes.filename');
    const currentPageId = selectCurrentPageId(getStore().getState());
    const downloadUrl = getAbsoluteUrl('/api/bgapi/file/' + data.id + '/' + currentPageId);
    const btnClasses = clsx({
      'c-button': true,
      'u-size-sm': true,
      'u-padding-vertical-xs': true,
      'u-padding-horizontal-xs': true,
      'c-button--dark': theme === 'dark',
    });

    if (!data) {
      return null;
    }

    return (
      <div className="c-file__display-item o-grid o-grid--unpad u-margin-bottom-sm">
        <div className="o-grid__cell o-grid__cell--width-6">
          <a className="o-text--no-decoration" download href={downloadUrl}>
            <FileName filename={fileName} />
          </a>
        </div>
        <div className="o-grid__cell o-grid__cell--width-4">{fileMime}</div>
        <div className="o-grid__cell o-grid__cell--width-2 o-text--right">
          <button type="button" onClick={this.handleRemove} className={btnClasses}>
            <i className="c-icon-custom c-icon-custom--trash u-size-md" />
            <span className="u-sr-only">Delete</span>
          </button>
        </div>
      </div>
    );
  }
}

export default UploadFiles;
