import clsx from 'clsx';
import type { ChangeEvent, FocusEvent, FormEvent, ForwardedRef } from 'react';
import { forwardRef, useCallback, useState } from 'react';

import useDebouncedCallback from '~hooks/useDebouncedCallback';
import styles from './UserInput.module.css';

type InputTextAreaProps = {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onDebouncedChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  hasChange?: boolean;
};

export const InputTextArea = forwardRef(function InputTextArea(
  { defaultValue, label, placeholder, onChange, onBlur, onDebouncedChange, hasChange = false }: InputTextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  const [rawInput, setRawInput] = useState('');

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      onBlur?.(event.target.value);
    },
    [onBlur],
  );

  const handleDebouncedChange = useDebouncedCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onDebouncedChange?.(event.target.value);
  }, 300);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      handleDebouncedChange(event);
      onChange?.(event.target.value);
    },
    [onChange, handleDebouncedChange],
  );

  const handleInput = useCallback((event: FormEvent<HTMLTextAreaElement>) => {
    setRawInput(event.currentTarget.value);
  }, []);

  return (
    <label className="inline-block w-full text-left font-sans bg-gray rounded shadow px-3 py-2 relative">
      {Boolean(label) && <span className="text-xs opacity-70">{label}</span>}
      <div className={clsx(styles.growWrap, 'text-base font-sans')} data-replicated-value={rawInput}>
        <textarea
          className="block appearance-none bg-transparent border-none outline-none text-base w-full font-sans p-0 m-0"
          defaultValue={defaultValue}
          placeholder={placeholder}
          rows={4}
          ref={ref}
          onBlur={handleBlur}
          onChange={handleChange}
          onInput={handleInput}
        />
      </div>
      <span
        className={clsx(
          'absolute rounded-full bg-red bottom-full left-full shadow-sm transition-all -mb-1 -ml-1 duration-100',
          hasChange ? 'w-2 h-2' : 'w-0 h-0',
        )}
      />
    </label>
  );
});
