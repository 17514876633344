type SidebarUserToggleEditingProps = {
  isEditing: boolean;
  onEnableEditing: () => void;
  onDisableEditing: () => void;
  disabled?: boolean;
};

export const SidebarUserToggleEditing = ({
  isEditing = false,
  onEnableEditing,
  onDisableEditing,
  disabled,
}: SidebarUserToggleEditingProps) => {
  const handleToggle = () => {
    if (isEditing) {
      onDisableEditing();
    } else {
      onEnableEditing();
    }
  };

  return (
    <div className="c-user__toggle-editing">
      <label className="c-toggle">
        <input disabled={disabled} type="checkbox" checked={isEditing} onChange={handleToggle} />
        <div className="c-toggle__track">
          <div className="c-toggle__handle" />
        </div>
      </label>
    </div>
  );
};

export default SidebarUserToggleEditing;
