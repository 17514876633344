import { connect } from 'react-redux';

import type { RootState } from '$store';
import { IPDFDownloadProps, PDFDownload } from '~components/page/PDFDownload';
import { selectAddonsIsPdfEnabled, selectCurrentPageNid } from '~store/selectors';
import { generatePdf } from '~store/thunks/pdf';

type StateProps = Pick<IPDFDownloadProps, 'enabled' | 'pageId'>;
const mapStateToProps = (state: RootState): StateProps => ({
  enabled: selectAddonsIsPdfEnabled(state),
  pageId: selectCurrentPageNid(state) as string, // ToDo: is it a boolean or an string?
});

type DispatchProps = Pick<IPDFDownloadProps, 'generatePagePDF'>;
const mapDispatchToProps: DispatchProps = {
  generatePagePDF: generatePdf,
};

export const PDFDownloadContainer = connect(mapStateToProps, mapDispatchToProps)(PDFDownload);
export default PDFDownloadContainer;
