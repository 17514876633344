import { getEntityTemplateLang } from '~services/entity-definitions/helpers';
import { createTemporaryEntityId } from '~services/entity-definitions/template-helpers';

export default () => {
  return {
    data: {
      type: 'paragraph--media_gallery',
      id: createTemporaryEntityId(),
      attributes: {
        langcode: getEntityTemplateLang(),
        status: true,
        default_langcode: true,
        field_configuration: null,
      },
      relationships: {},
      links: {},
      log: [],
      permissions: {
        update: true,
        create: true,
        delete: true,
      },
    },
  };
};
