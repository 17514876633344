import { useCallback, useRef, useState } from 'react';

import { ImageCropDialog } from '~components/generic/image-crop/ImageCropDialog';
import useEffectWhen from '~hooks/useEffectWhen';
import useLabels from '~hooks/useLabels';
import { ChangeIndicator } from './ChangeIndicator';
import { ClearMetaImageButton } from './ClearMetaImageButton';
import { MetaImageUploader } from './MetaImageUploader';
import { MetaPanelThumbnail } from './MetaPanelThumbnail';

enum CROP_TYPE {
  RECT,
  SQUARE,
}

type ImageSelectorProps = {
  image: MetaImage;
  crops: ImageCrops;
  onChangeCrops: (crops: ImageCrops) => void;
  onChangeImage: (image: MetaImage) => void;
};

export function ImageSelector({ crops, image, onChangeCrops, onChangeImage }: ImageSelectorProps) {
  const { labelMetaImageAspectRatioRect, labelMetaImageAspectRatioSquare } = useLabels([
    'labelMetaImageAspectRatioRect',
    'labelMetaImageAspectRatioSquare',
  ]);
  const [cropRect, setCropRect] = useState(crops?.rect);
  const [cropSquare, setCropSquare] = useState(crops?.square);
  const [showImageCropDialog, setShowImageCropDialog] = useState(false);
  const [hasCropRectChange, setHasCropRectChange] = useState(false);
  const [hasCropSquareChange, setHasCropSquareChange] = useState(false);

  // temporary data bag
  const cropBag = useRef<{
    which: CROP_TYPE;
    crop: PixelCrop;
    aspectRatio: number;
  }>();

  // handler
  const handleImageClick = useCallback(
    (cropType: CROP_TYPE) => {
      cropBag.current = {
        which: cropType,
        crop: cropType === CROP_TYPE.RECT ? cropRect : cropSquare,
        aspectRatio: cropType === CROP_TYPE.RECT ? 2 : 1,
      };
      setShowImageCropDialog(true);
    },
    [cropRect, cropSquare],
  );

  const handleImageCrop = useCallback((crop?: PixelCrop) => {
    if (crop) {
      if (cropBag.current.which === CROP_TYPE.RECT) {
        setCropRect(crop);
        setHasCropRectChange(true);
      }
      if (cropBag.current.which === CROP_TYPE.SQUARE) {
        setCropSquare(crop);
        setHasCropSquareChange(true);
      }
    }
    cropBag.current = undefined;
    setShowImageCropDialog(false);
  }, []);

  function handleImageChange(image: MetaImage) {
    onChangeImage(image);
    setHasCropRectChange(true);
    setHasCropSquareChange(true);
  }

  // update listener
  useEffectWhen(
    () => {
      // when crop dialog closes, update crop state in parent comp
      if (!showImageCropDialog) {
        onChangeCrops({ rect: cropRect, square: cropSquare });
      }
    },
    [cropRect, cropSquare, onChangeCrops, showImageCropDialog],
    [showImageCropDialog],
  );

  const imageSrc = image?.url;

  return (
    <>
      {!imageSrc && (
        <div className="flex w-full">
          <div className="w-full bg-gray flex items-center justify-center rounded shadow relative overflow-hidden relative">
            <div className="w-1/3">
              <div className="aspect-ratio-1/1 flex items-center justify-center" />
            </div>
            <div className="absolute inset-0">
              <MetaImageUploader onChange={handleImageChange} />
            </div>
          </div>
        </div>
      )}
      {imageSrc && (
        <div className="flex w-full justify-between">
          <div className="w-1/3 mr-2">
            <ChangeIndicator as="div" className="relative" hasChange={hasCropSquareChange}>
              <div
                className="aspect-ratio-1/1 bg-gray flex items-center justify-center rounded shadow relative overflow-hidden cursor-pointer"
                onClick={() => handleImageClick(CROP_TYPE.SQUARE)}
              >
                <div className="absolute inset-0">
                  <MetaPanelThumbnail crop={cropSquare} imageSrc={imageSrc} />
                </div>
              </div>
            </ChangeIndicator>
            <div className="text-sm mt-2">{labelMetaImageAspectRatioSquare}</div>
          </div>
          <div className="w-2/3 ml-2">
            <ChangeIndicator as="div" className="relative" hasChange={hasCropRectChange}>
              <div
                className="aspect-ratio-2/1 bg-gray flex items-center justify-center rounded shadow relative overflow-hidden cursor-pointer"
                onClick={() => handleImageClick(CROP_TYPE.RECT)}
              >
                <div className="absolute inset-0">
                  <MetaPanelThumbnail crop={cropRect} imageSrc={imageSrc} />
                </div>
              </div>
            </ChangeIndicator>
            <div className="mt-2 flex text-sm justify-between">
              <div>{labelMetaImageAspectRatioRect}</div>
              <ClearMetaImageButton onClick={() => handleImageChange(undefined)} />
            </div>
          </div>
        </div>
      )}
      {showImageCropDialog && (
        <ImageCropDialog
          open={true}
          imgSrc={imageSrc}
          crop={cropBag.current.crop}
          onCrop={handleImageCrop}
          defaultAspectRatio={cropBag.current.aspectRatio}
          aspectRatioOptions={false}
        />
      )}
    </>
  );
}
