import { lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PageLayout } from '~components/page/PageLayout';
import useAppSelector from '~hooks/useAppSelector';
import useLabels from '~hooks/useLabels';
import { selectAddonsIsTankLabellingEnabled } from '~store/selectors';
import { navigateToDefault } from '~store/thunks/navigation';

const TankLabelingExtension = lazy(() => import('~extension/tank-labelling/TankLabeling'));

export const TankLabellingPage = () => {
  const { hlPageTruckLabeling } = useLabels(['hlPageTruckLabeling']);
  const dispatch = useDispatch();
  // read store values
  const isEnabled = useAppSelector(selectAddonsIsTankLabellingEnabled);

  console.log({ isEnabled });

  useEffect(() => {
    if (!isEnabled) {
      dispatch(navigateToDefault());
    }
  }, [dispatch, isEnabled]);

  return (
    <PageLayout title={hlPageTruckLabeling} className="page--truck-labeling">
      <div className="c-view__content relative flex-grow overflow-hidden">
        <div className="h-full">
          <Suspense fallback="loading">
            <TankLabelingExtension />
          </Suspense>
        </div>
      </div>
    </PageLayout>
  );
};
