import clsx from 'clsx';
import { useCallback } from 'react';

import Button from '~components/generic/elements/Button';
import useAppDispatch from '~hooks/useAppDispatch';
import { ControlActions } from '~store/actions';
import styles from './SidebarExpander.module.css';

export type SidebarExpanderProps = {
  collapsed: boolean;
};

export function SidebarExpander({ collapsed }: SidebarExpanderProps) {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(ControlActions.openSidebar());
  }, [dispatch]);

  return (
    <Button
      className={clsx(
        'u-size-sm u-margin-top-sm c-button c-button--dark u-padding-vertical-xs u-padding-horizontal-xs c-sidebar-expander',
        {
          [styles.base]: true,
          [styles.sidebarIsCollapsed]: collapsed,
          [styles.sidebarIsNotCollapsed]: !collapsed,
        },
      )}
      label="Collapse sidebar"
      hideLabel={true}
      iconName="burger"
      iconSize="lg"
      onClick={handleClick}
    />
  );
}

export default SidebarExpander;
