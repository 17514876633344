import clsx from 'clsx';
import { MouseEvent, ReactNode, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// import { triggerNavigateByLinkAction } from 'state/actions/navigation';
import { ModuleTypes, ROUTER_PAGE, ROUTER_PAGE_WITH_ANCHOR } from '$const';
import type { RootState } from '$store';
import Button from '~components/generic/elements/Button';
import RouterLink from '~components/generic/RouterLink';
import { convertToRawValue } from '~services/data-conversion';
import { entityIsOfType, makeSlugForAnchorEntity } from '~services/entity-helpers';
import { getDataFromInternalUrl } from '~services/location-helpers';
import { selectEntityWithChangesById, selectUrlLanguagePrefix } from '~store/selectors';
import { selectMenuElementById } from '~store/selectors/menu';
import { navigateToEntity } from '~store/thunks/navigation';
import { trimLeadingSlash } from '~utils/string';
import stripHtml from '~utils/stripHtml';

export type LinkProps = {
  label?: string;
  url?: string;
  id?: string;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  language?: string;
  pageAlias?: string;
  slug?: string;
};

const LinkComponent = ({ label, url, id, className, children, language, pageAlias, onClick, slug }: LinkProps) => {
  const handleOnClick = useCallback(
    (evt: MouseEvent) => {
      if (onClick) {
        evt.preventDefault();
        onClick();
      }
    },
    [onClick],
  );

  if (id && children) {
    // Internal Link

    return (
      <RouterLink
        to={
          pageAlias
            ? {
                type: slug ? ROUTER_PAGE_WITH_ANCHOR : ROUTER_PAGE,
                payload: { lang: language, pageAlias, slug },
              }
            : '#'
        }
        className={className}
        onClick={handleOnClick}
      >
        {children}
      </RouterLink>
    );
  } else if (id) {
    // Internal Link but as Button
    return (
      <Button
        className={clsx('c-button c-button--dark u-margin-top-lg', className)}
        label={label}
        onClick={handleOnClick}
      />
    );
  } else if (children) {
    // External Link
    return (
      <RouterLink className={className} target="_blank" rel="noopener noreferrer" to={url}>
        {children}
      </RouterLink>
    );
  } else {
    // Button
    return (
      <Button
        className={clsx('c-button c-button--dark u-margin-top-lg', className)}
        href={url}
        target={url.substring(0, 4).toLowerCase() === 'http' ? '_blank' : '_self'}
        rel="noopener noreferrer"
        label={label || url}
      />
    );
  }
};

export type LinkContainerProps = {
  id?: string;
  label?: string;
};

export type StateProps = Pick<LinkProps, 'language' | 'pageAlias' | 'label' | 'slug'>;
const mapStateToProps = (state: RootState, ownProps: LinkContainerProps): StateProps => {
  const language = selectUrlLanguagePrefix(state);
  let pageAlias: string;
  let slug: string;
  let label = ownProps.label;

  if (ownProps.id && ownProps.id !== '-- none --') {
    const { id: uuid, type } = getDataFromInternalUrl(ownProps.id);

    if (uuid && type === ModuleTypes.anchorLink) {
      const entity = selectEntityWithChangesById(state, uuid);
      if (entity) {
        pageAlias = trimLeadingSlash(entity.attributes.page_path);
        slug = makeSlugForAnchorEntity(entity);
        if (!label) {
          if (entityIsOfType(entity, ModuleTypes.anchorLink)) {
            label = stripHtml(convertToRawValue(entity.attributes.field_title?.value || '', 'html'));
          }
        }
      }
    } else {
      // item is not loaded as entity -> it's a menu element
      const menuElement = selectMenuElementById(state, uuid);
      if (menuElement) {
        // We have to check if the menuElement exists because it could have been deleted
        pageAlias = trimLeadingSlash(menuElement.alias);
        if (!label) {
          label = menuElement.title;
        }
      }
    }
  }

  return {
    language,
    pageAlias,
    label,
    slug,
  };
};

export type DispatchProps = Pick<LinkProps, 'onClick'>;
function mapDispatchToProps(dispatch: Dispatch, ownProps: LinkContainerProps): DispatchProps {
  return bindActionCreators(
    {
      onClick: () => {
        if (!ownProps.id) return;
        const urlData = getDataFromInternalUrl(ownProps.id);
        return navigateToEntity({
          id: urlData.id,
          type: urlData.type === ModuleTypes.anchorLink ? ModuleTypes.anchorLink : ModuleTypes.internalLink,
        });
      },
    },
    dispatch,
  );
}

export const LinkContainer = connect(mapStateToProps, mapDispatchToProps)(LinkComponent);

export const Link = LinkContainer;
export default Link;
