import { createElement, ElementType, Fragment, ReactNode } from 'react';

type ChangeIndicatorProps = {
  children: ReactNode;
  hasChange?: boolean;
  className?: string;
  as?: ElementType;
};

export function ChangeIndicator({ children, hasChange = false, className, as = Fragment }: ChangeIndicatorProps) {
  return createElement(as, { className }, [
    children,
    <span
      key="indicator"
      className={`absolute rounded-full bg-red bottom-full left-full shadow-sm transition-all -mb-1 -ml-1 duration-100 ${
        hasChange ? 'w-2 h-2' : 'w-0 h-0'
      }`}
    />,
  ]);
}
