import { CroppedImage } from '~components/generic/image-crop/CroppedImage';
import { ThumbnailIcon } from '~components/icons';

type MetaPanelThumbnailProps = {
  imageSrc?: string;
  crop: PixelCrop;
};

export function MetaPanelThumbnail({ imageSrc, crop }: MetaPanelThumbnailProps) {
  if (imageSrc) {
    return <CroppedImage src={imageSrc} crop={crop} alt="Thumbnail" className="object-contain w-full h-full" />;
  }

  return (
    <div className="w-14 h-15 text-black opacity-30">
      <ThumbnailIcon />
    </div>
  );
}
