import { connect } from 'react-redux';

import type { RootState } from '$store';
import { Modal, ModalProps } from '~components/generic/modal/Modal';
import { ModalActions } from '~store/actions';
import { selectModal } from '~store/selectors/modal';

export type StateProps = Pick<ModalProps, 'modalConfig'>;
const mapStateToProps = (state: RootState): StateProps => ({
  modalConfig: selectModal(state),
});

export type DispatchProps = Pick<ModalProps, 'closeModal'>;
const mapDispatchToProps: DispatchProps = {
  closeModal: ModalActions.hide,
};

export const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(Modal);
export default ModalContainer;
